import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import classnames from "classnames";
import "../../App.css";
import { useEffect, useState } from "react";
import Select from "react-dropdown-select";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import dayjs from "dayjs";
import TableRow from "@mui/material/TableRow";
import TablePaginationActions from "../../utils/pagination";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { salesQuotationValidation } from "../../utils/Validation";
import { useNavigate, useParams } from "react-router";
import _ from "lodash";
import {
  customerData,
  customersSelector,
} from "../../store/reducer/CustomerReducer";
import {
  productData,
  productsSelector,
} from "../../store/reducer/ProductReducer";
import {
  salesinvoiceSelector,
  AddSalesinvoice,
  clearErrormsg,
  clearSinvLoadingDatas,
  salesinvoiceDataByID,
  Insertcreditinvoice,
  getInvoicecr_drReducer,
} from "../../store/reducer/SalesInvoiceReducer";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { API_STATUS, paymentTypewithcheck } from "../../utils/constants";
const SalesInvoiceCredit = () => {
  const [activeTab, setActiveTab] = useState("5");
  const dispatch = useDispatch();
  const params = useParams();
  const [editID, setEditID] = useState(params?.id);
  const [Cproducts, setCproducts] = useState([]);
  const { customerDatas } = useSelector(customersSelector);
  const { productDatas } = useSelector(productsSelector);
  const {
    insertcdloading,
    errorMessage,
    salesinvoiceDatasByID,
    invoice_cr_dr_num,
    getInvoicecrloading,
  } = useSelector(salesinvoiceSelector);
  console.log(invoice_cr_dr_num, "dawere3ta");
  let todayDate = new Date();
  let [companyDetails, setCompanyDetails] = useState({});
  console.log(companyDetails, "companyDetails");
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(salesQuotationValidation),
  });
  const navigate = useNavigate();
  let [sortBy, setSortBy] = useState("customer_name");
  let [activesortBy, setActiveSortBy] = useState("customer_name");
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("asc");
  let [searchQuery, setSearchQuery] = useState("");
  const [iban, setIban] = useState(false);
  const [selectedOption, setSelectedOption] = useState("simple");
  const [getIbanNo, setGetIbanNo] = useState(null);
  const [enableEdit, setEnableEdit] = useState(false);
  const handleOptionChange = (e) => {
    console.log(e.target.value, "7uihj");
    setSelectedOption(e.target.value);
  };
  //   let [result, setResult] = useState(0);
  //   let [vatresult, setvatresult] = useState(0);
  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
  };

  const handleSave = () => {
    console.log(customerDatas, "companyDetails");
    console.log(companyDetails, "companyDetails");
    companyDetails.invoice_type = 1;
    // selectedOption == "simple" ? 0 : 1;
    if (iban == false) {
      setGetIbanNo(null);
    }
    if (getIbanNo != null && iban == false) {
      companyDetails.customer_iban = getIbanNo;
    }
    // delete companyDetails.products[index].vat_amt
    let postData = companyDetails;
    delete postData?.payment_status;
    delete postData?.payment_balance;
    delete postData?.id;
    delete postData?.invoice_id;
    delete postData?.delete_products;
    postData.invoice_main_id = editID;
    postData.credit_debit_type = 381;
    postData.status = 5;
    console.log(postData, "56789");
    // return false;
    if (postData.credit_note_no == "")
      toastr.error("Credit Note number is required!");
    else if (postData.credit_reason == "")
      toastr.error("Credit Note reason is required!");
    else if (postData.payment_mode == "" || postData.payment_mode == null)
      toastr.error("Pay mode is required!");
    else if (postData?.products.length == 0)
      toastr.error("Products is required!");
    else dispatch(Insertcreditinvoice({ postData }));
    // toastr.success("suceess")
    // navigate("/sales");
  };

  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
  };

  const handleDeleteRow = (id, row_id) => {
    console.log(id, row_id, "id");
    if (companyDetails.products.length != 1) {
      console.log(companyDetails.products, "compprods");
      // companyDetails.products.splice(id, 1);
      console.log(companyDetails.products, "compprods");
      if (row_id) {
        let deleteids = companyDetails.delete_products ?? [];
        console.log(deleteids);
        deleteids.push(row_id);
        console.log(deleteids);
        companyDetails.delete_products = deleteids;
      }
      setCompanyDetails({
        ...companyDetails,
      });
      companyDetails.products = companyDetails.products.filter(
        (item, i) => i !== id
      );
      // setCompanyDetails((prevGroup) => ({
      //   ...prevGroup,
      //   products: prevGroup.products.filter((item, i) => i !== id),
      // }));
      console.log(companyDetails.products, "compprods");
      // rowCalculation()
      calculateDetails();
    }
  };

  const [invoiceval, setInvoiceVal] = useState("");
  useEffect(() => {
    dispatch(productData({}));
    dispatch(customerData({}));
    dispatch(salesinvoiceDataByID({ editID }));
  }, []);
  // useEffect(()=>{

  // }, [])
  useEffect(() => {
    const fetchAndProcessData = async () => {
      if (salesinvoiceDatasByID) {
        const payload = {
          invoice_NO: salesinvoiceDatasByID?.invoice_no,
          credit_debit_type: 381,
        };
        console.log(payload, "65789op");
        dispatch(
          getInvoicecr_drReducer({
            payload,
          })
        );
        // setInvoiceVal(salesinvoiceDatasByID?.invoice_no)
        salesinvoiceDatasByID?.invoice_type === 1
          ? setSelectedOption("normal")
          : setSelectedOption("simple");
        // salesinvoiceDatasByID.sales_invoice_details?.tax=="15"? companyDetails?.products[index].tax:""
        if (
          salesinvoiceDatasByID?.status !== undefined &&
          salesinvoiceDatasByID?.status !== null
        ) {
          // setEnableEdit(salesinvoiceDatasByID.status === 3);
        }

        console.log(
          JSON.parse(JSON.stringify(salesinvoiceDatasByID)),
          "sales_invoice_details"
        );
        let data1 = JSON.parse(JSON.stringify(salesinvoiceDatasByID));
        console.log(data1, "sales_invoice_details");
        delete data1.qr;
        delete data1.invoice_hash;
        let {
          sales_invoice_details,
          updated_at,
          trash,
          status,
          created_at,
          ...others
        } = data1;
        // let companydata =
        console.log(sales_invoice_details, "sales_invoice_details");
        const sales_invoice_details_data = sales_invoice_details.map(
          async (item) => {
            // delete item.updated_at;
            // delete item.created_at;
            // delete item.invoice_id;
            let { updated_at, created_at, invoice_id, ...others } = item;
            return await others;
          }
        );
        console.log({ ...others, sales_invoice_details }, "others");
        const processedItems = await Promise.all(sales_invoice_details_data);
        const productIds = processedItems.map((product) => product.product_id);
        setCproducts(productIds);
        console.log(processedItems, "sales_invoice_details_data");
        console.log("sales_invoice_details_data");
        companyDetails = {
          products: processedItems,
          ...others,
          // delete_products: [],
        };
        setCompanyDetails({
          products: processedItems,
          ...others,
          // delete_products: [],
        });
        // calculateDetails()
      }
    };
    console.log(salesinvoiceDatasByID, "salesinvoiceDatasByID");
    fetchAndProcessData();
  }, [salesinvoiceDatasByID]);

  useEffect(() => {
    console.log(Cproducts, "Cp");
  }, [Cproducts]);
  const selectedOptions = paymentTypewithcheck.filter(
    (option) =>
      option.value === companyDetails?.payment_mode ||
      salesinvoiceDataByID?.payment_mode
  );
  console.log(selectedOptions, "678ukj");
  // useEffect(() => {
  //   console.log(companyDetails, "cpdetails");
  //   if (companyDetails && companyDetails?.products) {
  //     const index = companyDetails?.products.length - 1;
  //     if (
  //       companyDetails.products[index]?.product_id !== "" &&
  //       companyDetails.products[index]?.product_id !== undefined
  //     ) {
  //       companyDetails.products.push({
  //         product_id: "",
  //         qty: 0,
  //         price: 0,
  //         amount: 0,
  //         tax: 0,
  //         discount: 0,
  //         type: 0,
  //         total_days: 0,
  //         month_rate: 0,
  //         // vat: "",
  //         // vat_amt: 0.0,
  //         additional_description: "",
  //       });
  //       setCompanyDetails({
  //         ...companyDetails,
  //       });
  //     }
  //   }
  // }, [companyDetails]);
  const calculateDetails = () => {
    let subTotal = 0;
    let totalTaxAmount = 0;
    let totalDiscount = 0;
    let vatRate = companyDetails.tax !== "" ? companyDetails.tax : 0;
    let delete_products = companyDetails?.delete_products ?? [];
    // console.log(      "deleted_prods",delete_products);
    companyDetails.products.forEach((product) => {
      // console.log(product, "6789p");
      // const foundItem = _.indexOf(delete_products, product.id);
      // console.log(
      //   "deleted_prods",
      //   _.indexOf(delete_products, product.id), product.id
      // );
      let productTotal = product.price * product.qty;

      productTotal -= product.discount;

      let productVatAmount = (productTotal * product.tax) / 100;

      subTotal += productTotal;

      totalTaxAmount += productVatAmount;
      totalDiscount = product.discount + product.discount;
    });

    setCompanyDetails({
      ...companyDetails,
      sub_total: subTotal,
      tax_amount: totalTaxAmount,
      total: subTotal + totalTaxAmount,
      discount: totalDiscount,
    });
  };

  // const calculateDetails = () => {
  //   let result = 0;
  //   let vatresult = 0;
  //   if (companyDetails.tax !== "") {
  //     vatresult = companyDetails.tax;
  //   }
  //   console.log(companyDetails.products, "sadsad");
  //   for (let index = 0; index < companyDetails.products.length; index++) {
  //     const element = companyDetails.products[index];
  //     result += parseFloat(
  //       parseFloat(element?.price) * parseFloat(element?.qty)
  //     );
  //     console.log(
  //       parseFloat(element?.price) * parseFloat(element?.qty),
  //       result,
  //       "sadsad"
  //     );
  //     // if (element?.vat_amt !== "") { vat_result += parseFloat(element?.vat_amt) };
  //   }
  //   let vat_amt = parseFloat((result * vatresult) / 100).toFixed(2);
  //   setCompanyDetails({
  //     ...companyDetails,
  //     sub_total: result,
  //     tax_amount: vat_amt,
  //     total: parseFloat(result) + parseFloat(vat_amt),
  //     round_amount: 0,
  //   });
  // };

  //   let result = 0;
  //   let vatresult = 0;
  //   for (let index = 0; index < companyDetails.products.length; index++) {
  //     const element = companyDetails.products[index];
  //     if (typeof element?.amount == "number")
  //       result += parseFloat(element?.amount);
  //     // if (element?.vat_amt !== "") { vat_result += parseFloat(element?.vat_amt) };
  //   }
  //   useEffect(() => {
  //     let vat_amt=parseFloat(result*vatresult/100).toFixed(2);
  //     setCompanyDetails({
  //       ...companyDetails,
  //       total_amt: result,
  //       vat_amt: vat_amt,
  //       net_amount: parseFloat(result) + parseFloat(vat_amt),
  //       round_off: 0,
  //     });
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [result, vatresult]);

  const rowCalculation = (index) => {
    let data = companyDetails.products[index];
    if (data.type == 0) {
      companyDetails.products[index].price = (
        companyDetails.products[index].month_rate / data.total_days
      ).toFixed(2);
      companyDetails.products[index].amount = (
        companyDetails.products[index].price * data.qty
      ).toFixed(2);
      companyDetails.products[index].vat_amt = (
        data.qty *
        (data.tax / 100) *
        companyDetails.products[index].price
      ).toFixed(2);
    } else if (data.type == 1) {
      companyDetails.products[index].price = (
        companyDetails.products[index].month_rate / data.qty
      ).toFixed(2);
      companyDetails.products[index].amount = (
        companyDetails.products[index].price * data.qty
      ).toFixed(2);
      companyDetails.products[index].vat_amt = (
        data.qty *
        (data.tax / 100) *
        companyDetails.products[index].price
      ).toFixed(2);
    } else if (data.type == 2 || data.type == 3) {
      if (companyDetails.products[index].month_rate == 0 || data.qty == 0) {
        companyDetails.products[index].price = (0).toFixed(2);
      } else {
        companyDetails.products[index].price =
          companyDetails.products[index].month_rate;
      }
      companyDetails.products[index].amount = (
        companyDetails.products[index].price * data.qty
      ).toFixed(2);
      companyDetails.products[index].vat_amt = (
        data.qty *
        (data.tax / 100) *
        companyDetails.products[index].price
      ).toFixed(2);
    }
    setCompanyDetails({
      ...companyDetails,
    });
  };

  const handleInput = (e, dest, type) => {
    const { value } = e.target;
    companyDetails[dest] = type !== "" ? Number(value) : value;
    setCompanyDetails({
      ...companyDetails,
    });
  };

  useEffect(() => {
    if (insertcdloading === API_STATUS.FULFILLED) {
      toastr.clear();
      toastr.success("Credit Note Invoice Created Successfully!");
      dispatch(clearSinvLoadingDatas());
      navigate("/sales_invoicelist");
      // reset();
    }
    if (getInvoicecrloading === API_STATUS.FULFILLED) {
      toastr.clear();
      console.log(invoice_cr_dr_num, "invoice_num");
      setCompanyDetails({
        ...companyDetails,
        credit_note_no: invoice_cr_dr_num?.io_num,
      });
      dispatch(clearSinvLoadingDatas());
      // reset();
    }
    if (errorMessage) {
      toastr.clear();
      if (errorMessage.message) toastr.error(errorMessage.message);
      else if (errorMessage.errors) {
        let data = "";
        errorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        toastr.error(data);
      }
      dispatch(clearErrormsg());
    }
  }, [insertcdloading, getInvoicecrloading]);

  const Customerdrop = () => {
    if (companyDetails.customer_id) {
      let fdata = _.filter(customerDatas, { id: companyDetails.customer_id });
      console.log(fdata, "fdata");
      return fdata;
    } else {
      return [];
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-content-end gap-3">
            <Button
              className="waves-effect waves-light btn btn-primary mb-2"
              onClick={() => {
                navigate("/sales_invoicelist/edit/" + editID);
              }}
            >
              Back to Invoice
            </Button>
          </div>
          <div className="d-flex justify-content-between">
            <h4 className="page-title">Credit Note</h4>
            <div
              className="d-flex justify-content-between"
              style={{ minWidth: "400px" }}
            >
              <div></div>
              <h5>
                Net Amount: <span>{companyDetails?.total}</span>
              </h5>
            </div>
          </div>
          <Card>
            <CardBody>
              <Row>
                <Col md={4} className="d-none">
                  <div className="d-flex">
                    <div
                      className="radios"
                      style={{
                        display: "flex",
                        transform: "none",
                        paddingLeft: "28px",
                      }}
                    >
                      <input
                        type="radio"
                        name="option"
                        value="simple"
                        checked={selectedOption === "simple"}
                        onChange={handleOptionChange}
                        disabled={enableEdit}
                      />
                      <label
                        htmlFor="simple"
                        style={{
                          transform: "none",
                          margin: "0",
                          position: "static",
                          paddingLeft: "10px",
                        }}
                      >
                        Simple
                      </label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        transform: "none",
                        paddingLeft: "28px",
                      }}
                    >
                      <input
                        type="radio"
                        name="option"
                        value="normal"
                        checked={selectedOption === "normal"}
                        onChange={handleOptionChange}
                        disabled={enableEdit}
                      />
                      <label
                        htmlFor="normal"
                        style={{
                          transform: "none",
                          margin: "0",
                          position: "static",
                          paddingLeft: "10px",
                        }}
                      >
                        Normal
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <div className="d-flex align-items-end">
                    <div class="input-container w-100">
                      <Select
                        placeholder="Customer"
                        className="js-states select-control"
                        options={customerDatas ? customerDatas : []}
                        disabled={enableEdit}
                        dropdownHandle={true}
                        searchable={true}
                        labelField={"customer_name"}
                        valueField={"id"}
                        values={Customerdrop()}
                        name="country_name"
                        onChange={(value) => {
                          companyDetails.customer_id = value[0].id;
                          setCompanyDetails({
                            ...companyDetails,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4">
                  <div className="input-container">
                    <Controller
                      name="payment_mode"
                      control={control}
                      render={({ field }) => (
                        <Select
                          className="js-states select-control"
                          placeholder={"Pay Mode"}
                          options={paymentTypewithcheck}
                          {...field}
                          disabled={enableEdit}
                          dropdownHandle={true}
                          labelField="mode"
                          values={selectedOptions}
                          searchable={true}
                          valueField={"value"}
                          name="payment_mode"
                          onChange={(value) => {
                            companyDetails.payment_mode = value[0]?.value;
                            setCompanyDetails({
                              ...companyDetails,
                            });
                            // if (
                            //   value[0].value == 30 ||
                            //   selectedOption[0].value == 30
                            // ) {
                            //   setIban(true);
                            // } else {
                            //   setIban(false);
                            // }
                          }}
                        />
                      )}
                      // {...register("payment_mode")}
                    />
                  </div>
                  {errors?.pay_mode?.message && (
                    <p className="error">{errors.pay_mode.message}</p>
                  )}
                </Col>
                {iban && (
                  <Col md="4" className="d-none">
                    <div class="input-container">
                      <input
                        placeholder=" "
                        type="text"
                        disabled={enableEdit}
                        // disabled
                        // value={companyDetails.invoice_no}
                        onChange={(e) => setGetIbanNo(e.target.value)}
                        name="invoice_no"
                      />
                      <label className="required" for="customer_iban">
                        IBAN ACC NO
                      </label>
                    </div>
                  </Col>
                )}
                <Col md="4">
                  <div className="d-flex align-items-end">
                    <div class="input-container w-100">
                      <input
                        placeholder=" "
                        type="datetime-local"
                        // value={dayjs(companyDetails.invoice_date).format(
                        //   "YYYY-MM-DD"
                        // )}
                        value={dayjs(companyDetails.invoice_date).format(
                          "YYYY-MM-DDTHH:mm"
                        )}
                        id="date"
                        onChange={(e) => handleInput(e, "invoice_date", "")}
                        name="date"
                        disabled={enableEdit}
                      />
                      <label for="date">Date</label>
                    </div>
                  </div>
                </Col>
                <Col md="4">
                  <div class="input-container">
                    <input
                      placeholder=" "
                      type="text"
                      value={companyDetails.invoice_no}
                      onChange={(e) => handleInput(e, "invoice_no", "")}
                      name="invoice_no"
                      disabled={enableEdit}
                    />
                    <label className="required" for="invoice_no">
                      Invoice No
                    </label>
                  </div>
                </Col>
                <Col md="4">
                  <div class="input-container">
                    <input
                      placeholder=" "
                      type="text"
                      value={companyDetails.credit_note_no}
                      onChange={(e) => handleInput(e, "credit_note_no", "")}
                      name="credit_note_no"
                      disabled={enableEdit}
                    />
                    <label className="required" for="credit_note_no">
                      Credit Note No
                    </label>
                  </div>
                </Col>
                <Col md="4">
                  <div class="input-container">
                    <input
                      placeholder=" "
                      type="text"
                      value={companyDetails.credit_reason}
                      onChange={(e) => handleInput(e, "credit_reason", "")}
                      name="credit_reason"
                      disabled={enableEdit}
                    />
                    <label className="required" for="credit_reason">
                      Reason
                    </label>
                  </div>
                </Col>
              </Row>
              {/* <Row>
                                  <Col md="4">
                                      <div class="input-container">
                                          <input
                                              placeholder=" "
                                              type="text"
                                              value={companyDetails.customer_po_no}
                                              onChange={(e) => handleInput(e, "customer_po_no", "")}
                                              name="customer_po_no"
                                          />
                                          <label className="required" for="customer_po_no">Customer PO No</label>
                                      </div>
                                  </Col>
                                  <Col md="4">
                                      <div class="input-container">
                                          <input
                                              placeholder=" "
                                              type="date"
                                              className="required"
                                              defaultValue="2020-03-19"
                                              value={companyDetails.po_date}
                                              id="po_date"
                                              onChange={(e) => handleInput(e, "po_date", "")}
                                              name="po_date"
                                          />
                                          <label for="po_date">PO Date</label>
                                      </div>
                                  </Col>
                                  <Col md="4">
                                      <div className="d-flex align-items-end">
                                          <div class="input-container w-100">
                                              <input
                                                  placeholder=" "
                                                  type="text"
                                                  id="delivery_no"
                                                  name="delivery_no"
                                                  value={companyDetails.delivery_no}
                                                  onChange={(e) => handleInput(e, "delivery_no", "")}
                                              />
                                              <label for="delivery_no">Delivery No</label>
                                          </div>
                                      </div>
                                  </Col>
                              </Row> */}
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <div className="table-responsive react-table">
                <TableContainer
                  component={Paper}
                  style={{ minHeight: "300px" }}
                >
                  <Table sx={{ minWidth: 700 }} stickyHeader>
                    <TableHead className="table-light table-nowrap">
                      <TableRow>
                        <StyledTableCell align="">
                          Service Description
                          <br />
                          <small>sent For zatca</small>
                        </StyledTableCell>
                        {/* <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "product_add_desc" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("product_add_desc");
                            }}
                          >
                            Additional Description
                          </TableSortLabel>
                        </StyledTableCell> */}
                        <StyledTableCell align="">Type</StyledTableCell>
                        <StyledTableCell align="">
                          Total Days/Hours
                        </StyledTableCell>
                        <StyledTableCell align="">Rate</StyledTableCell>
                        <StyledTableCell align="">
                          Worked Days / OT Hours
                          <br />
                          <small>sent For zatca</small>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          Per Day Price
                          <br />
                          <small>sent For zatca</small>
                        </StyledTableCell>
                        {/* <StyledTableCell align="">
                                                      <TableSortLabel
                                                          active={sortBy == "vat" ? true : false}
                                                          direction={order}
                                                          className="table-header-column-name"
                                                          onClick={(e) => {
                                                              sortByKey("vat");
                                                          }}
                                                      >
                                                          VAT
                                                      </TableSortLabel>
                                                  </StyledTableCell>
                                                  <StyledTableCell align="">
                                                      <TableSortLabel
                                                          active={sortBy == "vat_amt" ? true : false}
                                                          direction={order}
                                                          className="table-header-column-name"
                                                          onClick={(e) => {
                                                              sortByKey("vat_amt");
                                                          }}
                                                      >
                                                          VAT Amount
                                                      </TableSortLabel>
                                                  </StyledTableCell> */}
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "untax_amt" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("untax_amt");
                            }}
                          >
                            Amount
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "tax" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("tax");
                            }}
                          >
                            VAT PERCENT
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="" className=" d-none">
                          <TableSortLabel
                            active={sortBy == "discount" ? true : false}
                            direction={order}
                            className="table-header-column-name  d-none"
                            onClick={(e) => {
                              sortByKey("discount");
                            }}
                          >
                            Discount
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "amount" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("amount");
                            }}
                          >
                            Net Amount
                          </TableSortLabel>
                        </StyledTableCell>
                        {/* </StyledTableCell> */}
                        <StyledTableCell align="">Action</StyledTableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {companyDetails.products &&
                        companyDetails.products.map((row, index) => (
                          <StyledTableRow>
                            <StyledTableCell align="">
                              <Controller
                                name="Product Description"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    className="js-states select-control"
                                    options={productDatas ? productDatas : []}
                                    {...field}
                                    disabled={enableEdit}
                                    dropdownHandle={true}
                                    searchable={true}
                                    labelField={"description"}
                                    valueField={"id"}
                                    values={
                                      row.product_id
                                        ? _.filter(productDatas, {
                                            id: row.product_id,
                                          })
                                        : []
                                    }
                                    name="country_name"
                                    onChange={(value) => {
                                      // console.log(value, "products");
                                      if (value.length > 0) {
                                        companyDetails.products[
                                          index
                                        ].product_id = value[0].id;
                                        setCompanyDetails({
                                          ...companyDetails,
                                        });
                                      }
                                    }}
                                  />
                                )}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <div class="input-container w-100 m-1">
                                <Select
                                  className="js-states select-control"
                                  options={[
                                    { id: 0, name: "Days" },
                                    { id: 1, name: "OT hours - Fixed" },
                                    { id: 3, name: "OT hours - Calc" },
                                    { id: 2, name: "OT days" },
                                  ]}
                                  id={`type${index}`}
                                  name={`type${index}`}
                                  placeholder="Work Type"
                                  disabled={false}
                                  dropdownHandle={true}
                                  searchable={true}
                                  labelField={"name"}
                                  valueField={"id"}
                                  values={
                                    row.type
                                      ? _.filter(
                                          [
                                            { id: "0", name: "Days" },
                                            {
                                              id: "1",
                                              name: "OT hours - Fixed",
                                            },
                                            {
                                              id: "3",
                                              name: "OT hours - Calc",
                                            },
                                            { id: "2", name: "OT days" },
                                          ],
                                          {
                                            id: row.type,
                                          }
                                        )
                                      : []
                                  }
                                  onChange={(value) => {
                                    let updatedProducts = [
                                      ...companyDetails.products,
                                    ];
                                    updatedProducts[index].type = value[0]?.id;
                                    if (value[0]?.id == 1) {
                                      // companyDetails.products[
                                      //   index
                                      // ].total_days = 0;
                                      // companyDetails.products[
                                      //   index
                                      // ].month_rate = 0;
                                      // companyDetails.products[index].qty = 0;
                                    } else {
                                      // companyDetails.products[
                                      //   index
                                      // ].total_days = 0;
                                      // companyDetails.products[
                                      //   index
                                      // ].month_rate = 0;
                                      // companyDetails.products[index].qty = 0;
                                    }
                                    setCompanyDetails({
                                      ...companyDetails,
                                      products: updatedProducts,
                                    });
                                    rowCalculation(index);
                                    calculateDetails();
                                  }}
                                />
                                <datalist id={`type-options-${index}`}>
                                  <option value="Addtional"></option>
                                </datalist>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell className="d-none" align="">
                              <div class="input-container w-100 m-1">
                                <input
                                  placeholder=" "
                                  type="text"
                                  id={`additional_description${index}`}
                                  name={`additional_description${index}`}
                                  value={row.additional_description}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    companyDetails.products[
                                      index
                                    ].additional_description = value;
                                    setCompanyDetails({
                                      ...companyDetails,
                                    });
                                    calculateDetails();
                                  }}
                                  disabled={enableEdit}
                                />
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <div class="input-container w-100 m-1">
                                <input
                                  placeholder=" "
                                  type="number"
                                  id={`total_days${index}`}
                                  name={`total_days${index}`}
                                  disabled={
                                    companyDetails.products[index].type == 1 ||
                                    companyDetails.products[index].type == 2 ||
                                    companyDetails.products[index].type == 3
                                      ? true
                                      : false
                                  }
                                  value={row.total_days}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    companyDetails.products[index].total_days =
                                      value;
                                    setCompanyDetails({
                                      ...companyDetails,
                                    });
                                    rowCalculation(index);
                                    calculateDetails();
                                  }}
                                />
                                <datalist id={`total_days-options-${index}`}>
                                  <option value="Addtional"></option>
                                </datalist>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <div class="input-container w-100 m-1">
                                <input
                                  placeholder=" "
                                  type="text"
                                  id={`month_rate${index}`}
                                  name={`month_rate${index}`}
                                  value={row.month_rate}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    companyDetails.products[index].month_rate =
                                      value;
                                    setCompanyDetails({
                                      ...companyDetails,
                                    });
                                    rowCalculation(index);
                                    calculateDetails();
                                  }}
                                />
                                <datalist id={`month_rate-options-${index}`}>
                                  <option value="Addtional"></option>
                                </datalist>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <div class="input-container w-100 m-1">
                                <input
                                  placeholder=" "
                                  type="text"
                                  id={`quantity${index}`}
                                  name={`quantity${index}`}
                                  value={row.qty}
                                  disabled={enableEdit}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    // let data = companyDetails.products[index];
                                    // companyDetails.products[index].qty = value;
                                    // companyDetails.products[index].amount =
                                    //   data.price * value;
                                    // setCompanyDetails({
                                    //   ...companyDetails,
                                    // });
                                    // calculateDetails();

                                    companyDetails.products[index].qty = value;
                                    setCompanyDetails({
                                      ...companyDetails,
                                    });
                                    rowCalculation(index);
                                    calculateDetails();
                                  }}
                                />
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <div class="input-container w-100 m-1">
                                <input
                                  placeholder=" "
                                  type="text"
                                  id={`unit_price${index}`}
                                  name={`unit_price${index}`}
                                  value={row.price}
                                  disabled={enableEdit}
                                  // onChange={(e) => {
                                  //   const { value } = e.target;
                                  //   let data = companyDetails.products[index];
                                  //   companyDetails.products[index].price =
                                  //     value;
                                  //   companyDetails.products[index].amount =
                                  //     value * data.qty;
                                  //   // companyDetails.products[index].vat_amt = (data.quantity * (data.vat / 100) * value).toFixed(4)
                                  //   setCompanyDetails({
                                  //     ...companyDetails,
                                  //   });
                                  //   calculateDetails();
                                  // }}
                                  // onChange={(e) => {
                                  //   const { value } = e.target;
                                  //   let data = companyDetails.products[index];
                                  //   companyDetails.products[index].price =
                                  //     value;
                                  //   companyDetails.products[index].amount =
                                  //     value * data.qty;
                                  //   companyDetails.products[index].vat_amt = (
                                  //     data.quantity *
                                  //     (data.vat / 100) *
                                  //     value
                                  //   ).toFixed(4);
                                  //   setCompanyDetails({
                                  //     ...companyDetails,
                                  //   });
                                  //   calculateDetails();
                                  // }}
                                />
                                {/* <label for={`${index}`}></label> */}
                              </div>
                            </StyledTableCell>
                            {/* <StyledTableCell align="">
                                                              <div class="input-container w-100 m-1">
                                                                  <Select
                                                                      className="js-states select-control"
                                                                      options={[{ id: 15, name: "VAT 15%" }]}
                                                                      placeholder="VAT"
                                                                      disabled={false}
                                                                      dropdownHandle={true}
                                                                      searchable={true}
                                                                      labelField={"name"}
                                                                      valueField={"id"}
                                                                      name="country_name"
                                                                      onChange={(value) => {
                                                                          let data = companyDetails.products[index];
                                                                          companyDetails.products[index].vat = value[0].id
                                                                          let result = (data.unit_price * (value[0].id / 100)) * data.quantity;
                                                                          companyDetails.products[index].vat_amt = result.toFixed(4);
                                                                          // let ans =
                                                                          //     companyDetails.products[index].unit_price * 
                                                                          setCompanyDetails({
                                                                              ...companyDetails,
                                                                          });
                                                                      }}
                                                                  />
                                                              </div>
                                                          </StyledTableCell>
                                                          <StyledTableCell align="">
                                                              <div class="input-container w-100 m-1">
                                                                  <input
                                                                      value={companyDetails.products[index]?.vat_amt}
                                                                      placeholder=" "
                                                                      disabled
                                                                      type="text"
                                                                      id={`vat_amount${index}`}
                                                                      name={`vat_amount${index}`}
                                                                  />
                                                              </div>
                             
                             </StyledTableCell> */}
                            <StyledTableCell>
                              <div class="input-container w-100 m-1">
                                <input
                                  type="text"
                                  placeholder=" "
                                  id="tax"
                                  name="taxamout"
                                  disabled
                                  value={companyDetails.products[index].amount}
                                />
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {/* {row?.tax??0} */}
                              <div class="input-container w-100 m-1">
                                <Select
                                  className="js-states select-control"
                                  options={[
                                    { id: 15, name: "VAT 15%" },
                                    { id: 0, name: "No VAT " },
                                  ]}
                                  id={`tax${index}`}
                                  name={`tax${index}`}
                                  placeholder="VAT Percent"
                                  disabled={false}
                                  dropdownHandle={true}
                                  searchable={true}
                                  labelField={"name"}
                                  values={
                                    row?.tax
                                      ? _.filter(
                                          [
                                            { id: 15, name: "VAT 15%" },
                                            { id: 0, name: "No VAT" },
                                          ],
                                          {
                                            id: parseInt(row?.tax ?? 0),
                                          }
                                        )
                                      : [{ id: 0, name: "No VAT" }]
                                  }
                                  // values={row?.tax}
                                  valueField={"id"}
                                  onChange={(value) => {
                                    console.log(value, "12345");
                                    let updatedProducts = [
                                      ...companyDetails.products,
                                    ];
                                    updatedProducts[index].tax = value[0]?.id;
                                    setCompanyDetails({
                                      ...companyDetails,
                                      products: updatedProducts,
                                    });
                                    calculateDetails();
                                  }}
                                />
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="" className=" d-none">
                              <div class="input-container w-100 m-1">
                                <input
                                  placeholder=" "
                                  type="text"
                                  id={`discount${index}`}
                                  name={`discount${index}`}
                                  value={
                                    companyDetails?.products[index].discount
                                  }
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    companyDetails.products[index].discount =
                                      Number(value);
                                    setCompanyDetails({
                                      ...companyDetails,
                                    });
                                    calculateDetails();
                                  }}
                                />
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <div class="input-container w-100 m-1">
                                {/* <input
                                  value={companyDetails.products[index]?.amount}
                                  placeholder=" "
                                  disabled
                                  type="text"
                                  id={`amount${index}`}
                                  name={`amount${index}`}
                                  onChange={(e) => {
                                    console.log("object");
                                    calculateDetails();
                                  }}
                                /> */}
                                <input
                                  type="text"
                                  placeholder=" "
                                  id="net_amount"
                                  name="net_amount"
                                  disabled
                                  value={
                                    companyDetails.products[index].amount -
                                    companyDetails.products[index].discount +
                                    ((companyDetails.products[index].amount -
                                      companyDetails.products[index].discount) *
                                      companyDetails.products[index].tax) /
                                      100
                                  }
                                />

                                {/* <label for={`${index}`}></label> */}
                              </div>
                            </StyledTableCell>

                            {/* <StyledTableCell align="">
                              <div class="input-container w-100 m-1">
                                <input
                                  value={companyDetails.products[index]?.amount}
                                  placeholder=" "
                                  disabled
                                  type="text"
                                  id={`amount${index}`}
                                  name={`amount${index}`}
                                  onChange={(e) => {
                                    console.log("object");
                                    calculateDetails();
                                  }}
                                />
                                <label for={`${index}`}></label>
                              </div>
                            </StyledTableCell> */}
                            {/* <StyledTableCell align="">
                                                                {dayjs(row.created_at).format(
                                                                    "MM-DD-YYYY hh:mm A"
                                                                )}
                                                            </StyledTableCell> */}
                            <StyledTableCell align="" className="button-items">
                              <button
                                className="btn btn-danger delete-btn"
                                disabled={enableEdit}
                                onClick={() => {
                                  handleDeleteRow(index, row?.id);
                                }}
                                title="Delete"
                              >
                                <i className="ri-delete-bin-line"></i>
                              </button>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between">
                <div className="invoice-width">
                  <div class="input-container w-100">
                    <textarea
                      id="net_amount"
                      rows={3}
                      name="net_amount"
                      onChange={(e) => handleInput(e, "customer_notes", "")}
                      required
                      disabled={enableEdit}
                    />
                    <label for="net_amount">Customer Notes</label>
                  </div>
                </div>
                <div className="invoice-width">
                  <div class="input-container w-100">
                    <input
                      placeholder=" "
                      type="text"
                      id="total_amount"
                      name="total_amount"
                      value={companyDetails.sub_total}
                      disabled
                      required
                    />
                    <label for="total_amount">Total Amount</label>
                  </div>
                  <div class="input-container w-100 d-none">
                    <input
                      placeholder=" "
                      type="text"
                      id="discount"
                      name="discount"
                      value={companyDetails?.discount}
                      disabled
                    />
                    <label for="discount">Discount</label>
                  </div>
                  <div className="input-container w-100"></div>
                  {/* <div class="input-container w-100">
                    <Select
                      className="js-states select-control"
                      options={[{ id: 15, name: "VAT 15%" }]}
                      placeholder="VAT Percent"
                      values={
                        companyDetails.tax
                          ? _.filter([{ id: 15, name: "VAT 15%" }], {
                              id: companyDetails.tax,
                            })
                          : []
                      }
                      // disabled={enableEdit}
                      dropdownHandle={true}
                      searchable={true}
                      labelField={"name"}
                      valueField={"id"}
                      name="country_name"
                      onChange={(value) => {
                        companyDetails.tax = value[0].id;
                        setCompanyDetails({
                          ...companyDetails,
                        });
                        calculateDetails();
                      }}
                    />
                  </div> */}
                  <div class="input-container w-100">
                    <input
                      placeholder=" "
                      type="text"
                      id="vat_amount"
                      name="vat_amount"
                      disabled
                      value={companyDetails?.tax_amount}
                    />
                    <label for="vat_amount">VAT Amount</label>
                  </div>
                  <div class="input-container w-100 d-none">
                    <input
                      placeholder=" "
                      type="text"
                      id="round_off"
                      name="round_off"
                      disabled
                      value={companyDetails.round_amount}
                    />
                    <label for="round_off">Round Off</label>
                  </div>
                  <div class="input-container w-100">
                    <input
                      placeholder=" "
                      type="text"
                      id="net_amount"
                      name="net_amount"
                      disabled
                      value={companyDetails.total}
                    />
                    <label for="net_amount">Net Amount</label>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <div className="text-end">
            <Button
              color="primary"
              disabled={enableEdit}
              className=" waves-effect waves-light me-1"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
};
export default SalesInvoiceCredit;
