import React, { useMemo, useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { API_STATUS } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import {
  productData,
  UpdateProduct,
  DeleteProduct,
  AddProduct,
  productsSelector,
  clearProdLoadingDatas,
  clearErrormsg,
} from "../../store/reducer/ProductReducer";
//Import Breadcrumb
import { Card, CardBody, Container } from "reactstrap";
import { products } from "../../common/data/ecommerce";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import Breadcrumb from "../Common/Breadcrumb";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { usersSelector } from "../../store/reducer/UserReducer";

const ProductList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [sortBy, setSortBy] = useState("description");
  let [activesortBy, setActiveSortBy] = useState("description");
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("asc");
  let [searchQuery, setSearchQuery] = useState("");
  const [userActions, setUserActions] = useState({});
  const MySwal = withReactContent(Swal);
  const {
    productDatas,
    productCount,
    loading,
    addloading,
    deleteloading,
    updateloading,
    errorMessage,
  } = useSelector(productsSelector);
  const { selectScreenData } = useSelector(usersSelector);

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
    productDispatch();
  };

  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    productDispatch();
  };
  const searchData = (e) => {
    searchQuery = e.target.value;
    setSearchQuery(e.target.value);
    setPage(0);
    productDispatch();
  };

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
    productDispatch();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: "desciption",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Product Category",
        accessor: "product_category",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Product Type",
        accessor: "product_type",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Stock Unit",
        accessor: "stock_unit",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Part No",
        accessor: "part_no",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Selling Price",
        accessor: "selling_price",
        disableFilters: true,
        filterable: false,
      },
    ],
    []
  );

  const breadcrumbItems = [
    { title: "Tables", link: "/" },
    { title: "Data Tables", link: "#" },
  ];
  useEffect(() => {
    productDispatch();
  }, []);

  const productDispatch = () => {
    dispatch(
      productData({
        query: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
        sortby: sortBy,
        order: order,
      })
    );
  };

  const tog_fullscreen = () => {
    navigate("/product_add");
  };

  const deleteProduct = (row) => {
    //setshowAdd(true);
    //setUpdateID(row.id);
    const deleteID = row.id;
    MySwal.fire({
      title: "Are You Sure to Delete Product?",
      icon: "warning",
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(DeleteProduct({ deleteID }));
      }
    });
  };
  useEffect(() => {
    if (selectScreenData && selectScreenData.length > 0) {
      const value = selectScreenData[0]?.actions;
      setUserActions(value || {});
    }
  }, [selectScreenData]);
  useEffect(() => {   
    if (deleteloading === API_STATUS.FULFILLED) {
      toastr.clear();
      toastr.success("Product Deleted Successfully!");
      productDispatch();
      dispatch(clearProdLoadingDatas());
    }
    if (errorMessage) {
      toastr.clear();
      if (errorMessage.message) toastr.error(errorMessage.message);
      else if (errorMessage.errors) {
        let data = "";
        errorMessage?.errors?.map((err) => {
          data += err.message + " ";
        });
        toastr.error(data);
      }
      dispatch(clearErrormsg());
    }
  }, [addloading, updateloading, errorMessage, deleteloading]);
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-content-between">
            <h2>Products </h2>
            {userActions["Service Inventory-add"] && (
              <button
                type="button"
                className="waves-effect waves-light btn btn-primary mb-2"
                onClick={tog_fullscreen}
              >
                Add
              </button>
            )}
          </div>
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-md-8"></div>
                <div className="col-md-4 row">
                  <input
                    type="text"
                    name="search"
                    className="form-control"
                    style={{ float: "right" }}
                    value={searchQuery}
                    onChange={(e) => {
                      searchData(e);
                    }}
                    placeholder="Search Products"
                  />
                </div>
              </div>
              <div className="table-responsive react-table">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} stickyHeader>
                    <TableHead className="table-light table-nowrap">
                      <TableRow>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "description" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("description");
                            }}
                          >
                            Product Name
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "product_category" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("product_category");
                            }}
                          >
                            Product Category
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "product_type" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("product_type");
                            }}
                          >
                            Product Type
                          </TableSortLabel>
                        </StyledTableCell>
                        {/* <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "part_no" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("part_no");
                            }}
                          >
                            Part NO
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "selling_price" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("selling_price");
                            }}
                          >
                            Selling Price
                          </TableSortLabel>
                        </StyledTableCell> */}
                        <StyledTableCell align="">Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {productDatas &&
                        productDatas.map((row) => (
                          <StyledTableRow>
                            <StyledTableCell align="">
                              {row?.description}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row.product_category}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row.product_type}
                            </StyledTableCell>
                            {/* <StyledTableCell align="">
                              {row.part_no}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row.selling_price}
                            </StyledTableCell> */}
                            <StyledTableCell align="" className="button-items">
                              {/* <a
                                href="javascript:void(0);"
                                title="Edit"
                                className="btn btn-primary  "
                              > */}
                              {/* </a> */}
                              {userActions["Service Inventory-edit"] && (
                              <Link to={`edit/${row.id}`} title="Edit"
                                className="btn btn-primary  ">
                                <i className=" ri-ball-pen-line"></i>
                              </Link>
                               )}
                              {userActions["Service Inventory-delete"] && (
                              <a
                                href="javascript:void(0);"
                                title="Delete"
                                className="btn btn-danger"
                                onClick={(e) => {
                                  deleteProduct(row);
                                }}
                              >
                                <i className="ri-delete-bin-line"></i>
                              </a>
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50]}
                          colSpan={8}
                          count={productCount}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default ProductList;
