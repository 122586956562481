import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Social Profile Images
// falgs
import usFlag from "../../assets/images/flags/us.jpg";
import saudi from "../../assets/images/flags/saudi.png";
import germany from "../../assets/images/flags/germany.jpg";
import italy from "../../assets/images/flags/italy.jpg";
import russia from "../../assets/images/flags/russia.jpg";

function Language() {
  const username = "Admin";
  const [state, setState] = useState({
    menu: false,
    lng: "English",
    flag: usFlag,
  });
  const toggle = () => {
    setState((prevState) => ({
      menu: !prevState.menu,
    }));
  };

  const changeLanguageAction = (lng) => {
    //set the selected language to i18n

    if (lng === "ar") setState({ lng: "Arabic", flag: saudi });
    else if (lng === "eng") setState({ lng: "English", flag: usFlag });
  };

  return (
    <>
      {/* <Dropdown
        isOpen={state.menu}
        toggle={toggle}
        className="d-none d-sm-inline-block"
      >
        <DropdownToggle tag="button" className="btn header-item waves-effect">
          <img
            className=""
            src={usFlag}
            alt="Header Language"
            height="16"
          />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            active={state.lng === "English" ? true : false}
            href=""
            onClick={() => changeLanguageAction("eng")}
            className="notify-item"
          >
            <img src={usFlag} alt="user" className="me-1" height="12" />{" "}
            <span className="align-middle">English</span>
          </DropdownItem>

          <DropdownItem
            href=""
            active={state.lng === "Arabic" ? true : false}
            onClick={() => changeLanguageAction("ar")}
            className="notify-item"
          >
            <img src={saudi} alt="user" className="me-1" height="12" />{" "}
            <span className="align-middle">Arabic</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown> */}
    </>
  );
}
export default Language;
