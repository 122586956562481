import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "react-dropdown-select";
import { useNavigate, Link } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { customerValidationSchema } from "../../utils/Validation";
import  * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  CardHeader,
  Collapse,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
//Import Breadcrumb
import { products } from "../../common/data/ecommerce";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import Breadcrumb from "../Common/Breadcrumb";
import dayjs from "dayjs";
import {
  customerData,
  UpdateCustomer,
  DeleteCustomer,
  customersSelector,
  clearData,
  clearCustUpdateLoading,
  clearCustLoadingDatas,
  clearCustDeleteLoading,
  clearErrormsg,
} from "../../store/reducer/CustomerReducer";
import {
  clearSinvLoadingDatas,
  clearvatDetails,
  salesOrderList,
  salesOrderListAll,
  salesinvoiceSelector,
  vatOrderListAll,
} from "../../store/reducer/SalesInvoiceReducer";
import {
  invoiceStatus,
  paymentStatus,
  zatcaStatus,
} from "../../utils/constants";

const VatReport = () => {
  const dispatch = useDispatch();
  let [sortBy, setSortBy] = useState("");
  let [activesortBy, setActiveSortBy] = useState("");
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("asc");
  let [searchQuery, setSearchQuery] = useState("");
  const [state, setState] = useState({ modal_fullscreen: false });
  const [accord, setAccord] = useState({
    col1: false,
    col2: false,
    col3: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(customerValidationSchema),
  });
  const {
    customerDatas,
    customerCount,
    loading,
    addloading,
    deleteloading,
    updateloading,
    errorMessage,
  } = useSelector(customersSelector);
  const { salesOrders, vatOrdersAll } = useSelector(salesinvoiceSelector);
  // console.log(salesOrders, salesOrdersAll, "45678");
  const navigate = useNavigate();
  const [stockReport, setStockReport] = useState({
    from_date: "",
    to_date: "",
    id: 0,
  });
  const [value, setValue] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [quotationXcelDetails, setQuotationXcelDetails] = useState([]);

  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("");
  const [selectedZatcaStatus, setSelectedZatcaStatus] = useState("");
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState("");
  const [quotationDetails, setQuotationDetails] = useState([]);
  console.log(
    value,
    selectedInvoiceStatus,
    selectedCustomer,
    selectedPaymentStatus,
    "657ui"
  );
  let totBillAmt = 0;
  for (let index = 0; index < quotationDetails.length; index++) {
    const element = quotationDetails[index];
    totBillAmt += element.pay_amount;
  }
  console.log(quotationDetails, "quotationDetails");

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
    dispatch(
      salesOrderList({
        query: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
        sortby: sortBy,
        order: order,
        from_date: value?.[0] ?? "",
        to_date: value?.[1] ?? "",
        customer_id: selectedCustomer ?? "",
        payment_status: selectedPaymentStatus ?? "",
        invoice_status: selectedInvoiceStatus ?? "",
        zatca_status: selectedZatcaStatus ?? "",
      })
    );
  };

  const showButton = (formData) => {
    // console.log(formData.target, "5yuio")
    // const type = formData.type;
    // setType(type);
    // setState({
    //   from_date: formData.from_date,
    //   to_date: formData.to_date,
    // });
    if (selectedInvoiceStatus == "" && selectedZatcaStatus != "") {
      toastr.error("select invoice status!");
    } else {
      // dispatch(
      //   salesOrderList({
      //     query: searchQuery,
      //     page: page + 1,
      //     limit: rowsPerPage,
      //     sortby: sortBy,
      //     order: order,
      //     from_date: value?.[0] ?? "",
      //     to_date: value?.[1] ?? "",
      //     customer_id: selectedCustomer ?? "",
      //     payment_status: selectedPaymentStatus ?? "",
      //     invoice_status: selectedInvoiceStatus ?? "",
      //     zatca_status: selectedZatcaStatus ?? "",
      //   })
      // );
      dispatch(
        vatOrderListAll({
          query: searchQuery,
          page: page + 1,
          limit: 0,
          sortby: sortBy,
          order: order,
          from_date: value?.[0] ?? "",
          to_date: value?.[1] ?? "",
          customer_id: selectedCustomer ?? "",
          payment_status: selectedPaymentStatus ?? "",
          invoice_status: selectedInvoiceStatus ?? "",
          zatca_status: selectedZatcaStatus ?? "",
        })
      );
    }
  };
  const customerDispatch = () => {
    salesOrderList({
      query: searchQuery,
      page: page + 1,
      limit: 0,
      sortby: sortBy,
      order: order,
      from_date: value?.[0] ?? "",
      to_date: value?.[1] ?? "",
      customer_id: selectedCustomer ?? "",
      payment_status: selectedPaymentStatus ?? "",
      invoice_status: selectedInvoiceStatus ?? "",
      zatca_status: selectedZatcaStatus ?? "",
    });
  };
  const showButton1 = () => {
    setQuotationDetails([
      // {
      //   id: 1,
      //   quotation_no: 1211,
      //   customer_name: "Quinton",
      //   quotation_date: "01-11-2022",
      //   rfq_ref_date: "01-11-2022",
      //   bill_amount: 1645.0,
      //   rfq_doc_no: 1822,
      //   notes: "",
      //   status: 1,
      // },
    ]);
  };
  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const handleCustomerChange = (selectedOption) => {
    setSelectedCustomer(selectedOption[0]?.id);
  };

  const handlePaymentStatusChange = (selectedOption) => {
    setSelectedPaymentStatus(selectedOption[0]?.value);
  };
  const handleInvoiceStatusChange = (selectedOption) => {
    setSelectedInvoiceStatus(selectedOption[0]?.value);
  };
  const handleZatcaStatusChange = (selectedOption) => {
    setSelectedZatcaStatus(selectedOption[0]?.value);
  };
  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(
      salesOrderList({
        query: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
        sortby: sortBy,
        order: order,
        from_date: value?.[0] ?? "",
        to_date: value?.[1] ?? "",
        customer_id: selectedCustomer ?? "",
        payment_status: selectedPaymentStatus ?? "",
        invoice_status: selectedInvoiceStatus ?? "",
        zatca_status: selectedZatcaStatus ?? "",
      })
    );
  };
  const searchData = (e) => {
    searchQuery = e.target.value;
    setSearchQuery(e.target.value);
    setPage(0);
  };
  const sortByKey = (key) => {
    console.log(key, "567890-");
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
    dispatch(
      salesOrderList({
        query: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
        sortby: sortBy,
        order: order,
        from_date: value?.[0] ?? "",
        to_date: value?.[1] ?? "",
        customer_id: selectedCustomer ?? "",
        payment_status: selectedPaymentStatus ?? "",
        invoice_status: selectedInvoiceStatus ?? "",
        zatca_status: selectedZatcaStatus ?? "",
      })
    );
    // customerDispatch();
  };

  useEffect(() => {
    dispatch(
      customerData({
        query: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
        // sortby: sortBy,
        order: order,
      })
    );
    dispatch(clearSinvLoadingDatas());
  }, []);

  useEffect(() => {
    if (vatOrdersAll) {
      window.location.href=vatOrdersAll.url;
      dispatch(clearvatDetails());

      // setQuotationXcelDetails(xcelData);
    }
    console.log(vatOrdersAll, "vatOrdersAll");
  }, [vatOrdersAll]);

  useEffect(() => {
    console.log(salesOrders, "salesOrders");
  }, [salesOrders]);
  const t_col1 = () => {
    setAccord({ col1: !accord.col1, col2: false, col3: false });
  };
  const t_col2 = () => {
    setAccord({ col1: false, col2: !accord.col2, col3: false });
  };

  const onSubmitHandler = (formData) => {
    console.log({ formData });
    //dispatch(signInScheduler({ formData }));
    // reset();
  };
  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const breadcrumbItems = [
    { title: "Tables", link: "/" },
    { title: "Data Tables", link: "#" },
  ];

  const exportFile1 = useCallback(async () => {
    const response = await fetch('https://crmapi.digital-garage.in/uploads/VAT%20Report.xlsx'); // Adjust path if using different folder
    console.log(response)
      const blob = await response.blob();
      const reader = new FileReader();
      let jsonData ="";

      reader.onload = (e) => {
        console.log(e)
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        console.log(firstSheetName)
        const worksheet = workbook.Sheets[firstSheetName];
        console.log(worksheet)
        //  jsonData = XLSX.utils.sheet_to_json(worksheet);
         if (worksheet) {
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          // setData(jsonData);
        } else {
          console.error('Worksheet not found');
        }
         console.log(jsonData,"json")
        // setData(jsonData);
      };
      reader.readAsBinaryString(blob);
      console.log(jsonData,"json")
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    const keys = Object.keys(ws);
    const CaptKeys = keys.slice(keys.indexOf("A1"), -1);
    CaptKeys.map((ele) => {
      ws[ele].v = ws[ele]?.v?.toLocaleUpperCase().split("_").join(" ");
    });
    XLSX.writeFile(wb, `VAT Report.xlsx`);
    // const workbook = XLSX.utils.book_new();

    // // Create a worksheet from the existing data and prepare to add new data
    // const existingData = jsonData;
    // const emptyRows = Array.from({ length: 9 }, () => ({})); // 9 empty rows
    // const finalData = [...emptyRows, ...jsonData]; // Combine empty rows with actual data

    // const newWorksheet = XLSX.utils.json_to_sheet(finalData);
    // XLSX.utils.book_append_sheet(workbook, newWorksheet, 'Sheet1');

    // // Create a new Excel file with the same name
    // XLSX.writeFile(workbook, "updated.xlsx");
  }, [quotationXcelDetails]);

  const exportFile = async () => {
    const jsonData =[
      {
        name: "Item 1",
        price: 10,
        image: "https://picsum.photos/200/300"
      },
      {
        name: "Item 2",
        price: 20,
        image: "https://picsum.photos/200/300"
      }
    ];
    const workbook = XLSX.utils.book_new();
    const image =await getBase64("https://picsum.photos/200/300")
    const worksheetData = jsonData.map(async item => ({
      Name: item.name,
      Price: item.price,
      Image: image // Convert image to base64
    }));
    console.log(worksheetData,"data")
  
    const worksheet = XLSX.utils.json_to_sheet(worksheetData, { header: ["Name", "Price", "Image"] });
  
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
  
    worksheet["!cols"] = [{ wpx: 200 }, { wpx: 100 }, { wpx: 200 }];
  
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    
    saveAs(data, "data.xlsx");
  };
  
  // Helper function to convert image URL to base64
  const getBase64 = (url) => {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div>
            <h2>VAT Report</h2>
          </div>
          <Card>
            <CardBody>
              <div className="row">
                {/* <div className="col-md-12 d-flex">
                  <div className="col-md-3">
                    <div class="input-container">
                      <Select
                        placeholder="Customer"
                        className="js-states select-control"
                        options={[
                          { id: "", customer_name: "All" },
                          ...(Array.isArray(customerDatas) ? customerDatas : []),,
                        ]}
                        disabled={false}
                        dropdownHandle={true}
                        searchable={true}
                        searchBy="customer_name"
                        labelField={"customer_name"}
                        valueField={"id"}
                        name="customer_name"
                        value={selectedCustomer}
                        onChange={handleCustomerChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="input-container">
                      <Select
                        placeholder="Invoice Status"
                        className="js-states select-control"
                        options={invoiceStatus ? invoiceStatus : []}
                        disabled={false}
                        dropdownHandle={true}
                        searchable={true}
                        searchBy="mode"
                        labelField={"mode"}
                        valueField={"id"}
                        name="invoice_status"
                        value={selectedInvoiceStatus}
                        onChange={handleInvoiceStatusChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="input-container">
                      <Select
                        placeholder="Zatca Status"
                        className="js-states select-control"
                        options={zatcaStatus ? zatcaStatus : []}
                        disabled={false}
                        dropdownHandle={true}
                        searchable={true}
                        searchBy="mode"
                        labelField={"mode"}
                        valueField={"id"}
                        name="zatca_status"
                        value={selectedZatcaStatus}
                        onChange={handleZatcaStatusChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="input-container">
                      <Select
                        placeholder="Payment Status"
                        className="js-states select-control"
                        options={paymentStatus ? paymentStatus : []}
                        disabled={false}
                        dropdownHandle={true}
                        searchable={true}
                        searchBy="mode"
                        labelField={"mode"}
                        valueField={"id"}
                        name="payment_status"
                        value={selectedPaymentStatus}
                        onChange={handlePaymentStatusChange}
                      />
                    </div>
                  </div>
                </div> */}
                <div className="col-md-12 d-flex">
                  <div className="col-md-3">
                    <div class="input-container">
                      <DateRangePicker
                        value={value}
                        onChange={(newValue) => setValue(newValue)}
                        placeholder="Select Date Range"
                      />
                    </div>
                  </div>
                  <div className="col-md-1 d-flex justify-content-start align-items-center ">
                    <Button color="primary" onClick={showButton}>
                      Export xlsx File
                    </Button>
                  </div>

                  <div className="col-md-1  d-flex justify-content-start align-items-center d-none">
                    <Button color="primary" onClick={exportFile}>
                      Export xlsx File
                    </Button>
                  </div>
                </div>

                 {/* <div className="col-md-4 row">
                  <input
                    type="text"
                    name="search"
                    className="form-control align-self-center"
                    value={searchQuery}
                    style={{ height: "50px" }}
                    onChange={(e) => {
                      searchData(e);
                    }}
                    placeholder="Search"
                  />
                </div>   */}
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default VatReport;
