import React, { useDebugValue, useEffect, useState, useMemo } from "react";
// import "../../App.css";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Table,
} from "reactstrap";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { Breadcrumb, BreadcrumbItem } from "reactstrap";
// import "../../App.css";
import { useForm, Controller, Form } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { purchaseReturnValidator } from "../../utils/Validation";
import { API_STATUS, paymentType, requiredTaxes } from "../../utils/constants";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-dropdown-select";
import dayjs from "dayjs";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import {
  Paper,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import {
  clearSinvLoadingDatas,
  createPaymentDetails,
  getPaymentList,
  salesinvoiceSelector,
} from "../../store/reducer/SalesInvoiceReducer";
import {
  productData,
  productsSelector,
} from "../../store/reducer/ProductReducer";
import {
  clearsettingsLoadings,
  stockUnitList,
  settingsSelector,
} from "../../store/reducer/SettingsReducer";
import {
  getInvoiceNo,
  getReturnNo,
  purchaseSelector,
  createPurchaseInvoice,
  clearPurchaseLoadings,
  purchaseOrderList,
  purchaseInvoiceList,
  createPurchaseReturn,
  getInvoiceById,
} from "../../store/reducer/PurchaseReducer";
import { vendorData, vendorsSelector } from "../../store/reducer/VendorReducer";
import {
  clearPVLoadings,
  paymentVoucherSelector,
} from "../../store/reducer/PaymentVoucherReducer";

import {
  AccountsList,
  FinanceAccountsSelector,
  financeGroupList,
} from "../../store/reducer/FinanceAccountsReducer";
import _ from "lodash";
function AddPurchaseReturn() {
  /** Selectors */
  const { createJournalVoucherLoad, generatedPRno, accountAllData } =
    useSelector(paymentVoucherSelector);
  const { productDatas } = useSelector(productsSelector);
  const {
    createPurchaseReturnLoad,
    purchaseReturnno,
    purchaseOrders,
    purchseOrderDatasByID,
    purchaseInvoiceListData,
    purchseInvoiceDatasByID,
  } = useSelector(purchaseSelector);

  const { stockUnitListData } = useSelector(settingsSelector);
  const { vendorDatas } = useSelector(vendorsSelector);
  const { accountsListDatas, accountsGroupDatas } = useSelector(
    FinanceAccountsSelector
  );
  console.log(accountsListDatas, "accountsGroupDatas1");

  /** useState Values */
  let [sortBy, setSortBy] = useState("created_at");
  let [activesortBy, setActiveSortBy] = useState("customer_name");
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("asc");
  const [totalAmt, settotalAmt] = useState(0);
  const [vat_amount, setVatAmount] = useState("0");
  const [taxType, setTaxType] = useState([requiredTaxes && requiredTaxes[1]]);
  const [roundOff, setroundOff] = useState("0");
  const [label, setLabel] = useState("label");
  const [current_bal, setCurrentBal] = useState(0);
  useEffect(() => {
    setValue("return_no", purchaseReturnno?.return_no);
  }, [purchaseReturnno]);

  const [returnDetails, setreturnDetails] = useState([
    {
      additional_description: "",
      product_id: 0,
      ret_qty: 0,
      unit: "",
      avl_qty: 0,
      return_price: 0,
      return_amount: 0,
      id: 0,
    },
  ]);
  console.log(returnDetails, "returnDetails1");
  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
  };
  const taxcategories = [
    {
      id: 0,
      category: "Exempt",
    },
    {
      id: 1,
      category: "VAT 15%",
    },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [settingTz, setSettingTz] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    resolver: yupResolver(purchaseReturnValidator),
  });
  const { invoiceDetails, createPaymentDetailsloading } =
    useSelector(salesinvoiceSelector);
  let company_details = [{ payment_type: "1" }];
  const [total, setTotal] = useState(0);
  const [isWarning, setIsWarning] = useState(false);
  const [ids, setIds] = useState([]);

  const _options = useMemo(() => {
    const options = accountsListDatas?.rows?.map((ele) => {
      return {
        ...ele,
        label: `${ele.account_code} - ${ele.name}`,
      };
    });
    return options;
  }, [accountsListDatas]);

  const initHit = () => {
    dispatch(
      productData({
        query: "",
        page: 0,
        limit: "",
        sortby: "",
        order: "",
      })
    );
    dispatch(getInvoiceNo({}));
    dispatch(
      vendorData({
        query: "",
        page: 0,
        limit: 0,
        sortby: "",
        order: "",
      })
    );
    dispatch(stockUnitList({ page: 0, limit: 0, query: "" }));
    dispatch(getReturnNo({}));
    dispatch(
      purchaseInvoiceList({
        query: "",
        page: 0,
        limit: 0,
      })
    );
    dispatch(
      financeGroupList({
        query: "",
        page: page + 1,
        limit: rowsPerPage,
        sortby: "",
        order: order,
      })
    );
    dispatch(
      AccountsList({
        query: "",
        page: 0,
        limit: 0,
        sortby: "id",
        order: "",
        group_id: 0,
      })
    );
  };
  useEffect(() => {
    setValue(
      "vendor_id",
      purchseInvoiceDatasByID && purchseInvoiceDatasByID?.vendor_id
    );
    setValue(
      "pay_mode",
      purchseInvoiceDatasByID && purchseInvoiceDatasByID?.pay_mode
    );
    setValue(
      "pay_account",
      purchseInvoiceDatasByID && purchseInvoiceDatasByID?.pay_account
    );
    let AccountData = accountsListDatas?.rows?.find((acccounts) => {
      return acccounts.id == purchseInvoiceDatasByID?.pay_account;
    });
    setValue("current_bal", AccountData?.current_balance);
    clearErrors("vendor_id");

    let vdData = purchseInvoiceDatasByID?.purchase_invoice_details?.map(
      (orderDatas, key) => {
        return {
          additional_description: orderDatas?.additional_description,
          product_id: orderDatas?.product_id,
          ret_qty: orderDatas?.qty,
          unit: orderDatas?.uom,
          return_price: Number(orderDatas?.price),
          return_amount: Number(orderDatas?.amount),
          id: key++,
        };
      }
    );

    if (vdData) {
      console.log(vdData, "VDDATAS");
      setreturnDetails([...vdData, ...returnDetails]);
      setroundOff(purchseInvoiceDatasByID?.round_amount);
      setValue("net_amount", purchseInvoiceDatasByID?.net_amount);
      setValue("discount_amount", purchseInvoiceDatasByID?.discount_amount);
      // clearErrors("net_amount");
    }
  }, [purchseInvoiceDatasByID]);

  useEffect(() => {
    initHit();
  }, []);

  const handlePurchaseInvoice = (event) => {
    setValue("pi_number", event[0]?.id);
    clearErrors("pi_number");
    dispatch(
      getInvoiceById({
        id: event[0]?.id,
      })
    );
  };

  const CalAmt = () => {
    let taxValue = taxType[0]?.name != "VAT 15%" ? 0 : 15;
    let totalAmt = _.sumBy(returnDetails, function (o) {
      return parseFloat(o.return_amount);
    });

    let netAmt = 0;
    let vatamount = parseFloat(totalAmt * taxValue) / 100;
    let disAmt = Number(
      getValues("discount_amount") != undefined
        ? getValues("discount_amount")
        : 0
    );
    netAmt = parseFloat(
      totalAmt + Number(vatamount) + Number(roundOff) - disAmt
    );
    console.log(totalAmt, vatamount, roundOff, disAmt, "DISCOUNT");
    settotalAmt(totalAmt);
    setVatAmount(vatamount.toString());
    console.log(netAmt, "NETAMTS");
    setValue("net_amount", netAmt);
  };

  useEffect(() => {
    CalAmt();
  }, [returnDetails, roundOff, taxType]);

  function allKeysExceptNarrationHaveValues(obj) {
    const keysToCheck = _.omit(obj, "narration", "id");
    console.log(
      keysToCheck,
      "allObjectsKeysExceptNarrationHaveValues(arrayOfObjects)"
    );
    return _.every(keysToCheck, (value) => !_.isEmpty(value));
  }

  function allObjectsKeysExceptNarrationHaveValues(array) {
    return _.every(array, (obj) => allKeysExceptNarrationHaveValues(obj));
  }

  const onSubmitHandler = (formData) => {
    // console.log(
    //   allObjectsKeysExceptNarrationHaveValues(returnDetails),
    //   returnDetails,
    //   "allObjectsKeysExceptNarrationHaveValues(arrayOfObjects)"
    // );
    let details = returnDetails?.map((ele) => {
      return {
        product_id: ele.product_id,
        additional_description: ele.additional_description,
        ret_qty: ele.ret_qty,
        unit: ele.unit,
        avl_qty: ele.avl_qty,
        return_price: ele.return_price,
        return_amount: ele.return_amount,
      };
    });
    delete formData.product_id;
    delete formData.unit;
    formData.details = details;
    formData.total_return_amount = totalAmt;
    formData.vat = taxType;
    formData.vat_amount = vat_amount;
    formData.round_amount = roundOff;
    formData.net_amount = getValues("net_amount");
    console.log(formData, "formData1");
    dispatch(createPurchaseReturn({ formData }));
  };

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
  };

  useEffect(() => {
    if (createPurchaseReturnLoad === API_STATUS.FULFILLED) {
      toastr.success("Purchase Return Added Succesfully");
      reset();
      dispatch(clearPurchaseLoadings());
      navigate(-1);
    }
  }, [createPurchaseReturnLoad]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="row">
            <div className="col-md-6">
              <h4>
                <Breadcrumb>
                  <BreadcrumbItem>Purchase Return / New</BreadcrumbItem>
                </Breadcrumb>
              </h4>
            </div>
            <div className="col-md-6 text-end">
              <h5>Bill Amount : {getValues("net_amount")}</h5>
            </div>
          </div>
          <Row>
            <Col xs={12}>
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Card>
                  <CardBody>
                    <div>
                      <Row>
                        <Col md="4">
                          <div className="d-flex align-items-end">
                            <div class="input-container w-100">
                              <input
                                placeholder=" "
                                type="text"
                                id="return_no"
                                name="return_no"
                                required
                                disabled
                                {...register("return_no")}
                              />
                              <label for="return_no">Return No</label>
                            </div>
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="input-container">
                            <Controller
                              name="pi_number"
                              control={control}
                              render={({ field, ref }) => (
                                <Select
                                  className="js-states select-control"
                                  options={purchaseInvoiceListData?.rows ?? []}
                                  placeholder="Select PI"
                                  {...field}
                                  ref={ref}
                                  dropdownHandle={true}
                                  searchable={true}
                                  labelField={"invoice_no"}
                                  valueField={"id"}
                                  values={
                                    getValues("pi_number")
                                      ? purchaseInvoiceListData?.rows?.filter(
                                          (ele) =>
                                            getValues("pi_number") == ele.id
                                        )
                                      : []
                                  }
                                  name="pi_number"
                                  onChange={handlePurchaseInvoice}
                                />
                              )}
                              {...register("pi_number")}
                            />
                          </div>
                          {errors?.pi_number?.message && (
                            <p className="error">{errors.pi_number.message}</p>
                          )}
                        </Col>
                        <Col md="4">
                          <div className="input-container">
                            <Controller
                              name="vendor_id"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className="js-states select-control"
                                  options={vendorDatas}
                                  placeholder="Select Vendor"
                                  {...field}
                                  dropdownHandle={true}
                                  searchable={true}
                                  labelField={"vendor_name"}
                                  valueField={"id"}
                                  name="vendor_id"
                                  disabled
                                  values={
                                    getValues("vendor_id")
                                      ? vendorDatas.filter(
                                          (ele) =>
                                            getValues("vendor_id") == ele.id
                                        )
                                      : []
                                  }
                                  onChange={(value) => {
                                    setValue("vendor_id", value[0]?.id);
                                  }}
                                />
                              )}
                              {...register("vendor_id")}
                            />
                          </div>
                          {errors?.vendor_id?.message && (
                            <p className="error">{errors.vendor_id.message}</p>
                          )}
                        </Col>
                        <Col md="4">
                          <div className="input-container">
                            <input
                              placeholder=" "
                              type="date"
                              defaultValue={dayjs().format("YYYY-MM-DD")}
                              id="return_date"
                              name="return_date"
                              {...register("return_date")}
                            />
                            <label for="return_date">Return Date</label>
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="input-container">
                            <Controller
                              name="pay_mode"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className="js-states select-control"
                                  options={accountsGroupDatas?.rows}
                                  placeholder="Select Pay Mode"
                                  {...field}
                                  dropdownHandle={true}
                                  searchable={true}
                                  labelField={"name"}
                                  valueField={"id"}
                                  name="pay_mode"
                                  disabled
                                  values={
                                    getValues("pay_mode")
                                      ? accountsGroupDatas?.rows?.filter(
                                          (ele) =>
                                            getValues("pay_mode") == ele.id
                                        )
                                      : []
                                  }
                                  onChange={(value) => {
                                    setValue("pay_mode", value[0]?.id);
                                  }}
                                />
                              )}
                              {...register("pay_mode")}
                            />
                          </div>
                          {errors?.pay_mode?.message && (
                            <p className="error">{errors.pay_mode.message}</p>
                          )}
                        </Col>
                        <Col md="4">
                          <div className="input-container">
                            <Controller
                              name="pay_account"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className="js-states select-control"
                                  options={_options}
                                  placeholder="Select Pay Account"
                                  {...field}
                                  dropdownHandle={true}
                                  searchable={true}
                                  labelField={"name"}
                                  valueField={"id"}
                                  name="pay_account"
                                  disabled
                                  values={
                                    getValues("pay_account")
                                      ? _options.filter(
                                          (ele) =>
                                            getValues("pay_account") == ele.id
                                        )
                                      : []
                                  }
                                  onChange={(value) => {
                                    setValue("pay_account", value[0]?.id);
                                  }}
                                />
                              )}
                              {...register("pay_account")}
                            />
                          </div>
                          {errors?.pay_account?.message && (
                            <p className="error">
                              {errors.pay_account.message}
                            </p>
                          )}
                        </Col>
                        <Col md="4">
                          <div className="input-container mb-0">
                            <input
                              placeholder=" "
                              type="number"
                              id="current_bal"
                              disabled
                              name="current_bal"
                              {...register("current_bal")}
                            />
                            <label for="current_bal" className="required">
                              Current Bal.
                            </label>
                          </div>
                          {errors?.current_bal?.message && (
                            <p className="error">
                              {errors.current_bal.message}
                            </p>
                          )}
                          <span></span>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div className="table-responsive react-table">
                      <TableContainer
                        component={Paper}
                        style={{ minHeight: "300px" }}
                      >
                        <Table sx={{ minWidth: 700 }} stickyHeader>
                          <TableHead className="table-light table-nowrap">
                            <TableRow>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={
                                    sortBy == "product_desc" ? true : false
                                  }
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("product_desc");
                                  }}
                                >
                                  Product Description
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={
                                    sortBy == "additional_description"
                                      ? true
                                      : false
                                  }
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("additional_description");
                                  }}
                                >
                                  Additional Description
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={sortBy == "unit" ? true : false}
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("unit");
                                  }}
                                >
                                  Ret.Qty
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={sortBy == "unit" ? true : false}
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("unit");
                                  }}
                                >
                                  Unit
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={sortBy == "avl_qty" ? true : false}
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("avl_qty");
                                  }}
                                >
                                  Avl.Qty
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={
                                    sortBy == "return_price" ? true : false
                                  }
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("return_price");
                                  }}
                                >
                                  Return Price
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={
                                    sortBy == "return_amount" ? true : false
                                  }
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("return_amount");
                                  }}
                                >
                                  Return Amount
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">Action</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {returnDetails?.map((row, index) => {
                              return (
                                <StyledTableRow>
                                  <StyledTableCell align="">
                                    <Controller
                                      name="product_id"
                                      control={control}
                                      render={({ field }) => (
                                        <Select
                                          className="js-states select-control"
                                          options={productDatas}
                                          {...field}
                                          disabled={false}
                                          dropdownHandle={true}
                                          searchable={true}
                                          labelField={"description"}
                                          valueField={"id"}
                                          name="product_id"
                                          values={
                                            productDatas?.filter(
                                              (ele) =>
                                                ele.id ==
                                                returnDetails[index].product_id
                                            ) ?? []
                                          }
                                          onChange={(value) => {
                                            let data = returnDetails;
                                            data[index].product_id =
                                              value[0]?.id;
                                            setreturnDetails([
                                              ...data,
                                              {
                                                additional_description: "",
                                                ret_qty: 0,
                                                unit: "",
                                                avl_qty: 0,
                                                return_price: 0,
                                                return_amount: 0,
                                                id: index + 1,
                                              },
                                            ]);
                                          }}
                                        />
                                      )}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell align="">
                                    <div class="input-container w-100 m-1">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        value={row?.additional_description}
                                        id={`narration${index}`}
                                        name={`narration${index}`}
                                        onChange={(e) => {
                                          const { value } = e.target;
                                          let data = returnDetails;
                                          data[index].additional_description =
                                            value;
                                          setreturnDetails([...data]);
                                        }}
                                      />
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell align="">
                                    <div class="input-container w-100 m-1">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        value={row?.ret_qty}
                                        id={`narration${index}`}
                                        name={`narration${index}`}
                                        onChange={(e) => {
                                          const { value } = e.target;
                                          let data = returnDetails;
                                          let productQty =
                                            purchseInvoiceDatasByID
                                              .purchase_invoice_details[index]
                                              ?.qty;

                                          data[index].ret_qty =
                                            Number(value) <= productQty
                                              ? value
                                              : purchseInvoiceDatasByID
                                                  .purchase_invoice_details[
                                                  index
                                                ]?.qty;

                                          const returnPrice =
                                            data[index].return_price !=
                                            undefined
                                              ? data[index].return_price
                                              : 0;

                                          const returnAmount = parseFloat(
                                            data[index].ret_qty * returnPrice
                                          );

                                          data[index].return_amount =
                                            returnAmount.toFixed(2);

                                          data[index].avl_qty =
                                            purchseInvoiceDatasByID
                                              .purchase_invoice_details[index]
                                              ?.qty - data[index].ret_qty;
                                          setreturnDetails([...data]);
                                        }}
                                      />
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell align="">
                                    <Controller
                                      name="unit"
                                      control={control}
                                      render={({ field }) => (
                                        <Select
                                          className="js-states select-control"
                                          options={stockUnitListData}
                                          {...field}
                                          dropdownHandle={true}
                                          searchable={true}
                                          labelField={"unit_name"}
                                          valueField={"id"}
                                          name="unit"
                                          values={
                                            stockUnitListData?.filter(
                                              (ele) =>
                                                ele.id ==
                                                returnDetails[index].unit
                                            ) ?? []
                                          }
                                          onChange={(value) => {
                                            let data = returnDetails;
                                            data[index].unit = value[0]?.id;
                                            setreturnDetails([...data]);
                                            console.log(value, "products");
                                          }}
                                        />
                                      )}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell align="">
                                    <div class="input-container w-100 m-1">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id={`narration${index}`}
                                        name={`narration${index}`}
                                        value={row?.avl_qty}
                                        onChange={(e) => {
                                          const { value } = e.target;
                                          let data = returnDetails;
                                          data[index].avl_qty = value;
                                          setreturnDetails([...data]);
                                        }}
                                      />
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell align="">
                                    <div class="input-container w-100 m-1">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id={`narration${index}`}
                                        name={`narration${index}`}
                                        value={row?.return_price}
                                        onChange={(e) => {
                                          const { value } = e.target;
                                          let data = returnDetails;
                                          data[index].return_price = value;
                                          const qty =
                                            data[index].ret_qty != undefined
                                              ? data[index].ret_qty
                                              : 0;
                                          const return_amount = parseFloat(
                                            value * qty
                                          );
                                          data[index].return_amount =
                                            return_amount.toFixed(2);
                                          setreturnDetails([...data]);
                                        }}
                                      />
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell align="">
                                    <div class="input-container w-100 m-1">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        value={row?.return_amount}
                                        id={`narration${index}`}
                                        name={`narration${index}`}
                                        disabled
                                      />
                                    </div>
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align=""
                                    className="button-items"
                                  >
                                    <a
                                      href="javascript:void(0);"
                                      title="Delete"
                                      className="btn btn-danger delete-btn"
                                      onClick={() => {
                                        // if (voucherDetails.length > 1) {
                                        const returnsDetail = _.filter(
                                          returnDetails,
                                          (key, i) => i != index
                                        );
                                        console.log(
                                          returnDetails,
                                          "check voucherDetails"
                                        );
                                        setreturnDetails(returnsDetail);
                                        // }
                                      }}
                                    >
                                      <i className="ri-delete-bin-line"></i>
                                    </a>
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <div className="invoice-width">
                        <div class="input-container w-100">
                          <textarea
                            id="net_amount"
                            rows={3}
                            name="notes"
                            {...register("notes")}
                          />
                          <label for="notes">Notes</label>
                        </div>
                      </div>
                      <div className="invoice-width">
                        <div class="input-container w-100">
                          <input
                            placeholder=" "
                            type="text"
                            id="total_amount"
                            name="total_amount"
                            value={totalAmt}
                            disabled
                            required
                          />
                          <label for="total_amount">Total Return Amount</label>
                        </div>
                        <div className="input-container w-100">
                          <Controller
                            name="vat"
                            control={control}
                            render={({ field }) => (
                              <Select
                                className="js-states select-control"
                                options={requiredTaxes}
                                {...field}
                                dropdownHandle={true}
                                searchable={true}
                                values={taxType}
                                labelField={"name"}
                                valueField={"name"}
                                name="vat"
                                onChange={(value) => {
                                  setTaxType([value[0]]);
                                }}
                              />
                            )}
                          />
                        </div>
                        <div class="input-container w-100">
                          <input
                            placeholder=" "
                            type="text"
                            id="vat_amount"
                            name="vat_amount"
                            disabled
                            value={vat_amount}
                          />
                          <label for="vat_amount">VAT Amount</label>
                        </div>
                        <div class="input-container w-100">
                          <input
                            placeholder=" "
                            type="text"
                            id="round_off"
                            name="round_off"
                            onChange={(e) => {
                              setroundOff(
                                e.target.value != "" ? e.target.value : 0
                              );
                            }}
                            value={roundOff}
                          />
                          <label for="round_off">Round Off</label>
                        </div>
                        <div class="input-container w-100">
                          <input
                            placeholder=" "
                            type="text"
                            id="discount_amount"
                            name="discount_amount"
                            disabled
                            {...register("discount_amount")}
                          />
                          <label for="discount_amount">Purchse Discount</label>
                        </div>
                        <div class="input-container w-100">
                          <input
                            placeholder=" "
                            type="text"
                            id="net_amount"
                            name="net_amount"
                            disabled
                            {...register("net_amount")}
                          />
                          <label for="net_amount">Net Amount</label>
                        </div>
                        {errors?.net_amount?.message && (
                          <p className="error">{errors.net_amount.message}</p>
                        )}
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="text-end">
                  <Button
                    color="primary"
                    onClick={() => console.log("hello")}
                    type="submit"
                    disabled={isWarning}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default AddPurchaseReturn;
