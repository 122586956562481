import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Modal,
} from "reactstrap";
import classnames from "classnames";
import "../../App.css";
import { useEffect, useState } from "react";
import Select from "react-dropdown-select";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import dayjs from "dayjs";
import TableRow from "@mui/material/TableRow";
import TablePaginationActions from "../../utils/pagination";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { salesQuotationValidation } from "../../utils/Validation";
import { useNavigate } from "react-router";
import {
  customerData,
  customersSelector,
} from "../../store/reducer/CustomerReducer";
import {
  AddProduct,
  clearProdLoadingDatas,
  productData,
  productsSelector,
} from "../../store/reducer/ProductReducer";
import {
  salesinvoiceSelector,
  AddSalesinvoice,
  clearErrormsg,
  clearSinvLoadingDatas,
  getInvoiceReducer,
} from "../../store/reducer/SalesInvoiceReducer";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  API_STATUS,
  paymentTypewithcheck,
  paymentModetype,
} from "../../utils/constants";
import { handleTranslate } from "../../utils/translate";
const SalesInvoice = () => {
  const [activeTab, setActiveTab] = useState("5");
  const dispatch = useDispatch();
  const { customerDatas } = useSelector(customersSelector);
  console.log(customerDatas, "345678uh");
  const { productDatas, addloadings } = useSelector(productsSelector);
  const { addloading, errorMessage, invoice_num, getInvoiceloading } =
    useSelector(salesinvoiceSelector);
  console.log(getInvoiceloading, "getInvoiceloading");
  let todayDate = new Date();
  const now = dayjs().format("YYYY-MM-DDTHH:mm");

  /* States */
  const [companyDetails, setCompanyDetails] = useState({
    customer_id: "",
    payment_mode: 30,
    invoice_date: dayjs(todayDate).format("YYYY-MM-DDTHH:mm"),
    // quotation_date: "",
    invoice_no: 0,
    po_number:"",
    cost_center_name:"",
    cost_center_ph:"",
    // customer_po_no: "",
    // po_date: "",
    // delivery_no: "",
    products: [
      {
        product_id: "",
        qty: 0,
        price: 0,
        amount: 0,
        type: 0,
        total_days: 0,
        month_rate: 0,
        additional_description: "",
        // vat: 0,
        vat_amt: 0,
        tax: "",
        discount: 0,
        vat_amt: 0,
      },
    ],
    customer_notes: "",
    sub_total: 0,
    discount: 0,
    // vat: "",
    tax: "",
    tax_amount: 0,
    round_amount: "",
    total: 0,
  });
  console.log(companyDetails, "companyDetails");
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(salesQuotationValidation),
  });

  const navigate = useNavigate();
  let [sortBy, setSortBy] = useState("customer_name");
  let [activesortBy, setActiveSortBy] = useState("customer_name");
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("asc");
  let [searchQuery, setSearchQuery] = useState("");
  //   let [result, setResult] = useState(0);
  //   let [vatresult, setvatresult] = useState(0);
  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
  };
  const [selectedOption, setSelectedOption] = useState("simple");
  const [getIbanNo, setGetIbanNo] = useState(null);
  const [iban, setIban] = useState(false);
  const [state, setState] = useState({ modal_fullscreen: false });
  const [service, setService] = useState({
    description: "",
    ar_description: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  console.log(service, "4567yu");
  /* Functions */
  const handleOptionChange = (e) => {
    console.log(e.target.value, "7uihj");
    // let val = e.target.value == "simple" ? 0 : 1;
    setSelectedOption(e.target.value);
  };

  const handleSave = () => {
    console.log(customerDatas, "companyDetails");
    console.log(companyDetails, "companyDetails");
    companyDetails.invoice_type = 1;
    // selectedOption == "simple" ? 0 : 1;
    if (iban == false) {
      setGetIbanNo(null);
    }
    if (getIbanNo != null && iban == false) {
      companyDetails.customer_iban = getIbanNo;
    }
    let formData = companyDetails;
    if (companyDetails.customer_id == "") {
      toastr.error("Select Customer ");
    } else {
      dispatch(AddSalesinvoice({ formData }));
      setIsLoading(true);
      // console.log(formData, "567yuhjk");
    }
    // return false;
    // navigate("/sales");
  };

  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
  };

  const handleDeleteRow = (id) => {
    console.log(id, "id");
    if (companyDetails.products.length != 1) {
      companyDetails.products.splice(id, 1);
      setCompanyDetails({
        ...companyDetails,
      });
    }
  };
  const tog_fullscreen = () => {
    setState((prevState) => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }));
  };

  const Translate = async (value, field) => {
    const translated_data = await handleTranslate(value);
    // const values = getValues();
    setValue("ar_" + field, translated_data);
    if (field == "description") {
      setService((prevService) => ({
        ...prevService,
        ar_description: translated_data,
      }));
    }
    // let
    // if()
    // console.log(getValues());
  };
  useEffect(() => {
    dispatch(productData({}));
    dispatch(customerData({}));
    dispatch(getInvoiceReducer({}));
  }, []);

  useEffect(() => {
    const index = companyDetails.products.length - 1;
    if (
      companyDetails.products[index]?.product_id !== "" &&
      companyDetails.products[index]?.product_id !== undefined
    ) {
      companyDetails.products.push({
        product_id: "",
        qty: 0,
        type: 0,
        total_days: 0,
        month_rate: 0,
        price: 0,
        amount: 0,
        tax: "",
        discount: 0,
        additional_description: "",
        vat_amt: 0,
      });
      setCompanyDetails({
        ...companyDetails,
      });
    }
  }, [companyDetails]);

  //   let result = 0;
  //   let vatresult = 0;
  //   for (let index = 0; index < companyDetails.products.length; index++) {
  //     const element = companyDetails.products[index];
  //     if (typeof element?.amount == "number")
  //       result += parseFloat(element?.amount);
  //     // if (element?.vat_amt !== "") { vat_result += parseFloat(element?.vat_amt) };
  //   }
  //   useEffect(() => {
  //     let vat_amt=parseFloat(result*vatresult/100).toFixed(2);
  //     setCompanyDetails({
  //       ...companyDetails,
  //       total_amt: result,
  //       vat_amt: vat_amt,
  //       net_amount: parseFloat(result) + parseFloat(vat_amt),
  //       round_off: 0,
  //     });
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [result, vatresult]);

  const rowCalculation = (index) => {
    let data = companyDetails.products[index];
    // console.log(data,"data");
    if (data.type == 0) {
      if (companyDetails.products[index].month_rate == 0) {
        companyDetails.products[index].price = (0).toFixed(2);
      } else {
        companyDetails.products[index].price =
          companyDetails.products[index].month_rate / data.total_days;
      }
      companyDetails.products[index].amount = (
        companyDetails.products[index].price * data.qty
      ).toFixed(2);
      companyDetails.products[index].vat_amt = (
        data.qty *
        (data.tax / 100) *
        companyDetails.products[index].price
      ).toFixed(2);
      // console.log((companyDetails.products[index].month_rate / data.total_days)??0,companyDetails.products[index].month_rate ,"products")
    } else if (data.type == 1) {
      if (companyDetails.products[index].month_rate == 0 || data.qty == 0) {
        companyDetails.products[index].price = (0).toFixed(2);
      } else {
        companyDetails.products[index].price =
          companyDetails.products[index].month_rate / data.qty;
      }
      companyDetails.products[index].amount = (
        companyDetails.products[index].price * data.qty
      ).toFixed(2);
      companyDetails.products[index].vat_amt = (
        data.qty *
        (data.tax / 100) *
        companyDetails.products[index].price
      ).toFixed(2);
    } else if (data.type == 2 || data.type == 3) {
      if (companyDetails.products[index].month_rate == 0 || data.qty == 0) {
        companyDetails.products[index].price = (0).toFixed(2);
      } else {
        companyDetails.products[index].price =
          companyDetails.products[index].month_rate;
      }
      companyDetails.products[index].amount = (
        companyDetails.products[index].price * data.qty
      ).toFixed(2);
      companyDetails.products[index].vat_amt = (
        data.qty *
        (data.tax / 100) *
        companyDetails.products[index].price
      ).toFixed(2);
    }
    setCompanyDetails({
      ...companyDetails,
    });
  };
  const calculateDetails = () => {
    let subTotal = 0;
    let totalTaxAmount = 0;
    let totalDiscount = 0;
    let vatRate = companyDetails.tax !== "" ? companyDetails.tax : 0;

    companyDetails.products.forEach((product) => {
      console.log(product, "6789p");
      // let type = product.type;
      // let total_days = product.total_days;
      // let month_rate = product.month_rate;
      // let qty = product.qty;
      // if (type == "0") product.price = month_rate / total_days;

      let productTotal = product.price * product.qty;

      productTotal -= product.discount;

      let productVatAmount = (productTotal * product.tax) / 100;

      subTotal += parseFloat(productTotal);

      totalTaxAmount += productVatAmount;
      totalDiscount += product.discount;
    });

    setCompanyDetails({
      ...companyDetails,
      sub_total: subTotal.toFixed(2),
      tax_amount: totalTaxAmount.toFixed(2),
      total: (subTotal + totalTaxAmount).toFixed(2),
      discount: totalDiscount.toFixed(2),
    });
  };

  const handleInput = (e, dest, type) => {
    const { value } = e.target;
    console.log(value, "tyuio");
    companyDetails[dest] = type !== "" ? Number(value) : value;
    setCompanyDetails({
      ...companyDetails,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setService((prevService) => ({
      ...prevService,
      [name]: value,
    }));
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    const formData = {};
    formData.description = service.description;
    formData.ar_description = service.ar_description;
    formData.product_category = "sertice Category";
    formData.product_type = "Service";
    formData.stock_unit = "0";
    formData.financial_category = "Cash";
    console.log(service, "siuf");
    // dispatch(AddProduct({ formData }));
  };

  useEffect(() => {
    if (addloading === API_STATUS.FULFILLED) {
      toastr.clear();
      toastr.success("Sales Invoice Added Successfully!");
      setIsLoading(false);
      navigate("/sales_invoicelist");
      dispatch(clearSinvLoadingDatas());
      // reset();
    }
    if (addloading === API_STATUS.REJECTED) {
      toastr.clear();
      toastr.error("Sales Invoice Added Failed!");
      setIsLoading(false);
      
      // reset();
    }
    if (getInvoiceloading === API_STATUS.FULFILLED) {
      toastr.clear();
      console.log(invoice_num, "invoice_num");
      setCompanyDetails({
        ...companyDetails,
        invoice_no: invoice_num?.io_num,
      });
      dispatch(clearSinvLoadingDatas());
      // reset();
    }
    if (errorMessage) {
      toastr.clear();
      if (errorMessage.message) toastr.error(errorMessage.message);
      else if (errorMessage.errors) {
        let data = "";
        errorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        toastr.error(data);
      }
      dispatch(clearErrormsg());
    }
    if (addloadings === API_STATUS.FULFILLED) {
      toastr.clear();
      toastr.success("Service Added Successfully!");
      dispatch(productData({}));
      dispatch(clearProdLoadingDatas());
      setService({
        ar_description: "",
        description: "",
      });
      setState({ modal_fullscreen: false });
    } else if (addloadings === API_STATUS.REJECTED) {
      toastr.clear();
      toastr.error("Failed to add service. Please try again.");
    }
  }, [addloading, getInvoiceloading, addloadings]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-content-between">
            <h4 className="page-title">Invoice</h4>
            <div
              className="d-flex justify-content-between"
              style={{ minWidth: "400px" }}
            >
              <div></div>
              <h5>
                Net Amount: <span>{companyDetails.total}</span>
              </h5>
            </div>
          </div>
          <Card>
            <CardBody>
              <Row>
                <Col md={4} className="d-none">
                  <div className="d-flex">
                    <div
                      className="radios"
                      style={{
                        display: "flex",
                        transform: "none",
                        paddingLeft: "28px",
                      }}
                    >
                      <input
                        type="radio"
                        name="option"
                        value="simple"
                        checked={selectedOption === "simple"}
                        onChange={handleOptionChange}
                      />
                      <label
                        htmlFor="simple"
                        style={{
                          transform: "none",
                          margin: "0",
                          position: "static",
                          paddingLeft: "10px",
                        }}
                      >
                        Simple
                      </label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        transform: "none",
                        paddingLeft: "28px",
                      }}
                    >
                      <input
                        type="radio"
                        name="option"
                        value="normal"
                        checked={selectedOption === "normal"}
                        onChange={handleOptionChange}
                      />
                      <label
                        htmlFor="normal"
                        style={{
                          transform: "none",
                          margin: "0",
                          position: "static",
                          paddingLeft: "10px",
                        }}
                      >
                        Normal
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <div className="d-flex align-items-end">
                    <div class="input-container w-100">
                      <Controller
                        name="customer_name"
                        control={control}
                        render={({ field }) => (
                          <Select
                            placeholder="Customer"
                            className="js-states select-control"
                            {...field}
                            options={customerDatas ? customerDatas : []}
                            disabled={false}
                            searchBy="customer_name"
                            dropdownHandle={true}
                            searchable={true}
                            labelField={"customer_name"}
                            valueField={"id"}
                            name="country_name"
                            onChange={(value) => {
                              companyDetails.customer_id = value[0]?.id;
                              setCompanyDetails({
                                ...companyDetails,
                              });
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4" className="d-none">
                  <div className="input-container ">
                    <Controller
                      name="payment_mode"
                      control={control}
                      render={({ field }) => (
                        <Select
                          className="js-states select-control"
                          placeholder={"Pay Mode"}
                          options={paymentTypewithcheck}
                          {...field}
                          disabled={false}
                          dropdownHandle={true}
                          labelField="mode"
                          searchable={true}
                          valueField={"value"}
                          name="payment_mode"
                          onChange={(value) => {
                            companyDetails.payment_mode = value[0].value;
                            setCompanyDetails({
                              ...companyDetails,
                            });
                            if (value[0].value == 30) {
                              setIban(true);
                            } else {
                              setIban(false);
                            }
                          }}
                        />
                      )}
                      // {...register("payment_mode")}
                    />
                  </div>
                  {errors?.pay_mode?.message && (
                    <p className="error">{errors.pay_mode.message}</p>
                  )}
                </Col>
                {iban && (
                  <Col md="4">
                    <div class="input-container">
                      <input
                        placeholder=" "
                        type="text"
                        // disabled
                        // value={companyDetails.invoice_no}
                        onChange={(e) => setGetIbanNo(e.target.value)}
                        name="invoice_no"
                      />
                      <label className="required" for="customer_iban">
                        IBAN ACC NO
                      </label>
                    </div>
                  </Col>
                )}
                <Col md="4">
                  <div className="d-flex align-items-end">
                    <div class="input-container w-100">
                      <input
                        placeholder=" "
                        type="datetime-local"
                        value={companyDetails.invoice_date}
                        id="date"
                        onChange={(e) => handleInput(e, "invoice_date", "")}
                        name="date"
                      />
                      <label for="date">Date</label>
                    </div>
                  </div>
                </Col>
                <Col md="4">
                  <div class="input-container">
                    <input
                      placeholder=" "
                      type="text"
                      value={companyDetails.invoice_no}
                      onChange={(e) => handleInput(e, "invoice_no", "")}
                      name="invoice_no"
                    />
                    <label className="required" for="invoice_no">
                      Invoice No
                    </label>
                  </div>
                </Col>
                <Col md="4">
                  <div class="input-container">
                    <input
                      placeholder=" "
                      type="text"
                      value={companyDetails.cost_center_ph}
                      onChange={(e) => handleInput(e, "cost_center_ph", "")}
                      name="cost_center_ph"
                    />
                    <label for="cost_center_ph">
                      Cost Center Number
                    </label>
                  </div>
                </Col>
                <Col md="4">
                  <div class="input-container">
                    <input
                      placeholder=" "
                      type="text"
                      value={companyDetails.cost_center_name}
                      onChange={(e) => handleInput(e, "cost_center_name", "")}
                      name="cost_center_name"
                    />
                    <label for="cost_center_name">
                      Cost Center Name
                    </label>
                  </div>
                </Col>
                <Col md="4">
                  <div class="input-container">
                    <input
                      placeholder=" "
                      type="text"
                      value={companyDetails.po_number}
                      onChange={(e) => handleInput(e, "po_number", "")}
                      name="po_number"
                    />
                    <label for="po_number">
                      PO Number
                    </label>
                  </div>
                </Col>
              </Row>

              {/* <Row>
                                <Col md="4">
                                    <div class="input-container">
                                        <input
                                            placeholder=" "
                                            type="text"
                                            value={companyDetails.customer_po_no}
                                            onChange={(e) => handleInput(e, "customer_po_no", "")}
                                            name="customer_po_no"
                                        />
                                        <label className="required" for="customer_po_no">Customer PO No</label>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div class="input-container">
                                        <input
                                            placeholder=" "
                                            type="date"
                                            className="required"
                                            defaultValue="2020-03-19"
                                            value={companyDetails.po_date}
                                            id="po_date"
                                            onChange={(e) => handleInput(e, "po_date", "")}
                                            name="po_date"
                                        />
                                        <label for="po_date">PO Date</label>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="d-flex align-items-end">
                                        <div class="input-container w-100">
                                            <input
                                                placeholder=" "
                                                type="text"
                                                id="delivery_no"
                                                name="delivery_no"
                                                value={companyDetails.delivery_no}
                                                onChange={(e) => handleInput(e, "delivery_no", "")}
                                            />
                                            <label for="delivery_no">Delivery No</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row> */}
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <div className="table-responsive react-table">
                {/* <Button
                  color="primary"
                  className=" waves-effect waves-light me-1"
                  onClick={handleSave}
                >
                  Add Working Days or OT hours
                </Button> */}
                <TableContainer
                  component={Paper}
                  style={{ minHeight: "300px" }}
                >
                  <button
                    className="waves-effect waves-light btn btn-primary"
                    onClick={tog_fullscreen}
                  >
                    Add Service
                  </button>
                  <Table sx={{ minWidth: 700 }} stickyHeader>
                    <TableHead className="table-light table-nowrap">
                      <TableRow>
                        <StyledTableCell align="">
                          Service Description
                          <br />
                          <small>sent For zatca</small>
                        </StyledTableCell>
                        {/* <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "product_add_desc" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("product_add_desc");
                            }}
                          >
                            Additional Description
                          </TableSortLabel>
                        </StyledTableCell> */}
                        <StyledTableCell align="">Type</StyledTableCell>
                        <StyledTableCell align="">
                          Total Days/Hours
                        </StyledTableCell>
                        <StyledTableCell align="">Rate</StyledTableCell>
                        <StyledTableCell align="">
                          Worked Days / OT Hours
                          <br />
                          <small>sent For zatca</small>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          Per Day Price
                          <br />
                          <small>sent For zatca</small>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          Amout
                          <br />
                          <small>sent For zatca</small>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          VAT PERCENT
                          <br />
                          <small>sent For zatca</small>
                        </StyledTableCell>
                        <StyledTableCell align="" className=" d-none">
                          Discount
                        </StyledTableCell>
                        {/* <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "vat" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("vat");
                                                        }}
                                                    >
                                                        VAT
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "vat_amt" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("vat_amt");
                                                        }}
                                                    >
                                                        VAT Amount
                                                    </TableSortLabel>
                                                </StyledTableCell> */}
                        <StyledTableCell align="">Net Amount</StyledTableCell>
                        <StyledTableCell align="">Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {companyDetails.products &&
                        companyDetails.products.map((row, index) => (
                          <StyledTableRow>
                            <StyledTableCell align="">
                              <Controller
                                name="Product Description"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    className="js-states select-control"
                                    options={productDatas ? productDatas : []}
                                    {...field}
                                    disabled={false}
                                    dropdownHandle={true}
                                    searchable={true}
                                    labelField={"description"}
                                    valueField={"id"}
                                    searchBy={"description"}
                                    name="country_name"
                                    onChange={(value) => {
                                      console.log(value[0].id, "products");
                                      companyDetails.products[
                                        index
                                      ].product_id = value[0].id;
                                      setCompanyDetails({
                                        ...companyDetails,
                                      });
                                    }}
                                  />
                                )}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <div class="input-container w-100 m-1">
                                <Select
                                  className="js-states select-control"
                                  options={[
                                    { id: 0, name: "Days" },
                                    { id: 1, name: "OT hours - Fixed" },
                                    { id: 3, name: "OT hours - Calc" },
                                    { id: 2, name: "OT days" },
                                  ]}
                                  id={`type${index}`}
                                  name={`type${index}`}
                                  placeholder="Work Type"
                                  disabled={false}
                                  dropdownHandle={true}
                                  searchable={true}
                                  searchBy={"name"}
                                  labelField={"name"}
                                  valueField={"id"}
                                  onChange={(value) => {
                                    let updatedProducts = [
                                      ...companyDetails.products,
                                    ];
                                    updatedProducts[index].type = value[0].id;
                                    if (value[0].id == 1) {
                                      companyDetails.products[
                                        index
                                      ].total_days = 0;
                                      companyDetails.products[
                                        index
                                      ].month_rate = 0;
                                      companyDetails.products[index].qty = 0;
                                    } else {
                                      companyDetails.products[
                                        index
                                      ].total_days = 0;
                                      companyDetails.products[
                                        index
                                      ].month_rate = 0;
                                      companyDetails.products[index].qty = 0;
                                    }
                                    setCompanyDetails({
                                      ...companyDetails,
                                      products: updatedProducts,
                                    });
                                    rowCalculation(index);
                                    calculateDetails();
                                  }}
                                />
                                <datalist id={`type-options-${index}`}>
                                  <option value="Addtional"></option>
                                </datalist>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="" className="d-none">
                              <div class="input-container w-100 m-1">
                                <input
                                  placeholder=" "
                                  type="text"
                                  id={`additional_description${index}`}
                                  name={`additional_description${index}`}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    companyDetails.products[
                                      index
                                    ].additional_description = value;
                                    setCompanyDetails({
                                      ...companyDetails,
                                    });
                                    calculateDetails();
                                  }}
                                />
                                <datalist id={`description-options-${index}`}>
                                  <option value="Addtional"></option>
                                </datalist>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <div class="input-container w-100 m-1">
                                <input
                                  placeholder=" "
                                  type="number"
                                  id={`total_days${index}`}
                                  name={`total_days${index}`}
                                  disabled={
                                    companyDetails.products[index].type == 1 ||
                                    companyDetails.products[index].type == 2 ||
                                    companyDetails.products[index].type == 3
                                      ? true
                                      : false
                                  }
                                  value={
                                    companyDetails.products[index].total_days
                                  }
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    companyDetails.products[index].total_days =
                                      value;
                                    setCompanyDetails({
                                      ...companyDetails,
                                    });
                                    rowCalculation(index);
                                    calculateDetails();
                                  }}
                                />
                                <datalist id={`total_days-options-${index}`}>
                                  <option value="Addtional"></option>
                                </datalist>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <div class="input-container w-100 m-1">
                                <input
                                  placeholder=" "
                                  type="text"
                                  id={`month_rate${index}`}
                                  name={`month_rate${index}`}
                                  value={
                                    companyDetails.products[index].month_rate
                                  }
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    companyDetails.products[index].month_rate =
                                      value;
                                    setCompanyDetails({
                                      ...companyDetails,
                                    });
                                    rowCalculation(index);
                                    calculateDetails();
                                  }}
                                />
                                <datalist id={`month_rate-options-${index}`}>
                                  <option value="Addtional"></option>
                                </datalist>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <div class="input-container w-100 m-1">
                                <input
                                  placeholder=" "
                                  type="text"
                                  id={`quantity${index}`}
                                  name={`quantity${index}`}
                                  value={companyDetails.products[index].qty}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    // let data = companyDetails.products[index];
                                    // companyDetails.products[index].price = (
                                    //   data.month_rate / data.total_days
                                    // ).toFixed(2);
                                    // companyDetails.products[index].vat_amt = (
                                    //   data.quantity *
                                    //   (data.vat / 100) *
                                    //   value
                                    // ).toFixed(2);
                                    // let data = companyDetails.products[index];
                                    companyDetails.products[index].qty = value;
                                    setCompanyDetails({
                                      ...companyDetails,
                                    });
                                    rowCalculation(index);
                                    calculateDetails();
                                  }}
                                />
                              </div>
                            </StyledTableCell>

                            <StyledTableCell align="">
                              <div class="input-container w-100 m-1">
                                <input
                                  placeholder=" "
                                  type="text"
                                  disabled
                                  id={`unit_price${index}`}
                                  name={`unit_price${index}`}
                                  value={companyDetails.products[index].price}
                                />
                                {/* onChange={(e) => {
                                    const { value } = e.target;
                                    let data = companyDetails.products[index];
                                    companyDetails.products[index].price =
                                      value;
                                    companyDetails.products[index].amount =
                                      value * data.qty;
                                    companyDetails.products[index].vat_amt = (
                                      data.quantity *
                                      (data.vat / 100) *
                                      value
                                    ).toFixed(2);
                                    setCompanyDetails({
                                      ...companyDetails,
                                    });
                                    calculateDetails();
                                  }} */}
                                {/* <label for={`${index}`}></label> */}
                              </div>
                            </StyledTableCell>
                            <StyledTableCell>
                              <div class="input-container w-100 m-1">
                                <input
                                  type="text"
                                  placeholder=" "
                                  id="tax"
                                  name="taxamout"
                                  disabled
                                  value={companyDetails.products[index].amount}
                                />
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <div class="input-container w-100 m-1">
                                <Select
                                  className="js-states select-control"
                                  options={[
                                    { id: 15, name: "VAT 15%" },
                                    { id: 0, name: "No VAT" },
                                  ]}
                                  id={`tax${index}`}
                                  name={`tax${index}`}
                                  placeholder="VAT Percent"
                                  disabled={false}
                                  dropdownHandle={true}
                                  searchable={true}
                                  labelField={"name"}
                                  valueField={"id"}
                                  onChange={(value) => {
                                    let updatedProducts = [
                                      ...companyDetails.products,
                                    ];
                                    updatedProducts[index].tax = value[0].id;
                                    setCompanyDetails({
                                      ...companyDetails,
                                      products: updatedProducts,
                                    });
                                    calculateDetails();
                                  }}
                                />
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="" className=" d-none">
                              <div class="input-container w-100 m-1">
                                <input
                                  placeholder=" "
                                  type="text"
                                  id={`discount${index}`}
                                  name={`discount${index}`}
                                  value={
                                    companyDetails?.products[index].discount
                                  }
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    companyDetails.products[index].discount =
                                      Number(value);
                                    setCompanyDetails({
                                      ...companyDetails,
                                    });
                                    calculateDetails();
                                  }}
                                />
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <div class="input-container w-100 m-1">
                                {/* <input
                                  value={companyDetails.products[index]?.amount}
                                  placeholder=" "
                                  disabled
                                  type="text"
                                  id={`amount${index}`}
                                  name={`amount${index}`}
                                  onChange={(e) => {
                                    console.log("object");
                                    calculateDetails();
                                  }}
                                /> */}
                                <input
                                  type="text"
                                  placeholder=" "
                                  id="net_amount"
                                  name="net_amount"
                                  disabled
                                  value={
                                    companyDetails.products[index].amount -
                                    companyDetails.products[index].discount +
                                    ((companyDetails.products[index].amount -
                                      companyDetails.products[index].discount) *
                                      companyDetails.products[index]?.tax) /
                                      100
                                  }
                                />

                                {/* <label for={`${index}`}></label> */}
                              </div>
                            </StyledTableCell>
                            {/* <StyledTableCell align="">
                                                              {dayjs(row.created_at).format(
                                                                  "MM-DD-YYYY hh:mm A"
                                                              )}
                                                          </StyledTableCell> */}
                            <StyledTableCell align="" className="button-items">
                              <a
                                href="javascript:void(0);"
                                title="Delete"
                                className="btn btn-danger delete-btn"
                                onClick={() => {
                                  handleDeleteRow(index);
                                }}
                              >
                                <i className="ri-delete-bin-line"></i>
                              </a>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between">
                <div className="invoice-width">
                  <div class="input-container w-100">
                    <textarea
                      id="net_amount"
                      rows={3}
                      name="net_amount"
                      onChange={(e) => handleInput(e, "customer_notes", "")}
                      required
                    />
                    <label for="net_amount">Customer Notes</label>
                  </div>
                </div>
                <div className="invoice-width">
                  <div class="input-container w-100">
                    <input
                      placeholder=" "
                      type="text"
                      id="total_amount"
                      name="total_amount"
                      value={companyDetails.sub_total}
                      disabled
                      required
                    />
                    <label for="total_amount">Total Amount</label>
                  </div>
                  <div class="input-container w-100">
                    <input
                      placeholder=" "
                      type="text"
                      id="discount"
                      name="discount"
                      value={companyDetails?.discount}
                      // onChange={(e) => {
                      //   const { value } = e.target;
                      //   companyDetails.discount = Number(value);
                      //   setCompanyDetails({
                      //     ...companyDetails,
                      //   });
                      //   calculateDetails();
                      // }}
                      disabled
                    />
                    <label for="discount">Discount</label>
                  </div>
                  <div className="input-container w-100"></div>
                  <div
                    class="input-container w-100"
                    style={{ display: "none" }}
                  >
                    <Select
                      className="js-states select-control"
                      options={[
                        { id: 15, name: "VAT 15%" },
                        { id: 0, name: "No VAT" },
                      ]}
                      placeholder="VAT Percent"
                      disabled
                      dropdownHandle={true}
                      searchable={true}
                      labelField={"name"}
                      valueField={"id"}
                      name="country_name"
                      onChange={(value) => {
                        companyDetails.tax = value[0].id;
                        setCompanyDetails({
                          ...companyDetails,
                        });
                        calculateDetails();
                      }}
                    />
                  </div>
                  <div class="input-container w-100">
                    <input
                      placeholder=" "
                      type="text"
                      id="vat_amount"
                      name="vat_amount"
                      disabled
                      value={companyDetails?.tax_amount}
                    />
                    <label for="vat_amount">VAT Amount</label>
                  </div>
                  {/* <div class="input-container w-100 ">
                    <input
                      placeholder=" "
                      type="text"
                      id="round_off"
                      name="round_off"
                      disabled
                      value={companyDetails.round_amount}
                    />
                    <label for="round_off">Round Off</label>
                  </div> */}
                  <div class="input-container w-100">
                    <input
                      placeholder=" "
                      type="text"
                      id="net_amount"
                      name="net_amount"
                      disabled
                      value={companyDetails.total}
                    />
                    <label for="net_amount">Net Amount</label>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <div className="text-end">
            <Button
              color="primary"
              className=" waves-effect waves-light me-1"
              onClick={handleSave}
              disabled={isLoading} 
            >
              {isLoading ? "Saving..." : "Save"}
            </Button>
          </div>
        </Container>
        <Col sm={6} md={4} xl={3} className="mt-4">
          <Modal
            size="xl"
            isOpen={state.modal_fullscreen}
            toggle={tog_fullscreen}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel">
                Add Service
              </h5>
              <button
                onClick={() => setState({ modal_fullscreen: false })}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col md="5">
                  <div className="input-container">
                    <input
                      placeholder=" "
                      type="text"
                      id="description"
                      name="description"
                      value={service.description}
                      {...register("description")}
                      onChange={handleInputChange}
                      onBlur={(e) => {
                        Translate(e.target.value, "description");
                      }}
                    />
                    <label htmlFor="description" className="required">
                      Service Description
                    </label>
                  </div>
                  {errors?.description?.message && (
                    <p className="error">{errors.description.message}</p>
                  )}
                </Col>

                <Col md="5">
                  <div className="input-container">
                    <input
                      placeholder=" "
                      type="text"
                      id="ar_description"
                      name="ar_description"
                      style={{ direction: "rtl" }}
                      value={service.ar_description}
                      {...register("ar_description")}
                      // onChange={handleInputChange}
                    />
                    <label htmlFor="ar_description" className="required">
                      Service Description - Arabic
                    </label>
                  </div>
                  {errors?.ar_description?.message && (
                    <p className="error">{errors.ar_description.message}</p>
                  )}
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => setState({ modal_fullscreen: false })}
                type="button"
                className="btn btn-secondary "
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => onSubmitHandler(e)}
              >
                Save changes
              </button>
            </div>
          </Modal>
        </Col>
      </div>
    </>
  );
};
export default SalesInvoice;
