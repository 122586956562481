import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "react-dropdown-select";
import { useNavigate, Link } from "react-router-dom";
// import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { customerValidationSchema } from "../../utils/Validation";
import { assestURL } from "../../services/config";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import _ from "lodash";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Container,
  Modal as ReactStrapModel,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  Spinner,
  Input,
} from "reactstrap";
//Import Breadcrumb
import { products } from "../../common/data/ecommerce";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import Breadcrumb from "../Common/Breadcrumb";
import dayjs from "dayjs";
import CustomParseFormat from "dayjs/plugin/customParseFormat";
import {
  salesinvoiceData,
  salesinvoiceSelector,
  clearSinvLoadingDatas,
  clearSalesInvData,
  clearErrormsg,
  clearData,
  generatePdf,
  setProforma,
  setDraft,
  clearPdfData,
  setInvoiceStat,
  getSalesStat,
  getZatcaValidation,
  viewZatca,
  searchInvoiceDetails,
  sendPdfEmail,
  createPaymentDetails,
  cancelInvoiceStat,
  getInvoiceDetails,
} from "../../store/reducer/SalesInvoiceReducer";
import {
  API_STATUS,
  paymentType,
  paymentTypewithcheck,
} from "../../utils/constants";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  companySelector,
  getCompanyDetails,
} from "../../store/reducer/CompanyReducer";
import { Box, Drawer, Modal, Typography } from "@mui/material";
import { debounce } from "lodash";
import { usersSelector } from "../../store/reducer/UserReducer";
const SalesInvoiceList = () => {
  dayjs.extend(CustomParseFormat);
  const dropdownValues = {
    0: {
      data: [
        { handlerName: "setProfoma", label: "Set To Proforma" },
        { handlerName: "setInvoice", label: "Set To Invoice" },
        { handlerName: "cancelInvoice", label: "Cancel Invoice" },
      ],
    },
    1: {
      data: [
        { handlerName: "setInvoice", label: "Set To Invoice" },
        { handlerName: "cancelInvoice", label: "Cancel Invoice" },
        // , { handlerName: 'setDraft', label: 'Set To Draft' }
      ],
    },
    4: {
      data: [
        // { handlerName: "setInvoice", label: "Set To Invoice" },

        { handlerName: "validateZatca", label: "Validate Zatca" },
        // , { handlerName: 'setDraft', label: 'Set To Draft' }
      ],
    },
    5: {
      data: [
        // { handlerName: "setInvoice", label: "Set To Invoice" },

        { handlerName: "validateZatca", label: "Validate Zatca" },
        // , { handlerName: 'setDraft', label: 'Set To Draft' }
      ],
    },
    7: {
      data: [
        // { handlerName: "setInvoice", label: "Set To Invoice" },

        { handlerName: "validateZatca", label: "Validate Zatca" },
        // , { handlerName: 'setDraft', label: 'Set To Draft' }
      ],
    },
    6: {
      data: [
        // { handlerName: 'setProfoma', label: 'Set To Proforma' }, { handlerName: 'setDraft', label: 'Set To Draft' }
      ],
    },
    2: {
      data: [
        { handlerName: "cancelInvoice", label: "Cancel Invoice" },
        // { handlerName: 'setProfoma', label: 'Set To Proforma' }, { handlerName: 'setDraft', label: 'Set To Draft' }
      ],
    },
  };
  const style = {
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 30,
    p: 4,
  };

  const infoStyle = {
    boxShadow: " 2px 4px 2px 4px #d6e6ff ",
    padding: "10px",
  };

  const WarningStyle = {
    boxShadow: " 2px 4px 2px 4px #ffd28e ",
    padding: "10px",
    marginTop: "16px",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();
  const { selectScreenData } = useSelector(usersSelector);
  console.log(selectScreenData, 4567890);
  const dispatch = useDispatch();
  let [sortBy, setSortBy] = useState("invoice_date");
  const [openDrawer, setOpenDrawer] = useState(false);
  let [activesortBy, setActiveSortBy] = useState("invoice_date");
  const [info, setInfo] = useState({ 0: false });
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("desc");
  let [searchQuery, setSearchQuery] = useState("");
  const [userActions, setUserActions] = useState({});
  console.log(userActions, "2345678");
  console.log(searchQuery, "searchQuery");
  const [state, setState] = useState({ modal_fullscreen: false });
  let { company_details } = useSelector(companySelector);
  const [accord, setAccord] = useState({
    col1: false,
    col2: false,
    col3: false,
  });
  const [id, setId] = useState({
    id: 0,
    email: "",
  });
  console.log(id, "sfjdsa");
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState();
  const [isWarning, setIsWarning] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const {
    salesinvoiceDatas,
    salesinvoiceCount,
    loading,
    errorMessage,
    pdfMessage,
    setDraftLoading,
    generateLoading,
    getZatcaValidationloading,
    setProformaLoading,
    setInvoiceStatLoading,
    getSalesStatLoading,
    StatDatas,
    zatcaData,
    mailSentLoaing,
    createPaymentDetailsloading,
    setCancelLoading,
    invoiceDetails,
  } = useSelector(salesinvoiceSelector);
  console.log(invoiceDetails, "invoiceDetadils");

  const navigate = useNavigate();
  console.log(mailSentLoaing, "mailSentLoaing");
  const tog_fullscreen = () => {
    navigate("/psales/new");
  };
  console.log(company_details, "company_details");
  dropdownValues[2].data = company_details?.zatca_validation
    ? [
        // { handlerName: 'setProfoma', label: 'Set To Proforma' }, { handlerName: 'setDraft', label: 'Set To Draft' },
        { handlerName: "validateZatca", label: "Validate Zatca" },
        { handlerName: "cancelInvoice", label: "Cancel Invoice" },
      ]
    : [
        { handlerName: "setProfoma", label: "Set To Proforma" },
        //  , { handlerName: 'setDraft', label: 'Set To Draft' }
      ];

  const creditAmount = (row) => {
    let invoice_credits = _.filter(invoiceDetails, {
      invoice_main_id: row.id,
      credit_debit_type: 381,
    });
    console.log(row, invoice_credits, "invoicecreduts");
    let total_cred = 0;
    invoice_credits?.forEach((invoice) => {
      if (invoice.status != 8) {
        total_cred += parseFloat(invoice.total);
      }
    });
    return total_cred;
  };

  const debitAmount = (row) => {
    let invoice_credits = _.filter(invoiceDetails, {
      invoice_main_id: row.id,
      credit_debit_type: 383,
    });
    console.log(row, invoice_credits, "invoicecreduts");
    let total_cred = 0;
    invoice_credits?.forEach((invoice) => {
      if (invoice.status != 8) {
        total_cred += parseFloat(invoice.total);
      }
    });
    return total_cred;
  };

  useEffect(() => {
    console.log(generateLoading, "generateLoading");
    if (generateLoading === "Fulfilled") {
      window.open(`${assestURL}${pdfMessage}`, "_blank", "noreferrer");
    }
    dispatch(clearPdfData());
  }, [pdfMessage]);
  const handleDropDown = (data, type) => {
    console.log(data, "data row");
    if (type === "print") {
      dispatch(generatePdf(data.id));
    }
    if (type === "setProfoma") {
      dispatch(setProforma({ id: data.id }));
    }
    if (type === "setDraft") {
      dispatch(setDraft({ id: data.id }));
    }
    if (type === "setInvoice") {
      dispatch(setInvoiceStat({ id: data.id }));
    }
    if (type === "cancelInvoice") {
      dispatch(cancelInvoiceStat({ id: data.id }));
    }
    if (type === "validateZatca") {
      dispatch(getZatcaValidation({ ID: data.id }));
    }
    if (type == "Send Email") {
      handleClickOpen();
      console.log(data, "iuiofsdif");
      setId({
        email: data?.customer?.contact_email,
        id: data?.id,
      });
    }
  };
  const getUserActionKey = (handlerName) => {
    console.log(handlerName, 567890675);
    switch (handlerName) {
      case "print":
        return "Sales Invoice-print";
      case "setProfoma":
        return "Sales Invoice-proforma";
      case "setDraft":
        return "Sales Invoice-draft";
      case "setInvoice":
        return "Sales Invoice-invoice";
      case "validateZatca":
        return "Sales Invoice-validate";
      case "Send Email":
        return "Sales Invoice-sendMail";
      default:
        return null;
    }
  };
  const toggleModal = (e) => {
    console.log(e, "  ");
    console.log(e.payment_balance, creditAmount(e), debitAmount(e), "546789");
    setIsModalOpen(!isModalOpen);
    setValue("invoice_bal", e.total);
    setValue("pay_bal", e.payment_balance - creditAmount(e) + debitAmount(e));
    setData(e);
  };
  const onSubmit = (formData) => {
    console.log("Submitted Data:", formData);
    delete formData.delete;
    delete formData.pay_bal;
    formData.customer_id = data?.customer?.id;

    formData.payment_type =
      formData?.payment_type && Number(formData?.payment_type[0]?.id);
    formData.payment_date = dayjs();
    formData.invoice_ids = [data?.id];
    dispatch(createPaymentDetails({ formData }));
  };

  const getDates = (status, createdDate) => {
    const today = dayjs();
    const created = dayjs(createdDate, "DD-MM-YYYY");
    const differenceInDays = today.diff(created, "day");
    switch (status) {
      case 0:
      case 1:
        return differenceInDays >= 0 ? differenceInDays : 0;
      default:
        return "-";
    }
  };

  const salesDispatch = () => {
    dispatch(clearSalesInvData());
    dispatch(
      salesinvoiceData({
        query: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
        sortby: sortBy,
        order: order,
      })
    );
    dispatch(getSalesStat({}));
    dispatch(getCompanyDetails());
    dispatch(clearSinvLoadingDatas());
    dispatch(
      getInvoiceDetails({
        query: "",
        page: page + 1,
        limit: rowsPerPage,
        sortby: sortBy,
        order: order,
      })
    );
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
  };

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
    salesDispatch();
  };
  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    salesDispatch();
  };
  const searchData = (e) => {
    searchQuery = e;
    setSearchQuery(e);
    setPage(0);
    dispatch(searchInvoiceDetails({ e }));
  };

  const handleZatcaView = (data) => {
    setOpenDrawer(true);
    console.log("first");
    dispatch(viewZatca({ ID: data?.id }));
  };

  const debounceSearch = useCallback(debounce(searchData, 500), []);

  const handleAmountValidation = (event) => {
    const { value } = event.target;
    const enteredAmount = parseFloat(value);
    if (enteredAmount > data?.total) {
      toastr.warning("Amount Should be equal or lesser than total amount");
      setIsWarning(true);
    } else {
      setIsWarning(false);
    }
    if (data?.payment_status != 0 && enteredAmount > data?.payment_balance) {
      toastr.warning("Amount Should be equal or lesser than total amount");
      setIsWarning(true);
    } else {
      setIsWarning(false);
    }
  };
  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
    dispatch(
      salesinvoiceData({
        query: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
        sortby: sortBy,
        order: order,
      })
    );
  };

  useEffect(() => {
    salesDispatch();
  }, []);
  useEffect(() => {
    if (selectScreenData && selectScreenData.length > 0) {
      const value = selectScreenData[0]?.actions;
      setUserActions(value || {});
    }
  }, [selectScreenData]);
  useEffect(() => {
    if (createPaymentDetailsloading === API_STATUS.FULFILLED) {
      toastr.clear();
      setIsModalOpen(false);
      dispatch(clearSinvLoadingDatas());
      salesDispatch();

      toastr.success("Payment Success");
      reset();
    }
  }, [createPaymentDetailsloading]);

  useEffect(() => {
    if (setDraftLoading === API_STATUS.FULFILLED) {
      toastr.success("Updated");
      salesDispatch();
      dispatch(clearSinvLoadingDatas());
    }
    if (getZatcaValidationloading === API_STATUS.FULFILLED) {
      toastr.success("Updated");
      salesDispatch();
      dispatch(clearSinvLoadingDatas());
    }
    if (setProformaLoading === API_STATUS.FULFILLED) {
      toastr.success("Updated");
      salesDispatch();
      dispatch(clearSinvLoadingDatas());
    }
    if (setInvoiceStatLoading === API_STATUS.FULFILLED) {
      toastr.success("Updated");
      salesDispatch();
      dispatch(clearSinvLoadingDatas());
    }
    if (setCancelLoading === API_STATUS.FULFILLED) {
      toastr.success("Invoice Cancelled");
      salesDispatch();
      dispatch(clearSinvLoadingDatas());
    }
    if (mailSentLoaing === API_STATUS.FULFILLED) {
      toastr.success("Mail Send Success");
      salesDispatch();
      dispatch(clearSinvLoadingDatas());
    }
    console.log(setDraftLoading, "setDraftLoading");
  }, [
    setDraftLoading,
    setCancelLoading,
    setProformaLoading,
    setInvoiceStatLoading,
    getSalesStatLoading,
    getZatcaValidationloading,
    mailSentLoaing,
  ]);

  const ProcessDATA = (datas) => {
    return (
      datas &&
      JSON.parse(datas).map((info) => {
        return <p style={{ textIndent: "16px" }}>{info?.message}</p>;
      })
    );
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-content-between">
            <h2>Sales Invoice</h2>
            {userActions?.["Service Inventory-add"] && (
              <button
                type="button"
                className="waves-effect waves-light btn btn-primary mb-2"
                onClick={tog_fullscreen}
              >
                Add
              </button>
            )}
          </div>
          <Row>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Total Invoices This Year
                      </p>
                      <h4 className="mb-0">
                        {StatDatas?.invoice_this_year?.length}
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-stack-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>

                {/* <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge badge-soft-success font-size-11 me-1">
                                            <i className="mdi mdi-menu-up"> </i> 2
                                        </span>
                                        <span className="text-muted ms-2">Increase</span>
                                    </div>
                                </CardBody> */}
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Total Invoices This Month
                      </p>
                      <h4 className="mb-0">
                        {StatDatas?.invoice_this_month?.length}
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-funds-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>

                {/* <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge badge-soft-danger font-size-11 me-1">
                                            <i className="mdi mdi-menu-down"> </i> 2
                                        </span>
                                        <span className="text-muted ms-2">Decrease</span>
                                    </div>
                                </CardBody> */}
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Number of Proforma
                      </p>
                      <h4 className="mb-0">
                        {StatDatas?.no_of_proforma?.length}
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-stack-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>

                {/* <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge badge-soft-success font-size-11 me-1">
                                            <i className="mdi mdi-menu-up"> </i> 2
                                        </span>
                                        <span className="text-muted ms-2">Increase</span>
                                    </div>
                                </CardBody> */}
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        No of Quotation
                      </p>
                      <h4 className="mb-0">
                        {StatDatas?.no_of_drafted?.length}
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-funds-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>

                {/* <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge badge-soft-success font-size-11 me-1">
                                            <i className="mdi mdi-menu-up"> </i> 2
                                        </span>
                                        <span className="text-muted ms-2">Increase</span>
                                    </div>
                                </CardBody> */}
              </Card>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-md-8">
                  {/* <h5>
                    {" "}
                    <span>{}</span>
                  </h5> */}
                  {/* <Button color="success" outline>
                    {" "}
                    Select All for Zatca Validation
                  </Button> */}
                </div>
                <div className="col-md-4 row">
                  <input
                    type="text"
                    name="search"
                    className="form-control"
                    style={{ float: "right" }}
                    // value={searchQuery}
                    onChange={(e) => {
                      debounceSearch(e.target.value);
                    }}
                    placeholder="Search"
                  />
                </div>
              </div>
              <div className="table-responsive react-table">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} stickyHeader>
                    <TableHead className="table-light table-nowrap">
                      <TableRow>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "invoice_no" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("invoice_no");
                            }}
                          >
                            Invoice No
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "customer_name" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("customer_name");
                            }}
                          >
                            Customer
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "invoice_date" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("invoice_date");
                            }}
                          >
                            Invoice Date
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "total" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("total");
                            }}
                          >
                            Invoice Amount
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "total" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("total");
                            }}
                          >
                            Outstanding <br /> Amount
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "total" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("total");
                            }}
                          >
                            Days from <br />
                            Create Date
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "status" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("status");
                            }}
                          >
                            Status
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">Zatca Status</StyledTableCell>
                        <StyledTableCell align="">Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {generateLoading !== "Pending" &&
                    getZatcaValidationloading !== "Pending" ? (
                      <TableBody>
                        {salesinvoiceDatas &&
                          salesinvoiceDatas.map((row, index) => {
                            const relatedEntries = invoiceDetails?.filter(
                              (ele) => ele.invoice_main_id === row.id
                            );
                            let originalAmount = parseFloat(row?.total) || 0;
                            let totalCredits = 0;
                            let totalDebits = 0;
                            let hasCredit = false;
                            let hasDebit = false;
                            let hasCreditDebitEntries = false;
                            relatedEntries?.forEach((invoice) => {
                              if (
                                invoice.status == 2 ||
                                invoice.status == 3 ||
                                invoice.status == 5
                              ) {
                                if (invoice.credit_debit_type === 0) {
                                  originalAmount = parseFloat(invoice.total);
                                } else if (invoice.credit_debit_type === 381) {
                                  totalCredits += parseFloat(invoice.total);
                                  hasCredit = true;
                                  hasCreditDebitEntries = true;
                                } else if (invoice.credit_debit_type === 383) {
                                  totalDebits += parseFloat(invoice.total);
                                  hasDebit = true;
                                  hasCreditDebitEntries = true;
                                }
                              }
                            });
                            let finalTotal;
                            if (hasCredit && hasDebit) {
                              finalTotal =
                                originalAmount - totalCredits + totalDebits;
                            } else if (hasCredit) {
                              finalTotal = originalAmount - totalCredits;
                            } else if (hasDebit) {
                              finalTotal = originalAmount + totalDebits;
                            } else {
                              finalTotal = originalAmount;
                            }
                            return (
                              <StyledTableRow>
                                <StyledTableCell align="">
                                  {row?.credit_debit_type == 0
                                    ? row?.invoice_no
                                    : row?.credit_note_no +
                                      " (" +
                                      row?.invoice_no +
                                      ")"}
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  {row?.customer?.customer_name}
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  {dayjs(row?.invoice_date).format(
                                    "DD-MM-YYYY HH:mm"
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  {parseFloat(row?.total ?? 0).toFixed(2)}
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  {finalTotal.toFixed(2) -
                                    (row?.total - row?.payment_balance) >=
                                  0
                                    ? finalTotal.toFixed(2) -
                                      (row?.total - row?.payment_balance)
                                    : 0}
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  {/* {row?.status==0 || row?.status==1} */}
                                  {getDates(
                                    row.status,
                                    dayjs(row?.invoice_date).format(
                                      "DD-MM-YYYY"
                                    )
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  {row.status === 0
                                    ? "Quotation"
                                    : row.status === 1
                                    ? "Proforma"
                                    : row.status === 8
                                    ? "Cancelled"
                                    : row.status === 2 ||
                                      row.status === 3 ||
                                      row.status === 4
                                    ? "Invoice"
                                    : row?.credit_debit_type == 381
                                    ? "Credit Note"
                                    : "Debit Note"}
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  {row.status == 3
                                    ? "Validated"
                                    : row.status === 4
                                    ? "Error"
                                    : row.status === 6
                                    ? "Validated"
                                    : row?.status == 7
                                    ? "Error"
                                    : "-"}
                                  {}
                                </StyledTableCell>
                                <StyledTableCell
                                  align=""
                                  className="button-items"
                                >
                                  <div className="d-flex">
                                    {(row?.status === 3 || row?.status === 2) &&
                                      (row?.payment_status === 0 ||
                                        row?.payment_status === 2) && (
                                        <div
                                          className="btn  "
                                          style={{ backgroundColor: "#FFD700" }}
                                          title="Pay Amount"
                                          onClick={() => {
                                            toggleModal(row);
                                          }}
                                        >
                                          <i style={{ color: "#000000" }}>﷼</i>
                                        </div>
                                      )}

                                    {row?.credit_debit_type == 0 &&
                                    row?.status != 8 ? (
                                      <a
                                        href="javascript:void(0);"
                                        title="Edit"
                                        className="btn btn-primary  "
                                        onClick={() => {
                                          navigate(`/psales/edit/${row.id}`);
                                        }}
                                      >
                                        <i className=" ri-ball-pen-line"></i>
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                    <div
                                      className="btn btn-success "
                                      title="Send Email"
                                      onClick={() =>
                                        handleDropDown(row, "Send Email")
                                      }
                                    >
                                      <i class="ri-mail-send-line"></i>
                                    </div>
                                    <div
                                      className="btn btn-secondary"
                                      title="print"
                                      onClick={() =>
                                        handleDropDown(row, "print")
                                      }
                                    >
                                      <i className=" ri-printer-line"></i>
                                    </div>

                                    {/* <a
                                                                          href="javascript:void(0);"
                                                                          title="Validate Zatca"
                                                                          className="btn btn-success"
                                                                          onClick={handleZatca}
                                                                      >
                                                                          <i class="ri-send-plane-fill"></i>
                                                                      </a> */}
                                    {row.status != 3 &&
                                    row.status != 6 &&
                                    row.status != 8 ? (
                                      <>
                                        <Dropdown
                                          isOpen={info[index]}
                                          direction="left"
                                          toggle={() => {
                                            info[index] = !info[index];
                                            setInfo({ ...info });
                                          }}
                                        >
                                          <DropdownToggle color="danger" caret>
                                            <i class="ri-more-2-line"></i>
                                          </DropdownToggle>
                                          <DropdownMenu>
                                            {dropdownValues[
                                              row.status
                                            ].data.map((items) => {
                                              return (
                                                <DropdownItem
                                                  onClick={() =>
                                                    handleDropDown(
                                                      row,
                                                      items.handlerName
                                                    )
                                                  }
                                                >
                                                  {items.label}
                                                </DropdownItem>
                                              );
                                            })}
                                          </DropdownMenu>
                                        </Dropdown>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {row.status == 3 ||
                                    row.status == 4 ||
                                    row.status == 6 ||
                                    row.status == 7 ? (
                                      <>
                                        <div
                                          className="btn btn-success"
                                          title="View"
                                          onClick={() => handleZatcaView(row)}
                                        >
                                          <i class="ri-eye-line"></i>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })}
                      </TableBody>
                    ) : (
                      <td colSpan={7}>
                        <div
                          className="d-flex justify-content-center"
                          style={{ width: "100%" }}
                        >
                          <Spinner className="m-2" color="success" />
                        </div>
                      </td>
                    )}
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50]}
                          colSpan={9}
                          count={salesinvoiceCount}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
            </CardBody>
          </Card>
        </Container>

        <Modal
          open={openDrawer}
          onClose={toggleDrawer(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="overflow-auto"
        >
          <Box sx={style}>
            <div className="overflow-auto">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <div className="d-flex justify-content-between">
                  <h3>Zatca Response</h3>
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={toggleDrawer(false)}
                  >
                    <i class="ri-close-line"></i>
                  </p>
                </div>
              </Typography>
              <hr />
              <Typography id="modal-modal-description">
                <div>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} stickyHeader>
                      <TableHead className="table-light table-nowrap">
                        <TableRow>
                          <StyledTableCell align="">
                            Complaince Status
                          </StyledTableCell>
                          <StyledTableCell align="">
                            Clearance Status
                          </StyledTableCell>
                          <StyledTableCell align="">Message</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {zatcaData?.viewData &&
                          zatcaData?.viewData.map((row, index) => (
                            <StyledTableRow>
                              <StyledTableCell align="">
                                {row.status}
                              </StyledTableCell>
                              <StyledTableCell align="">
                                {row?.clearancestatus ?? row?.reportstatus}
                              </StyledTableCell>
                              <StyledTableCell align="">
                                {row?.status == "WARNING"
                                  ? ProcessDATA(row?.warnings)
                                  : ProcessDATA(row?.error)}
                              </StyledTableCell>
                              <StyledTableCell align=""></StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div>
                  {/* <div style={infoStyle}>
                          <div>
                            <b>Type : </b>
                            <p style={{ textIndent: "16px" }}>{info?.type}</p>
                          </div>
                          <div>
                            <b>Code : </b>
                            <p style={{ textIndent: "16px" }}>{info?.code}</p>
                          </div>
                          <div>
                            <b>Category : </b>
                            <p style={{ textIndent: "16px" }}>
                              {info?.category}
                            </p>
                          </div>
                          <div>
                            <b>Message : </b>
                            <p style={{ textIndent: "16px" }}>
                              {info?.message}
                            </p>
                          </div>
                          <div>
                            <b>Status : </b>
                            <p style={{ textIndent: "16px" }}>{info?.status}</p>
                          </div>
                        </div> */}
                  {/* {zatcaData?.viewData &&
                    JSON.parse(zatcaData.viewData?.warnings).map((info) => {
                      return (
                        <div style={WarningStyle}>
                          <div>
                            <b>Type : </b>
                            <p style={{ textIndent: "16px" }}>{info?.type}</p>
                          </div>
                          <div>
                            <b>Code : </b>
                            <p style={{ textIndent: "16px" }}>{info?.code}</p>
                          </div>
                          <div>
                            <b>Category : </b>
                            <p style={{ textIndent: "16px" }}>
                              {info?.category}
                            </p>
                          </div>
                          <div>
                            <b>Message : </b>
                            <p style={{ textIndent: "16px" }}>
                              {info?.message}
                            </p>
                          </div>
                          <div>
                            <b>Status : </b>
                            <p style={{ textIndent: "16px" }}>{info?.status}</p>
                          </div>
                        </div>
                      );
                    })} */}
                </div>
              </Typography>
            </div>
          </Box>
        </Modal>
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              component: "form",
              onSubmit: (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());
                const email = formJson.email;
                console.log(email, "77jjh");
                const combinedData = {
                  ...formJson,
                  id: id.id,
                };
                dispatch(sendPdfEmail(combinedData));
                handleClose();
              },
            }}
          >
            <DialogTitle>Invoice Email Data</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please Verify Or Enter your email address here. We will send
                Invoice for your email.
              </DialogContentText>
              <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                name="email"
                label="Email Address"
                type="email"
                fullWidth
                variant="standard"
                defaultValue={id.email}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Send</Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
        <Col sm={6} md={4} xl={3} className="mt-4">
          <ReactStrapModel size="xl" isOpen={isModalOpen} toggle={toggleModal}>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel">
                Payment Details
              </h5>
              <button
                onClick={toggleModal}
                type="button"
                className="close"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleSubmit((data, event) => {
                  event.preventDefault();
                  onSubmit(data);
                })}
              >
                <Row>
                  {/* Invoice Number Input */}
                  <Col md="6">
                    <div className="input-container">
                      <input
                        placeholder=" "
                        type="text"
                        id="invoice_bal"
                        name="invoice_bal"
                        {...register("invoice_bal")}
                        readOnly
                      />
                      <label htmlFor="invoice_bal">Invoice Amount</label>
                    </div>
                    {/* {errors?.pay_amt?.message && (
                  <p className="error mx-4">{errors?.invoice_bal?.message}</p>
                )} */}
                  </Col>

                  <Col md="6">
                    <div className="input-container">
                      <input
                        placeholder=" "
                        type="text"
                        id="pay_amt"
                        name="pay_amt"
                        {...register("pay_amt")}
                        onChange={handleAmountValidation}
                      />
                      <label htmlFor="pay_amt">Pay Amount</label>
                    </div>
                    {errors?.pay_amt?.message && (
                      <p className="error mx-4">{errors?.pay_amt?.message}</p>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="input-container">
                      <input
                        placeholder=" "
                        type="text"
                        id="pay_bal"
                        name="pay_bal"
                        readOnly
                        {...register("pay_bal")}
                        // onChange={handleAmountValidation}
                      />
                      <label htmlFor="pay_bal">Invoice Balance Amt</label>
                    </div>
                    {errors?.pay_amt?.message && (
                      <p className="error mx-4">{errors?.pay_amt?.message}</p>
                    )}
                  </Col>

                  <Col md="6">
                    <div className="input-container">
                      <Controller
                        name="payment_type"
                        control={control}
                        render={({ field }) => (
                          <Select
                            className="js-states select-control"
                            placeholder="Payment Type"
                            options={paymentTypewithcheck}
                            {...field}
                            isClearable
                            searchable
                            labelField="mode"
                            valueField="id"
                          />
                        )}
                      />
                      {errors?.payment_type?.message && (
                        <p className="error">{errors.payment_type.message}</p>
                      )}
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="input-container">
                      <input
                        placeholder=" "
                        type="date"
                        defaultValue={dayjs().format("YYYY-MM-DD")}
                        id="payment_date"
                        name="payment_date"
                        {...register("payment_date")}
                      />
                      <label htmlFor="payment_date">Payment Date</label>
                    </div>
                  </Col>
                </Row>
                <Row></Row>
              </form>
            </div>
            <div className="modal-footer">
              <Button color="secondary" onClick={toggleModal}>
                Close
              </Button>
              <Button
                color="primary"
                disabled={isWarning}
                onClick={handleSubmit(onSubmit)}
              >
                Submit Payment
              </Button>
            </div>
          </ReactStrapModel>
        </Col>
      </div>
    </>
  );
};
export default SalesInvoiceList;
