import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "react-dropdown-select";
import { useNavigate, Link } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import {
  customerValidationSchema,
  reportsValidation,
} from "../../utils/Validation";
import { read, utils, writeFile } from "xlsx";

import { Card, CardBody, Container, Button } from "reactstrap";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import dayjs from "dayjs";
import {
  clearPurchaseData,
  purchaseInvoiceList,
  purchaseOrderList,
  purchaseOrderListAll,
  purchaseReturnList,
  purchaseSelector,
  vendorpaymentList,
} from "../../store/reducer/PurchaseReducer";
import {
  paymentTypewithcheck,
  typesOfPayments,
  PinvoiceStatus,
  paymentStatus,
} from "../../utils/constants";

import {
  clearVendorDeleteLoading,
  clearVendorLoadingDatas,
  DeleteVendor,
  vendorData,
  vendorsSelector,
} from "../../store/reducer/VendorReducer";
import toastr from "toastr";

const Report = () => {
  const dispatch = useDispatch();
  let [sortBy, setSortBy] = useState("invoice_no");
  let [activesortBy, setActiveSortBy] = useState("invoice_no");
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("asc");
  let [searchQuery, setSearchQuery] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("");
  const [selectedZatcaStatus, setSelectedZatcaStatus] = useState("");
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState("");
  const [state, setState] = useState({ from_date: "", to_date: "" });
  const [value, setValues] = useState(null);
  const [info, setInfo] = useState({ 0: false });
  const {
    purchaseOrders,
    purchaseInvoiceListData,
    purchaseReturnListData,
    vendorPaymentListData,
    purchaseOrdersAll,
  } = useSelector(purchaseSelector);
  console.log(purchaseOrders, purchaseOrdersAll, "thiru");
  const { vendorDatas, vendorCount, deleteloading } =
    useSelector(vendorsSelector);
    console.log(vendorDatas, "23456789jkhk")
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(reportsValidation),
  });

  const navigate = useNavigate();
  const [type, setType] = useState(2);
  const [quotationXcelDetails, setQuotationXcelDetails] = useState([]);
  const types = [
    {
      type: 1,
      label: "Purchase Order",
      dispatchFile: purchaseOrderList,
    },
    {
      type: 2,
      label: "Purchase Invoice",
      dispatchFile: purchaseInvoiceList,
    },
    {
      type: 3,
      label: "Purchase Return",
      dispatchFile: purchaseReturnList,
    },
    {
      type: 4,
      label: "Vendor Payment",
      dispatchFile: vendorpaymentList,
    },
  ];

  const handleCustomerChange = (selectedOption) => {
    setSelectedCustomer(selectedOption[0]?.id);
  };
  const handlePaymentStatusChange = (selectedOption) => {
    setSelectedPaymentStatus(selectedOption[0]?.value);
  };
  const handleInvoiceStatusChange = (selectedOption) => {
    setSelectedInvoiceStatus(selectedOption[0]?.value);
  };

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
    const payload = {
      query: searchQuery,
      page: page + 1,
      limit: rowsPerPage,
      sortby: sortBy,
      order: order,
      vendor_id: selectedCustomer ?? "",
      payment_status: selectedPaymentStatus ?? "",
      invoice_status: selectedInvoiceStatus ?? "",
      from_date: value?.[0] ?? "",
      to_date: value?.[1] ?? "",
    };
    dispatch(purchaseOrderList(payload));
  };

  const showButton = (formData) => {
    console.log(formData, "345678");
    // const type = formData.type;
    // if (type) {
    //   setType(type);
    //   setState({
    //     from_date: value?.[0] ?? "",
    //     to_date: value?.[1] ?? "",
    //   });
    //   dispatch(
    //     types
    //       .find((ele) => ele.type == type)
    //       ?.dispatchFile({
    //         query: searchQuery,
    //         page: page + 1,
    //         limit: rowsPerPage,
    //         sortby: sortBy,
    //         order: order,
    //         from_date: value?.[0] ?? "",
    //         to_date: value?.[1] ?? "",
    //       })
    //   );
    // } else {
    //   toastr.error("Select Any One Type");
    // }
    const payload = {
      query: searchQuery,
      page: page + 1,
      limit: rowsPerPage,
      sortby: sortBy,
      order: order,
      vendor_id: selectedCustomer ?? "",
      payment_status: selectedPaymentStatus ?? "",
      invoice_status: selectedInvoiceStatus ?? "",
      from_date: value?.[0] ?? "",
      to_date: value?.[1] ?? "",
    };
    dispatch(purchaseOrderList(payload));
    const val = {
      query: searchQuery,
      page: page + 1,
      limit: 0,
      sortby: sortBy,
      order: order,
      vendor_id: selectedCustomer ?? "",
      payment_status: selectedPaymentStatus ?? "",
      invoice_status: selectedInvoiceStatus ?? "",
      from_date: value?.[0] ?? "",
      to_date: value?.[1] ?? "",
    };
    dispatch(purchaseOrderListAll(val));
  };

  // useEffect(() => {
  //   const payload = {
  //     query: searchQuery,
  //     page: page + 1,
  //     limit: rowsPerPage,
  //     sortby: sortBy,
  //     order: order,
  //     from_date: state.from_date,
  //     to_date: state.to_date,
  //   };
  //   if (type == 1) {
  //     dispatch(purchaseOrderList(payload));
  //   } else if (type == 2) {
  //     dispatch(purchaseInvoiceList(payload));
  //   } else if (type == 3) {
  //     dispatch(purchaseReturnList(payload));
  //   } else if (type == 4) {
  //     dispatch(vendorpaymentList(payload));
  //   }
  // }, [searchQuery]);

  useEffect(() => {
    let data = [];
    console.log(type, "quotationdetails x");
    if (type == 1) {
      const xcelData = purchaseOrders?.rows?.map((ele) => {
        const { status, trash, created_at, updated_at, vendor, ...rest } = ele;
        return { ...rest, vendor: vendor.vendor_name };
      });
      setQuotationXcelDetails(xcelData);
    } else if (type == 2) {
      const xcelData = purchaseInvoiceListData?.rows?.map((ele) => {
        const {
          status,
          trash,
          created_at,
          updated_at,
          created_by,
          purchase_order,
          ...rest
        } = ele;
        return rest;
      });
      setQuotationXcelDetails(xcelData);
    } else if (type == 3) {
      data = purchaseReturnListData?.rows;
      const xcelData = purchaseReturnListData?.rows?.map((ele) => {
        const { status, trash, created_at, updated_at, ...rest } = ele;
        return rest;
      });
      setQuotationXcelDetails(xcelData);
    } else if (type == 4) {
      const xcelData = vendorPaymentListData?.rows?.map((ele) => {
        const { status, trash, created_at, updated_at, ...rest } = ele;
        return rest;
      });
      setQuotationXcelDetails(xcelData);
    }
  }, [
    purchaseOrders,
    purchaseReturnListData,
    purchaseInvoiceListData,
    vendorPaymentListData,
  ]);

  const exportFile = useCallback(() => {
    const ws = utils.json_to_sheet(quotationXcelDetails);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    const keys = Object.keys(ws);
    const CaptKeys = keys.slice(keys.indexOf("A1"), -1);
    CaptKeys.map((ele) => {
      ws[ele].v = ws[ele]?.v?.toLocaleUpperCase().split("_").join(" ");
    });
    writeFile(wb, `${types.find((ele) => ele.type == type)?.label}.xlsx`);
  }, [quotationXcelDetails]);

  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    const payload = {
      query: searchQuery,
      page: page + 1,
      limit: rowsPerPage,
      sortby: sortBy,
      order: order,
      vendor_id: selectedCustomer ?? "",
      payment_status: selectedPaymentStatus ?? "",
      invoice_status: selectedInvoiceStatus ?? "",
      from_date: value?.[0] ?? "",
      to_date: value?.[1] ?? "",
    };
    dispatch(purchaseOrderList(payload));
  };
  const searchData = (e) => {
    searchQuery = e.target.value;
    setSearchQuery(e.target.value);
    setPage(0);
  };

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
    const payload = {
      query: searchQuery,
      page: page + 1,
      limit: rowsPerPage,
      sortby: sortBy,
      order: order,
      from_date: state.from_date,
      to_date: state.to_date,
    };
    if (type == 1) {
      dispatch(purchaseOrderList(payload));
    } else if (type == 2) {
      dispatch(purchaseInvoiceList(payload));
    } else if (type == 3) {
      dispatch(purchaseReturnList(payload));
    } else if (type == 4) {
      dispatch(vendorpaymentList(payload));
    }
  };

  const handleChange = (e) => {
    console.log(e, "formData");
    setValue("type", e[0]?.type);
  };
  useEffect(() => {
    dispatch(vendorData({}));
    dispatch(clearPurchaseData())
  }, []);
  const VendorPaymenttable = () => {
    return (
      <>
        <div className="table-responsive react-table">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} stickyHeader>
              <TableHead className="table-light table-nowrap">
                <TableRow>
                  <StyledTableCell align="">
                    <TableSortLabel
                      active={sortBy == "receipt_no" ? true : false}
                      direction={order}
                      className="table-header-column-name"
                      onClick={(e) => {
                        sortByKey("receipt_no");
                      }}
                    >
                      Receipt No
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="">
                    <TableSortLabel
                      active={sortBy == "vendor_id" ? true : false}
                      direction={order}
                      className="table-header-column-name"
                      onClick={(e) => {
                        sortByKey("vendor_id");
                      }}
                    >
                      Vendor
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="">
                    <TableSortLabel
                      active={sortBy == "total" ? true : false}
                      direction={order}
                      className="table-header-column-name"
                      onClick={(e) => {
                        sortByKey("total");
                      }}
                    >
                      Payment Date
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="">
                    <TableSortLabel
                      active={sortBy == "status" ? true : false}
                      direction={order}
                      className="table-header-column-name"
                      onClick={(e) => {
                        sortByKey("status");
                      }}
                    >
                      Pay Amount
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="">Payment Type</StyledTableCell>
                  <StyledTableCell align="">Pay Mode</StyledTableCell>
                  <StyledTableCell align="">Payment Account</StyledTableCell>
                  <StyledTableCell align="">Notes</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vendorPaymentListData &&
                  vendorPaymentListData?.rows?.map((row, index) => (
                    <StyledTableRow>
                      <StyledTableCell align="">
                        {row?.payment_no}
                      </StyledTableCell>
                      <StyledTableCell align="">
                        {row?.vendor?.vendor_name}
                      </StyledTableCell>
                      <StyledTableCell align="">
                        {dayjs(row?.pay_date).format("DD-MM-YYYY")}
                      </StyledTableCell>

                      <StyledTableCell align="">{row?.pay_amt}</StyledTableCell>
                      <StyledTableCell align="">
                        {typesOfPayments.map((ele) => {
                          return ele.id == Number(row?.pay_type)
                            ? ele.type
                            : "";
                        })}
                      </StyledTableCell>
                      <StyledTableCell align="">
                        {paymentTypewithcheck.map((ele) => {
                          return ele.id == Number(row?.pay_mode)
                            ? ele.mode
                            : "";
                        })}
                      </StyledTableCell>
                      <StyledTableCell align="">
                        {
                          row?.vendor?.purchase_invoice[0]?.finance_account
                            ?.name
                        }
                      </StyledTableCell>
                      <StyledTableCell align="">{row?.notes}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={9}
                    count={vendorPaymentListData?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </>
    );
  };

  const PurchaseReturntable = () => {
    return (
      <>
        <div className="table-responsive react-table">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} stickyHeader>
              <TableHead className="table-light table-nowrap">
                <TableRow>
                  <StyledTableCell align="">
                    <TableSortLabel
                      active={sortBy == "return_no" ? true : false}
                      direction={order}
                      className="table-header-column-name"
                      onClick={(e) => {
                        sortByKey("return_no");
                      }}
                    >
                      Purchase Return No
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="">
                    <TableSortLabel
                      active={sortBy == "return_date" ? true : false}
                      direction={order}
                      className="table-header-column-name"
                      onClick={(e) => {
                        sortByKey("return_date");
                      }}
                    >
                      Return Date
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="">
                    <TableSortLabel
                      active={sortBy == "vendor" ? true : false}
                      direction={order}
                      className="table-header-column-name"
                      onClick={(e) => {
                        sortByKey("vendor");
                      }}
                    >
                      Vendor
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="">
                    <TableSortLabel
                      active={sortBy == "invoice_no" ? true : false}
                      direction={order}
                      className="table-header-column-name"
                      onClick={(e) => {
                        sortByKey("invoice_no");
                      }}
                    >
                      Invoice No
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="">Return Amount</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {purchaseReturnListData &&
                  purchaseReturnListData?.rows?.map((row, index) => (
                    <StyledTableRow>
                      <StyledTableCell align="">
                        {row?.return_no}
                      </StyledTableCell>
                      <StyledTableCell align="">
                        {dayjs(row?.return_date).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="">
                        {row?.vendor?.vendor_name}
                      </StyledTableCell>
                      <StyledTableCell align="">
                        {row?.purchase_invoice?.invoice_no}
                      </StyledTableCell>
                      <StyledTableCell align="">
                        {row?.bill_amount}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={12}
                    count={purchaseReturnListData?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </>
    );
  };

  const PurchaseOrderTable = () => {
    return (
      <>
        <div className="table-responsive react-table">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} stickyHeader>
              <TableHead className="table-light table-nowrap">
                <TableRow>
                  <StyledTableCell align="">
                    <TableSortLabel
                      active={sortBy == "invoice_no" ? true : false}
                      direction={order}
                      className="table-header-column-name"
                      onClick={(e) => {
                        sortByKey("invoice_no");
                      }}
                    >
                      Purchase Order No
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="">
                    <TableSortLabel
                      active={sortBy == "invoice_date" ? true : false}
                      direction={order}
                      className="table-header-column-name"
                      onClick={(e) => {
                        sortByKey("invoice_date");
                      }}
                    >
                      Vendor
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="">
                    <TableSortLabel
                      active={sortBy == "total" ? true : false}
                      direction={order}
                      className="table-header-column-name"
                      onClick={(e) => {
                        sortByKey("total");
                      }}
                    >
                      Order Date
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="">
                    <TableSortLabel
                      active={sortBy == "status" ? true : false}
                      direction={order}
                      className="table-header-column-name"
                      onClick={(e) => {
                        sortByKey("status");
                      }}
                    >
                      Notes
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="">Amount</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {purchaseOrders &&
                  purchaseOrders?.rows?.map((row, index) => (
                    <StyledTableRow>
                      <StyledTableCell align="">
                        {row?.quotation_no}
                      </StyledTableCell>
                      <StyledTableCell align="">
                        {row?.vendor?.vendor_name}
                      </StyledTableCell>
                      <StyledTableCell align="">
                        {dayjs(row?.date).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="">
                        {row?.notes != "" ? row?.notes : "-"}
                      </StyledTableCell>
                      <StyledTableCell align="">
                        {row?.total_amount}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>

              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={12}
                    count={purchaseOrders?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </>
    );
  };

  const PurchaseInvoiceTable = () => {
    return (
      <>
        <div className="table-responsive react-table">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} stickyHeader>
              <TableHead className="table-light table-nowrap">
                <TableRow>
                  <StyledTableCell align="">
                    <TableSortLabel
                      active={sortBy == "vendor_invoice_no" ? true : false}
                      direction={order}
                      className="table-header-column-name"
                      onClick={(e) => {
                        sortByKey("vendor_invoice_no");
                      }}
                    >
                      Invoice No
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="">
                    <TableSortLabel
                      active={sortBy == "invoice_date" ? true : false}
                      direction={order}
                      className="table-header-column-name"
                      onClick={(e) => {
                        sortByKey("invoice_date");
                      }}
                    >
                      Purchase Date
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="">Vendor Name</StyledTableCell>
                  <StyledTableCell align="">Invoice Due Date</StyledTableCell>
                  <StyledTableCell align="">Invoice Amount</StyledTableCell>
                  <StyledTableCell align="">Balance</StyledTableCell>
                  <StyledTableCell align="">Due days</StyledTableCell>
                  <StyledTableCell align="">Notes</StyledTableCell>
                  <StyledTableCell align="">Status</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {purchaseOrders &&
                  purchaseOrders?.rows?.map((row, index) => (
                    <StyledTableRow>
                      <StyledTableCell align="">
                        {row?.invoice_no}
                      </StyledTableCell>
                      <StyledTableCell align="">
                        {dayjs(row?.purchase_date).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="">
                        {row?.vendor?.vendor_name}
                      </StyledTableCell>

                      <StyledTableCell align="">
                        {row?.invoice_due_date&&dayjs(row?.invoice_due_date).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="">
                        {row?.bill_amount}
                      </StyledTableCell>
                      <StyledTableCell align="">
                        {row?.invoice_current_balance}
                      </StyledTableCell>
                      <StyledTableCell align="">
                        {row?.due_days}
                      </StyledTableCell>
                      <StyledTableCell align="">{row?.notes}</StyledTableCell>
                      <StyledTableCell align="">
                        {row.status === 0
                          ? "Draft"
                          : row.status === 1
                          ? "Proforma"
                          : row.status === 2
                          ? "Invoice"
                          : ""}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={12}
                    count={purchaseOrders?.count??0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </>
    );
  };

  const breadcrumbItems = [
    { title: "Tables", link: "/" },
    { title: "Data Tables", link: "#" },
  ];

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div>
            <h2>Purchase Report</h2>
          </div>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit(showButton)}>
                <div className="row">
                  <div className="col-md-12 d-flex">
                    <div className="col-md-3">
                      <div class="input-container">
                        <Select
                          placeholder="Vendor"
                          className="js-states select-control"
                          options={[  { id: "", vendor_name: 'All' },
                            ...(Array.isArray(vendorDatas) ? vendorDatas : [])]
                          }
                          disabled={false}
                          dropdownHandle={true}
                          searchable={true}
                          labelField={"vendor_name"}
                          valueField={"id"}
                          name="customer_name"
                          value={selectedCustomer}
                          onChange={handleCustomerChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div class="input-container">
                        <Select
                          placeholder="Invoice Status"
                          className="js-states select-control"
                          options={PinvoiceStatus ? PinvoiceStatus : []}
                          disabled={false}
                          dropdownHandle={true}
                          searchable={true}
                          labelField={"mode"}
                          valueField={"id"}
                          name="invoice_status"
                          value={selectedInvoiceStatus}
                          onChange={handleInvoiceStatusChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div class="input-container">
                        <Select
                          placeholder="Payment Status"
                          className="js-states select-control"
                          options={paymentStatus ? paymentStatus : []}
                          disabled={false}
                          dropdownHandle={true}
                          searchable={true}
                          labelField={"mode"}
                          valueField={"id"}
                          name="payment_status"
                          value={selectedPaymentStatus}
                          onChange={handlePaymentStatusChange}
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-3">
                      <div className="input-container">
                        <input
                          placeholder=" "
                          type="date"
                          defaultValue={dayjs().format("YYYY-MM-DD")}
                          id="from_date"
                          name="from_date"
                          {...register("from_date")}
                        />
                        <label for="from_date">From Date</label>
                      </div>
                    </div> */}
                  </div>
                  <div className="col-md-12 d-flex">
                    {/* <div className="col-md-3">
                      <div className="input-container">
                        <input
                          placeholder=" "
                          type="date"
                          defaultValue={dayjs().format("YYYY-MM-DD")}
                          id="to_date"
                          name="to_date"
                          {...register("to_date")}
                        />
                        <label for="to_date">To Date</label>
                      </div>
                    </div> */}

                    <div className="col-md-4">
                      <div class="input-container">
                        <DateRangePicker
                          value={value}
                          onChange={(newValue) => setValues(newValue)}
                          placeholder="Select Date Range"
                        />
                      </div>
                    </div>
                    <div className="col-md-1 d-flex justify-content-start align-items-center">
                      <Button color="primary" type="submit">
                        Filter
                      </Button>
                    </div>

                    <div className="col-md-1 d-flex justify-content-start align-items-center">
                      <Button color="primary" onClick={exportFile}>
                        Export xlsx File
                      </Button>
                    </div>
                  </div>

                  <div className="col-md-4 row d-none">
                    <input
                      type="text"
                      name="search"
                      className="form-control align-self-center"
                      value={searchQuery}
                      style={{ height: "50px" }}
                      onChange={(e) => {
                        searchData(e);
                      }}
                      placeholder="Search"
                    />
                  </div>
                </div>
              </form>
              <div className="table-responsive react-table">
                <>
                  <PurchaseInvoiceTable />
                </>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default Report;
