/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../App.css";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  CardHeader,
  Collapse,
} from "reactstrap";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import "../../App.css";
import { companyvalidator } from "../../utils/Validation";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { API_STATUS } from "../../utils/constants";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-dropdown-select";
import dayjs from "dayjs";
import {
  companySelector,
  getCompanyDetails,
  postCompanyDetails,
} from "../../store/reducer/CompanyReducer";
import UserReducer, {
  countryList,
  currencyList,
  usersSelector,
} from "../../store/reducer/UserReducer";
import { handleTranslate, handleTranslateNo } from "../../utils/translate";
function Company() {
  const _ = require("lodash");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [settingTz, setSettingTz] = useState([]);
  console.log(settingTz, "settingTzsettingTz");
  const [taxCategory, setTaxCategory] = useState([]);
  const [from_date, setFromDate] = useState({ from: null, to: null });
  const [Currency, setCurrency] = useState([]);
  const [zatca, setZatca] = useState(false);
  const [accord, setAccord] = useState({
    col1: false,
    col2: false,
    col3: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(companyvalidator),
  });
  console.log(errors, "6789ijk");
  let { company_details } = useSelector(companySelector);
  const { countries, currencies } = useSelector(usersSelector);
  const [activeTab, setActiveTab] = useState("5");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const t_col1 = () => {
    setAccord({ col1: !accord.col1, col2: false, col3: false });
  };
  const taxcategories = [
    {
      id: 0,
      category: "VAT 15%",
    },
  ];

  const Translate = async (value, field) => {
    const translated_data = await handleTranslate(value);
    const values = getValues();
    setValue("ar_" + field, translated_data);
    console.log(getValues());
  };
  const TranslateNumber = async (value, field) => {
    console.log(value, field);
    if (field == "tax_registration_no") field = "tax_registeration_no";
    const translated_data = await handleTranslateNo(value);
    console.log(translated_data, "translated");
    setValue("ar_" + field, translated_data);
  };

  useEffect(() => {
    let query = "";
    let c_query = "";
    dispatch(getCompanyDetails());
    dispatch(countryList({ query }));
    dispatch(currencyList({ query: c_query }));
  }, []);

  const onSubmitHandler = (formData) => {
    console.log(formData, "6yuhf");
    const unwantedPayload = [
      "created_at",
      "updated_at",
      "user_id",
      "status",
      "id",
      "production_id",
      "private_key",
      "production_api_secret",
      "production_certificate",
      "csr",
      "compliance_certificate",
      "compliance_api_secret",
      "compliance_request_id",
    ];
    let keys = Object.keys(formData);
    formData.country = settingTz[0]?.id.toString();
    formData.currency = Currency[0]?.id.toString();
    formData.setting_tz = settingTz[0]?.id.toString();
    formData.tax_category = JSON.stringify(taxCategory[0]);
    formData.financial_yr_from = from_date.from;
    formData.financial_yr_to = from_date.to;
    formData.zatca_validation = zatca;
    keys.forEach((ele) => {
      if (formData[ele] === "" || formData[ele] === null) {
        delete formData[ele];
      }
    });
    unwantedPayload.forEach((ele) => {
      delete formData[ele];
    });
    console.log(formData, "formdata");
    dispatch(postCompanyDetails(formData));
    // reset();
  };
  const SelectedSetting = (dest, countrId) => {
    console.log(countrId, "countryId");
    return _.filter(dest, ["id", countrId]);
  };
  useEffect(() => {
    if (company_details) {
      reset(company_details);
    }
    setFromDate({
      from: dayjs(company_details?.financial_yr_from).format("YYYY-MM-DD"),
      to: dayjs(company_details?.financial_yr_to).format("YYYY-MM-DD"),
    });
    let data = countries?.filter(
      (country) => country?.id == company_details?.country
    );
    setSettingTz(data ?? []);
    setTaxCategory(
      company_details?.tax_category
        ? [JSON.parse(company_details?.tax_category)]
        : []
    );
    setCurrency(SelectedSetting(currencies, company_details?.currency));
    setZatca(company_details?.zatca_validation);
  }, [company_details, countries]);

  console.log(company_details, "company_details");
  const handleChange = (event) => {
    setSettingTz(event);
    setCurrency(
      currencies.filter((currency) => currency?.country_id === event[0]?.id)
    );
  };
  const handleCurrency = (event) => {
    setCurrency(event);
    setSettingTz(
      countries.filter((country) => country?.id === event[0]?.country_id)
    );
  };

  const handleTaxCat = (event) => {
    setTaxCategory(event);
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="row">
            <div className="col-md-6">
              <h4>
                <Breadcrumb>
                  <BreadcrumbItem>Company</BreadcrumbItem>
                </Breadcrumb>
              </h4>
            </div>
          </div>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Nav pills className="navtab-bg nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "5",
                        })}
                        onClick={() => {
                          toggleTab("5");
                        }}
                      >
                        Company Profile
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "6",
                        })}
                        onClick={() => {
                          toggleTab("6");
                        }}
                      >
                        About
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "7",
                        })}
                        onClick={() => {
                          toggleTab("7");
                        }}
                      >
                        Shipping Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "8",
                        })}
                        onClick={() => {
                          toggleTab("8");
                        }}
                      >
                        Settings
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="5">
                        <Card>
                          <CardBody>
                            <Row>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={company_details?.company_name}
                                    type="text"
                                    id="company_name"
                                    name="company_name"
                                    {...register("company_name")}
                                    onBlur={(e) => {
                                      Translate(e.target.value, "company_name");
                                    }}
                                  />
                                  <label
                                    for="company_name"
                                    className="required"
                                  >
                                    Company Name
                                  </label>
                                </div>
                                {errors?.company_name?.message && (
                                  <p className="error">
                                    {errors.company_name.message}
                                  </p>
                                )}
                              </Col>
                              {/* <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={company_details?.lacale_name}
                                    type="text"
                                    id="lacale_name"
                                    name="lacale_name"
                                    {...register("lacale_name")}
                                  />

                                  <label for="lacale_name">Name (Locale)</label>
                                </div>
                              </Col> */}
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={company_details?.short_name}
                                    type="text"
                                    id="short_name"
                                    name="short_name"
                                    {...register("short_name")}
                                  />
                                  <label for="short_name" className="required">
                                    Business Type/ sector
                                  </label>
                                </div>
                                {errors?.short_name?.message && (
                                  <p className="error">
                                    {errors.short_name.message}
                                  </p>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    defaultValue={
                                      company_details?.responsible_person
                                    }
                                    placeholder=" "
                                    type="text"
                                    id="responsible_person"
                                    name="responsible_person"
                                    {...register("responsible_person")}
                                    onBlur={(e) => {
                                      Translate(
                                        e.target.value,
                                        "responsible_person"
                                      );
                                    }}
                                  />
                                  <label for="responsible_person">
                                    Responsible Person
                                  </label>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={company_details?.phone1}
                                    type="number"
                                    id="phone1"
                                    name="phone1"
                                    {...register("phone1")}
                                  />

                                  <label for="phone1">
                                    Primary Phone Number
                                  </label>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={company_details?.phone2}
                                    type="number"
                                    id="phone2"
                                    name="phone2"
                                    {...register("phone2")}
                                  />
                                  <label for="phone2">
                                    Secondary Phone Number
                                  </label>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={company_details?.email}
                                    type="text"
                                    id="email"
                                    name="email"
                                    {...register("email")}
                                  />
                                  <label for="email" className="required">
                                    Email
                                  </label>
                                </div>
                                {errors?.email?.message && (
                                  <p className="error">
                                    {errors.email.message}
                                  </p>
                                )}
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={company_details?.fax}
                                    type="text"
                                    id="fax"
                                    name="fax"
                                    {...register("fax")}
                                  />

                                  <label for="fax">Fax</label>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={
                                      company_details?.tax_registration_name
                                    }
                                    type="text"
                                    id="tax_registration_name"
                                    name="tax_registration_name"
                                    {...register("tax_registration_name")}
                                    onBlur={(e) => {
                                      Translate(
                                        e.target.value,
                                        "tax_registration_name"
                                      );
                                    }}
                                  />
                                  <label
                                    for="tax_registration_name"
                                    className="required"
                                  >
                                    VAT Registration Name
                                  </label>
                                </div>
                                {errors?.tax_registration_name?.message && (
                                  <p className="error">
                                    {errors.tax_registration_name.message}
                                  </p>
                                )}
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="number"
                                    defaultValue={
                                      company_details?.tax_registration_no
                                    }
                                    id="tax_registration_no"
                                    name="tax_registration_no"
                                    {...register("tax_registration_no")}
                                    onChange={(e) => {
                                      TranslateNumber(
                                        e.target.value,
                                        "tax_registration_no"
                                      );
                                    }}
                                    onBlur={(e) => {
                                      TranslateNumber(
                                        e.target.value,
                                        "tax_registration_no"
                                      );
                                    }}
                                  />

                                  <label
                                    for="tax_registration_no"
                                    className="required"
                                  >
                                    VAT Registration Number
                                  </label>
                                </div>
                                {errors?.tax_registration_no?.message && (
                                  <p className="error">
                                    {errors.tax_registration_no.message}
                                  </p>
                                )}
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={company_details?.tax_type}
                                    type="text"
                                    id="tax_type"
                                    name="tax_type"
                                    {...register("tax_type")}
                                  />
                                  <label for="tax_type" className="required">
                                    Tax Type
                                  </label>
                                  {errors?.tax_type?.message && (
                                    <p className="error">
                                      {errors.tax_type.message}
                                    </p>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="4">
                                <div className="input-container">
                                  <Controller
                                    name="country"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        className="js-states select-control"
                                        placeholder={"Country"}
                                        options={countries}
                                        {...field}
                                        // defaultValue={company_details?.country ? countries.filter((country) => country?.id == company_details?.country)[0] : []}
                                        disabled={false}
                                        dropdownHandle={true}
                                        values={settingTz}
                                        searchable={true}
                                        labelField={"country_name"}
                                        valueField={"country_code"}
                                        name="country"
                                        onChange={handleChange}
                                      />
                                    )}
                                  />
                                </div>
                                {errors?.country?.message && (
                                  <p className="error">
                                    {errors.country.message}
                                  </p>
                                )}
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <Controller
                                    name="currency"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        className="js-states select-control"
                                        placeholder="Currency"
                                        options={currencies}
                                        {...field}
                                        defaultValue={
                                          company_details?.currency
                                            ? company_details?.currency
                                            : []
                                        }
                                        disabled={false}
                                        dropdownHandle={true}
                                        values={Currency}
                                        searchable={true}
                                        labelField={"currency_name"}
                                        valueField={"currency_code"}
                                        name="currency"
                                        onChange={handleCurrency}
                                      />
                                    )}
                                  />
                                </div>
                                {errors?.tax_category?.message && (
                                  <p className="error">
                                    {errors.tax_category.message}
                                  </p>
                                )}
                              </Col>
                              <Col md="4">
                                {/* <div className="input-container">
                                                                    <input
                                                                        placeholder=" "
                                                                        type="text"
                                                                        defaultValue={company_details?.tax_category}
                                                                        id="tax_category"
                                                                        name="tax_category"
                                                                        {...register("tax_category")}
                                                                    />
                                                                    <label for="tax_category" >
                                                                        Tax Category
                                                                    </label>
                                                                </div> */}
                                <div className="input-container">
                                  <Controller
                                    name="tax_category"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        className="js-states select-control"
                                        placeholder="Tax Category"
                                        options={taxcategories}
                                        {...field}
                                        defaultValue={
                                          company_details?.tax_category
                                            ? company_details?.tax_category
                                            : []
                                        }
                                        disabled={false}
                                        dropdownHandle={true}
                                        values={taxCategory}
                                        searchable={true}
                                        labelField={"category"}
                                        valueField={"id"}
                                        name="tax_category"
                                        onChange={handleTaxCat}
                                      />
                                    )}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              {/* <Col md="4">
                                <div className="input-container">
                                  <textarea
                                    placeholder=" "
                                    id="net_amount"
                                    defaultValue={company_details?.address}
                                    rows={3}
                                    name="address"
                                    {...register("address")}
                                    onBlur={(e) => {Translate(e.target.value,'address')}}
                                  />
                                  <label for="address" className="required">
                                    Address
                                  </label>
                                </div>
                                {errors?.address?.message && (
                                  <p className="error">
                                    {errors.address.message}
                                  </p>
                                )}
                              </Col> */}
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={company_details?.tin}
                                    type="text"
                                    id="tin"
                                    name="tin"
                                    {...register("tin")}
                                    // onChange={(e) => {
                                    //   TranslateNumber(e.target.value, "tin");
                                    // }}
                                    onBlur={(e) => {
                                      TranslateNumber(e.target.value, "tin");
                                    }}
                                  />

                                  <label for="tin" className="required">
                                    TIN No
                                  </label>
                                </div>
                                {errors?.tin?.message && (
                                  <p className="error">{errors.tin.message}</p>
                                )}
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={company_details?.cr_number}
                                    type="number"
                                    id="cr_number"
                                    name="cr_number"
                                    {...register("cr_number")}
                                    // onChange={(e) => {
                                    //   TranslateNumber(
                                    //     e.target.value,
                                    //     "cr_number"
                                    //   );
                                    // }}
                                    onBlur={(e) => {
                                      TranslateNumber(
                                        e.target.value,
                                        "cr_number"
                                      );
                                    }}
                                  />

                                  <label for="cr_number" className="required">
                                    CR Number
                                  </label>
                                </div>
                                {errors?.cr_number?.message && (
                                  <p className="error">
                                    {errors.cr_number.message}
                                  </p>
                                )}
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={company_details?.building_no}
                                    type="text"
                                    id="building_no"
                                    name="building_no"
                                    {...register("building_no")}
                                    // onChange={(e) => {
                                    //   TranslateNumber(
                                    //     e.target.value,
                                    //     "building_no"
                                    //   );
                                    // }}
                                    onBlur={(e) => {
                                      TranslateNumber(
                                        e.target.value,
                                        "building_no"
                                      );
                                    }}
                                  />

                                  <label for="building_no" className="required">
                                    Building No
                                  </label>
                                </div>
                                {errors?.building_no?.message && (
                                  <p className="error">
                                    {errors.building_no.message}
                                  </p>
                                )}
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={
                                      company_details?.plot_identification_no
                                    }
                                    type="text"
                                    id="plot_identification_no"
                                    name="plot_identification_no"
                                    {...register("plot_identification_no")}
                                    // onChange={(e) => {
                                    //   TranslateNumber(
                                    //     e.target.value,
                                    //     "plot_identification_no"
                                    //   );
                                    // }}
                                    onBlur={(e) => {
                                      TranslateNumber(
                                        e.target.value,
                                        "plot_identification_no"
                                      );
                                    }}
                                  />

                                  <label
                                    for="plot_identification_no"
                                    className="required"
                                  >
                                    Plot Identification No
                                  </label>
                                </div>
                                {errors?.plot_identification_no?.message && (
                                  <p className="error">
                                    {errors.plot_identification_no.message}
                                  </p>
                                )}
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={company_details?.address}
                                    type="text"
                                    id="address"
                                    name="address"
                                    {...register("address")}
                                    onBlur={(e) => {
                                      Translate(e.target.value, "address");
                                    }}
                                  />

                                  <label for="address" className="required">
                                    Street Address
                                  </label>
                                </div>
                                {errors?.address?.message && (
                                  <p className="error">
                                    {errors.address.message}
                                  </p>
                                )}
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={company_details?.city}
                                    type="text"
                                    id="city"
                                    name="city"
                                    {...register("city")}
                                    onBlur={(e) => {
                                      Translate(e.target.value, "city");
                                    }}
                                  />

                                  <label for="city" className="required">
                                    City
                                  </label>
                                </div>
                                {errors?.city?.message && (
                                  <p className="error">{errors.city.message}</p>
                                )}
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    defaultValue={
                                      company_details?.sub_divisioncity
                                    }
                                    id="sub_divisioncity"
                                    name="sub_divisioncity"
                                    {...register("sub_divisioncity")}
                                    onBlur={(e) => {
                                      Translate(
                                        e.target.value,
                                        "sub_divisioncity"
                                      );
                                    }}
                                  />

                                  <label
                                    for="sub_divisioncity"
                                    className="required"
                                  >
                                    District
                                  </label>
                                </div>
                                {errors?.sub_divisioncity?.message && (
                                  <p className="error">
                                    {errors.sub_divisioncity.message}
                                  </p>
                                )}
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={
                                      company_details?.country_region
                                    }
                                    type="text"
                                    id="country_region"
                                    name="country_region"
                                    {...register("country_region")}
                                    onBlur={(e) => {
                                      Translate(
                                        e.target.value,
                                        "country_region"
                                      );
                                    }}
                                  />

                                  <label
                                    for="country_region"
                                    className="required"
                                  >
                                    Country Region
                                  </label>
                                </div>
                                {errors?.country_region?.message && (
                                  <p className="error">
                                    {errors.country_region.message}
                                  </p>
                                )}
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="number"
                                    defaultValue={company_details?.postal_code}
                                    id="postal_code"
                                    name="postal_code"
                                    {...register("postal_code")}
                                    // onChange={(e) => {
                                    //   TranslateNumber(
                                    //     e.target.value,
                                    //     "postal_code"
                                    //   );
                                    // }}
                                    onBlur={(e) => {
                                      TranslateNumber(
                                        e.target.value,
                                        "postal_code"
                                      );
                                    }}
                                  />
                                  <label for="postal_code" className="required">
                                    Postal Code
                                  </label>
                                </div>
                                {errors?.postal_code?.message && (
                                  <p className="error">
                                    {errors.postal_code.message}
                                  </p>
                                )}
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <label
                                    style={{
                                      paddingLeft: "20px",
                                      marginTop: "4px",
                                      marginRight: "10px",
                                    }}
                                    className="label"
                                    for="zatca_validation"
                                  >
                                    Zatca Validation
                                  </label>
                                  <input
                                    placeholder=" "
                                    className="form-check-input"
                                    type="checkbox"
                                    id="zatca_validation"
                                    onChange={(e) => setZatca(e.target.checked)}
                                    name="zatca_validation"
                                    checked={zatca}
                                    // {...register("zatca_validation")}
                                  />
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="number"
                                    defaultValue={company_details?.otp}
                                    id="otp"
                                    name="otp"
                                    {...register("otp")}
                                  />
                                  <label for="otp">
                                    OTP (EGS registration)
                                  </label>
                                </div>
                                {errors?.otp?.message && (
                                  <p className="error">{errors.otp.message}</p>
                                )}
                              </Col>
                            </Row>
                          </CardBody>
                          <CardBody>
                            <div id="accordion">
                              <Card className="mb-1 shadow-none">
                                <Link
                                  to="#"
                                  onClick={t_col1}
                                  style={{ cursor: "pointer" }}
                                  className="text-dark"
                                >
                                  <CardHeader id="headingOne">
                                    <h6 className="m-0 font-14">
                                      Arabic
                                      <i
                                        className={
                                          accord.col1
                                            ? "mdi mdi-minus float-end accor-plus-icon"
                                            : "mdi mdi-plus float-end accor-plus-icon"
                                        }
                                      ></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={accord.col1}>
                                  <CardBody>
                                    <Row>
                                      <Col md="4">
                                        <div class="input-container">
                                          <input
                                            type="text"
                                            placeholder=" "
                                            id="edit_ar_company_name"
                                            name="ar_company_name"
                                            style={{ direction: "rtl" }}
                                            {...register("ar_company_name")}
                                            defaultValue={
                                              company_details?.ar_company_name
                                            }
                                          />
                                          <label for="city">
                                            Company Name (Arabic)
                                          </label>
                                        </div>
                                        {errors?.ar_company_name?.message && (
                                          <p className="error">
                                            {errors.ar_company_name.message}
                                          </p>
                                        )}
                                      </Col>
                                      <Col md="4">
                                        <div class="input-container">
                                          <input
                                            type="text"
                                            placeholder=" "
                                            id="edit_ar_responsible_person"
                                            name="ar_responsible_person"
                                            {...register(
                                              "ar_responsible_person"
                                            )}
                                            style={{ direction: "rtl" }}
                                            defaultValue={
                                              company_details?.ar_responsible_person
                                            }
                                          />
                                          <label for="city">
                                            Responsible Person (Arabic)
                                          </label>
                                        </div>
                                        {errors?.ar_responsible_person
                                          ?.message && (
                                          <p className="error">
                                            {
                                              errors.ar_responsible_person
                                                .message
                                            }
                                          </p>
                                        )}
                                      </Col>

                                      <Col md="4">
                                        <div className="input-container">
                                          <input
                                            placeholder=" "
                                            style={{ direction: "rtl" }}
                                            defaultValue={
                                              company_details?.ar_tax_registration_name
                                            }
                                            type="text"
                                            id="ar_tax_registration_name"
                                            name="ar_tax_registration_name"
                                            {...register(
                                              "ar_tax_registration_name"
                                            )}
                                            // onBlur={(e) => {
                                            //   Translate(
                                            //     e.target.value,
                                            //     "ar_tax_registration_name"
                                            //   );
                                            // }}
                                          />
                                          <label
                                            for="ar_tax_registration_name"
                                            className="required"
                                          >
                                            VAT Registration Name (Arabic)
                                          </label>
                                        </div>
                                        {errors?.ar_tax_registration_name
                                          ?.message && (
                                          <p className="error">
                                            {
                                              errors.ar_tax_registration_name
                                                .message
                                            }
                                          </p>
                                        )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="4">
                                        <div class="input-container">
                                          <input
                                            type="text"
                                            placeholder=" "
                                            id="edit_ar_tax_registeration_no"
                                            name="ar_tax_registeration_no"
                                            style={{ direction: "rtl" }}
                                            {...register(
                                              "ar_tax_registeration_no"
                                            )}
                                            defaultValue={
                                              company_details?.ar_tax_registeration_no
                                            }
                                          />
                                          <label for="city">
                                            VAT Registeration No (Arabic)
                                          </label>
                                        </div>
                                        {errors?.ar_tax_registeration_no
                                          ?.message && (
                                          <p className="error">
                                            {
                                              errors.ar_tax_registeration_no
                                                .message
                                            }
                                          </p>
                                        )}
                                      </Col>
                                      <Col md="4">
                                        <div className="input-container">
                                          <input
                                            placeholder=" "
                                            defaultValue={
                                              company_details?.ar_tin
                                            }
                                            style={{ direction: "rtl" }}
                                            type="text"
                                            id="ar_tin"
                                            name="ar_tin"
                                            {...register("ar_tin")}
                                          />

                                          <label
                                            for="ar_tin"
                                            className="required"
                                          >
                                            TIN No (Arabic)
                                          </label>
                                        </div>
                                        {errors?.ar_tin?.message && (
                                          <p className="error">
                                            {errors.ar_tin.message}
                                          </p>
                                        )}
                                      </Col>
                                      <Col md="4">
                                        <div className="input-container">
                                          <input
                                            placeholder=" "
                                            defaultValue={
                                              company_details?.ar_cr_number
                                            }
                                            style={{ direction: "rtl" }}
                                            type="text"
                                            id="ar_cr_number"
                                            name="ar_cr_number"
                                            {...register("ar_cr_number")}
                                          />

                                          <label
                                            for="ar_cr_number"
                                            className="required"
                                          >
                                            CR Number (Arabic)
                                          </label>
                                        </div>
                                        {errors?.ar_cr_number?.message && (
                                          <p className="error">
                                            {errors.ar_cr_number.message}
                                          </p>
                                        )}
                                      </Col>
                                      <Col md="4">
                                        <div className="input-container">
                                          <input
                                            placeholder=" "
                                            defaultValue={
                                              company_details?.ar_building_no
                                            }
                                            style={{ direction: "rtl" }}
                                            type="text"
                                            id="ar_building_no"
                                            name="ar_building_no"
                                            {...register("ar_building_no")}
                                          />

                                          <label
                                            for="ar_building_no"
                                            className="required"
                                          >
                                            Building No (Arabic)
                                          </label>
                                        </div>
                                        {errors?.ar_building_no?.message && (
                                          <p className="error">
                                            {errors.ar_building_no.message}
                                          </p>
                                        )}
                                      </Col>
                                      <Col md="4">
                                        <div className="input-container">
                                          <input
                                            placeholder=" "
                                            defaultValue={
                                              company_details?.ar_plot_identification_no
                                            }
                                            style={{ direction: "rtl" }}
                                            type="text"
                                            id="ar_plot_identification_no"
                                            name="ar_plot_identification_no"
                                            {...register(
                                              "ar_plot_identification_no"
                                            )}
                                          />

                                          <label
                                            for="ar_plot_identification_no"
                                            className="required"
                                          >
                                            Plot Identification No (Arabic)
                                          </label>
                                        </div>
                                        {errors?.ar_plot_identification_no
                                          ?.message && (
                                          <p className="error">
                                            {
                                              errors.ar_plot_identification_no
                                                .message
                                            }
                                          </p>
                                        )}
                                      </Col>
                                      <Col md="4">
                                        <div className="input-container">
                                          <input
                                            placeholder=" "
                                            id="net_amount"
                                            style={{ direction: "rtl" }}
                                            defaultValue={
                                              company_details?.ar_address
                                            }
                                            // rows={3}
                                            name="ar_address"
                                            {...register("ar_address")}
                                          />
                                          <label
                                            style={{
                                              transform: "translateY(-15px)",
                                            }}
                                            for="ar_address"
                                            className="required"
                                          >
                                            Address
                                          </label>
                                        </div>
                                        {errors?.ar_address?.message && (
                                          <p className="error">
                                            {errors.ar_address.message}
                                          </p>
                                        )}
                                      </Col>
                                      <Col md="4">
                                        <div className="input-container">
                                          <input
                                            placeholder=" "
                                            defaultValue={
                                              company_details?.ar_city
                                            }
                                            style={{ direction: "rtl" }}
                                            type="text"
                                            id="ar_city"
                                            name="ar_city"
                                            {...register("ar_city")}
                                          />

                                          <label
                                            for="ar_city"
                                            className="required"
                                          >
                                            City (Arabic)
                                          </label>
                                        </div>
                                        {errors?.ar_city?.message && (
                                          <p className="error">
                                            {errors.ar_city.message}
                                          </p>
                                        )}
                                      </Col>
                                      <Col md="4">
                                        <div className="input-container">
                                          <input
                                            placeholder=" "
                                            defaultValue={
                                              company_details?.ar_sub_divisioncity
                                            }
                                            style={{ direction: "rtl" }}
                                            type="text"
                                            id="ar_sub_divisioncity"
                                            name="ar_sub_divisioncity"
                                            {...register("ar_sub_divisioncity")}
                                          />

                                          <label
                                            for="ar_sub_divisioncity"
                                            className="required"
                                          >
                                            District (Arabic)
                                          </label>
                                        </div>
                                        {errors?.ar_sub_divisioncity
                                          ?.message && (
                                          <p className="error">
                                            {errors.ar_sub_divisioncity.message}
                                          </p>
                                        )}
                                      </Col>
                                      <Col md="4">
                                        <div className="input-container">
                                          <input
                                            placeholder=" "
                                            defaultValue={
                                              company_details?.ar_country_region
                                            }
                                            style={{ direction: "rtl" }}
                                            type="text"
                                            id="ar_country_region"
                                            name="ar_country_region"
                                            {...register("ar_country_region")}
                                          />

                                          <label
                                            for="ar_country_region"
                                            className="required"
                                          >
                                            Country Region (Arabic)
                                          </label>
                                        </div>
                                        {errors?.ar_country_region?.message && (
                                          <p className="error">
                                            {errors.ar_country_region.message}
                                          </p>
                                        )}
                                      </Col>
                                      <Col md="4">
                                        <div class="input-container">
                                          <input
                                            type="text"
                                            placeholder=" "
                                            id="edit_ar_postal_code"
                                            style={{ direction: "rtl" }}
                                            name="ar_postal_code"
                                            {...register("ar_postal_code")}
                                            defaultValue={
                                              company_details?.ar_postal_code
                                            }
                                          />
                                          <label for="city">
                                            Postal Code (Arabic)
                                          </label>
                                        </div>
                                        {errors?.ar_postal_code?.message && (
                                          <p className="error">
                                            {errors.ar_postal_code.message}
                                          </p>
                                        )}
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Collapse>
                              </Card>
                            </div>
                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane tabId={"6"}>
                        <Card>
                          <CardBody>
                            <Row>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    id="compaign_slogan"
                                    defaultValue={
                                      company_details?.compaign_slogan
                                    }
                                    name="compaign_slogan"
                                    {...register("compaign_slogan")}
                                  />
                                  <label for="compaign_slogan">
                                    Company Slogan
                                  </label>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    defaultValue={company_details?.website_url}
                                    id="website_url"
                                    name="website_url"
                                    {...register("website_url")}
                                  />
                                  <label for="website_url">Website</label>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={company_details?.history}
                                    type="text"
                                    id="history"
                                    name="history"
                                    {...register("history")}
                                  />
                                  <label for="history">History</label>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="number"
                                    id="since"
                                    defaultValue={company_details?.since}
                                    name="since"
                                    {...register("since")}
                                  />
                                  <label for="since">Since</label>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    id="certifications"
                                    defaultValue={
                                      company_details?.certifications
                                    }
                                    name="certifications"
                                    {...register("certifications")}
                                  />
                                  <label for="certifications">
                                    Certifications
                                  </label>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane tabId={"7"}>
                        <Card>
                          <CardBody>
                            <Row>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    id="shipping_name"
                                    defaultValue={
                                      company_details?.shipping_name
                                    }
                                    name="shipping_name"
                                    {...register("shipping_name")}
                                  />
                                  <label for="shipping_name">Full Name</label>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={
                                      company_details?.shipping_street
                                    }
                                    type="text"
                                    id="shipping_street"
                                    name="shipping_street"
                                    {...register("shipping_street")}
                                  />
                                  <label for="shipping_street">Street</label>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={
                                      company_details?.shipping_appartment
                                    }
                                    type="text"
                                    id="shipping_appartment"
                                    name="shipping_appartment"
                                    {...register("shipping_appartment")}
                                  />
                                  <label for="shipping_appartment">
                                    Appartment
                                  </label>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    id="shipping_city"
                                    defaultValue={
                                      company_details?.shipping_city
                                    }
                                    name="shipping_city"
                                    {...register("shipping_city")}
                                  />
                                  <label for="shipping_city">City</label>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    defaultValue={
                                      company_details?.shipping_state
                                    }
                                    id="shipping_state"
                                    name="shipping_state"
                                    {...register("shipping_state")}
                                  />
                                  <label for="shipping_state">State</label>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    defaultValue={company_details?.shipping_zip}
                                    id="shipping_zip"
                                    name="shipping_zip"
                                    {...register("shipping_zip")}
                                  />
                                  <label for="shipping_zip">ZIP</label>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={
                                      company_details?.shipping_phone
                                    }
                                    type="number"
                                    id="shipping_phone"
                                    name="shipping_phone"
                                    {...register("shipping_phone")}
                                  />
                                  <label for="shipping_phone">Phone</label>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane tabId={"8"}>
                        <Card>
                          <CardBody>
                            <h4>Financial Year</h4>
                            <Row>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="date"
                                    // defaultValue={company_details?.financial_yr_from}
                                    // defaultValue={dayjs()}
                                    value={from_date.from}
                                    onChange={(e) =>
                                      setFromDate({
                                        ...from_date,
                                        from: dayjs(e.target.value).format(
                                          "YYYY-MM-DD"
                                        ),
                                      })
                                    }
                                    id="financial_yr_from"
                                    name="financial_yr_from"
                                    // {...register("financial_yr_from")}
                                  />
                                  <label
                                    for="financial_yr_from"
                                    className="required"
                                  >
                                    Date From
                                  </label>
                                </div>
                                {errors?.financial_yr_from?.message && (
                                  <p className="error">
                                    {errors.financial_yr_from.message}
                                  </p>
                                )}
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="date"
                                    defaultValue={
                                      company_details?.financial_yr_to
                                    }
                                    value={from_date.to}
                                    // defaultValue={dayjs()}
                                    onChange={(e) =>
                                      setFromDate({
                                        ...from_date,
                                        to: dayjs(e.target.value).format(
                                          "YYYY-MM-DD"
                                        ),
                                      })
                                    }
                                    id="financial_yr_to"
                                    name="financial_yr_to"
                                    // {...register("financial_yr_to")}
                                  />
                                  <label
                                    for="financial_yr_to"
                                    className="required"
                                  >
                                    Date To
                                  </label>
                                </div>
                                {errors?.financial_yr_to?.message && (
                                  <p className="error">
                                    {errors.financial_yr_to.message}
                                  </p>
                                )}
                              </Col>
                            </Row>
                            <h4>Settings</h4>
                            <Row>
                              <Col md="4">
                                <div className="input-container">
                                  <Controller
                                    name="setting_tz"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        className="js-states select-control"
                                        placeholder="Time Zone"
                                        options={countries}
                                        {...field}
                                        disabled={false}
                                        dropdownHandle={true}
                                        values={settingTz}
                                        searchable={true}
                                        labelField={"country_name"}
                                        valueField={"country_code"}
                                        name="setting_tz"
                                        onChange={handleChange}
                                        // {...register('setting_tz')}
                                      />
                                    )}
                                  />
                                </div>
                                {errors?.setting_tz?.message && (
                                  <p className="error">
                                    {errors.setting_tz.message}
                                  </p>
                                )}
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="number"
                                    id="decimal_places"
                                    name="decimal_places"
                                    defaultValue={
                                      company_details?.decimal_places
                                    }
                                    {...register("decimal_places")}
                                  />
                                  <label for="decimal_places">
                                    Decimal Places
                                  </label>
                                </div>
                              </Col>

                              {/* <Col md="4">
                                                                <div className="input-container">
                                                                    <input
                                                                        placeholder=" "
                                                                        type="text"
                                                                        id="shipping_appartment"
                                                                        name="shipping_appartment"
                                                                        {...register("shipping_appartment")}
                                                                    />
                                                                    <label for="shipping_appartment" className="required">
                                                                        Appartment
                                                                    </label>
                                                                </div>
                                                                {errors?.shipping_appartment?.message && (
                                                                    <p className="error">{errors.shipping_appartment.message}</p>
                                                                )}
                                                            </Col> */}
                            </Row>
                            <h4>Bank Details</h4>
                            <Row>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    defaultValue={
                                      company_details?.company_banks
                                    }
                                    placeholder=" "
                                    type="text"
                                    id="company_banks"
                                    name="company_banks"
                                    {...register("company_banks")}
                                    onBlur={(e) => {
                                      Translate(
                                        e.target.value,
                                        "company_banks"
                                      );
                                    }}
                                  />
                                  <label for="company_banks">Bank Name</label>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    id="bank_code"
                                    name="bank_code"
                                    defaultValue={company_details?.bank_code}
                                    {...register("bank_code")}
                                    // onChange={(e) => {
                                    //   TranslateNumber(
                                    //     e.target.value,
                                    //     "bank_code"
                                    //   );
                                    // }}
                                    onBlur={(e) => {
                                      TranslateNumber(
                                        e.target.value,
                                        "bank_code"
                                      );
                                    }}
                                  />
                                  <label for="bank_code">Bank Code</label>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    defaultValue={company_details?.account_name}
                                    id="account_name"
                                    name="account_name"
                                    {...register("account_name")}
                                    onBlur={(e) => {
                                      Translate(e.target.value, "account_name");
                                    }}
                                  />
                                  <label for="account_name">Account Name</label>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    defaultValue={company_details?.bank_address}
                                    placeholder=" "
                                    type="text"
                                    id="bank_address"
                                    name="bank_address"
                                    {...register("bank_address")}
                                    onBlur={(e) => {
                                      Translate(e.target.value, "bank_address");
                                    }}
                                  />
                                  <label for="bank_address">Bank Address</label>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={company_details?.account_no}
                                    type="number"
                                    id="account_no"
                                    name="account_no"
                                    {...register("account_no")}
                                    // onChange={(e) => {
                                    //   TranslateNumber(
                                    //     e.target.value,
                                    //     "account_no"
                                    //   );
                                    // }}
                                    onBlur={(e) => {
                                      TranslateNumber(
                                        e.target.value,
                                        "account_no"
                                      );
                                    }}
                                  />
                                  <label for="account_no">Account Number</label>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    id="swift_code"
                                    defaultValue={company_details?.swift_code}
                                    name="swift_code"
                                    {...register("swift_code")}
                                    // onChange={(e) => {
                                    //   TranslateNumber(
                                    //     e.target.value,
                                    //     "swift_code"
                                    //   );
                                    // }}
                                    onBlur={(e) => {
                                      TranslateNumber(
                                        e.target.value,
                                        "swift_code"
                                      );
                                    }}
                                  />
                                  <label for="swift_code">Swift Code</label>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="4">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    defaultValue={company_details?.iban}
                                    type="text"
                                    id="iban"
                                    name="iban"
                                    {...register("iban")}
                                    // onChange={(e) => {
                                    //   TranslateNumber(e.target.value, "iban");
                                    // }}
                                    onBlur={(e) => {
                                      TranslateNumber(e.target.value, "iban");
                                    }}
                                  />
                                  <label for="iban">IBAN</label>
                                </div>
                              </Col>
                              <Col md="4" className="d-none">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    id="gl_account"
                                    name="gl_account"
                                    defaultValue={company_details?.gl_account}
                                    {...register("gl_account")}
                                  />
                                  <label for="gl_account">GL Account</label>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>

                          <CardBody>
                            <div id="accordion">
                              <Card className="mb-1 shadow-none">
                                <Link
                                  to="#"
                                  onClick={t_col1}
                                  style={{ cursor: "pointer" }}
                                  className="text-dark"
                                >
                                  <CardHeader id="headingOne">
                                    <h6 className="m-0 font-14">
                                      Arabic
                                      <i
                                        className={
                                          accord.col1
                                            ? "mdi mdi-minus float-end accor-plus-icon"
                                            : "mdi mdi-plus float-end accor-plus-icon"
                                        }
                                      ></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={accord.col1}>
                                  <CardBody>
                                    <Row>
                                      <Col md="4">
                                        <div class="input-container">
                                          <input
                                            type="text"
                                            placeholder=" "
                                            id="edit_ar_company_banks"
                                            name="ar_company_banks"
                                            style={{ direction: "rtl" }}
                                            {...register("ar_company_banks")}
                                            defaultValue={
                                              company_details?.ar_company_banks
                                            }
                                          />
                                          <label for="city">
                                            Bank Name (Arabic)
                                          </label>
                                        </div>
                                        {errors?.ar_company_banks?.message && (
                                          <p className="error">
                                            {errors.ar_company_banks.message}
                                          </p>
                                        )}
                                      </Col>
                                      <Col md="4">
                                        <div class="input-container">
                                          <input
                                            type="text"
                                            placeholder=" "
                                            id="edit_ar_bank_code"
                                            style={{ direction: "rtl" }}
                                            name="ar_bank_code"
                                            {...register("ar_bank_code")}
                                            defaultValue={
                                              company_details?.ar_bank_code
                                            }
                                          />
                                          <label for="city">
                                            Bank Code (Arabic)
                                          </label>
                                        </div>
                                        {errors?.ar_bank_code?.message && (
                                          <p className="error">
                                            {errors.ar_bank_code.message}
                                          </p>
                                        )}
                                      </Col>
                                      <Col md="4">
                                        <div class="input-container">
                                          <input
                                            type="text"
                                            placeholder=" "
                                            id="edit_ar_account_name"
                                            style={{ direction: "rtl" }}
                                            name="ar_account_name"
                                            {...register("ar_account_name")}
                                            defaultValue={
                                              company_details?.ar_account_name
                                            }
                                          />
                                          <label for="city">
                                            Account Name (Arabic)
                                          </label>
                                        </div>
                                        {errors?.ar_account_name?.message && (
                                          <p className="error">
                                            {errors.ar_account_name.message}
                                          </p>
                                        )}
                                      </Col>
                                      <Col md="4">
                                        <div className="input-container">
                                          <input
                                            placeholder=" "
                                            type="text"
                                            id="ar_bank_address"
                                            style={{ direction: "rtl" }}
                                            defaultValue={
                                              company_details?.ar_bank_address
                                            }
                                            name="ar_bank_address"
                                            {...register("ar_bank_address")}
                                          />
                                          <label for="ar_bank_address">
                                            Bank Address (Arabic)
                                          </label>
                                        </div>
                                      </Col>
                                      <Col md="4">
                                        <div class="input-container">
                                          <input
                                            type="text"
                                            placeholder=" "
                                            id="edit_ar_account_no"
                                            style={{ direction: "rtl" }}
                                            name="ar_account_no"
                                            {...register("ar_account_no")}
                                            defaultValue={
                                              company_details?.ar_account_no
                                            }
                                          />
                                          <label for="ar_account_no">
                                            Account No (Arabic)
                                          </label>
                                        </div>
                                        {errors?.ar_account_no?.message && (
                                          <p className="error">
                                            {errors.ar_account_no.message}
                                          </p>
                                        )}
                                      </Col>

                                      <Col md="4">
                                        <div class="input-container">
                                          <input
                                            type="text"
                                            placeholder=" "
                                            id="edit_ar_swift_code"
                                            name="ar_swift_code"
                                            style={{ direction: "rtl" }}
                                            {...register("ar_swift_code")}
                                            defaultValue={
                                              company_details?.ar_swift_code
                                            }
                                          />
                                          <label for="city">
                                            Swift Code (Arabic)
                                          </label>
                                        </div>
                                        {errors?.ar_swift_code?.message && (
                                          <p className="error">
                                            {errors.ar_swift_code.message}
                                          </p>
                                        )}
                                      </Col>
                                      <Col md="4">
                                        <div class="input-container">
                                          <input
                                            type="text"
                                            placeholder=" "
                                            id="edit_ar_iban"
                                            style={{ direction: "rtl" }}
                                            name="ar_iban"
                                            {...register("ar_iban")}
                                            defaultValue={
                                              company_details?.ar_iban
                                            }
                                          />
                                          <label for="city">
                                            IBAN (Arabic)
                                          </label>
                                        </div>
                                        {errors?.ar_iban?.message && (
                                          <p className="error">
                                            {errors.ar_iban.message}
                                          </p>
                                        )}
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Collapse>
                              </Card>
                            </div>
                          </CardBody>
                        </Card>
                      </TabPane>
                    </TabContent>
                    <div className="text-end">
                      {activeTab === "8" || activeTab === "5" ? (
                        <Button color="primary">Save</Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default Company;
