import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import classnames from "classnames";
import "../../App.css";
import { useEffect, useState, useMemo } from "react";
import Select from "react-dropdown-select";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import dayjs from "dayjs";
import TableRow from "@mui/material/TableRow";
import TablePaginationActions from "../../utils/pagination";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { purchaseInvoiceValidator } from "../../utils/Validation";
import { useNavigate } from "react-router";
import toastr from "toastr";
import _ from "loadsh";
import {
  API_STATUS,
  payAccount,
  paymentType,
  requiredTaxes,
} from "../../utils/constants";
import {
  getInvoiceNo,
  purchaseSelector,
  createPurchaseInvoice,
  clearPurchaseLoadings,
  purchaseOrderList,
  getOrderById,
} from "../../store/reducer/PurchaseReducer";
import {
  clearsettingsLoadings,
  stockUnitList,
  settingsSelector,
} from "../../store/reducer/SettingsReducer";
import { vendorData, vendorsSelector } from "../../store/reducer/VendorReducer";
import {
  AddPaymentVoucherSchedule,
  clearPVLoadings,
  getAccountsList,
  getGeneratedPaymentVoucherNumber,
  paymentVoucherSelector,
} from "../../store/reducer/PaymentVoucherReducer";
import {
  AccountsList,
  FinanceAccountsSelector,
  financeGroupList,
} from "../../store/reducer/FinanceAccountsReducer";
import {
  PurchaseData,
  purchasesInveSelector,
} from "../../store/reducer/PurchaseDescReducer";

const AddPurchaseInvoice = () => {
  const [activeTab, setActiveTab] = useState("5");
  const dispatch = useDispatch();
  const { PurchaseDatas } = useSelector(purchasesInveSelector);
  const {
    purchaseInvoiceno,
    purchaseerrorMessage,
    purchaseOrders,
    createPurchaseInvoiceLoad,
    purchseOrderDatasByID,
    getOrderByIdLoading,
  } = useSelector(purchaseSelector);
  console.log(purchaseOrders, "7uii");
  const { accountsListDatas, accountsGroupDatas } = useSelector(
    FinanceAccountsSelector
  );
  const { stockUnitListData } = useSelector(settingsSelector);
  const { vendorDatas } = useSelector(vendorsSelector);

  const [voucherDetails, setVoucherDetails] = useState([
    {
      additional_description: "",
      product_id: 0,
      quantity: 0,
      unit: "0",
      unit_price: 0,
      amount: 0,
      tax: "",
      discount_val: 0,
      id: 0,
    },
  ]);
  console.log(voucherDetails, "voucherDetails");
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    resolver: yupResolver(purchaseInvoiceValidator),
  });
  console.log(errors, "ERRORS");

  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState("customer_name");
  const [activesortBy, setActiveSortBy] = useState("customer_name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [current_bal, setCurrentBal] = useState(0);
  const [invoiceDD, setinvoiceDD] = useState("");
  const [totalAmt, settotalAmt] = useState(0);
  const [discount_val, setDiscountVal] = useState("0");
  const [roundOffAmt, setroundOffAmt] = useState("0");
  const [vat_amount, setVatAmount] = useState("0");
  const [freightCharge, setfreightCharge] = useState("0");
  const [label, setLabel] = useState("label");
  const [taxType, setTaxType] = useState([requiredTaxes && requiredTaxes[1]]);
  let [purchaseDateVal, setpurchaseDateVal] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
  };

  const initHit = () => {
    dispatch(
      PurchaseData({
        query: "",
        page: 0,
        limit: "",
        sortby: "description",
        order: "asc",
      })
    );
    dispatch(getInvoiceNo({}));
    dispatch(getAccountsList({}));
    dispatch(
      financeGroupList({
        query: "",
        page: page + 1,
        limit: rowsPerPage,
        sortby: "",
        order: order,
      })
    );
    dispatch(
      purchaseOrderList({
        query: "",
        page: 0,
        limit: 0,
      })
    );
    dispatch(
      vendorData({
        query: "",
        page: 0,
        limit: 0,
        sortby: "",
        order: "",
      })
    );
    dispatch(stockUnitList({ page: 0, limit: 0, query: "" }));
  };

  const handlePurchaseOrder = (event) => {
    setValue("po_number", event[0]?.id);
    clearErrors("po_number");
    dispatch(
      getOrderById({
        id: event[0]?.id,
      })
    );
  };

  const _options = useMemo(() => {
    const options = accountsListDatas?.rows?.map((ele) => {
      return {
        ...ele,
        label: `${ele.account_code} - ${ele.name}`,
      };
    });
    return options;
  }, [accountsListDatas]);

  useEffect(() => {
    setValue(
      "vendor_id",
      purchseOrderDatasByID && purchseOrderDatasByID?.vendor_id
    );
    clearErrors("vendor_id");

    let vdData = purchseOrderDatasByID?.purchase_order_details?.map(
      (orderDatas, key) => {
        return {
          additional_description: orderDatas?.additional_description,
          product_id: orderDatas?.product_description,
          quantity: orderDatas?.qty,
          unit: "1",
          unit_price: orderDatas?.price,
          amount: Number(orderDatas?.amount),
          id: key++,
        };
      }
    );
    if (vdData) {
      setVoucherDetails([...vdData, ...voucherDetails]);
      setDiscountVal(purchseOrderDatasByID?.discount_amount);
      setroundOffAmt(purchseOrderDatasByID?.round_off);
      setValue("net_amount", purchseOrderDatasByID?.net_amount);
      clearErrors("net_amount");
    }
  }, [purchseOrderDatasByID]);
  useEffect(() => {
    console.log(voucherDetails, "voucherDetails1");
  }, [voucherDetails]);
  useEffect(() => {
    initHit();
  }, []);

  function allKeysExceptNarrationHaveValues(obj) {
    const keysToCheck = _.omit(obj, "narration", "id");
    console.log(
      keysToCheck,
      "allObjectsKeysExceptNarrationHaveValues(arrayOfObjects)"
    );
    return _.every(keysToCheck, (value) => !_.isEmpty(value));
  }

  function allObjectsKeysExceptNarrationHaveValues(array) {
    return _.every(array, (obj) => allKeysExceptNarrationHaveValues(obj));
  }

  const onSubmitHandler = (formData) => {
    // console.log(
    //   allObjectsKeysExceptNarrationHaveValues(voucherDetails),
    //   voucherDetails,
    //   "allObjectsKeysExceptNarrationHaveValues(arrayOfObjects)"
    // );
    // console.log(voucherDetails, "voucherDetails1");
    let details = voucherDetails?.map((ele) => {
      return {
        product_id: ele.product_id,
        additional_description: ele.additional_description,
        quantity: ele.quantity,
        unit: ele.unit,
        tax: ele.tax,
        discount_val: ele.discount_val,
        unit_price: ele.unit_price,
        amount: ele.amount,
      };
    });
    formData.details = details;
    formData.purchase_date = purchaseDateVal;
    formData.invoice_due_date = invoiceDD;
    formData.total_amount = totalAmt;
    formData.discount_amount = discount_val;
    formData.vat_amount = vat_amount;
    formData.round_amount = roundOffAmt;
    formData.freight_amount = freightCharge;
    formData.net_amount = getValues("net_amount");
    formData.billAmount = getValues("net_amount");
    // formData.tax_type = taxType[0];
    // let currentBalance =
    //   parseFloat(current_bal) - parseFloat(getValues("net_amount"));
    // if (currentBalance < 0) {
    //   toastr.error("Invalid Balance");
    // } else {
    console.log(formData, "2233");
    if (formData.total_amount > 0) {
      dispatch(createPurchaseInvoice({ formData }));
      // toastr.success("Select Products");
    } else {
      toastr.error("Select Products");
    }
    // }
  };
  useEffect(() => {
    console.log(stockUnitListData, "stockUnitListData1");
  }, [stockUnitListData]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleInput = (e, dest, type) => {
    const { value } = e.target;
    voucherDetails[dest] = type !== "" ? Number(value) : value;
    setVoucherDetails({
      ...voucherDetails,
    });
  };

  const handleinvoiceDueDate = (event, type) => {
    let invoiceDueDate = "";
    let quotation_date = "";

    if (type == "invoice_date") {
      invoiceDueDate = event?.target?.value;
      quotation_date = purchaseDateVal;
      setinvoiceDD(invoiceDueDate);
    } else if (type == "quotation_date") {
      quotation_date = event?.target?.value;
      setpurchaseDateVal(quotation_date);
      invoiceDueDate = invoiceDD;
    }

    let date1 = new Date(invoiceDueDate);
    let date2 = new Date(quotation_date);

    let Difference_In_Time = date1.getTime() - date2.getTime();
    let Difference_In_Days = Math.round(
      Difference_In_Time / (1000 * 3600 * 24)
    );
    if (Difference_In_Days < 0) {
      toastr.clear();
      toastr.error("Please choose the valid Date!");
      setinvoiceDD("");
      setValue("due_days", 0);
    } else setValue("due_days", invoiceDueDate != "" ? Difference_In_Days : 0);
  };
  const calculateAmount = (index) => {
    const data = [...voucherDetails];

    if (index < 0 || index >= data.length) {
      console.error("Invalid index provided");
      return;
    }

    const qty = parseFloat(data[index].quantity) || 0;
    const unitPrice = parseFloat(data[index].unit_price) || 0;
    const baseAmount = qty * unitPrice;
    const discountPercentage = parseFloat(data[index]?.discount_val || 0);
    if (discountPercentage > baseAmount) {
      toastr.error("Discount value cannot be greater than the amount!");
      return;
    }
    const discountedAmount = Math.max(baseAmount - discountPercentage, 0);

    const taxPercentage = data[index]?.tax === "VAT 15%" ? 15 : 0 ?? 0;
    const taxAmount = (taxPercentage / 100) * discountedAmount;
    const finalAmount = discountedAmount + taxAmount;

    data[index] = {
      ...data[index],
      amount: finalAmount.toFixed(2),
    };

    setVoucherDetails(data);
    updateTotals(data);
  };

  const updateTotals = (data) => {
    const totalAmt = data
      .reduce((sum, item) => {
        const qty = parseFloat(item.quantity) || 0;
        const unitPrice = parseFloat(item.unit_price) || 0;
        return sum + qty * unitPrice;
      }, 0)
      .toFixed(2);

    const vatAmount = data
      .reduce((sum, item) => {
        const discountPercentage = parseFloat(item?.discount_val || 0);
        const baseAmount =
          (parseFloat(item.quantity) || 0) * (parseFloat(item.unit_price) || 0);
        const discountedAmount = baseAmount - discountPercentage;
        const taxPercentage = item?.tax === "VAT 15%" ? 15 : 0;
        return sum + (taxPercentage / 100) * discountedAmount;
      }, 0)
      .toFixed(2);

    const netAmt = data
      .reduce((sum, item) => {
        const qty = parseFloat(item.quantity) || 0;
        const unitPrice = parseFloat(item.unit_price) || 0;
        const baseAmount = qty * unitPrice;
        const discountPercentage = parseFloat(item?.discount_val || 0);
        const discountedAmount = baseAmount - discountPercentage;
        const taxPercentage = item?.tax === "VAT 15%" ? 15 : 0;
        return (
          sum + (discountedAmount + (taxPercentage / 100) * discountedAmount)
        );
      }, 0)
      .toFixed(2);
    const discountTotal = data
      .reduce((sum, item) => {
        const discountPercentage = parseFloat(item?.discount_val || 0);

        return sum + discountPercentage;
      }, 0)
      .toFixed(2);

    settotalAmt(totalAmt);
    setVatAmount(vatAmount);
    setDiscountVal(discountTotal);
    setValue("net_amount", netAmt);
  };

  useEffect(() => {
    setValue("invoice_no", purchaseInvoiceno?.invoice_no);
  }, [purchaseInvoiceno]);

  // const CalAmt = (tax, key) => {
  //   let taxValue = taxType[0]?.name != "VAT 15%" ? 0 : 15;
  //   let totalAmt = _.sumBy(voucherDetails, function (o) {
  //     return parseFloat(o.amount);
  //   });

  //   let netAmt = 0;
  //   let vatamount = parseFloat(totalAmt * taxValue) / 100;
  //   netAmt = parseFloat(
  //     totalAmt -
  //       discount_val +
  //       Number(vatamount) +
  //       Number(roundOffAmt) +
  //       Number(freightCharge)
  //   );
  //   settotalAmt(totalAmt);
  //   setVatAmount(vatamount.toString());
  //   setValue("net_amount", netAmt);
  // };

  // useEffect(() => {
  //   calculateAmount();
  // }, [voucherDetails, discount_val, roundOffAmt, freightCharge, taxType]);

  useEffect(() => {
    if (createPurchaseInvoiceLoad === API_STATUS.FULFILLED) {
      toastr.success("Purchase Invoice Added Succesfully");
      reset();
      dispatch(clearPurchaseLoadings());
      navigate(-1);
    }
    if (purchaseerrorMessage) {
      toastr.clear();
      if (purchaseerrorMessage.message)
        toastr.error(purchaseerrorMessage.message);
      else if (purchaseerrorMessage.errors) {
        let data = "";
        purchaseerrorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        toastr.error(data);
      }
      dispatch(clearPurchaseLoadings());
      initHit();
    }
  }, [createPurchaseInvoiceLoad, purchaseerrorMessage]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-content-between">
            <h4 className="page-title">Purchase Invoice</h4>
            <h5>Bill Amount : {getValues("net_amount")}</h5>
          </div>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Card>
              <CardBody>
                <Row>
                  <Col md="4">
                    <div className="d-flex align-items-end">
                      <div class="input-container w-100">
                        <input
                          placeholder=" "
                          type="text"
                          id="invoice_no"
                          name="invoice_no"
                          disabled
                          {...register("invoice_no")}
                        />
                        <label for="invoice_no">Invoice No</label>
                      </div>
                    </div>
                  </Col>
                  <Col md="4" className="d-none">
                    <div className="input-container">
                      <Controller
                        name="po_number"
                        control={control}
                        render={({ field, ref }) => (
                          <Select
                            className="js-states select-control"
                            options={purchaseOrders?.rows ?? []}
                            placeholder="Select PO"
                            {...field}
                            ref={ref}
                            dropdownHandle={true}
                            searchable={true}
                            labelField={"quotation_no"}
                            valueField={"id"}
                            values={
                              getValues("po_number")
                                ? purchaseOrders?.rows?.filter(
                                    (ele) => getValues("po_number") == ele.id
                                  )
                                : []
                            }
                            name="po_number"
                            onChange={handlePurchaseOrder}
                          />
                        )}
                        {...register("po_number")}
                      />
                    </div>
                    {errors?.po_number?.message && (
                      <p className="error">{errors.po_number.message}</p>
                    )}
                  </Col>
                  <Col md="4">
                    <div className="input-container">
                      <Controller
                        name="vendor_id"
                        control={control}
                        render={({ field, ref }) => (
                          <Select
                            className="js-states select-control"
                            options={vendorDatas}
                            placeholder="Select Vendor"
                            {...field}
                            ref={ref}
                            dropdownHandle={true}
                            searchable={true}
                            labelField={"vendor_name"}
                            valueField={"id"}
                            name="vendor_id"
                            values={
                              getValues("vendor_id")
                                ? vendorDatas.filter(
                                    (ele) => getValues("vendor_id") == ele.id
                                  )
                                : []
                            }
                            onChange={(value) =>
                              setValue("vendor_id", value[0]?.id)
                            }
                          />
                        )}
                        {...register("vendor_id")}
                      />
                    </div>
                    {errors?.vendor_id?.message && (
                      <p className="error">{errors.vendor_id.message}</p>
                    )}
                  </Col>
                  <Col md="4">
                    <div className="d-flex align-items-end">
                      <div class="input-container w-100">
                        <input
                          placeholder=" "
                          type="date"
                          defaultValue={dayjs().format("YYYY-MM-DD")}
                          id="quotation_date"
                          onChange={(e) =>
                            handleinvoiceDueDate(e, "quotation_date")
                          }
                          name="quotation_date"
                          value={purchaseDateVal}
                        />

                        <label for="quotation_date">Purchase Date</label>
                      </div>
                    </div>
                  </Col>

                  <Col md="4">
                    <div className="d-flex align-items-end">
                      <div class="input-container w-100">
                        <input
                          placeholder=" "
                          type="date"
                          id="invoice_due_date"
                          onChange={(e) =>
                            handleinvoiceDueDate(e, "invoice_date")
                          }
                          name="invoice_due_date"
                          value={invoiceDD}
                        />

                        <label for="invoice_due_date">Invoice Due Date</label>
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="d-flex align-items-end">
                      <div class="input-container w-100">
                        <input
                          placeholder=" "
                          type="text"
                          id="due_days"
                          name="due_days"
                          onChange={(e) => handleInput(e, "due_days", "")}
                          {...register("due_days")}
                        />
                        <label for="due_days">Due Days</label>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="table-responsive react-table">
                  <TableContainer
                    component={Paper}
                    style={{ minHeight: "300px" }}
                  >
                    <Table sx={{ minWidth: 700 }} stickyHeader>
                      <TableHead className="table-light table-nowrap">
                        <TableRow>
                          <StyledTableCell align="">
                            <TableSortLabel
                              active={sortBy == "product_desc" ? true : false}
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                sortByKey("product_desc");
                              }}
                            >
                              Product Description
                            </TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">
                            <TableSortLabel
                              active={
                                sortBy == "additional_description"
                                  ? true
                                  : false
                              }
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                sortByKey("additional_description");
                              }}
                            >
                              Additional Description
                            </TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">
                            <TableSortLabel
                              active={sortBy == "unit" ? true : false}
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                sortByKey("unit");
                              }}
                            >
                              Qty
                            </TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">
                            <TableSortLabel
                              active={sortBy == "unit_price" ? true : false}
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                sortByKey("unit_price");
                              }}
                            >
                              Unit
                            </TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">
                            <TableSortLabel
                              active={sortBy == "price" ? true : false}
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                sortByKey("price");
                              }}
                            >
                              Price
                            </TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">VAT</StyledTableCell>
                          <StyledTableCell align="">Discount</StyledTableCell>
                          <StyledTableCell align="">
                            <TableSortLabel
                              active={sortBy == "amount" ? true : false}
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                sortByKey("amount");
                              }}
                            >
                              Amount
                            </TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">Action</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {voucherDetails?.map((row, index) => {
                          return (
                            <StyledTableRow>
                              <StyledTableCell align="">
                                <Controller
                                  name="product_id"
                                  control={control}
                                  render={({ field, ref }) => (
                                    <Select
                                      className="js-states select-control"
                                      options={PurchaseDatas ?? []}
                                      {...field}
                                      ref={ref}
                                      disabled={false}
                                      dropdownHandle={true}
                                      searchable={true}
                                      labelField={"description"}
                                      valueField={"id"}
                                      name="product_id"
                                      values={
                                        PurchaseDatas?.filter(
                                          (ele) =>
                                            ele.id ==
                                            voucherDetails[index].product_id
                                        ) ?? []
                                      }
                                      onChange={(value) => {
                                        let data = voucherDetails;
                                        console.log(value, "VOUCHERDETAILS1");
                                        data[index].product_id = value[0]?.id;
                                        data[index].unit = 1;
                                        setVoucherDetails([
                                          ...data,
                                          {
                                            additional_description: "",
                                            quantity: 0,
                                            unit: "0",
                                            unit_price: 0,
                                            amount: 0,
                                            id: index + 1,
                                          },
                                        ]);
                                      }}
                                    />
                                  )}
                                />
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <div class="input-container w-100 m-1">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    value={row?.additional_description}
                                    id={`narration${index}`}
                                    name={`narration${index}`}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      let data = voucherDetails;
                                      data[index].additional_description =
                                        value;
                                      setVoucherDetails([...data]);
                                    }}
                                  />
                                </div>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <div class="input-container w-100 m-1">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    value={row?.quantity}
                                    id={`narration${index}`}
                                    name={`narration${index}`}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      let data = voucherDetails;
                                      data[index].quantity = value;
                                      const pro_amt = data[index].unit_price;
                                      const pro_amount = parseFloat(
                                        value * pro_amt
                                      );
                                      data[index].amount =
                                        pro_amount.toFixed(2);
                                      setVoucherDetails([...data]);
                                    }}
                                  />
                                </div>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <Controller
                                  name="unit"
                                  control={control}
                                  render={({ field, ref }) => (
                                    <Select
                                      className="js-states select-control"
                                      options={stockUnitListData}
                                      {...field}
                                      ref={ref}
                                      dropdownHandle={true}
                                      searchable={true}
                                      labelField={"unit_name"}
                                      valueField={"id"}
                                      name="unit"
                                      values={
                                        stockUnitListData?.filter(
                                          (ele) =>
                                            ele.id == voucherDetails[index].unit
                                        ) ?? []
                                      }
                                      onChange={(value) => {
                                        let data = voucherDetails;
                                        data[index].unit = value[0]?.id;
                                        setVoucherDetails([...data]);
                                        console.log(value, "products");
                                      }}
                                    />
                                  )}
                                />
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <div class="input-container w-100 m-1">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    id={`narration${index}`}
                                    name={`narration${index}`}
                                    value={row?.unit_price}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      let data = voucherDetails;
                                      data[index].unit_price = value;
                                      const qty = data[index].quantity;
                                      const pro_amount = parseFloat(
                                        value * qty
                                      );
                                      data[index].amount =
                                        pro_amount.toFixed(2);
                                      setVoucherDetails([...data]);
                                      calculateAmount(index);
                                    }}
                                  />
                                </div>
                              </StyledTableCell>

                              <StyledTableCell align="">
                                <Select
                                  className="js-states select-control"
                                  options={requiredTaxes}
                                  dropdownHandle={true}
                                  searchable={true}
                                  values={
                                    requiredTaxes?.filter(
                                      (ele) =>
                                        ele.name == voucherDetails[index].tax
                                    ) ?? []
                                  }
                                  labelField={"name"}
                                  valueField={"name"}
                                  name={`vat${index}`}
                                  onChange={(value) => {
                                    let data = [...voucherDetails];
                                    data[index].tax = value[0]?.name;
                                    calculateAmount(index);
                                  }}
                                />
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <div className="input-container w-100 m-1">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    value={row?.discount_val}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      let data = voucherDetails;
                                      data[index].discount_val = value;
                                      calculateAmount(index);
                                    }}
                                  />
                                </div>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <div class="input-container w-100 m-1">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    value={row?.amount}
                                    id={`narration${index}`}
                                    name={`narration${index}`}
                                    disabled
                                  />
                                </div>
                              </StyledTableCell>
                              <StyledTableCell
                                align=""
                                className="button-items"
                              >
                                <a
                                  href="javascript:void(0);"
                                  title="Delete"
                                  className="btn btn-danger delete-btn"
                                  onClick={() => {
                                    if (voucherDetails?.length > 1) {
                                      const voucherDetail = _.filter(
                                        voucherDetails,
                                        (key, i) => i != index
                                      );
                                      console.log(
                                        voucherDetail,
                                        "check voucherDetails"
                                      );
                                      setVoucherDetails(voucherDetail);
                                    }
                                  }}
                                >
                                  <i className="ri-delete-bin-line"></i>
                                </a>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div className="invoice-width">
                    <div class="input-container w-100">
                      <textarea
                        id="net_amount"
                        rows={3}
                        name="notes"
                        {...register("notes")}
                      />
                      <label for="notes">Notes</label>
                    </div>
                  </div>
                  <div className="invoice-width">
                    <div class="input-container w-100">
                      <input
                        placeholder=" "
                        type="text"
                        id="total_amount"
                        name="total_amount"
                        value={totalAmt}
                        disabled
                      />
                      <label for="total_amount">Total Amount</label>
                    </div>
                    <div class="input-container w-100">
                      <input
                        placeholder=" "
                        type="text"
                        id="discount"
                        name="discount"
                        value={discount_val}
                        readOnly
                      />
                      <label for="discount">Total Discount Amount</label>
                    </div>
                    <div className="input-container w-100 d-none">
                      {/* <Controller
                        name="vat"
                        control={control}
                        render={({ field, ref }) => (
                          <Select
                            className="js-states select-control"
                            options={requiredTaxes}
                            {...field}
                            ref={ref}
                            dropdownHandle={true}
                            searchable={true}
                            values={taxType}
                            labelField={"name"}
                            valueField={"name"}
                            name="vat"
                            onChange={(value) => {
                              setTaxType([value[0]]);
                            }}
                          />
                        )}
                      /> */}
                      <input type="text" readOnly></input>
                    </div>
                    <div class="input-container w-100">
                      <input
                        placeholder=" "
                        type="text"
                        id="vat_amount"
                        name="vat_amount"
                        disabled
                        value={vat_amount}
                      />
                      <label for="vat_amount">VAT Amount</label>
                    </div>
                    <div class="input-container w-100 d-none">
                      <input
                        placeholder=" "
                        type="text"
                        id="round_off"
                        name="round_off"
                        value={roundOffAmt}
                        onChange={(e) =>
                          setroundOffAmt(
                            e.target.value != "" ? e.target.value : "0"
                          )
                        }
                      />
                      <label for="round_off">Round Off</label>
                    </div>
                    <div class="input-container w-100 d-none">
                      <input
                        placeholder=" "
                        type="text"
                        id="freight_charge"
                        name="freight_charge"
                        value={freightCharge}
                        onChange={(e) =>
                          setfreightCharge(
                            e.target.value != "" ? e.target.value : "0"
                          )
                        }
                      />
                      <label for="freight_charge">Freight Charges</label>
                    </div>
                    <div class="input-container w-100">
                      <input
                        placeholder=" "
                        type="text"
                        id="net_amount"
                        name="net_amount"
                        disabled
                        // value={netAmount}
                        {...register("net_amount")}
                      />
                      <label for="net_amount">Net Amount</label>
                    </div>
                    {errors?.net_amount?.message && (
                      <p className="error">{errors.net_amount.message}</p>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
            <div className="text-end">
              <Button
                color="primary"
                onClick={() => console.log("hello")}
                type="submit"
              >
                Save
              </Button>
            </div>
          </form>
        </Container>
      </div>
    </>
  );
};
export default AddPurchaseInvoice;
