import * as yup from "yup";

export const signInValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .test("email", "Email is invalid", (value) => {
      return validateEmail(value) || validatePhone(parseInt(value ?? "0"));
    }),
  password: yup.string().required("Password is required"),
});

export const userValidationSchema = yup.object().shape({
  name: yup.string().required("User Name is required"),
  email: yup.string().required("Email is required")
    .test("email", "Email is invalid", (value) => {
      return validateEmail(value) || validatePhone(parseInt(value ?? "0"));
    }),
  password: yup.string().required("Password is required"),
  mobile: yup
    .number()
    .transform((_, val) => (val !== "" ? Number(val) : null)).
    required("Mobile is required"),
  // profile_url: yup.string().nullable(true),
});
export const salesQuotationValidation = yup.object().shape({
  customer_name: yup.string().required("Sales Name is required"),
  contact_name: yup.string().nullable(true),
  quotation_date: yup.string(),
  rfq_date: yup.string(),
  requested_by: yup.string(),
  valid_upto: yup.string(),
  product_desc: yup.string(),
  quantity: yup.number(),
  unit: yup.string(),
  unit_price: yup.number(),
  amount: yup.number(),
  customer_notes: yup.string(),
  total_amt: yup.number(),
  discount: yup.string(),
  vat: yup.string(),
  vat_amt: yup.number(),
  round_off: yup.string(),
  net_amount: yup.string(),
});

export const companyvalidator = yup.object().shape({
  company_name: yup.string().required(),
  email: yup.string().required(),
  tax_type: yup.string().required(),
  short_name: yup.string().required("Business Type/Sector is required"),
  building_no: yup.number().required("Building No is required"),
  plot_identification_no: yup.number().required("Plot Idenitification No is required"),
  city: yup.string().required("City is required"),
  sub_divisioncity: yup.string().required("District is required"),
  country_region: yup.string().required("Country Region is required"),
  postal_code: yup.number().required("Postal Code is required"),
  tin: yup.string().required(),
  address: yup.string().required("Address is required"),
  ar_address: yup.string().required(),
  
  // financial_yr_from: yup.date().required(),
  // financial_yr_to: yup.date().required()
})

export const paymentValidator = yup.object().shape({
  // customer_id: yup.number().required(),
  // payment_type: yup.number(),
  // payment_date: yup.date(),
  sales_person: yup.string(),
  invoice_bal: yup.string(),
  pay_amt: yup.number().required("Pay Amount Only in Number"),
  // pay_mode: yup.number(),
  balance_after_payment: yup.string(),
  notes: yup.string(),
})

export const customerValidationSchema = yup.object().shape({
  customer_name: yup.string().required("Custormer Name Requiered"),
  customer_name_arabic: yup.string().nullable(true),
  contact_person: yup.string().nullable(true),
  contact_number: yup
    .number()
    .nullable(true)
    .transform((_, val) => (val !== "" ? Number(val) : null)),
  contact_email: yup.string().email().required("Email Required"),
  payment_method: yup.string().nullable(true),
  credit_limit: yup.string().nullable(true),
  duedays_limit: yup.string().nullable(true),
  invoice_due_days: yup.string().nullable(true),
  vat_no: yup.string().required("VAT Number is Requiered"),
  address: yup.string().required("Street Address is Requiered"),
  zip_code: yup.string().required("Zipcode is Requiered"),
  city: yup.string().required("city is Requiered"),
  iban_no: yup.string().notRequired(),
  plot_identification: yup.string().required("Plot Identification Number is Requiered"),
  city_subdivision: yup.string().required("District is Requiered"),
  cr_number: yup.string().required("CR Number is Requiered"),
  building: yup.string().required("Building Number is Requiered"),
  fax: yup.string().nullable(true),
  ar_customer_name:yup.string().nullable(true),
  ar_contact_person:yup.string().nullable(true),
  ar_address:yup.string().nullable(true),
  ar_zip_code:yup.string().nullable(true),
  ar_city:yup.string().nullable(true),
  ar_vat_no:yup.string().nullable(true),
});

export const productValidationSchema = yup.object().shape({
  description: yup.string().required("Product Description is required"),
  product_category: yup.string().nullable(true),
  product_type: yup.string().nullable(true),
  stock_unit: yup.string().required("Stock Unit is required"),
  purchase_description: yup.string().nullable(true),
  selling_price: yup.string().nullable(true),
  product_cost: yup.string().nullable(true),
  market_cost: yup.string().nullable(true),
  product_barcode: yup.string().nullable(true),
  part_no: yup.string().nullable(true),
  financial_category: yup
    .string()
    .nullable(true)
    .required("Financial Category is required"),
  hs_code: yup.string().nullable(true),
  brand: yup.string().nullable(true),
  manufacturer: yup.string().nullable(true),
  rack: yup.string().nullable(true),
  length: yup.string().nullable(true),
  height: yup.string().nullable(true),
  width: yup.string().nullable(true),
  weight: yup.string().nullable(true),
  color: yup.string().nullable(true),
  temperature: yup.string().nullable(true),
  long_description: yup.string().nullable(true),
  warrenty_days: yup.string().nullable(true),
  warrenty_type: yup.string().nullable(true),
  warrenty_remarks: yup.string().nullable(true),
  term_condition: yup.string().nullable(true),
  ar_description:yup.string().nullable(true),
  ar_product_category:yup.string().nullable(true),
  ar_product_type:yup.string().nullable(true),
});
// quotation_image: yup.string().nullable(true),
// pos_image: yup.string().nullable(true),
export const vendorValidationSchema = yup.object().shape({
  vendor_name: yup.string().required("Vendor Name is required"),
  vendor_name_arabic: yup.string().nullable(true),
  contact_person: yup.string().nullable(true),
  contact_number: yup
    .number()
    .nullable(true)
    .transform((_, val) => (val !== "" ? Number(val) : null)),
  contact_email: yup.string().email().nullable(true),
  payment_method: yup.number().nullable(true),
  credit_limit: yup.string().nullable(true),
  duedays_limit: yup.string().nullable(true),
  invoice_due_days: yup.string().nullable(true),
  vat_no: yup.string().nullable(true),
  address: yup.string().nullable(true),
  zip_code: yup.string().nullable(true),
  city: yup.string().nullable(true),
  fax: yup.string().nullable(true),
});
export const vendorCreate = yup.object().shape({
  vendor_name: yup.string().required(),
  ar_vendor_name: yup.string().required(),
  contact_person: yup.string(),
  contact_number: yup.string(),
  fax: yup.string(),
  contact_email: yup.string(),
  // web_address: yup.string(),
  phone: yup.number(),
  country_name: yup.number(),
  currency_type: yup.number(),
  zip_code: yup.number(),
  address: yup.string(),
  // pay_payments_from: yup.string(),
  // payment_method: yup.number(),
  // vendor_type: yup.string(),
  // partner_type: yup.string(),
  cr_no: yup.string(),
  vat_number: yup
  .string()
  .max(15, "VAT number must be 15 characters or less.")
  // gl_acount: yup.string(),
  // advanced_acc_mapped_to: yup.string(),
  // vat_code: yup.string(),
  // avg_delivery_delay: yup.number(),
  // invoice_due_days: yup.number(),
  // remainder: yup.string(),
  // allow_cash_pay: yup.string(),
  // auto_generate_gl: yup.string(),
  // inactive: yup.string(),
});
export const reportsValidation = yup.object().shape({
  type: yup.string(),
});

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const signUpValidationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .test("email", "Email is invalid", (value) => {
      return validateEmail(value);
    }),
  phone_number: yup
    .string()
    .required("Phone number is required")
    .test("phone", "Phone is invalid", (value) => {
      return validatePhone(parseInt(value ?? "0"));
    }),
  password: yup
    .string()
    .required("Password is required")
    .matches(passwordRules, { message: "Please create a stronger password" }),
});

const validateEmail = (email) => {
  return yup.string().email().isValidSync(email);
};

const validatePhone = (phone) => {
  return yup
    .number()
    .integer()
    .positive()
    .test((phone) => {
      return phone &&
        phone.toString().length >= 8 &&
        phone.toString().length <= 14
        ? true
        : false;
    })
    .isValidSync(phone);
};
export const PurchaseOrderValidator = yup.object().shape({
  vendor_id: yup.number().required("Vendor"),
  date: yup.date().required("date is required"),
  eta: yup.date("ETA is required"),
  product_type: yup.string(),
  attention: yup.string(),
  due_days: yup.number(),
  quotation_no: yup.string(),
  quotation_date: yup.date(),
  notes: yup.string(),
  total_amount: yup.number().required("Required"),
  discount_amount: yup.number().nullable(true),
  vat_cat: yup.string(),
  vat_amount: yup.number(),
  round_off: yup.number(),
  net_amount: yup.number(),
});
export const purchaseReturnValidator = yup.object().shape({
  return_no: yup.string(),
  vendor_id: yup.number().required("Vendor is required"),
  return_date: yup.string(),
  notes: yup.string(),
  total_amount: yup.string(),
  vat: yup.string(),
  vat_amount: yup.string(),
  round_off: yup.string(),
  discount_amount: yup.string(),
  net_amount: yup.string(),
  pay_mode: yup.number(),
  pay_account: yup.number(),
  current_bal: yup.string().required("Current Balance is Required"),
});
export const purchaseInvoiceValidator = yup.object().shape({
  invoice_no: yup.string().required(),
  // po_number: yup.number().required("PO is required"),
  vendor_id: yup.number().required("Vendor is required"),
  purchase_date: yup.string(),
  invoice_due_date: yup.string(),
  due_days: yup.string(),
  notes: yup.string(),
  total_amount: yup.string(),
  discount_amount: yup.string(),
  vat: yup.string(),
  vat_amount: yup.string(),
  round_off: yup.string(),
  net_amount: yup.number().required("Net Amount is required"),
});
export const vendorPaymentValidator = yup.object().shape({
  payment_no: yup.string().required("Receipt No is required"),
  vendor_id: yup.number().required("Vendor is required"),
  payable_bal: yup.string(),
  bal_after_pay: yup.string(),
  pay_date: yup.date(),
  pay_type: yup.object(),
  pay_amt: yup.number(),
  pay_mode: yup.string().required("Pay Mode is required"),
  // pay_account: yup.string().required("Pay Account is required"),
  pay_account: yup.string().notRequired(),
  account_bal: yup.string(),
  pay_all_status: yup.number(),
  notes: yup.string(),
});
export const purchaseValidationSchema = yup.object().shape({
  description: yup.string().required("Purchase Description is required"),
  product_category: yup.string().nullable(true),
  product_type: yup.string().nullable(true),
  stock_unit: yup.string().required("Stock Unit is required"),
  purchase_description: yup.string().nullable(true),
  selling_price: yup.string().nullable(true),
  product_cost: yup.string().nullable(true),
  market_cost: yup.string().nullable(true),
  product_barcode: yup.string().nullable(true),
  part_no: yup.string().nullable(true),
  financial_category: yup
    .string()
    .nullable(true)
    .required("Financial Category is required"),
  hs_code: yup.string().nullable(true),
  brand: yup.string().nullable(true),
  manufacturer: yup.string().nullable(true),
  rack: yup.string().nullable(true),
  length: yup.string().nullable(true),
  height: yup.string().nullable(true),
  width: yup.string().nullable(true),
  weight: yup.string().nullable(true),
  color: yup.string().nullable(true),
  temperature: yup.string().nullable(true),
  long_description: yup.string().nullable(true),
  warrenty_days: yup.string().nullable(true),
  warrenty_type: yup.string().nullable(true),
  warrenty_remarks: yup.string().nullable(true),
  term_condition: yup.string().nullable(true),
  ar_description:yup.string().nullable(true),
  ar_product_category:yup.string().nullable(true),
  ar_product_type:yup.string().nullable(true),
});