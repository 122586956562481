import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "react-dropdown-select";
import { useNavigate, Link } from "react-router-dom";

import {
  customerValidationSchema,
  paymentValidator,
} from "../../utils/Validation";

import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  CardHeader,
  Collapse,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
//Import Breadcrumb
import { products } from "../../common/data/ecommerce";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import Breadcrumb from "../Common/Breadcrumb";
import dayjs from "dayjs";
import {
  getPaymentList,
  paymentStat,
  salesinvoiceSelector,
} from "../../store/reducer/SalesInvoiceReducer";
import { getInvoiceReducer } from "../../store/reducer/SalesInvoiceReducer";
import {
  customerData,
  customersSelector,
} from "../../store/reducer/CustomerReducer";
import { paymentType } from "../../utils/constants";
import { debounce } from "lodash";
import { usersSelector } from "../../store/reducer/UserReducer";

const SalesPayment = () => {
  const dispatch = useDispatch();
  let [sortBy, setSortBy] = useState("created_at");
  let [activesortBy, setActiveSortBy] = useState("created_at");
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("desc");
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(paymentValidator),
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [monthTotal, setMonthTotal] = useState(0);
  const [totToday, settotToday] = useState(0);
  const [accord, setAccord] = useState({
    col1: false,
    col2: false,
    col3: false,
  });
  const [userActions, setUserActions] = useState({});

  const { payments, statData } = useSelector(salesinvoiceSelector);
  const { customerDatas } = useSelector(customersSelector);
  const { selectScreenData } = useSelector(usersSelector);
  console.log(customerDatas, "customerDatas");
  console.log(payments, "payments");
  const navigate = useNavigate();
  const [quotationDetails, setQuotationDetails] = useState([
    {
      id: "1",
      receipt_no: "1324",
      customer_name: "tie",
      transaction_type: "1",
      payment_date: "10-07-19",
      pay_amount: 1234,
      pay_mode: "2",
      pay_account: "",
      notes: "-",
    },
  ]);

  // let totBillAmt = 0;
  // for (let index = 0; index < quotationDetails.length; index++) {
  //     const element = quotationDetails[index];
  //     totBillAmt += element.pay_amount
  // }
  // console.log(payments, 'payments');

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
    dispatch(
        getPaymentList({
          query: searchQuery,
          page: page + 1,
          limit: rowsPerPage,
          sortby: sortBy,
          order: order,
        })
      );
  };

  useEffect(() => {
    let sum = 0;
    statData?.stat_month?.rows?.forEach((num) => {
      sum += parseFloat(num.pay_amt);
    });
    let tsum = 0;
    statData?.stat_today?.rows?.forEach((num) => {
      tsum += parseFloat(num.pay_amt);
    });
    setMonthTotal(sum);
    settotToday(tsum);
  }, [statData]);

  const tog_fullscreen = () => {
    navigate("/sales/payment/new");
  };
  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const handleInput = (e, dest, type) => {
    const { value } = e.target;
    console.log(value);
    quotationDetails[dest] = type !== "" ? Number(value) : value;
    setQuotationDetails({
      ...quotationDetails,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(
        getPaymentList({
          query: searchQuery,
          page: page + 1,
          limit: rowsPerPage,
          sortby: sortBy,
          order: order,
        })
      );
  };
  const searchData = (e) => {
    // searchQuery = e;
    setSearchQuery(e);
    setPage(0);
  };
  const debounceSearch = useCallback(debounce(searchData, 500), []);

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
    dispatch(
      getPaymentList({
        query: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
        sortby: sortBy,
        order: order,
      })
    );
  };
  useEffect(() => {
    dispatch(
      getPaymentList({
        query: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
        sortby: sortBy,
        order: order,
      })
    );
    dispatch(customerData({}));
    dispatch(paymentStat({}));
  }, [searchQuery]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Position",
        accessor: "position",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Office",
        accessor: "office",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Age",
        accessor: "age",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Start Date",
        accessor: "startdate",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Salary",
        accessor: "salary",
        disableFilters: true,
        filterable: false,
      },
    ],
    []
  );
  const t_col1 = () => {
    setAccord({ col1: !accord.col1, col2: false, col3: false });
  };
  const t_col2 = () => {
    setAccord({ col1: false, col2: !accord.col2, col3: false });
  };

  const breadcrumbItems = [
    { title: "Tables", link: "/" },
    { title: "Data Tables", link: "#" },
  ];

  useEffect(() => {
    if (selectScreenData && selectScreenData.length > 0) {
      const value = selectScreenData[0]?.actions;
      setUserActions(value || {});
    }
  }, [selectScreenData]);
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-content-between">
            <h2>Customer Payment</h2>
            {userActions["Sales Payment-add"] && (
              <button
                type="button"
                className="waves-effect waves-light btn btn-primary mb-2"
                onClick={tog_fullscreen}
              >
                Add
              </button>
            )}
          </div>
          <Row>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Number of Payment Today
                      </p>
                      <h4 className="mb-0">
                        {statData?.stat_today?.count ?? "-"}
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-stack-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>

                {/* <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge badge-soft-danger font-size-11 me-1"><i className="mdi mdi-menu-down"> </i> 2</span>
                                        <span className="text-muted ms-2">Decrease</span>
                                    </div>
                                </CardBody> */}
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Payment total Today
                      </p>
                      <h4 className="mb-0">{totToday}</h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-funds-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>

                {/* <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge badge-soft-danger font-size-11 me-1"><i className="mdi mdi-menu-down"> </i> 2</span>
                                        <span className="text-muted ms-2">Decrease</span>
                                    </div>
                                </CardBody> */}
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Number of Payment This Month
                      </p>
                      <h4 className="mb-0">
                        {statData?.stat_month?.count ?? "-"}
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-stack-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>

                {/* <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge badge-soft-success font-size-11 me-1"><i className="mdi mdi-menu-up"> </i> 2</span>
                                        <span className="text-muted ms-2">Increase</span>
                                    </div>
                                </CardBody> */}
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Payment Total This Month
                      </p>
                      <h4 className="mb-0">{monthTotal}</h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-funds-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>

                {/* <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge badge-soft-success font-size-11 me-1"><i className="mdi mdi-menu-up"> </i> 2</span>
                                        <span className="text-muted ms-2">Increase</span>
                                    </div>
                                </CardBody> */}
              </Card>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-md-8">
                  {/* <h5>Total <span>{totBillAmt.toFixed(2)}</span></h5> */}
                </div>
                <div className="col-md-4 row">
                  <input
                    type="text"
                    name="search"
                    className="form-control"
                    style={{ float: "right" }}
                    // value={searchQuery}
                    onChange={(e) => {
                      // searchData(e);
                      debounceSearch(e.target.value);
                    }}
                    placeholder="Search"
                  />
                </div>
              </div>
              <div className="table-responsive react-table">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} stickyHeader>
                    <TableHead className="table-light table-nowrap">
                      <TableRow>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "id" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("id");
                            }}
                          >
                            Receipt No
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "customer" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("customer_name");
                            }}
                          >
                            Customer
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "payment_type" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("payment_type");
                            }}
                          >
                            Transaction Type
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "payment_date" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("payment_date");
                            }}
                          >
                            Payment Date
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "pay_amt" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("pay_amt");
                            }}
                          >
                            Pay Amount
                          </TableSortLabel>
                        </StyledTableCell>
                        {/* <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "pay_mode" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("pay_mode");
                                                        }}
                                                    >
                                                        Pay Mode
                                                    </TableSortLabel> */}
                        {/* </StyledTableCell> */}
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "notes" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("notes");
                            }}
                          >
                            Notes
                          </TableSortLabel>
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {payments.length > 0 &&
                        payments?.map((row) => (
                          <StyledTableRow>
                            <StyledTableCell align="">
                              {row?.id}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {customerDatas?.length > 0 &&
                                customerDatas.find(
                                  (item) => item?.id === row.customer_id
                                )?.customer_name}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {
                                paymentType.find(
                                  (type) => (type.id = row?.payment_type)
                                )?.mode
                              }
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row?.payment_date &&
                                dayjs(row?.payment_date).format("YYYY-MM-DD")}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row?.pay_amt}
                            </StyledTableCell>
                            {/* <StyledTableCell align="">
                                                            {row?.pay_mode}
                                                        </StyledTableCell> */}
                            <StyledTableCell align="">
                              {row?.notes ?? "-"}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50]}
                          colSpan={9}
                          count={statData?.stat_month?.count}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default SalesPayment;
