import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/constants";
import {
  purchase_invoice_data,
  purchase_invoice_list,
  get_purchase_invoice_no,
  create_purchase_invoice,
  purchaseinvoiceUpdate,
  createpurchaseorder,
  getpurchaseorder,
  getpurchaseorderno,
  purchaseorderUpdate,
  purchaseorderStat,
  create_purchase_return,
  purchase_return_list,
  purchase_return_data,
  purchasereturnUpdate,
  get_purchase_return_no,
  vendor_payement_list,
  vendor_payment_data,
  get_vendor_payment_no,
  create_vendor_payment,
  get_vendor_invoice,
  order_by_id,
  invoice_by_id,
  invoiceUpdatePurchase,
} from "../../services/api";
import EncryptDecrypt, { decryptdata } from "./../../utils/encrypt&decrypt";
import { secretKey } from "../../services/config";
import { sortBy } from "lodash";

const namespace = "purchase";

const initialState = {
  purchaseerrorMessage: "",
  purchaseInvoiceLoader: "initial",
  orderNumberLoad: "initial",
  purchaseInvoiceDataLoader: "initial",
  purchaseOrderListLoader: "initial",
  purchaseOrderListAllLoader: "initial",
  purchaseOrderLoad: "initial",
  generatedPILoad: "initial",
  createPurchaseInvoiceLoad: "initial",
  updatePurchaseInvoiceStatusLoad: "initial",
  updateOrderLoad: "initial",
  getStatofOrderLoad: "initial",
  purchaseInvoiceListData: [],
  purchaseInvoiceCardDatas: [],
  purchaseorderStat: {},
  purchaseorderStatLoad: "initial",
  orderNumber: null,
  purchaseOrders: [],
  purchaseOrdersAll: [],
  purchaseReturnListLoad: "initial",
  createPurchaseReturnLoad: "initial",
  purchaseReturnDataLoader: "initial",
  updatePurchaseReturnStatusLoad: "initial",
  generatedPRLoad: "initial",
  vendorpaymentListLoader: "initial",
  vendorPaymentDataLoader: "initial",
  createVendorPaymentLoad: "initial",
  generatedVPLoad: "initial",
  getVendorInvoiceDetailsLoad: "initial",
  getOrderByIdLoading: "initial",
  getInvoiceByIdLoading: "initial",
  purchaseInvoiceListData: [],
  purchaseInvoiceCardDatas: [],
  purchaseReturnListData: [],
  purchaseReturnCardDatas: [],
  vendorPaymentListData: [],
  vendorPaymentCardDatas: [],
  vendorPaymentsData: [],
  purchseOrderDatasByID: [],
  purchaseInvoiceno: 0,
  purchaseReturnno: 0,
  purchaseVPno: 0,
  purchseInvoiceDatasByID: [],
  purchaseIUloading: "initial",
};

export const purchaseInvoiceList = createAsyncThunk(
  `${namespace}/purchaseInvoiceList`,
  async (
    { query = "", page = 0, limit = 0, sortby = "invoice_no", order = "desc" },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await purchase_invoice_list(
        query,
        page,
        limit,
        sortby,
        order
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateOrderStatus = createAsyncThunk(
  `${namespace}/updateOrderStatus`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await purchaseorderUpdate(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const purchaseOrderList = createAsyncThunk(
  `${namespace}/purchaseOrderList`,
  async (
    {
      query,
      page,
      limit,
      order,
      sortby,
      from_date = "",
      to_date = "",
      vendor_id = "",
      payment_status = "",
      invoice_status = "",
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await getpurchaseorder(
        query,
        page,
        limit,
        order,
        sortby,
        from_date,
        to_date,
        vendor_id,
        payment_status,
        invoice_status
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const purchaseOrderListAll = createAsyncThunk(
  `${namespace}/purchaseOrderListAll`,
  async (
    {
      query,
      page,
      limit,
      order,
      sortby,
      from_date = "",
      to_date = "",
      vendor_id = "",
      payment_status = "",
      invoice_status = "",
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await getpurchaseorder(
        query,
        page,
        limit,
        order,
        sortby,
        from_date,
        to_date,
        vendor_id,
        payment_status,
        invoice_status
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const purchaseInvoiceDatas = createAsyncThunk(
  `${namespace}/purchaseInvoiceDatas`,
  async ({}, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await purchase_invoice_data();
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getInvoiceNo = createAsyncThunk(
  `${namespace}/getInvoiceNo`,
  async ({}, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await get_purchase_invoice_no();
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getOrderNo = createAsyncThunk(
  `${namespace}/getOrderNo`,
  async ({}, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await getpurchaseorderno();
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createPurchaseInvoice = createAsyncThunk(
  `${namespace}/createPurchaseInvoice`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "createPurchaseInvoice formData");
      const { data } = await create_purchase_invoice(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createPurchaseOrder = createAsyncThunk(
  `${namespace}/createPurchaseOrder`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "createPurchaseOrder formData");
      const { data } = await createpurchaseorder(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updatePurchaseInvoiceStatus = createAsyncThunk(
  `${namespace}/updatePurchaseInvoiceStatus`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, 567890);

      const data = await purchaseinvoiceUpdate(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updatePurchaseInvoice = createAsyncThunk(
  `${namespace}/updatePurchaseInvoice`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, 567890);

      const data = await invoiceUpdatePurchase(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getStatofOrder = createAsyncThunk(
  `${namespace}/getStatofOrder`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const data = await purchaseorderStat(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const purchaseReturnList = createAsyncThunk(
  `${namespace}/purchaseReturnList`,
  async (
    { query, page, limit, from_date = "", to_date = "" },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await purchase_return_list(
        query,
        page,
        limit,
        from_date,
        to_date
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createPurchaseReturn = createAsyncThunk(
  `${namespace}/createPurchaseReturn`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "createPurchaseReturn formData");
      const { data } = await create_purchase_return(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const purchaseReturnDatas = createAsyncThunk(
  `${namespace}/purchaseReturnDatas`,
  async ({}, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await purchase_return_data();
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updatePurchaseReturnStatus = createAsyncThunk(
  `${namespace}/updatePurchaseReturnStatus`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const data = await purchasereturnUpdate(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getReturnNo = createAsyncThunk(
  `${namespace}/getReturnNo`,
  async ({}, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await get_purchase_return_no();
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const vendorpaymentList = createAsyncThunk(
  `${namespace}/vendorpaymentList`,
  async (
    { query, page, limit, from_date = "", to_date = "" },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await vendor_payement_list(
        query,
        page,
        limit,
        from_date,
        to_date
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const vendorPaymentData = createAsyncThunk(
  `${namespace}/vendorPaymentData`,
  async ({}, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await vendor_payment_data();
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getPaymentNo = createAsyncThunk(
  `${namespace}/getPaymentNo`,
  async ({}, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await get_vendor_payment_no();
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createVendorPayment = createAsyncThunk(
  `${namespace}/createVendorPayment`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await create_vendor_payment(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getVendorInvoiceDetails = createAsyncThunk(
  `${namespace}/getVendorInvoiceDetails`,
  async (
    { query = "", page = 0, limit = 0, sortby = "description", order = "asc" },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const data = await get_vendor_invoice(query, page, limit, sortby, order);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const getOrderById = createAsyncThunk(
  `${namespace}/getOrderById`,
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const data = await order_by_id(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const getInvoiceById = createAsyncThunk(
  `${namespace}/getInvoiceById`,
  async (ids, { rejectWithValue, dispatch }) => {
    try {
      let id = parseInt(ids.editID);
      const { data } = await invoice_by_id(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
const purchaseSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearPurchaseData: () => {
      return initialState;
    },
    clearPurchaseLoadings: (state) => {
      state.purchaseInvoiceLoader = "initial";
      state.purchaseInvoiceDataLoader = "initial";
      state.generatedPILoad = "initial";
      state.createPurchaseInvoiceLoad = "initial";
      state.updatePurchaseInvoiceStatusLoad = "initial";
      state.purchaseOrderLoad = "initial";
      state.purchaseOrderListLoader = "initial";
      state.purchaseOrderListAllLoader = "initial";
      state.orderNumberLoad = "initial";
      state.updateOrderLoad = "initial";
      state.getStatofOrderLoad = "initial";
      state.purchaseorderStatLoad = "initial";
      state.createPurchaseReturnLoad = "initial";
      state.purchaseReturnListLoad = "initial";
      state.purchaseReturnDataLoader = "initial";
      state.updatePurchaseReturnStatusLoad = "initial";
      state.generatedPRLoad = "initial";
      state.vendorpaymentListLoader = "initial";
      state.vendorPaymentDataLoader = "initial";
      state.createVendorPaymentLoad = "initial";
      state.generatedVPLoad = "initial";
      state.getVendorInvoiceDetailsLoad = "initial";
      state.getOrderByIdLoading = "initial";
      state.getInvoiceByIdLoading = "initial";
      state.purchaseIUloading = "initial";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purchaseInvoiceList.pending, (state) => {
      state.purchaseInvoiceLoader = API_STATUS.PENDING;
    });
    builder.addCase(purchaseInvoiceList.fulfilled, (state, { payload }) => {
      state.purchaseInvoiceLoader = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.datas, secretKey)
      );
      state.purchaseInvoiceListData = payloaddatas;
    });
    builder.addCase(purchaseInvoiceList.rejected, (state, action) => {
      state.purchaseInvoiceLoader = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });
    builder.addCase(purchaseOrderList.pending, (state) => {
      state.purchaseOrderListLoader = API_STATUS.PENDING;
    });
    builder.addCase(purchaseOrderList.fulfilled, (state, { payload }) => {
      state.purchaseOrderListLoader = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.datas, secretKey)
      );
      state.purchaseOrders = payloaddatas;
    });
    builder.addCase(purchaseOrderList.rejected, (state, action) => {
      state.purchaseOrderListLoader = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });
    builder.addCase(purchaseOrderListAll.pending, (state) => {
      state.purchaseOrderListAllLoader = API_STATUS.PENDING;
    });
    builder.addCase(purchaseOrderListAll.fulfilled, (state, { payload }) => {
      state.purchaseOrderListAllLoader = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.datas, secretKey)
      );
      state.purchaseOrdersAll = payloaddatas;
    });
    builder.addCase(purchaseOrderListAll.rejected, (state, action) => {
      state.purchaseOrderListAllLoader = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });
    builder.addCase(purchaseInvoiceDatas.pending, (state) => {
      state.purchaseInvoiceDataLoader = API_STATUS.PENDING;
    });
    builder.addCase(purchaseInvoiceDatas.fulfilled, (state, { payload }) => {
      state.purchaseInvoiceDataLoader = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.datas, secretKey)
      );
      state.purchaseInvoiceCardDatas = payloaddatas;
    });
    builder.addCase(purchaseInvoiceDatas.rejected, (state, action) => {
      state.purchaseInvoiceDataLoader = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });
    builder.addCase(getOrderNo.pending, (state) => {
      state.orderNumberLoad = API_STATUS.PENDING;
    });
    builder.addCase(getOrderNo.fulfilled, (state, { payload }) => {
      state.orderNumberLoad = API_STATUS.FULFILLED;
      state.orderNumber = payload?.invoice_no;
    });
    builder.addCase(getOrderNo.rejected, (state, action) => {
      state.orderNumberLoad = API_STATUS.REJECTED;
    });
    builder.addCase(updateOrderStatus.pending, (state) => {
      state.updateOrderLoad = API_STATUS.PENDING;
    });
    builder.addCase(updateOrderStatus.fulfilled, (state, { payload }) => {
      state.updateOrderLoad = API_STATUS.FULFILLED;
    });
    builder.addCase(updateOrderStatus.rejected, (state, action) => {
      state.updateOrderLoad = API_STATUS.REJECTED;
    });
    builder.addCase(getStatofOrder.pending, (state) => {
      state.purchaseorderStatLoad = API_STATUS.PENDING;
    });
    builder.addCase(getStatofOrder.fulfilled, (state, { payload }) => {
      state.purchaseorderStatLoad = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );
      console.log(payloaddatas, "PAYLOADCAARD");
      state.purchaseorderStat = payloaddatas;
    });
    builder.addCase(getStatofOrder.rejected, (state, action) => {
      state.purchaseorderStatLoad = API_STATUS.REJECTED;
    });
    builder.addCase(createPurchaseOrder.pending, (state) => {
      state.purchaseOrderLoad = API_STATUS.PENDING;
    });
    builder.addCase(createPurchaseOrder.fulfilled, (state, { payload }) => {
      state.purchaseOrderLoad = API_STATUS.FULFILLED;
    });
    builder.addCase(createPurchaseOrder.rejected, (state, action) => {
      state.purchaseOrderLoad = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });
    builder.addCase(getInvoiceNo.pending, (state) => {
      state.generatedPILoad = API_STATUS.PENDING;
    });
    builder.addCase(getInvoiceNo.fulfilled, (state, { payload }) => {
      state.generatedPILoad = API_STATUS.FULFILLED;
      state.purchaseInvoiceno = payload;
    });
    builder.addCase(getInvoiceNo.rejected, (state, action) => {
      state.generatedPILoad = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });
    builder.addCase(createPurchaseInvoice.pending, (state) => {
      state.createPurchaseInvoiceLoad = API_STATUS.PENDING;
    });
    builder.addCase(createPurchaseInvoice.fulfilled, (state, { payload }) => {
      state.createPurchaseInvoiceLoad = API_STATUS.FULFILLED;
    });
    builder.addCase(createPurchaseInvoice.rejected, (state, action) => {
      state.createPurchaseInvoiceLoad = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });
    builder.addCase(updatePurchaseInvoice.pending, (state) => {
      state.purchaseIUloading = API_STATUS.PENDING;
    });
    builder.addCase(updatePurchaseInvoice.fulfilled, (state, { payload }) => {
      state.purchaseIUloading = API_STATUS.FULFILLED;
    });
    builder.addCase(updatePurchaseInvoice.rejected, (state, action) => {
      state.purchaseIUloading = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });
    builder.addCase(updatePurchaseInvoiceStatus.pending, (state) => {
      state.updatePurchaseInvoiceStatusLoad = API_STATUS.PENDING;
    });
    builder.addCase(
      updatePurchaseInvoiceStatus.fulfilled,
      (state, { payload }) => {
        state.updatePurchaseInvoiceStatusLoad = API_STATUS.FULFILLED;
      }
    );
    builder.addCase(updatePurchaseInvoiceStatus.rejected, (state, action) => {
      state.updatePurchaseInvoiceStatusLoad = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });
    builder.addCase(purchaseReturnList.pending, (state) => {
      state.purchaseReturnListLoader = API_STATUS.PENDING;
    });
    builder.addCase(purchaseReturnList.fulfilled, (state, { payload }) => {
      state.purchaseReturnListLoader = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.datas, secretKey)
      );
      state.purchaseReturnListData = payloaddatas;
    });
    builder.addCase(purchaseReturnList.rejected, (state, action) => {
      state.purchaseReturnListLoader = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });
    builder.addCase(createPurchaseReturn.pending, (state) => {
      state.createPurchaseReturnLoad = API_STATUS.PENDING;
    });
    builder.addCase(createPurchaseReturn.fulfilled, (state, { payload }) => {
      state.createPurchaseReturnLoad = API_STATUS.FULFILLED;
    });
    builder.addCase(createPurchaseReturn.rejected, (state, action) => {
      state.createPurchaseReturnLoad = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });
    builder.addCase(purchaseReturnDatas.pending, (state) => {
      state.purchaseReturnDataLoader = API_STATUS.PENDING;
    });
    builder.addCase(purchaseReturnDatas.fulfilled, (state, { payload }) => {
      state.purchaseReturnDataLoader = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.datas, secretKey)
      );
      state.purchaseReturnCardDatas = payloaddatas;
    });
    builder.addCase(purchaseReturnDatas.rejected, (state, action) => {
      state.purchaseReturnDataLoader = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });
    builder.addCase(updatePurchaseReturnStatus.pending, (state) => {
      state.updatePurchaseReturnStatusLoad = API_STATUS.PENDING;
    });
    builder.addCase(
      updatePurchaseReturnStatus.fulfilled,
      (state, { payload }) => {
        state.updatePurchaseReturnStatusLoad = API_STATUS.FULFILLED;
      }
    );
    builder.addCase(updatePurchaseReturnStatus.rejected, (state, action) => {
      state.updatePurchaseReturnStatusLoad = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });
    builder.addCase(getReturnNo.pending, (state) => {
      state.generatedPRLoad = API_STATUS.PENDING;
    });
    builder.addCase(getReturnNo.fulfilled, (state, { payload }) => {
      state.generatedPRLoad = API_STATUS.FULFILLED;
      state.purchaseReturnno = payload;
    });
    builder.addCase(getReturnNo.rejected, (state, action) => {
      state.generatedPRLoad = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });
    builder.addCase(vendorpaymentList.pending, (state) => {
      state.vendorpaymentListLoader = API_STATUS.PENDING;
    });
    builder.addCase(vendorpaymentList.fulfilled, (state, { payload }) => {
      state.vendorpaymentListLoader = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.datas, secretKey)
      );
      console.log(payloaddatas, "vendorpaymentList1");
      state.vendorPaymentListData = payloaddatas;
    });
    builder.addCase(vendorpaymentList.rejected, (state, action) => {
      state.vendorpaymentListLoader = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });
    builder.addCase(vendorPaymentData.pending, (state) => {
      state.vendorPaymentDataLoader = API_STATUS.PENDING;
    });
    builder.addCase(vendorPaymentData.fulfilled, (state, { payload }) => {
      state.vendorPaymentDataLoader = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.datas, secretKey)
      );
      state.vendorPaymentCardDatas = payloaddatas;
    });
    builder.addCase(vendorPaymentData.rejected, (state, action) => {
      state.vendorPaymentDataLoader = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });
    builder.addCase(getPaymentNo.pending, (state) => {
      state.generatedVPLoad = API_STATUS.PENDING;
    });
    builder.addCase(getPaymentNo.fulfilled, (state, { payload }) => {
      state.generatedVPLoad = API_STATUS.FULFILLED;
      state.purchaseVPno = payload;
    });
    builder.addCase(getPaymentNo.rejected, (state, action) => {
      state.generatedVPLoad = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });
    builder.addCase(createVendorPayment.pending, (state) => {
      state.createVendorPaymentLoad = API_STATUS.PENDING;
    });
    builder.addCase(createVendorPayment.fulfilled, (state, { payload }) => {
      state.createVendorPaymentLoad = API_STATUS.FULFILLED;
    });
    builder.addCase(createVendorPayment.rejected, (state, action) => {
      state.createVendorPaymentLoad = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });

    builder.addCase(getVendorInvoiceDetails.pending, (state) => {
      state.getVendorInvoiceDetailsLoad = API_STATUS.PENDING;
    });
    builder.addCase(getVendorInvoiceDetails.fulfilled, (state, { payload }) => {
      state.getVendorInvoiceDetailsLoad = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.data, secretKey)
      );
      console.log(payloaddatas?.data, "vendorPaymentsData12");
      state.vendorPaymentsData = payloaddatas?.data;
    });
    builder.addCase(getVendorInvoiceDetails.rejected, (state, action) => {
      state.getVendorInvoiceDetailsLoad = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });
    builder.addCase(getOrderById.pending, (state) => {
      state.getOrderByIdLoading = API_STATUS.PENDING;
    });
    builder.addCase(getOrderById.fulfilled, (state, { payload }) => {
      state.getOrderByIdLoading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );
      console.log(payloaddatas, "payload");
      state.purchseOrderDatasByID = payloaddatas?.response;
    });
    builder.addCase(getOrderById.rejected, (state, action) => {
      state.getOrderByIdLoading = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });
    builder.addCase(getInvoiceById.pending, (state) => {
      state.getInvoiceByIdLoading = API_STATUS.PENDING;
    });
    builder.addCase(getInvoiceById.fulfilled, (state, { payload }) => {
      state.getInvoiceByIdLoading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.datas, secretKey)
      );
      state.purchseInvoiceDatasByID = payloaddatas?.response;
    });
    builder.addCase(getInvoiceById.rejected, (state, action) => {
      state.getInvoiceByIdLoading = API_STATUS.REJECTED;
      state.purchaseerrorMessage = action?.payload?.data;
    });
  },
});

export const { clearPurchaseData, clearPurchaseLoadings } =
  purchaseSlice.actions;

export const purchaseSelector = (state) => state.purchase;

export default purchaseSlice.reducer;
