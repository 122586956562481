import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "./Common/Breadcrumb";
//Import Chart
import PurchasePieChart from "./Dashboard/PurchasePieChart";
import InventoryPieChart from "./Dashboard/InventoryPieChart";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import ReactApexChart from "react-apexcharts";
import TablePaginationActions from "../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../utils/tableRows";
import dayjs from "dayjs";

import {
  productData,
  dashboardinvoiceData,
  dashboardChartData,
  dashboardSelector,
  clearProdLoadingDatas,
} from "../store/reducer/DashboardReducer";
import {
  salesinvoiceSelector,
  getSalesStat,
  paymentStat,
  getInvoiceDetails,
} from "../store/reducer/SalesInvoiceReducer";

import { API_STATUS } from "../utils/constants";
import SalesPieChart from "./Dashboard/SalesPieChart";

function Dashboard() {
  const dispatch = useDispatch();
  const {
    cardDatas,
    dashboardinvoiceDatas,
    dashboardChartDatas,
    draftinvoiceDatas,
    paymentinvoiceDatas,
  } = useSelector(dashboardSelector);
  const { StatDatas, invoiceDetails } = useSelector(salesinvoiceSelector);
  const [monthTotal, setMonthTotal] = useState(0);
  const [totToday, settotToday] = useState(0);

  const { payments, statData } = useSelector(salesinvoiceSelector);

  let [sortBy, setSortBy] = useState("invoice_no");
  const dashboardDispatch = () => {
    dispatch(getSalesStat({}));
    dispatch(paymentStat({}));
    dispatch(
      dashboardinvoiceData({
        query: 2,
        page: page + 1,
        limit: 5,
        sortby: "created_at",
        order: order,
      })
    );
    dispatch(productData({}));
    dispatch(dashboardChartData({}));
  };
  let [activesortBy, setActiveSortBy] = useState("invoice_no");
  let [page, setPage] = useState(0);
  // let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("desc");
  let [searchQuery, setSearchQuery] = useState("");
  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
    // dashboardDispatch();
  };

  const getDates = (status, createdDate) => {
    const today = dayjs();
    const created = dayjs(createdDate, "DD-MM-YYYY");
    console.log(created);
    const differenceInDays = today.diff(created, "day");
    switch (status) {
      case 0:
        return differenceInDays >= 0 ? differenceInDays : 0;
      case 1:
        return differenceInDays >= 0 ? differenceInDays : 0;
      default:
        return "-";
    }
  };
  const getpaymentDates = (duedays_limit, createdDate) => {
    const today = dayjs();
    const created = dayjs(createdDate, "DD-MM-YYYY").add(duedays_limit, "day");
    console.log(created, "er");
    const differenceInDays = today.diff(created, "day");
    return differenceInDays >= 0 ? differenceInDays : 0;
  };
  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
  };
  useEffect(() => {
    dashboardDispatch();

    dispatch(
      getInvoiceDetails({
        query: "",
      })
    );
  }, []);
  useEffect(() => {
    console.log(dashboardinvoiceDatas, "dashboardinvoiceDatas");
  }, [dashboardinvoiceDatas]);

  useEffect(() => {
    console.log(StatDatas, "cardDatas");
  }, [cardDatas]);

  useEffect(() => {
    let sum = 0;
    statData?.stat_month?.rows?.forEach((num) => {
      sum += parseFloat(num.pay_amt);
    });
    let tsum = 0;
    statData?.stat_today?.rows?.forEach((num) => {
      tsum += parseFloat(num.pay_amt);
    });
    setMonthTotal(sum);
    settotToday(tsum);
  }, [statData]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            {/* Sales */}
            <Row>
              {/* <div className="">
                                <h4>Sales</h4>
                            </div> */}

              <Col md="3">
                <Card color="primary">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          Received Revenue This Month
                        </p>
                        <h4 className="mb-0">
                          {cardDatas?.received_rev?.length}
                        </h4>
                      </div>
                      <div className="text-light">
                        <i className="ri-line-chart-fill font-size-24"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card color="success">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2 ">
                          Revenue This Month
                        </p>
                        <h4 className="mb-0">
                          KWD {cardDatas?.invoice_this_month}
                        </h4>
                      </div>
                      <div className="text-light">
                        <i className="ri-bar-chart-fill font-size-24"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card color="warning">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          Total Customers
                        </p>
                        <h4 className="mb-0">{cardDatas?.totalUser}</h4>
                      </div>
                      <div className="text-primary text-light">
                        <i className="dripicons-archive font-size-24"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card color="light">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          Active Services
                        </p>
                        <h4 className="mb-0">{cardDatas?.product_count}</h4>
                      </div>
                      <div className="">
                        <i className="ri-gift-fill font-size-24"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* Purchase */}
            <Row>
              <div className="">
                <h4>Payment</h4>
              </div>
              <Col md="3">
                <Card color="primary">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          Number of Payment Today
                        </p>
                        <h4 className="mb-0">
                          {statData?.stat_today?.count ?? "-"}
                        </h4>
                      </div>
                      <div className="">
                        <i className="fas fa-door-open font-size-24"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card color="success">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          Payment total Today
                        </p>
                        <h4 className="mb-0"> KWD {totToday} </h4>
                      </div>
                      <div className="text-primary">
                        <i className="ri-book-read-fill font-size-24"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card color="warning">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          Number of Payment This Month
                        </p>
                        <h4 className="mb-0">
                          {statData?.stat_month?.count ?? "-"}
                        </h4>
                      </div>
                      <div className="text-primary">
                        <i className="fas fa-truck-moving font-size-24"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col md="3">
                <Card color="">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          Payment Total This Month
                        </p>
                        <h4 className="mb-0">KWD {monthTotal} </h4>
                      </div>
                      <div className="text-primary">
                        <i className="fas fa-dolly-flatbed font-size-24"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Inventory */}
            <Row style={{}}>
              <div className="">
                <h4>Invoice</h4>
              </div>
              <Col md="3">
                <Card color="primary">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          Total Invoices This Year
                        </p>
                        <h4 className="mb-0">
                          {StatDatas?.invoice_this_year?.length}
                        </h4>
                      </div>
                      <div className="">
                        <i className="ri-gift-fill font-size-24"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card color="success">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          Total Invoices This Month
                        </p>
                        <h4 className="mb-0">
                          {StatDatas?.invoice_this_month?.length}
                        </h4>
                      </div>
                      <div className="text-primary">
                        <i className="fab fa-buffer font-size-24"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card color="warning">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          Number of Proforma
                        </p>
                        <h4 className="mb-0">
                          {StatDatas?.no_of_proforma?.length}
                        </h4>
                      </div>
                      <div className="">
                        <i className="fas fa-recycle font-size-24"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card color="">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          No of Quotation
                        </p>
                        <h4 className="mb-0">
                          {StatDatas?.no_of_drafted?.length}
                        </h4>
                      </div>
                      <div className="text-primary">
                        <i className="fab fa-creative-commons-zero font-size-24"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card color="">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          Invoice Overdue
                        </p>
                        <h4 className="mb-0">
                          {StatDatas?.overdueInvoices?.length}
                        </h4>
                      </div>
                      <div className="text-primary">
                        <i className="fab fa-creative-commons-zero font-size-24"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card color="">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          Invoice Paid
                        </p>
                        <h4 className="mb-0">
                          {StatDatas?.no_of_proforma?.length}
                        </h4>
                      </div>
                      <div className="text-primary">
                        <i className="fab fa-creative-commons-zero font-size-24"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card color="">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          Invoice UnPaid
                        </p>
                        <h4 className="mb-0">
                          {StatDatas?.no_of_unpaid?.length}
                        </h4>
                      </div>
                      <div className="text-primary">
                        <i className="fab fa-creative-commons-zero font-size-24"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col md="6">
              <Card>
                <CardBody>
                  <div className="">
                    <h4>Payment Reminders</h4>
                  </div>
                  <TableContainer component={Paper}>
                    <Table stickyHeader>
                      <TableHead className="table-light table-nowrap">
                        <TableRow>
                          <StyledTableCell align="">
                            <TableSortLabel>Customer</TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">
                            <TableSortLabel>Invoice No</TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">
                            <TableSortLabel>Invoice Date</TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">
                            <TableSortLabel>Invoice Amount</TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">
                            <TableSortLabel>Days</TableSortLabel>
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paymentinvoiceDatas?.length > 0 ? (
                          paymentinvoiceDatas.map((row) => (
                            <StyledTableRow>
                              <StyledTableCell align="">
                                {row?.customer?.customer_name}
                              </StyledTableCell>
                              <StyledTableCell align="">
                                {row?.invoice_no}
                              </StyledTableCell>
                              <StyledTableCell align="">
                                {dayjs(row?.invoice_date).format("DD-MM-YYYY")}
                              </StyledTableCell>
                              <StyledTableCell align="">
                                {row?.total}
                              </StyledTableCell>
                              <StyledTableCell align="">
                                {getpaymentDates(
                                  row?.customer?.duedays_limit ?? 0,
                                  row?.invoice_date
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        ) : (
                          <TableRow>
                            <StyledTableCell colSpan={5} align="center">
                              No records found
                            </StyledTableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card>
                <CardBody>
                  <div className="">
                    <h4>Draft/Performa Reminders</h4>
                  </div>
                  <TableContainer component={Paper}>
                    <Table stickyHeader>
                      <TableHead className="table-light table-nowrap">
                        <TableRow>
                          <StyledTableCell align="">
                            <TableSortLabel>Customer</TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">
                            <TableSortLabel>Invoice No</TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">
                            <TableSortLabel>Invoice Date</TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">
                            <TableSortLabel>Invoice Amount</TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">
                            <TableSortLabel>Days</TableSortLabel>
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {draftinvoiceDatas?.length > 0 ? (
                          draftinvoiceDatas.map((row) => (
                            <StyledTableRow>
                              <StyledTableCell align="">
                                {row?.customer?.customer_name}
                              </StyledTableCell>
                              <StyledTableCell align="">
                                {row?.invoice_no}
                              </StyledTableCell>
                              <StyledTableCell align="">
                                {dayjs(row?.invoice_date).format("DD-MM-YYYY")}
                              </StyledTableCell>
                              <StyledTableCell align="">
                                {row?.total}
                              </StyledTableCell>
                              <StyledTableCell align="">
                                {getDates(row.status, row?.created_at)}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        ) : (
                          <TableRow>
                            <StyledTableCell colSpan={5} align="center">
                              No records found
                            </StyledTableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Row>
              <Col md="4">
                <Card>
                  <CardBody>
                    <div className="">
                      <h4>Top 5 Customers</h4>
                    </div>
                    {/* <SalesPieChart
                      props={dashboardChartDatas ? dashboardChartDatas : []}
                    /> */}
                    <TableContainer component={Paper}>
                      <Table stickyHeader>
                        <TableHead className="table-light table-nowrap">
                          <TableRow>
                            <StyledTableCell align="">
                              <TableSortLabel>Customer</TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <TableSortLabel>Invoice Amount</TableSortLabel>
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dashboardChartDatas &&
                            dashboardChartDatas.map((row) => (
                              <StyledTableRow>
                                <StyledTableCell align="">
                                  {row?.name}
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  {row?.amount}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardBody>
                    <div className="">
                      <h4>Latest 5 Sales Invoice List</h4>
                    </div>
                    <div className="table-responsive react-table">
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} stickyHeader>
                          <TableHead className="table-light table-nowrap">
                            <TableRow>
                              <StyledTableCell align="">
                                <TableSortLabel>Invoice No</TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel>Customer</TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel>Invoice Date</TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel>Invoice Amount</TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel>
                                  Outstanding Amount
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel>Status</TableSortLabel>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dashboardinvoiceDatas &&
                              dashboardinvoiceDatas.map((row) => {
                                const relatedEntries = invoiceDetails?.filter(
                                  (ele) => ele.invoice_main_id === row.id
                                );
                                let originalAmount =
                                  parseFloat(row?.total) || 0;
                                let totalCredits = 0;
                                let totalDebits = 0;
                                let hasCredit = false;
                                let hasDebit = false;
                                let hasCreditDebitEntries = false;
                                relatedEntries?.forEach((invoice) => {
                                  if (
                                    invoice.status == 2 ||
                                    invoice.status == 3 ||
                                    invoice.status == 5
                                  ) {
                                    if (invoice.credit_debit_type === 0) {
                                      originalAmount = parseFloat(
                                        invoice.total
                                      );
                                    } else if (
                                      invoice.credit_debit_type === 381
                                    ) {
                                      totalCredits += parseFloat(invoice.total);
                                      hasCredit = true;
                                      hasCreditDebitEntries = true;
                                    } else if (
                                      invoice.credit_debit_type === 383
                                    ) {
                                      totalDebits += parseFloat(invoice.total);
                                      hasDebit = true;
                                      hasCreditDebitEntries = true;
                                    }
                                  }
                                });
                                let finalTotal;
                                if (hasCredit && hasDebit) {
                                  finalTotal =
                                    originalAmount - totalCredits + totalDebits;
                                } else if (hasCredit) {
                                  finalTotal = originalAmount - totalCredits;
                                } else if (hasDebit) {
                                  finalTotal = originalAmount + totalDebits;
                                } else {
                                  finalTotal = originalAmount;
                                }
                                return (
                                  <StyledTableRow>
                                    <StyledTableCell align="">
                                      {row?.invoice_no}
                                    </StyledTableCell>
                                    <StyledTableCell align="">
                                      {row?.customer?.customer_name}
                                    </StyledTableCell>
                                    <StyledTableCell align="">
                                      {dayjs(row?.invoice_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell align="">
                                      {row?.total}
                                    </StyledTableCell>
                                    <StyledTableCell align="">
                                      {finalTotal.toFixed(2) -
                                        (row?.total - row?.payment_balance) >=
                                      0
                                        ? finalTotal.toFixed(2) -
                                          (row?.total - row?.payment_balance)
                                        : 0}
                                    </StyledTableCell>
                                    <StyledTableCell align="">
                                      {row.status === 0
                                        ? "Draft"
                                        : row.status === 1
                                        ? "Proforma"
                                        : "Invoice"}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" style={{ display: "None" }}>
                <div className="">
                  <h4>Purchase</h4>
                </div>
                <PurchasePieChart />
              </Col>
              <Col md="4" style={{ display: "None" }}>
                <div className="">
                  <h4>Inventory</h4>
                </div>
                <InventoryPieChart />
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default Dashboard;
