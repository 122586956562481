// export const API_STATUS = Object.freeze({
//     PENDING: 'Pending',
//     REJECTED: 'Rejected',
//     FULFILLED: 'Fulfilled',
//     NETWORK_ERROR: 'Network Error'
// })

// export const paymentType = [{ id: 1, mode: 'Cash' }, { id: 2, mode: 'Credit' }]
export const API_STATUS = Object.freeze({
  PENDING: "Pending",
  REJECTED: "Rejected",
  FULFILLED: "Fulfilled",
  NETWORK_ERROR: "Network Error",
});

export const paymentType = [
  { id: 1, mode: "Cash" },
  { id: 2, mode: "Credit" },
];
export const paymentTypewithcheck = [
  { id: 1, mode: "CASH", value: 10 },
  { id: 2, mode: "CREDIT", value: 30 },
  { id: 3, mode: "BANK_ACCOUNT", value: 42 },
  { id: 4, mode: "BANK_CARD", value: 48 },
];

export const payAccount = [
  {
    id: 0,
    parent_id: [2],
    title: "Cash On Hand",
  },
  {
    id: 1,
    parent_id: [1, 3],
    title: "Bank 1",
  },
  {
    id: 2,
    parent_id: [1, 3],
    title: "Bank 2",
  },
];

export const productType = [
  {
    id: 1,
    type: "Stockable Product",
  },
  {
    id: 2,
    type: "Service",
  },
];

export const requiredTaxes = [{ name: "Exempt" }, { name: "VAT 15%" }];

export const typesOfPayments = [
  {
    id: 0,
    type: "Advance Payment",
  },
  {
    id: 1,
    type: "Invoice Payment",
  },
];

export const reportType = [
  {
    id: 4,
    label: "All",
    name: "Finance_Report",
  },
  {
    id: 1,
    label: "Payment",
    name: "Finance_Payment_Report",
  },
  {
    id: 2,
    label: "Receipt",
    name: "Finance_Receipt_Report",
  },
  {
    id: 3,
    label: "Finance_Journal_Report",
  },
];

export const paymentModetype = {
  CASH: "10",
  CREDIT: "30",
  BANK_ACCOUNT: "42",
  BANK_CARD: "48",
};

export const arabicnumbers = {
  0: "۰",
  1: "۱",
  2: "۲",
  3: "۳",
  4: "٤",
  5: "٥",
  6: "٦",
  7: "۷",
  8: "۸",
  9: "۹",
};

export const paymentStatus = [
  { id: "", mode: "All", value: "" },
  { id: 0, mode: "UNPAID", value: 0 },
  { id: 1, mode: "PAID", value: 1 },
  { id: 2, mode: "PARTIALLY PAID", value: 2 },
];

export const invoiceStatus = [
  { id: "", mode: "All", value: "" },
  { id: 0, mode: "Draft", value: 0 },
  { id: 1, mode: "Performa", value: 1 },
  { id: 2, mode: "Invoice", value: 2 },
  { id: 3, mode: "Credit note", value: 3 },
  { id: 4, mode: "Debit note", value: 4 },
];

export const PinvoiceStatus = [
  { id: "", mode: "All", value: "" },
  { id: 0, mode: "Draft", value: 0 },
  { id: 1, mode: "Performa", value: 1 },
  { id: 2, mode: "Invoice", value: 2 },
];


export const zatcaStatus = [
  { id: "", mode: "All", value: "" },
  { id: 1, mode: "Yet to validate", value: 1 },
  { id: 2, mode: "Validated", value: 2 },
  { id: 3, mode: "Error", value: 3 },
];
