import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
// import logger from "redux-logger";
import CustomerReducer from "./reducer/CustomerReducer";
import SignInReducer from "./reducer/SignInReducer";
import ProductReducer from "./reducer/ProductReducer";
import VendorReducer from "./reducer/VendorReducer";
import CompanyReducer from "./reducer/CompanyReducer";
import UserReducer from "./reducer/UserReducer";
import SalesInvoiceReducer from "./reducer/SalesInvoiceReducer";
import DashboardReducer from "./reducer/DashboardReducer";
import PurchaseReducer from "./reducer/PurchaseReducer";
import FinanceAccountsReducer from "./reducer/FinanceAccountsReducer";
import PaymentVoucherReducer from "./reducer/PaymentVoucherReducer";
import SettingsReducer from "./reducer/SettingsReducer";
import PurchaseDescReducer from "./reducer/PurchaseDescReducer";
export const reducer = {
  customers: CustomerReducer,
  signInConfiguration: SignInReducer,
  products: ProductReducer,
  users:UserReducer,
  vendors:VendorReducer,
  company:CompanyReducer,
  salesinvoice:SalesInvoiceReducer,
  dashboard:DashboardReducer,
  purchase: PurchaseReducer,
  finance_accounts: FinanceAccountsReducer,
  paymentVoucher: PaymentVoucherReducer,
  settings: SettingsReducer,
  purchase_invent:PurchaseDescReducer

};

const store = configureStore({
  reducer,
  // middleware: [ // Because we define the middleware property here, we need to explictly add the defaults back in.
  //     ...getDefaultMiddleware(),
  // ]
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
