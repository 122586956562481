import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "react-dropdown-select";
import { useNavigate, Link } from "react-router-dom";

import { customerValidationSchema } from "../../utils/Validation";
import { assestURL } from "../../services/config";
import _ from "loadsh";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  Spinner,
} from "reactstrap";
//Import Breadcrumb
import { products } from "../../common/data/ecommerce";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
// import Breadcrumb from "../src/Common/Breadcrumb";
import dayjs from "dayjs";
import {
  salesinvoiceData,
  salesinvoiceSelector,
  clearSinvLoadingDatas,
  clearErrormsg,
  clearData,
  generatePdf,
  setProforma,
  setDraft,
  clearPdfData,
  setInvoiceStat,
  getSalesStat,
  getZatcaValidation,
  viewZatca,
  searchInvoiceDetails,
} from "../../store/reducer/SalesInvoiceReducer";
import { API_STATUS } from "../../utils/constants";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  companySelector,
  getCompanyDetails,
} from "../../store/reducer/CompanyReducer";
import { Box, Modal, Typography } from "@mui/material";
import { debounce } from "lodash";
import {
  ListPaymentVoucher,
  paymentVoucherSelector,
} from "../../store/reducer/PaymentVoucherReducer";
import {
  clearPurchaseLoadings,
  getStatofOrder,
  purchaseOrderList,
  purchaseSelector,
  updateOrderStatus,
} from "../../store/reducer/PurchaseReducer";
import { vendorData, vendorsSelector } from "../../store/reducer/VendorReducer";

const PurchaseOrder = () => {
  const dropdownValues = {
    0: {
      data: [
        { handlerName: "setProfoma", label: "Set To Proforma" },
        { handlerName: "setInvoice", label: "Set To Invoice" },
      ],
    },
    1: {
      data: [
        { handlerName: "setInvoice", label: "Set To Invoice" },
        // , { handlerName: 'setDraft', label: 'Set To Draft' }
      ],
    },
    2: {
      data: [
        // { handlerName: 'setProfoma', label: 'Set To Proforma' }, { handlerName: 'setDraft', label: 'Set To Draft' }
      ],
    },
  };
  const style = {
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 30,
    p: 4,
  };

  const infoStyle = {
    boxShadow: " 2px 4px 2px 4px #d6e6ff ",
    padding: "10px",
  };

  const WarningStyle = {
    boxShadow: " 2px 4px 2px 4px #ffd28e ",
    padding: "10px",
    marginTop: "16px",
  };

  const handleDropDown = (data, type) => {
    console.log(data, type, "ifaeiof ");
    let formData = {};
    formData.id = data.id;
    if (type == "setProfoma") {
      formData.status = 1;
      dispatch(updateOrderStatus({ formData }));
    }
    // if (type == "Draft") {
    //   formData.status = 0;
    //   dispatch(updateOrderStatus({ formData }));
    // }
    if (type == "setInvoice") {
      formData.status = 2;
      dispatch(updateOrderStatus({ formData }));
    }
  };

  const { paymentVoucherListData } = useSelector(paymentVoucherSelector);
  const dispatch = useDispatch();
  let [sortBy, setSortBy] = useState("invoice_no");
  const [openDrawer, setOpenDrawer] = useState(false);
  let [activesortBy, setActiveSortBy] = useState("invoice_no");
  const [info, setInfo] = useState({ 0: false });
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("desc");
  let [searchQuery, setSearchQuery] = useState("");
  const [state, setState] = useState({ modal_fullscreen: false });
  let {
    purchaseOrders,
    updateOrderLoad,
    purchaseorderStat,
    purchaseorderStatLoad,
  } = useSelector(purchaseSelector);
  const [accord, setAccord] = useState({
    col1: false,
    col2: false,
    col3: false,
  });

  const {
    salesinvoiceCount,
    pdfMessage,
    generateLoading,
    getZatcaValidationloading,
    StatDatas,
    zatcaData,
  } = useSelector(salesinvoiceSelector);
  const { vendorDatas } = useSelector(vendorsSelector);
  const navigate = useNavigate();

  const tog_fullscreen = () => {
    navigate("add");
  };

  const paymentVoucherHit = () => {
    dispatch(
      purchaseOrderList({
        query: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
      })
    );
    dispatch(getStatofOrder({}));
    dispatch(
      vendorData({
        query: "",
        page: "",
        limit: "",
        id: "",
      })
    );
  };

  useEffect(() => {
    paymentVoucherHit();
  }, [searchQuery, rowsPerPage]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
    paymentVoucherHit();
  };

  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchData = (e) => {
    searchQuery = e;
    setSearchQuery(e);
    setPage(0);
    // dispatch(searchInvoiceDetails({ e }));
  };

  // const handleZatcaView = (data) => {
  //   setOpenDrawer(true);
  //   console.log("first");
  //   dispatch(viewZatca({ ID: data?.id }));
  // };

  const debounceSearch = useCallback(debounce(searchData, 500), []);

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
  };

  useEffect(() => {
    paymentVoucherHit();
  }, []);

  useEffect(() => {
    if (updateOrderLoad === API_STATUS.FULFILLED) {
      toastr.clear();
      toastr.success("Status Updated");
      paymentVoucherHit();
      dispatch(clearPurchaseLoadings());
    }

    if (purchaseorderStatLoad === API_STATUS) {
      dispatch(clearPurchaseLoadings());
    }

    return () => {
      toastr.clear();
    };
  }, [updateOrderLoad, purchaseorderStatLoad]);
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-content-between">
            <h2>Purchase Order</h2>
            <button
              type="button"
              className="waves-effect waves-light btn btn-primary mb-2"
              onClick={tog_fullscreen}
            >
              Add
            </button>
          </div>
          <Row>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Total Orders this Year
                      </p>
                      <h4 className="mb-0">
                        {purchaseorderStat?.yearData &&
                          purchaseorderStat?.yearData[0]?.voucherCount}
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-stack-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Total Invoices This Month
                      </p>
                      <h4 className="mb-0">
                        {purchaseorderStat?.monthData &&
                          purchaseorderStat?.monthData[0]?.voucherCount}
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-funds-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Total Order Amount for this Year
                      </p>
                      <h4 className="mb-0">
                        {purchaseorderStat?.yearData &&
                          purchaseorderStat?.yearData[0]?.totalAmount}
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-stack-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Total Order Amount for this Month
                      </p>
                      <h4 className="mb-0">
                        {" "}
                        {purchaseorderStat?.monthData &&
                          purchaseorderStat?.monthData[0]?.totalAmount}
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-funds-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-md-8">
                  <h5>
                    {" "}
                    <span>{}</span>
                  </h5>
                </div>
                <div className="col-md-4 row">
                  <input
                    type="text"
                    name="search"
                    className="form-control"
                    style={{ float: "right" }}
                    // value={searchQuery}
                    onChange={(e) => {
                      debounceSearch(e.target.value);
                    }}
                    placeholder="Search"
                  />
                </div>
              </div>
              <div className="table-responsive react-table">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} stickyHeader>
                    <TableHead className="table-light table-nowrap">
                      <TableRow>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "invoice_no" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("invoice_no");
                            }}
                          >
                            Purchase Order No
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "invoice_date" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("invoice_date");
                            }}
                          >
                            Vendor
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "total" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("total");
                            }}
                          >
                            Order Date
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "status" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("status");
                            }}
                          >
                            Notes
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">Amount</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {generateLoading !== "Pending" &&
                    getZatcaValidationloading !== "Pending" ? (
                      <TableBody>
                        {purchaseOrders &&
                          purchaseOrders?.rows?.map((row, index) => (
                            <StyledTableRow>
                              <StyledTableCell align="">
                                {row?.quotation_no}
                              </StyledTableCell>
                              <StyledTableCell align="">
                                {row?.vendor?.vendor_name}
                              </StyledTableCell>
                              <StyledTableCell align="">
                                {dayjs(row?.date).format("DD-MM-YYYY")}
                              </StyledTableCell>
                              <StyledTableCell align="">
                                {row?.notes != "" ? row?.notes : "-"}
                              </StyledTableCell>
                              <StyledTableCell align="">
                                {row?.total_amount}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    ) : (
                      <td colSpan={7}>
                        <div
                          className="d-flex justify-content-center"
                          style={{ width: "100%" }}
                        >
                          <Spinner className="m-2" color="success" />
                        </div>
                      </td>
                    )}
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50]}
                          colSpan={12}
                          count={purchaseOrders?.count}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
            </CardBody>
          </Card>
        </Container>
        <Modal
          open={openDrawer}
          onClose={toggleDrawer(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="overflow-auto"
        >
          <Box sx={style}>
            <div className="overflow-auto">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <div className="d-flex justify-content-between">
                  <h3>Zatca Response</h3>
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={toggleDrawer(false)}
                  >
                    <i class="ri-close-line"></i>
                  </p>
                </div>
              </Typography>
              <hr />
              <Typography id="modal-modal-description">
                <div>
                  {zatcaData?.viewData &&
                    JSON.parse(zatcaData.viewData?.info).map((info) => {
                      return (
                        <div style={infoStyle}>
                          <div>
                            <b>Type : </b>
                            <p style={{ textIndent: "16px" }}>{info?.type}</p>
                          </div>
                          <div>
                            <b>Code : </b>
                            <p style={{ textIndent: "16px" }}>{info?.code}</p>
                          </div>
                          <div>
                            <b>Category : </b>
                            <p style={{ textIndent: "16px" }}>
                              {info?.category}
                            </p>
                          </div>
                          <div>
                            <b>Message : </b>
                            <p style={{ textIndent: "16px" }}>
                              {info?.message}
                            </p>
                          </div>
                          <div>
                            <b>Status : </b>
                            <p style={{ textIndent: "16px" }}>{info?.status}</p>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div>
                  {zatcaData?.viewData &&
                    JSON.parse(zatcaData.viewData?.warnings).map((info) => {
                      return (
                        <div style={WarningStyle}>
                          <div>
                            <b>Type : </b>
                            <p style={{ textIndent: "16px" }}>{info?.type}</p>
                          </div>
                          <div>
                            <b>Code : </b>
                            <p style={{ textIndent: "16px" }}>{info?.code}</p>
                          </div>
                          <div>
                            <b>Category : </b>
                            <p style={{ textIndent: "16px" }}>
                              {info?.category}
                            </p>
                          </div>
                          <div>
                            <b>Message : </b>
                            <p style={{ textIndent: "16px" }}>
                              {info?.message}
                            </p>
                          </div>
                          <div>
                            <b>Status : </b>
                            <p style={{ textIndent: "16px" }}>{info?.status}</p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Typography>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};
export default PurchaseOrder;
