import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toastr from "toastr";
import EncryptDecrypt, { decryptdata } from "./../../utils/encrypt&decrypt";
import "toastr/build/toastr.min.css";
import {
  accounts_group_list,
  group_by_id,
  group_add,
  group_update,
  group_delete,
  sub_group_list,
  sub_group_by_id,
  sub_group_add,
  sub_group_update,
  sub_group_delete,
  accounts_add,
  accounts_list,
  account_by_id,
  account_delete,
  account_update,
} from "../../services/api";
import { secretKey } from "../../services/config";
import { API_STATUS } from "../../utils/constants";
import alertmessage from "../../utils/alert";

const namespace = "finance_accounts";

const initialState = {
  accountsGroupDatas: [],
  subGroupDatas: [],
  accountsListDatas: [],
  GroupByIdData: null,
  subGroupByIdData: null,
  AccountByIdData: null,
  financeAccountserrorMessage: null,
  accountsGroupLoading: "initial",
  GroupByIdLoading: "initial",
  GroupAddLoading: "initial",
  GroupUpdateLoading: "initial",
  GroupDeleteLoading: "initial",
  subGroupByIdLoading: "initial",
  subGroupAddLoading: "initial",
  subGroupUpdateLoading: "initial",
  subGroupDeleteLoading: "initial",
  accountsAddLoading: "initial",
  accountsListLoading: "initial",
  AccountByIdLoading: "initial",
  accountDeleteLoading: "initial",
  accountUpdateLoading: "initial",
};

export const financeGroupList = createAsyncThunk(
  `${namespace}/financeGroupList`,
  async ({ query, page, limit, sortby, order }, { rejectWithValue }) => {
    try {
      const response = await accounts_group_list(
        query,
        page,
        limit,
        sortby,
        order
      );
      return response;
    } catch (error) {
      toastr.error(error.message);
      return rejectWithValue(error.response.data);
    }
  }
);

export const GroupById = createAsyncThunk(
  `${namespace}/GroupById`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await group_by_id(id);
      return response;
    } catch (error) {
      toastr.error(error.message);
      return rejectWithValue(error.response.data);
    }
  }
);

export const GroupAdd = createAsyncThunk(
  `${namespace}/GroupAdd`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      let payload = EncryptDecrypt.encryptdata(formData, secretKey);
      const data = await group_add({ payload: payload });
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const GroupUpdate = createAsyncThunk(
  `${namespace}/GroupUpdate`,
  async ({ formData, id }, { rejectWithValue, dispatch }) => {
    try {
      let payload = EncryptDecrypt.encryptdata(formData, secretKey);
      const data = await group_update({ payload: payload }, id);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const GroupDelete = createAsyncThunk(
  `${namespace}/GroupDelete`,
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const data = await group_delete(id);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const AccountsAdd = createAsyncThunk(
  `${namespace}/AccountsAdd`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      let payload = EncryptDecrypt.encryptdata(formData, secretKey);
      const data = await accounts_add({ payload: payload });
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const AccountsList = createAsyncThunk(
  `${namespace}/AccountsList`,
  async (
    { query, page, limit, sortby, order, group_id },
    { rejectWithValue }
  ) => {
    try {
      const response = await accounts_list(
        query,
        page,
        limit,
        sortby,
        order,
        group_id
      );
      return response;
    } catch (error) {
      toastr.error(error.message);
      return rejectWithValue(error.response.data);
    }
  }
);

export const AccountById = createAsyncThunk(
  `${namespace}/AccountById`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await account_by_id(id);
      return response;
    } catch (error) {
      toastr.error(error.message);
      return rejectWithValue(error.response.data);
    }
  }
);

export const accountDelete = createAsyncThunk(
  `${namespace}/accountDelete`,
  async ({ id,type }, { rejectWithValue, dispatch }) => {
    try {
      const data = await account_delete(id,type);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const accountUpdate = createAsyncThunk(
  `${namespace}/accountUpdate`,
  async ({ formData, id }, { rejectWithValue, dispatch }) => {
    try {
      console.log(id, "REDUCER");
      let payload = EncryptDecrypt.encryptdata(formData, secretKey);
      const data = await account_update({ payload: payload }, id);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

const FinancialAccountsSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
    clearAccountsLoaders: (state) => {
      state.accountsGroupDatas = [];      
      state.accountsListDatas = [];
      state.GroupByIdData = null;
      state.financeAccountserrorMessage = null;      
      state.AccountByIdData = null;
      state.accountsGroupLoading = "initial";
      state.GroupByIdLoading = "initial";
      state.GroupAddLoading = "initial";
      state.GroupUpdateLoading = "initial";
      state.GroupDeleteLoading = "initial";      
      state.accountsAddLoading = "initial";
      state.accountsListLoading = "initial";
      state.AccountByIdLoading = "initial";
      state.accountUpdateLoading = "initial";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(financeGroupList.pending, (state) => {
      state.accountsGroupLoading = API_STATUS.PENDING;
    });
    builder.addCase(financeGroupList.fulfilled, (state, { payload }) => {
      console.log(payload, "payload_financeGroupList");
      state.accountsGroupLoading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );
      state.accountsGroupDatas = payloaddatas;
    });
    builder.addCase(financeGroupList.rejected, (state, action) => {
      state.accountsGroupLoading = API_STATUS.REJECTED;
      if (action?.payload?.data?.auth === "deleted") {
        alertmessage.sweetalert(action?.payload?.data?.message);
      }
      state.financeAccountserrorMessage = action?.payload?.data;
    });
    builder.addCase(GroupById.pending, (state) => {
      state.GroupByIdLoading = API_STATUS.PENDING;
    });
    builder.addCase(GroupById.fulfilled, (state, { payload }) => {
      console.log(payload, "GroupById");
      state.GroupByIdLoading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data, secretKey)
      );
      console.log(payloaddatas?.data, "PAYLOAD1");
      state.GroupByIdData = payloaddatas?.data;
    });
    builder.addCase(GroupById.rejected, (state, action) => {
      state.GroupByIdLoading = API_STATUS.REJECTED;
      if (action?.payload?.data?.auth === "deleted") {
        alertmessage.sweetalert(action?.payload?.data?.message);
      }
      state.financeAccountserrorMessage = action?.payload?.data;
    });
    builder.addCase(GroupAdd.pending, (state) => {
      state.GroupAddLoading = API_STATUS.PENDING;
    });
    builder.addCase(GroupAdd.fulfilled, (state, { payload }) => {
      state.GroupAddLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(GroupAdd.rejected, (state, action) => {
      state.GroupAddLoading = API_STATUS.REJECTED;
      state.financeAccountserrorMessage = action?.payload?.data;
    });
    builder.addCase(GroupUpdate.pending, (state) => {
      state.GroupUpdateLoading = API_STATUS.PENDING;
    });
    builder.addCase(GroupUpdate.fulfilled, (state, { payload }) => {
      state.GroupUpdateLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(GroupUpdate.rejected, (state, action) => {
      state.GroupUpdateLoading = API_STATUS.REJECTED;
      state.financeAccountserrorMessage = action?.payload?.data;
    });
    builder.addCase(GroupDelete.pending, (state) => {
      state.GroupDeleteLoading = API_STATUS.PENDING;
    });
    builder.addCase(GroupDelete.fulfilled, (state, { payload }) => {
      state.GroupDeleteLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(GroupDelete.rejected, (state, action) => {
      state.GroupDeleteLoading = API_STATUS.REJECTED;
      state.financeAccountserrorMessage = action?.payload?.data;
    });
   
    builder.addCase(AccountsAdd.pending, (state) => {
      state.accountsAddLoading = API_STATUS.PENDING;
    });
    builder.addCase(AccountsAdd.fulfilled, (state, { payload }) => {
      state.accountsAddLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(AccountsAdd.rejected, (state, action) => {
      state.accountsAddLoading = API_STATUS.REJECTED;
      state.financeAccountserrorMessage = action?.payload?.data;
    });
    builder.addCase(AccountsList.pending, (state) => {
      state.accountsListLoading = API_STATUS.PENDING;
    });
    builder.addCase(AccountsList.fulfilled, (state, { payload }) => {
      console.log(payload, "AccountsList1");
      state.accountsListLoading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );
      console.log(payloaddatas,"ASDFGSH")
      state.accountsListDatas = payloaddatas;
    });
    builder.addCase(AccountsList.rejected, (state, action) => {
      state.accountsListLoading = API_STATUS.REJECTED;
      if (action?.payload?.data?.auth === "deleted") {
        alertmessage.sweetalert(action?.payload?.data?.message);
      }
      state.financeAccountserrorMessage = action?.payload?.data;
    });
    builder.addCase(AccountById.pending, (state) => {
      state.AccountByIdLoading = API_STATUS.PENDING;
    });
    builder.addCase(AccountById.fulfilled, (state, { payload }) => {
      console.log(payload, "subGroupById");
      state.AccountByIdLoading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data, secretKey)
      );
      console.log(payloaddatas?.data, "payloaddatas");
      state.AccountByIdData = payloaddatas?.data;
    });
    builder.addCase(AccountById.rejected, (state, action) => {
      state.AccountByIdLoading = API_STATUS.REJECTED;
      if (action?.payload?.data?.auth === "deleted") {
        alertmessage.sweetalert(action?.payload?.data?.message);
      }
      state.financeAccountserrorMessage = action?.payload?.data;
    });
    builder.addCase(accountDelete.pending, (state) => {
      state.accountDeleteLoading = API_STATUS.PENDING;
    });
    builder.addCase(accountDelete.fulfilled, (state, { payload }) => {
      state.accountDeleteLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(accountDelete.rejected, (state, action) => {
      state.accountDeleteLoading = API_STATUS.REJECTED;
      state.financeAccountserrorMessage = action?.payload?.data;
    });
    builder.addCase(accountUpdate.pending, (state) => {
      state.accountUpdateLoading = API_STATUS.PENDING;
    });
    builder.addCase(accountUpdate.fulfilled, (state, { payload }) => {
      state.accountUpdateLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(accountUpdate.rejected, (state, action) => {
      state.accountUpdateLoading = API_STATUS.REJECTED;
      state.financeAccountserrorMessage = action?.payload?.data;
    });
  },
});

export const { clearData, clearAccountsLoaders } =
  FinancialAccountsSlice.actions;

export const FinanceAccountsSelector = (state) => state.finance_accounts;
export default FinancialAccountsSlice.reducer;
