import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  purchaseAdd,
  purchaseUpdate,
  purchaseDelete,
  purchaseEdit,
  purchaseList,
} from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from "./../../utils/encrypt&decrypt";
import { secretKey } from "../../services/config";
import alertmessage from "./../../utils/alert";
import toastr from 'toastr';
const namespace = 'products'

const initialState = {
  data: null,
  status: 'idle',
  error: null,
  PurchaseCount:null,
  product_details:null,
  PurchaseDatas:null,
};

export const PurchaseData = createAsyncThunk(
  `${namespace}/PurchaseData`,
  async (
    { query = "", page = 0, limit = 0, sortby = "description", order = "asc" },
    { rejectWithValue, dispatch }
  ) => {
    try {
      console.log("interdata123");
      //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
      const data = await purchaseList(query, page, limit, sortby, order);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);
export const EditPurchase = createAsyncThunk(
  `${namespace}/EditPurchase`,
  async ({ productId }, { rejectWithValue, dispatch }) => {
    try {          
      // let payload = EncryptDecrypt.encryptdata(productId, secretKey);
      const data = await purchaseEdit(productId);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);
export const UpdatePurchase = createAsyncThunk(
  `${namespace}/UpdatePurchase`,
  async ({ formData, productId }, { rejectWithValue, dispatch }) => {
    try {
      console.log(productId, "updateID");
      let payload = EncryptDecrypt.encryptdata(formData, secretKey);
      console.log(formData,payload,"Pay");
      const data = await purchaseUpdate({ payload: payload }, productId);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);
export const AddPurchase = createAsyncThunk(
  `${namespace}/AddPurchase`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "interdata");
      let payload = EncryptDecrypt.encryptdata(formData, secretKey);
      const data = await purchaseAdd({ payload: payload });
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);
export const DeletePurchase = createAsyncThunk(
  `${namespace}/DeletePurchase`,
  async ({ deleteID }, { rejectWithValue, dispatch }) => {
    try {
      console.log(deleteID, "interdata");
      const data = await purchaseDelete(deleteID);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);


const productsSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
    clearProdLoadingDatas: (state) => {
      state.loading = "initial";
      state.addloadings = "initial";
      state.deleteloading = "initial";
      state.updateloading = "initial";
    },
    clearProdUpdateLoading: (state) => {
      state.updateloading = "initial";
    },
    clearProdDeleteLoading: (state) => {
      state.deleteloading = "initial";
    },
    clearErrormsg: (state) => {
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
      console.log(builder,'payload');
      builder.addCase(PurchaseData.pending, (state) => {
      state.loading = API_STATUS.PENDING;
    });
    builder.addCase(PurchaseData.fulfilled, (state, { payload }) => {
      console.log(payload,'payloaasdd');
      state.loading = API_STATUS.FULFILLED;
      
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );
      console.log(payloaddatas, "345yu")
      state.PurchaseDatas = payloaddatas?.data;
      state.PurchaseCount = payloaddatas?.count;
    });
    builder.addCase(PurchaseData.rejected, (state, action) => {
      state.loading = API_STATUS.REJECTED;
      if (action?.payload?.data?.auth === "deleted") {
        alertmessage.sweetalert(action?.payload?.data?.message);
      }
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(AddPurchase.pending, (state) => {
      state.addloadings = API_STATUS.PENDING;
    });
    builder.addCase(AddPurchase.fulfilled, (state, { payload }) => {
      state.addloadings = API_STATUS.FULFILLED;
    });
    builder.addCase(AddPurchase.rejected, (state, action) => {
      state.addloadings = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder
    .addCase(EditPurchase.pending, (state) => {
      state.status = 'loading'
    }); 
    builder.addCase(EditPurchase.fulfilled, (state, { payload }) => {
      state.loading = API_STATUS.FULFILLED;      
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );      
      state.product_details = payloaddatas?.data;
    });
  builder.addCase(EditPurchase.rejected, (state, action) => {
    state.status = 'failed';
    state.error = action.payload;
  });
    builder.addCase(UpdatePurchase.pending, (state) => {
      state.updateloading = API_STATUS.PENDING;
    });
    builder.addCase(UpdatePurchase.fulfilled, (state, { payload }) => {
      state.updateloading = API_STATUS.FULFILLED;
    });
    builder.addCase(UpdatePurchase.rejected, (state, action) => {
      state.updateloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(DeletePurchase.pending, (state) => {
      state.deleteloading = API_STATUS.PENDING;
    });
    builder.addCase(DeletePurchase.fulfilled, (state, { payload }) => {
      state.deleteloading = API_STATUS.FULFILLED;
    });
    builder.addCase(DeletePurchase.rejected, (state, action) => {
      state.deleteloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
  },
});

export const {
  clearData,
  clearProdUpdateLoading,
  clearProdLoadingDatas,
  clearProdDeleteLoading,
  clearErrormsg,
} = productsSlice.actions;

export const purchasesInveSelector = (state) => state.purchase_invent;

export default productsSlice.reducer;
