import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/constants";
import { getStockUnits } from "../../services/api";
import EncryptDecrypt, { decryptdata } from "./../../utils/encrypt&decrypt";
import { secretKey } from "../../services/config";

const namespace = "settings";

const initialState = {
  settingserrorMessage: "",
  stockUnitLoader: "initial",
  stockUnitListData: [],
};

export const stockUnitList = createAsyncThunk(
  `${namespace}/stockUnitList`,
  async ({ query, page, limit }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await getStockUnits(query, page, limit);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const settingsSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearsettingsData: () => {
      return initialState;
    },
    clearsettingsLoadings: (state) => {
      state.stockUnitLoader = "initial";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(stockUnitList.pending, (state) => {
      state.stockUnitLoader = API_STATUS.PENDING;
    });
    builder.addCase(stockUnitList.fulfilled, (state, { payload }) => {
      state.stockUnitLoader = API_STATUS.FULFILLED;
      console.log(payload, "SADFAGSHGJ");
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload, secretKey)
      );
      console.log(payloaddatas, "payloaddatass");

      state.stockUnitListData = payloaddatas?.data;
    });
    builder.addCase(stockUnitList.rejected, (state, action) => {
      state.stockUnitLoader = API_STATUS.REJECTED;
      state.settingserrorMessage = action?.payload?.data;
    });
  },
});

export const { clearsettingsData, clearsettingsLoadings } =
  settingsSlice.actions;

export const settingsSelector = (state) => state.settings;

export default settingsSlice.reducer;
