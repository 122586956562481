import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "react-dropdown-select";
import { useNavigate, Link } from "react-router-dom";

import { customerValidationSchema } from "../../utils/Validation";
import { assestURL } from "../../services/config";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  Spinner,
} from "reactstrap";
//Import Breadcrumb
import { products } from "../../common/data/ecommerce";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import Breadcrumb from "../Common/Breadcrumb";
import dayjs from "dayjs";

import {
  API_STATUS,
  paymentTypewithcheck,
  typesOfPayments,
} from "../../utils/constants";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Box, Modal, Typography } from "@mui/material";
import { debounce } from "lodash";
import {
  ListJournalVoucher,
  journalCards,
  updateJournalStatus,
  paymentVoucherSelector,
  clearPVLoadings,
} from "../../store/reducer/PaymentVoucherReducer";
import {
  purchaseSelector,
  clearPurchaseLoadings,
  vendorpaymentList,
  vendorPaymentData,
} from "../../store/reducer/PurchaseReducer";
import { usersSelector } from "../../store/reducer/UserReducer";

const VendorPayement = () => {
  const dropdownValues = {
    0: {
      data: [
        { handlerName: "setProfoma", label: "Set To Proforma" },
        { handlerName: "setInvoice", label: "Set To Invoice" },
      ],
    },
    1: {
      data: [
        { handlerName: "setInvoice", label: "Set To Invoice" },
        // , { handlerName: 'setDraft', label: 'Set To Draft' }
      ],
    },
    2: {
      data: [
        // { handlerName: 'setProfoma', label: 'Set To Proforma' }, { handlerName: 'setDraft', label: 'Set To Draft' }
      ],
    },
  };
  const style = {
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 30,
    p: 4,
  };

  const infoStyle = {
    boxShadow: " 2px 4px 2px 4px #d6e6ff ",
    padding: "10px",
  };

  const WarningStyle = {
    boxShadow: " 2px 4px 2px 4px #ffd28e ",
    padding: "10px",
    marginTop: "16px",
  };
  /** Selectors */
  const {
    journalVoucherListData,
    journalCardDatas,
    updateJournalStatusVoucherLoad,
    errorMessage,
  } = useSelector(paymentVoucherSelector);

  const {
    vendorPaymentListData,
    vendorPaymentCardDatas,
    purchaseerrorMessage,
  } = useSelector(purchaseSelector);
  const { selectScreenData } = useSelector(usersSelector);
  console.log(vendorPaymentListData, "vendorPaymentListData1");
  /** State Values */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [sortBy, setSortBy] = useState("invoice_no");
  const [openDrawer, setOpenDrawer] = useState(false);
  let [activesortBy, setActiveSortBy] = useState("invoice_no");
  const [info, setInfo] = useState({ 0: false });
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("desc");
  let [searchQuery, setSearchQuery] = useState("");
  const [state, setState] = useState({ modal_fullscreen: false });
  const [accord, setAccord] = useState({
    col1: false,
    col2: false,
    col3: false,
  });
  const [userActions, setUserActions] = useState({});
  /** Functions */
  const tog_fullscreen = () => {
    navigate("add");
  };

  const journalVoucherHit = () => {
    dispatch(
      vendorpaymentList({
        query: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
      })
    );
    dispatch(vendorPaymentData({}));
  };

  useEffect(() => {
    journalVoucherHit();
  }, [searchQuery]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
    journalVoucherHit();
  };

  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    journalVoucherHit();
  };

  const searchData = (e) => {
    searchQuery = e;
    setSearchQuery(e);
    setPage(0);
  };
  const handleDropDown = (data, type) => {
    console.log(data, "data row");

    if (type === "setProfoma") {
      dispatch(updateJournalStatus({ id: data.id, status: 1 }));
    }
    if (type === "setDraft") {
      dispatch(updateJournalStatus({ id: data.id, status: 0 }));
    }
    if (type === "setInvoice") {
      dispatch(updateJournalStatus({ id: data.id, status: 2 }));
    }
  };

  const debounceSearch = useCallback(debounce(searchData, 500), []);

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
  };
  /** initial Hit */
  useEffect(() => {
    journalVoucherHit();
  }, []);
  useEffect(() => {
    if (selectScreenData && selectScreenData.length > 0) {
      const value = selectScreenData[0]?.actions;
      setUserActions(value || {});
    }
  }, [selectScreenData]);
  /** Messages */
  useEffect(() => {
    if (updateJournalStatusVoucherLoad === API_STATUS.FULFILLED) {
      toastr.clear();
      toastr.success("Vendor Payment Updated Successfully!");
      journalVoucherHit();
      dispatch(clearPurchaseLoadings());
    }

    if (errorMessage) {
      toastr.clear();
      if (errorMessage.message) toastr.error(errorMessage.message);
      else if (errorMessage.errors) {
        let data = "";
        errorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        toastr.error(data);
      }
      journalVoucherHit();
      dispatch(clearPurchaseLoadings());
    }
  }, [errorMessage, updateJournalStatusVoucherLoad]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-content-between">
            <h2>Vendor Payment</h2>
            {userActions["Purchase Payment-add"] && (
              <button
                type="button"
                className="waves-effect waves-light btn btn-primary mb-2"
                onClick={tog_fullscreen}
              >
                Add
              </button>
            )}
          </div>
          <Row>
            <Col md="4">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Total Vendors
                      </p>
                      <h4 className="mb-0">
                        {vendorPaymentCardDatas?.no_of_vendors}
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-message-2-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Total No of Payments
                      </p>
                      <h4 className="mb-0">
                        {vendorPaymentCardDatas?.no_of_proforma?.length}
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-stack-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md="4">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Total Paid Amount
                      </p>
                      <h4 className="mb-0">
                        {vendorPaymentCardDatas?.total_amount}
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i
                        className={"ri-money-dollar-circle-line  font-size-24"}
                      ></i>
                    </div>
                  </div>
                </CardBody>

                {/* <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge badge-soft-danger font-size-11 me-1">
                                            <i className="mdi mdi-menu-down"> </i> 2
                                        </span>
                                        <span className="text-muted ms-2">Decrease</span>
                                    </div>
                                </CardBody> */}
              </Card>
            </Col>
            {/* <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Number of Proforma
                      </p>
                      <h4 className="mb-0">
                        {vendorPaymentCardDatas?.no_of_proforma?.length}
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={" ri-chat-upload-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-md-8">
                  <h5>
                    {" "}
                    <span>{}</span>
                  </h5>
                </div>
                <div className="col-md-4 row">
                  <input
                    type="text"
                    name="search"
                    className="form-control"
                    style={{ float: "right" }}
                    // value={searchQuery}
                    onChange={(e) => {
                      debounceSearch(e.target.value);
                    }}
                    placeholder="Search"
                  />
                </div>
              </div>
              <div className="table-responsive react-table">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} stickyHeader>
                    <TableHead className="table-light table-nowrap">
                      <TableRow>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "receipt_no" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("receipt_no");
                            }}
                          >
                            Receipt No
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "vendor_id" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("vendor_id");
                            }}
                          >
                            Vendor
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "total" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("total");
                            }}
                          >
                            Payment Date
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "status" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("status");
                            }}
                          >
                            Pay Amount
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">Payment Type</StyledTableCell>
                        <StyledTableCell align="">Pay Mode</StyledTableCell>
                        <StyledTableCell align="">
                          Payment Account
                        </StyledTableCell>
                        <StyledTableCell align="">Notes</StyledTableCell>
                        {/* <StyledTableCell align="">Action</StyledTableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {vendorPaymentListData &&
                        vendorPaymentListData?.rows?.map((row, index) => (
                          <StyledTableRow>
                            <StyledTableCell align="">
                              {row?.payment_no}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row?.vendor?.vendor_name}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {dayjs(row?.pay_date).format("DD-MM-YYYY")}
                            </StyledTableCell>

                            <StyledTableCell align="">
                              {row?.pay_amt}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {typesOfPayments.map((ele) => {
                                return ele.id == Number(row?.pay_type)
                                  ? ele.type
                                  : "";
                              })}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {paymentTypewithcheck.map((ele) => {
                                return ele.id == Number(row?.pay_mode)
                                  ? ele.mode
                                  : "";
                              })}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {
                                row?.vendor?.purchase_invoice?.finance_account
                                  ?.name
                              }
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row?.notes}
                            </StyledTableCell>
                            {/* <StyledTableCell align="" className="button-items">
                              <div className="d-flex">
                                <>
                                  {row.status != 2 ? (
                                    <>
                                      <Dropdown
                                        isOpen={info[index]}
                                        direction="left"
                                        toggle={() => {
                                          info[index] = !info[index];
                                          setInfo({ ...info });
                                        }}
                                      >
                                        <DropdownToggle color="danger" caret>
                                          <i class="ri-more-2-line"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          {dropdownValues[row.status].data.map(
                                            (items) => {
                                              return (
                                                <DropdownItem
                                                  onClick={() =>
                                                    handleDropDown(
                                                      row,
                                                      items.handlerName
                                                    )
                                                  }
                                                >
                                                  {items.label}
                                                </DropdownItem>
                                              );
                                            }
                                          )}
                                        </DropdownMenu>
                                      </Dropdown>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <span className="mx-4">-</span>{" "}
                                    </>
                                  )}
                                </>
                              </div>
                            </StyledTableCell> */}
                          </StyledTableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50]}
                          colSpan={9}
                          count={vendorPaymentListData?.count}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};
export default VendorPayement;
