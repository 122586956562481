import { Http } from "./Http";
import { getBaseEndpointUrl } from "./config";

export const signIn = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/auth/signin", postData);
};
export const forgotPassword = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/auth/forgot-password", postData);
};
export const resetPassword = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/auth/reset-password", postData);
};

/* Dashboard */
export const dashboardCards = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/dashboard/dashboard_cards/", Http.getAuthToken());
};
export const dashboardInvoices = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/dashboard/dashboard_invoice_list?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const dashboardChart = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/dashboard/dashboard_pie_chart/", Http.getAuthToken());
};
/* Customers */
export const customersList = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/user/customerlist?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const customerAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/create_customer", postData, Http.getAuthToken());
};

export const customerUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/user/update_customer/" + updateID, postData, Http.getAuthToken());
};
export const customerDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/user/delete_customer/" + deleteID, Http.getAuthToken());
};

export const customerEdit = (cutomerID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/user/customer/" + cutomerID, Http.getAuthToken());
};

/* Products */
export const productsList = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/product/productlist?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const productEdit = (productId) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/product/edit/" + productId, Http.getAuthToken());
};
export const productAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/product/create", postData, Http.getAuthToken());
};

export const productUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/product/update/" + updateID, postData, Http.getAuthToken());
};
export const productDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/product/delete/" + deleteID, Http.getAuthToken());
};

/* Purchase Inventry */
export const purchaseList = (query, page, limit, sortby, order) => {
  console.log(query, page, limit, sortby, order, "4567890")
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/purchasedesc/productlist?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const purchaseEdit = (productId) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/purchasedesc/edit/" + productId, Http.getAuthToken());
};
export const purchaseAdd = (postData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/purchasedesc/create", postData, Http.getAuthToken());
};

export const purchaseUpdate = (postData, updateID) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(baseUrl + "/purchasedesc/update/" + updateID, postData, Http.getAuthToken());
};
export const purchaseDelete = (deleteID) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.delete(baseUrl + "/purchasedesc/delete/" + deleteID, Http.getAuthToken());
};

/* Company */
export const companyAdd = (formData) => {
  console.log(formData, 'formData  ');
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/company/create", formData, Http.getAuthToken());
};
export const companyList = (postData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/company", Http.getAuthToken());
};
/* Users */
export const userList = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/user/userlist?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const userAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/create_user", postData, Http.getAuthToken());
};

export const userEdit = (userId) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/user/edit/" + userId, Http.getAuthToken());
};
export const userUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/user/update_user/" + updateID, postData, Http.getAuthToken());
};
export const userDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/user/delete_user/" + deleteID, Http.getAuthToken());
};


/* Vendor */
export const vendorsList = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/user/vendorlist?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const vendorAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/create_vendor", postData, Http.getAuthToken());
};
export const vendorEdit = (vendorId) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/user/vendor/" + vendorId, Http.getAuthToken());
};
export const vendorUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/user/update_vendor/" + updateID, postData, Http.getAuthToken());
};
export const vendorDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/user/delete_vendor/" + deleteID, Http.getAuthToken());
};
export const getScreenApi = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/user/screen_data", Http.getAuthToken());
};
export const getselectUser = (id) => {
  console.log(id, "56ysssuj")
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/user/getselect_screen/"+id,);
};
/* Products */
export const salesinvoiceList = (query, page, limit, sortby, order, invoice) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/salesinvoice/list?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order+"&invoice_main_id="+invoice, Http.getAuthToken());
};
export const vatreturnList = (query, page, limit, sortby, order, invoice) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/salesinvoice/get_vatreturn_list?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order+"&invoice_main_id="+invoice, Http.getAuthToken());
};
export const salesinvoiceById = (editID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/salesinvoice/edit/" + editID, Http.getAuthToken());
};
export const getInvoiceNumber = (editID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/salesinvoice/get_invoice_number", Http.getAuthToken());
};

export const getInvoicecr_drNumber = (invoiceNO, credit_debit_type) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/salesinvoice/get_invoicecr_dr_number?invoiceNO="+invoiceNO+"&credit_debit_type="+credit_debit_type, Http.getAuthToken());
};
export const getpaymentStat = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/salesinvoice/payment/stat", Http.getAuthToken());
};

export const createPayment = (data) => {
    console.log(data, 'data');
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/salesinvoice/payment/create", data, Http.getAuthToken());
};
export const getPayments = (query, page, limit, sortby, order) => {
  console.log(query, page, limit, order,sortby, "54678")
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/salesinvoice/payment?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};

export const getInvoiceDetail = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/salesinvoice/get_invoice_details/?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};

export const salesinvoiceAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/salesinvoice/create", postData, Http.getAuthToken());
};
export const salesinvoiceUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/salesinvoice/update/" + updateID, postData, Http.getAuthToken());
};
export const salesinvoiceInsertcreditdebit = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/salesinvoice/create_credit_debit/", postData, Http.getAuthToken());
};

export const salesinvoiceDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/salesinvoice/delete/" + deleteID, Http.getAuthToken());
};

export const salesinvoicePDF = (ID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/salesinvoice/generate_invoice/" + ID, Http.getAuthToken());
};

export const selesData = (ID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/salesinvoice/getdata/", Http.getAuthToken());
};

export const salesinvoiceProforma = (ID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/salesinvoice/setproforma", ID, Http.getAuthToken());
};

export const salesInvoiceDraft = (ID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/salesinvoice/set_draft", ID, Http.getAuthToken());
};

export const salesInvoice = (ID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/salesinvoice/set_invoice", ID, Http.getAuthToken());
};

export const salesInvoiceCancel = (ID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/salesinvoice/set_cancel_invoice", ID, Http.getAuthToken());
};
/** Sales Invoice Report */
export const reportinvoiceList = (query, from_date, to_date, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/dashboard/report_invoice_list/?query=" + query + "&from_date=" + from_date + "&to_date=" + to_date + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};

export const salesReportDownload = (query, from_date, to_date) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/dashboard/downloadExcel/?query=" + query + "&from_date=" + from_date + "&to_date=", Http.getAuthToken());
}

export const zatcaValidation = (id) => {
    console.log(id, 'ZATCA API ID')
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/salesinvoice/zatcavalidate/?query=" + id, Http.getAuthToken());
}

export const zatcaView = (id) => {
    console.log(id,'Zatca View Reducer API')
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/auth//zatca/view?query=" + id?.ID, Http.getAuthToken());
}

export const searchInvoice = (query) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/salesinvoice/search?query=" + query, Http.getAuthToken());
}


export const getCountry = (query) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/settings/countrylist" + query, Http.getAuthToken());
}

export const getCurrency = (query) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/settings/currency_list" + query, Http.getAuthToken());
}

export const sendPdfMail = (formData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/salesinvoice/sendemail",formData, Http.getAuthToken());
};

export const purchase_invoice_list = (
    query,
    page,
    limit,
    sortby,
    order
  ) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl +
        "/purchase/invoice_list?query=" +
        query +
        "&page=" +
        page +
        "&limit=" +
        limit +
        "&sortby=" +
        sortby +
        "&order=" +
        order ,
      Http.getAuthToken()
    );
  };
  
  export const purchase_invoice_data = (ID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/purchase/get_invoice_data/", Http.getAuthToken());
  };
  
  export const get_purchase_invoice_no = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl + "/purchase/generate_purchase_invoice",
      Http.getAuthToken()
    );
  };

  export const create_purchase_invoice = (formData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(
      baseUrl + "/purchase/create_invoice",
      formData,
      Http.getAuthToken()
    );
  };
  
  export const purchaseinvoiceUpdate = (formData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(
      baseUrl + "/purchase/update_invoice_status",
      formData,
      Http.getAuthToken()
    );
  };
  
  export const invoiceUpdatePurchase = (formData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(
      baseUrl + "/purchase/update_invoice",
      formData,
      Http.getAuthToken()
    );
  };
export const createvendor = (formData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(
      baseUrl + "/user/create_vendor",
      formData,
      Http.getAuthToken()
    );
  };
  
  export const createpurchaseorder = (formData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(
      baseUrl + "/purchase/create_order",
      formData,
      Http.getAuthToken()
    );
  };
  export const purchase_return_list = (
    query,
    page,
    limit,
    from_date,
    to_date
  ) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl +
        "/purchase/return_list?query=" +
        query +
        "&page=" +
        page +
        "&limit=" +
        limit +
        "&from_date=" +
        from_date +
        "&to_date=" +
        to_date,
      Http.getAuthToken()
    );
  };
  
  export const getpurchaseorder = (query, page, limit,sortby,order, from_date, to_date,vendor_id,payment_status,invoice_status

  ) => {
    console.log(from_date,to_date,vendor_id,invoice_status,payment_status, "4567")
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl +
        "/purchase/order_list?query=" +
        query +
        "&page=" +
        page +
        "&limit=" +
        limit +
        "&sortby=" +
        sortby +
        "&order=" +
        order +
        "&from_date=" +
        from_date +
        "&to_date=" +
        to_date +
        "&vendor_id=" +
        vendor_id+
        "&invoice_status=" +
        invoice_status+
      
        "&payment_status=" +
        payment_status,
      Http.getAuthToken()
    );
  };
  
  export const getsalesorder = (query, page, limit,order,sortby, from_date, to_date, customer_id, payment_status,invoice_status,zatca_status) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl +
        "/salesinvoice/order_list?query=" +
        query +
        "&page=" +
        page +
        "&limit=" +
        limit +
        "&sortby=" +
        sortby +
        "&order=" +
        order +
        "&from_date=" +
        from_date +
        "&to_date=" +
        to_date +
        "&customer_id=" +
        customer_id+
        "&invoice_status=" +
        invoice_status+
        "&zatca_status=" +
        zatca_status+
        "&payment_status=" +
        payment_status,
      Http.getAuthToken()
    );
  };
  
  export const getvatorder = (query, page, limit,order,sortby, from_date, to_date, customer_id, payment_status,invoice_status,zatca_status) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl +
        "/salesinvoice/vat_list?query=" +
        query +
        "&page=" +
        page +
        "&limit=" +
        limit +
        "&sortby=" +
        sortby +
        "&order=" +
        order +
        "&from_date=" +
        from_date +
        "&to_date=" +
        to_date +
        "&customer_id=" +
        customer_id+
        "&invoice_status=" +
        invoice_status+
        "&zatca_status=" +
        zatca_status+
        "&payment_status=" +
        payment_status,
      Http.getAuthToken()
    );
  };
  
  export const getpurchaseorderno = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/purchase/order_no", Http.getAuthToken());
  };
  
  export const purchaseorderUpdate = (formData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(
      baseUrl + "/purchase/update_order_status",
      formData,
      Http.getAuthToken()
    );
  };
  
  export const create_purchase_return = (formData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(
      baseUrl + "/purchase/create_return",
      formData,
      Http.getAuthToken()
    );
  };
  
  export const purchaseorderStat = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/purchase/order_stat", Http.getAuthToken());
  };
  export const purchase_return_data = (ID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/purchase/get_return_data/", Http.getAuthToken());
  };
  
  export const purchasereturnUpdate = (formData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(
      baseUrl + "/purchase/update_return_status",
      formData,
      Http.getAuthToken()
    );
  };
  
  export const get_purchase_return_no = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl + "/purchase/generate_purchase_return",
      Http.getAuthToken()
    );
  };
  
  export const vendor_payement_list = (
    query,
    page,
    limit,
    from_date,
    to_date
  ) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl +
        "/purchase/vendor_payment_list?query=" +
        query +
        "&page=" +
        page +
        "&limit=" +
        limit +
        "&from_date=" +
        from_date +
        "&to_date=" +
        to_date,
      Http.getAuthToken()
    );
  };
  
  export const vendor_payment_data = (ID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl + "/purchase/get_vendor_payment_data/",
      Http.getAuthToken()
    );
  };
  
  export const get_vendor_payment_no = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl + "/purchase/generate_vendor_payment",
      Http.getAuthToken()
    );
  };
  
  export const create_vendor_payment = (formData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(
      baseUrl + "/purchase/create_vendor_payment",
      formData,
      Http.getAuthToken()
    );
  };
  
  export const get_vendor_invoice = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl +
        "/purchase/get_vendor_payments/?query=" +
        query +
        "&page=" +
        page +
        "&limit=" +
        limit +
        "&sortby=" +
        sortby +
        "&order=" +
        order,
      Http.getAuthToken()
    );
  };
  export const order_by_id = (id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl + "/purchase/get_order_by_id/" + id,
      Http.getAuthToken()
    );
  };
  
  /** Get Invoice By Id */
  export const invoice_by_id = (id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl + "/purchase/get_invoice_by_id/" + id,
      Http.getAuthToken()
    );
  };
  export const receiptvoucherUpdate = (formData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(
      baseUrl + "/receipt_voucher/update",
      formData,
      Http.getAuthToken()
    );
  };
  
export const accounts_group_list = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl +
        "/finance_accounts/grouplist?query=" +
        query +
        "&page=" +
        page +
        "&limit=" +
        limit +
        "&sortby=" +
        sortby +
        "&order=" +
        order,
      Http.getAuthToken()
    );
  };
  
  export const group_by_id = (id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl + "/finance_accounts/group/" + id,
      Http.getAuthToken()
    );
  };
  
  export const group_add = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(
      baseUrl + "/finance_accounts/create_group",
      postData,
      Http.getAuthToken()
    );
  };
  
  export const group_update = (postData, id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(
      baseUrl + "/finance_accounts/update_group/" + id,
      postData,
      Http.getAuthToken()
    );
  };
  
  export const group_delete = (id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(
      baseUrl + "/finance_accounts/delete_group/" + id,
      Http.getAuthToken()
    );
  };
  
  export const sub_group_list = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl +
        "/finance_accounts/subgrouplist?query=" +
        query +
        "&page=" +
        page +
        "&limit=" +
        limit,
      Http.getAuthToken()
    );
  };
  
  export const sub_group_by_id = (id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl + "/finance_accounts/sub_group/" + id,
      Http.getAuthToken()
    );
  };
  
  export const sub_group_add = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(
      baseUrl + "/finance_accounts/create_sub_group",
      postData,
      Http.getAuthToken()
    );
  };
  
  export const sub_group_update = (postData, id) => {
    const baseUrl = getBaseEndpointUrl();
    console.log(id, "SUBGROUP");
    return Http.put(
      baseUrl + "/finance_accounts/update_sub_group/" + id,
      postData,
      Http.getAuthToken()
    );
  };
  
  export const sub_group_delete = (id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(
      baseUrl + "/finance_accounts/delete_sub_group/" + id,
      Http.getAuthToken()
    );
  };
  
  export const accounts_add = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(
      baseUrl + "/finance_accounts/create_account",
      postData,
      Http.getAuthToken()
    );
  };
  
  export const accounts_list = (query, page, limit, sortby, order, group_id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl +
        "/finance_accounts/accountlist?query=" +
        query +
        "&page=" +
        page +
        "&limit=" +
        limit +
        "&sortby=" +
        sortby +
        "&order=" +
        order +
        "&group_id=" +
        group_id,
      Http.getAuthToken()
    );
  };
  export const account_by_id = (id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(
      baseUrl + "/finance_accounts/account/" + id,
      Http.getAuthToken()
    );
  };
  
  export const account_delete = (id, type) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(
      baseUrl + "/finance_accounts/delete_account/" + id + "/" + type,
      Http.getAuthToken()
    );
  };
  
  export const account_update = (postData, id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(
      baseUrl + "/finance_accounts/update_account/" + id,
      postData,
      Http.getAuthToken()
    );
  };
  
  export const get_finance_reports = (type, from_date, to_date) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl +
        "/finance_accounts/voucherReport?query=" +
        type +
        "&from_date=" +
        from_date +
        "&to_date=" +
        to_date,
      Http.getAuthToken()
    );
  };
  export const journalvoucherUpdate = (id, status) => {
    const baseUrl = getBaseEndpointUrl();
    let formData = {};
    formData.id = id;
    formData.status = status;
    console.log(formData, "APIFORM");
    return Http.put(
      baseUrl + "/journal_voucher/update",
      formData,
      Http.getAuthToken()
    );
  };
  export const create_journal_voucher = (formData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(
      baseUrl + "/journal_voucher/create",
      formData,
      Http.getAuthToken()
    );
  };
  export const generatejournalvoucher = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl + "/journal_voucher/generate_journal_voucher",
      Http.getAuthToken()
    );
  };
  export const journal_voucher_list = (query, page, limit) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl +
        "/journal_voucher/list?query=" +
        query +
        "&page=" +
        page +
        "&limit=" +
        limit,
      Http.getAuthToken()
    );
  };
  export const journal_card_data = (ID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/journal_voucher/getdata/", Http.getAuthToken());
  };
  
  export const getStockUnits = (query, page, limit) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl +
        "/settings/stock_unit_list?query=" +
        query +
        "&page=" +
        page +
        "&limit=" +
        limit,
      Http.getAuthToken()
    );
  };
  export const getpvstat = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/payment_voucher/stats/", Http.getAuthToken());
  };
  export const updatePaymentStatus = (updateID, type) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(
      baseUrl + "/payment_voucher/update_status/" + updateID,
      { type },
      Http.getAuthToken()
    );
  };
  export const generatepaymentvoucher = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl + "/payment_voucher/generate_payment_voucher",
      Http.getAuthToken()
    );
  };
  
  export const payment_voucher_list = (query, page, limit) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl +
        "/payment_voucher/list?query=" +
        query +
        "&page=" +
        page +
        "&limit=" +
        limit,
      Http.getAuthToken()
    );
  };
  
  export const create_payment_voucher = (formData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(
      baseUrl + "/payment_voucher/create",
      formData,
      Http.getAuthToken()
    );
  };

export const create_receipt_voucher = (formData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(
      baseUrl + "/receipt_voucher/create",
      formData,
      Http.getAuthToken()
    );
  };
  
  export const generatereceiptvoucher = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl + "/receipt_voucher/generate_payment_voucher",
      Http.getAuthToken()
    );
  };
  
  export const receiptvoucher_list = (query, page, limit) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl +
        "/receipt_voucher/list?query=" +
        query +
        "&page=" +
        page +
        "&limit=" +
        limit,
      Http.getAuthToken()
    );
  };
  
  export const receiptData = (ID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/receipt_voucher/getdata/", Http.getAuthToken());
  };
  
export const getTranslate = (query) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/settings/translate?query=" + query, Http.getAuthToken());
}
