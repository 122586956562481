import React, { useEffect, useState, useMemo } from "react";
import "../../App.css";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Table,
} from "reactstrap";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import "../../App.css";
import {
  vendorPaymentValidator,
  paymentValidator,
} from "../../utils/Validation";
import { useForm, Controller, Form } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  API_STATUS,
  payAccount,
  paymentTypewithcheck,
  typesOfPayments,
} from "../../utils/constants";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-dropdown-select";
import dayjs from "dayjs";
import {
  companySelector,
  getCompanyDetails,
  postCompanyDetails,
} from "../../store/reducer/CompanyReducer";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import {
  Paper,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import {
  AddPaymentVoucherSchedule,
  clearPVLoadings,
  getAccountsList,
  getGeneratedPaymentVoucherNumber,
  paymentVoucherSelector,
} from "../../store/reducer/PaymentVoucherReducer";
import {
  FinanceAccountsSelector,
  AccountsList,
} from "../../store/reducer/FinanceAccountsReducer";
import { vendorData, vendorsSelector } from "../../store/reducer/VendorReducer";
import {
  productData,
  productsSelector,
} from "../../store/reducer/ProductReducer";
import {
  purchaseSelector,
  clearPurchaseLoadings,
  getVendorInvoiceDetails,
  getPaymentNo,
  createVendorPayment,
} from "../../store/reducer/PurchaseReducer";

import {
  clearSinvLoadingDatas,
  createPaymentDetails,
  getPaymentList,
  salesinvoiceSelector,
  getInvoiceDetails,
} from "../../store/reducer/SalesInvoiceReducer";
import _ from "loadsh";

function VendorPayementAdd() {
  const { AddPaymentVoucherLoad, generatedPVno } = useSelector(
    paymentVoucherSelector
  );
  const { createVendorPaymentLoad, purchaseVPno, vendorPaymentsData } =
    useSelector(purchaseSelector);

  useEffect(() => {
    console.log(vendorPaymentsData, "vendorPaymentsData1");
    let payableAmt = _.sumBy(vendorPaymentsData, function (o) {
      return o.invoice_current_balance != 0
        ? parseFloat(o.invoice_current_balance)
        : parseFloat(o.bill_amount);
    });
    console.log(payableAmt, "payableAmt");
    setValue("payable_bal", payableAmt);
    setValue("bal_after_pay", payableAmt);
  }, [vendorPaymentsData]);

  const { invoiceDetails, createPaymentDetailsloading } =
    useSelector(salesinvoiceSelector);

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
  };
  const taxcategories = [
    {
      id: 0,
      category: "VAT 15%",
    },
  ];
  // const { accountsListDatas } = useSelector(FinanceAccountsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,
    watch,
    clearErrors,
  } = useForm({
    resolver: yupResolver(vendorPaymentValidator),
  });
  const { vendorDatas } = useSelector(vendorsSelector);
  const { productDatas } = useSelector(productsSelector);
  /** State Values */
  useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      console.log(value, name, type)
    );
    return () => subscription.unsubscribe();
  }, [watch]);
  const [total, setTotal] = useState(0);
  console.log(total, "total");
  const [isWarning, setIsWarning] = useState(false);
  const [ids, setIds] = useState([]);
  const [label, setLabel] = useState("label");
  let [sortBy, setSortBy] = useState("created_at");
  let [activesortBy, setActiveSortBy] = useState("customer_name");
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("asc");
  const [payaccount, setPayAccount] = useState([]);
  const [payAmt, setpayAmt] = useState(0);
  const [payAllStatus, setpayAllStatus] = useState(0);
  let subtract_amounts = [];
  let each_amounts = [];

  useEffect(() => {
    dispatch(
      productData({
        query: "",
        page: parseInt(page + 1),
        limit: parseInt(rowsPerPage),
        sortby: "",
        order: "",
      })
    );
    dispatch(getAccountsList({}));
    dispatch(
      vendorData({
        query: "",
        page: page + 1,
        limit: rowsPerPage,
        id: "",
      })
    );
    dispatch(getPaymentNo({}));
  }, []);

  useEffect(() => {
    setValue("pay_type", typesOfPayments[1]);
  }, [typesOfPayments]);
  /** Get Invoice Data */
  const getInvoiceDatas = (event) => {
    setValue("vendor_id", event[0]?.id);
    dispatch(
      getVendorInvoiceDetails({
        query: event[0]?.id,
        page: page + 1,
        limit: rowsPerPage,
        sortby: sortBy,
        order: order,
      })
    );
  };

  // const _options = useMemo(() => {
  //   const options = accountsListDatas?.rows?.map((ele) => {
  //     return {
  //       ...ele,
  //       label: `${ele.account_code} - ${ele.name}`,
  //     };
  //   });
  //   return options;
  // }, [accountsListDatas]);
console.log(errors, "rtyuikl")
  const onSubmitHandler = (formData) => {
    let invoicePaidAmt = each_amounts.filter((amts) => {
      return !_.isEmpty(amts);
    });
    formData.each_amounts = invoicePaidAmt;
    formData.pay_amt = payAmt;
    formData.pay_all_status = payAllStatus;
    console.log(formData, "34e5h")
    dispatch(createVendorPayment({ formData }));
  };

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
  };

  const handlePayMode = (event) => {
    setValue("pay_mode", event[0]?.id);
    clearErrors("pay_mode");
    dispatch(
      AccountsList({
        query: "",
        page: 0,
        limit: 0,
        sortby: "id",
        order: "",
        group_id: event[0]?.id,
      })
    );
  };

  /** Calculate the paying Amount */
  const handlePayAmount = (event) => {
    let { value } = event.target;
    let TotalPayAmt = getValues("payable_bal");
    let maxPayAmt = TotalPayAmt >= value ? value : TotalPayAmt;

    setpayAmt(maxPayAmt);
  };

  useEffect(() => {
    if (createVendorPaymentLoad === API_STATUS.FULFILLED) {
      toastr.success("Vendor Payment Added Succesfully");
      reset();
      dispatch(clearPurchaseLoadings());
      navigate(-1);
    }
  }, [createVendorPaymentLoad]);

  useEffect(() => {
    setValue("payment_no", purchaseVPno?.payment_no);
  }, [purchaseVPno]);

  useEffect(() => {
    let sum = 0;
    let selected_datas = vendorPaymentsData?.filter((items) =>
      ids?.some((isData) => isData == items.id)
    );
    selected_datas?.forEach((num) => {
      if (num.invoice_current_balance != 0) {
        sum += parseFloat(num.invoice_current_balance);
      } else {
        sum += parseFloat(num.net_amount);
      }
    });

    setTotal(sum);
    setpayAmt(sum);
  }, [ids]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="row">
            <div className="col-md-6">
              <h4>
                <Breadcrumb>
                  <BreadcrumbItem>Vendor Payment / New</BreadcrumbItem>
                </Breadcrumb>
              </h4>
            </div>
          </div>
          <Row>
            <Col xs={12}>
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Card>
                  <CardBody>
                    <div>
                      <Row>
                        <Col md="3">
                          <div className="input-container">
                            <Controller
                              name="vendor_id"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className="js-states select-control"
                                  placeholder={"Vendor"}
                                  options={vendorDatas}
                                  {...field}
                                  disabled={false}
                                  dropdownHandle={true}
                                  labelField="vendor_name"
                                  searchable={true}
                                  valueField={"id"}
                                  name="vendor_id"
                                  onChange={getInvoiceDatas}
                                  required
                                />
                              )}
                              {...register("vendor_id")}
                            />
                          </div>
                          {errors?.vendor_id?.message && (
                            <p className="error">{errors.vendor_id.message}</p>
                          )}
                        </Col>
                        <Col md="3">
                          <div className="input-container">
                            <Controller
                              name="pay_mode"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className="js-states select-control"
                                  placeholder={"Pay Mode"}
                                  options={paymentTypewithcheck}
                                  {...field}
                                  disabled={false}
                                  dropdownHandle={true}
                                  labelField="mode"
                                  searchable={true}
                                  valueField={"id"}
                                  name="pay_mode"
                                  onChange={handlePayMode}
                                  required
                                />
                              )}
                              {...register("pay_mode")}
                            />
                          </div>
                          {errors?.pay_mode?.message && (
                            <p className="error">{errors.pay_mode.message}</p>
                          )}
                        </Col>
                        {/* <Col md={3}>
                          <div className="input-container mb-0">
                            <Controller
                              name="pay_account"
                              control={control}
                              render={({ field, ref }) => (
                                <Select
                                  className="js-states select-control"
                                  placeholder={"Pay Account"}
                                  options={_options}
                                  value={
                                    getValues("pay_account")
                                      ? _options.filter(
                                          (ele) =>
                                            getValues("pay_account") == ele.id
                                        )
                                      : []
                                  }
                                  {...field}
                                  disabled={false}
                                  dropdownHandle={true}
                                  ref={ref}
                                  searchable={true}
                                  labelField={label}
                                  valueField={"id"}
                                  name="pay_account"
                                  onDropdownOpen={() => {
                                    setLabel("label");
                                  }}
                                  onChange={(value) => {
                                    setLabel("name");
                                    setValue("pay_account", value[0].id);
                                    setValue(
                                      "account_bal",
                                      value[0]?.current_balance
                                    );
                                  }}
                                />
                              )}
                            />
                          </div>
                        </Col> */}
                        <Col md="3">
                          <div className="input-container">
                            <Controller
                              name="pay_type"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className="js-states select-control"
                                  placeholder={"Pay Type"}
                                  options={typesOfPayments}
                                  {...field}
                                  disabled={false}
                                  dropdownHandle={true}
                                  values={[
                                    typesOfPayments && typesOfPayments[1],
                                  ]}
                                  labelField="type"
                                  searchable={true}
                                  valueField={"id"}
                                  name="pay_type"
                                  onChange={(value) =>
                                    setValue("pay_type", value[0])
                                  }
                                />
                              )}
                            />
                          </div>
                          {errors?.pay_type?.message && (
                            <p className="error">{errors.pay_type.message}</p>
                          )}
                        </Col>
                        <Col md="3">
                          <div className="input-container mb-0">
                            <input
                              placeholder=" "
                              type="text"
                              id="payable_bal"
                              name="payable_bal"
                              {...register("payable_bal")}
                              disabled
                            />
                            <label for="payable_bal">Payable Balance</label>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="input-container mb-0">
                            <input
                              placeholder=" "
                              type="text"
                              id="bal_after_pay"
                              name="bal_after_pay"
                              disabled
                              {...register("bal_after_pay")}
                            />
                            <label for="bal_after_pay">
                              Balance After Payment
                            </label>
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="input-container mb-0">
                            <input
                              placeholder=" "
                              type="text"
                              id="pay_amt"
                              name="pay_amt"
                              value={payAmt}
                              onChange={handlePayAmount}
                            />
                            <label for="pay_amt">Pay Amount</label>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="input-container">
                            <input
                              placeholder=" "
                              type="date"
                              defaultValue={dayjs().format("YYYY-MM-DD")}
                              id="pay_date"
                              name="pay_date"
                              {...register("pay_date")}
                            />
                            <label for="eta">Payment Date</label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="3">
                          <div className="input-container mb-0">
                            <input
                              placeholder=" "
                              type="text"
                              defaultValue={purchaseVPno?.payment_no}
                              id="payment_no"
                              name="payment_no"
                              {...register("payment_no")}
                            />
                            <label for="payment_no">Receipt No</label>
                          </div>
                          {errors?.quotation_no?.message && (
                            <p className="error">
                              {errors.quotation_no.message}
                            </p>
                          )}
                          <span></span>
                        </Col>
                        <Col md="3" className="">
                          <div className="input-container d-none">
                            <input
                              placeholder=" "
                              type="text"
                              id="account_bal"
                              name="account_bal"
                              {...register("account_bal")}
                              disabled
                            />
                            <label for="account_bal">Account Balance</label>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-check form-check-right float-right mt-2">
                            <span className="mx-2">Pay All</span>
                            <input
                              type="checkbox"
                              disabled={!vendorPaymentsData?.length > 0}
                              checked={
                                ids != 0
                                  ? ids.length === vendorPaymentsData?.length
                                  : false
                              }
                              name="pay_all_status"
                              id="pay_all_status"
                              onChange={(e) => {
                                let id = [];
                                if (e.target.checked) {
                                  setpayAllStatus(1);
                                  vendorPaymentsData?.forEach((element) => {
                                    id.push(element?.id);
                                  });
                                  payAmt == 0 &&
                                    setpayAmt(getValues("payable_bal"));
                                } else {
                                  setpayAllStatus(0);
                                  setpayAmt(0);
                                }
                                setIds(id);
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div className="table-responsive react-table">
                      <TableContainer
                        component={Paper}
                        style={{ minHeight: "300px" }}
                      >
                        <Table sx={{ minWidth: 700 }} stickyHeader>
                          <TableHead className="table-light table-nowrap">
                            <TableRow>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={
                                    sortBy == "payment_type" ? true : false
                                  }
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("payment_type");
                                  }}
                                >
                                  Type
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={sortBy == "invoice_no" ? true : false}
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("invoice_no");
                                  }}
                                >
                                  Invoice No
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={sortBy == "date" ? true : false}
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("date");
                                  }}
                                >
                                  Date
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={sortBy == "amount_due" ? true : false}
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("amount_due");
                                  }}
                                >
                                  Amount Due
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={sortBy == "notes" ? true : false}
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("notes");
                                  }}
                                >
                                  Notes
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={sortBy == "discount" ? true : false}
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("discount");
                                  }}
                                >
                                  Discount
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={
                                    sortBy == "amount_paid" ? true : false
                                  }
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("amount_paid");
                                  }}
                                >
                                  Amount Paid
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">Pay</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {watch("pay_type")?.id == 1 &&
                              vendorPaymentsData?.length > 0 &&
                              vendorPaymentsData?.map((row, index) => {
                                let amt = payAmt;
                                let eachPay = 0;
                                let payStatus = 0;
                                let idVal = row?.id;
                                console.log(amt, ids, "AMOUNTS");
                                let invoiceAmt =
                                  row?.invoice_current_balance != 0 &&
                                  row?.invoice_current_balance != null
                                    ? row?.invoice_current_balance
                                    : row?.bill_amount;

                                let subAmt =
                                  parseFloat(invoiceAmt) - parseFloat(amt);

                                if (index == 0) {
                                  if (subAmt > 0) {
                                    eachPay = amt;
                                    payStatus = 1;
                                  } else {
                                    eachPay = invoiceAmt;
                                    payStatus = 2;
                                  }
                                  subtract_amounts[index] = subAmt;
                                  console.log(ids, "IDVALUES");
                                  eachPay = ids.includes(idVal) ? eachPay : 0;

                                  each_amounts.push({
                                    id: idVal,
                                    created_by: row?.created_by,
                                    vendor_id: row?.vendor_id,
                                    purchase_date: row?.purchase_date,
                                    pay_mode: row?.pay_mode,
                                    amount: subAmt > 0 ? subAmt : 0,
                                    paid_status: payStatus,
                                    // pay_account: row?.pay_account,
                                  });
                                } else {
                                  if (subtract_amounts[index - 1] <= 0) {
                                    subAmt =
                                      parseFloat(invoiceAmt) +
                                      parseFloat(subtract_amounts[index - 1]);

                                    subtract_amounts[index] = subAmt;

                                    if (subAmt > 0) {
                                      eachPay = Math.abs(
                                        subtract_amounts[index - 1]
                                      );
                                      payStatus = 1;
                                    } else {
                                      eachPay = invoiceAmt;
                                      payStatus = 2;
                                    }

                                    each_amounts.push({
                                      id: idVal,
                                      created_by: row?.created_by,
                                      vendor_id: row?.vendor_id,
                                      purchase_date: row?.purchase_date,
                                      pay_mode: row?.pay_mode,
                                      amount: subAmt > 0 ? subAmt : 0,
                                      paid_status: payStatus,
                                      // pay_account: row?.pay_account,
                                    });
                                  }
                                }

                                let balAmt =
                                  parseFloat(getValues("payable_bal")) -
                                  parseFloat(payAmt);
                                balAmt = isNaN(balAmt) ? 0 : balAmt;
                                setValue("bal_after_pay", balAmt);
                                return (
                                  <StyledTableRow>
                                    <StyledTableCell align="">
                                      PURCHASE
                                    </StyledTableCell>
                                    <StyledTableCell align="">
                                      {row?.invoice_no}
                                    </StyledTableCell>
                                    <StyledTableCell align="">
                                      {row.purchase_date &&
                                        dayjs(row.purchase_date).format(
                                          "YYYY-MM-DD"
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell align="">
                                      {invoiceAmt}
                                    </StyledTableCell>
                                    <StyledTableCell align="">
                                      {row?.notes}
                                    </StyledTableCell>
                                    <StyledTableCell align="">
                                      {row?.discount_amount}
                                    </StyledTableCell>
                                    <StyledTableCell align="">
                                      {eachPay}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align=""
                                      className="button-items"
                                    >
                                      <div>
                                        <label
                                          htmlFor={`pay${row?.id}`}
                                        ></label>
                                        <div>
                                          <input
                                            type="checkbox"
                                            name={`pay${row?.id}`}
                                            id={`pay${row?.id}`}
                                            checked={ids.includes(row.id)}
                                            onChange={(e) =>
                                              e.target.checked
                                                ? setIds((prev) => [
                                                    ...prev,
                                                    row.id,
                                                  ])
                                                : setIds(
                                                    ids?.filter(
                                                      (items) =>
                                                        items != row?.id
                                                    )
                                                  )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                        {total > 0 ? (
                          <>
                            {" "}
                            <div className="text-end">
                              <h6>
                                <b>Selected Amount : {total}</b>
                              </h6>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </TableContainer>
                    </div>
                  </CardBody>
                </Card>
                {/* <Card>
                  <CardBody>
                    <div className="table-responsive react-table">
                      <TableContainer
                        component={Paper}
                        style={{ minHeight: "300px" }}
                      >
                        <Table sx={{ minWidth: 700 }} stickyHeader>
                          <TableHead className="table-light table-nowrap">
                            <TableRow>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={
                                    sortBy == "payment_type" ? true : false
                                  }
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("payment_type");
                                  }}
                                >
                                  Product Description
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={sortBy == "invoice_no" ? true : false}
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("invoice_no");
                                  }}
                                >
                                  Qty
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell width={150} align="">
                                <TableSortLabel
                                  active={sortBy == "date" ? true : false}
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("date");
                                  }}
                                >
                                  Unit
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell width={300} align="">
                                <TableSortLabel
                                  active={
                                    sortBy == "description" ? true : false
                                  }
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("description");
                                  }}
                                >
                                  Price
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={
                                    sortBy == "project_name" ? true : false
                                  }
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("project_name");
                                  }}
                                >
                                  Amount
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">Action</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {voucherDetails?.map((row, index) => (
                              <StyledTableRow>
                                <StyledTableCell align="">
                                  <Controller
                                    name="country"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        className="js-states select-control"
                                        options={productDatas}
                                        value={
                                          voucherDetails[index].product_desc ??
                                          ""
                                        }
                                        {...field}
                                        disabled={false}
                                        dropdownHandle={true}
                                        searchable={true}
                                        labelField={"description"}
                                        valueField={"id"}
                                        name="country_name"
                                        onChange={(value) => {
                                          let data = voucherDetails;
                                          data[index].product_desc = value;
                                          setVoucherDetails([
                                            ...data,
                                            {
                                              product_desc: "",
                                              qty: "",
                                              amount: "",
                                              vat: "",
                                              vat_amount: "",
                                              total_amount: "",
                                              id: data.length,
                                            },
                                          ]);
                                        }}
                                      />
                                    )}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  <div class="input-container w-100 m-1">
                                    <input
                                      placeholder=" "
                                      type="text"
                                      value={row?.qty}
                                      id={`qty${index}`}
                                      name={`qty${index}`}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        let data = voucherDetails;
                                        data[index].qty = value;
                                        if (data[index].price) {
                                          data[index].amount =
                                            parseInt(value) *
                                            parseFloat(data[index].price);
                                        }
                                        setVoucherDetails([...data]);
                                      }}
                                    />
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  <div class="input-container w-100 m-1">
                                    <input
                                      placeholder=" "
                                      type="text"
                                      // id={`qty${index}`}
                                      value={row.unit}
                                      // disabled
                                      // name={`qty${index}`}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        let data = voucherDetails;
                                        data[index].unit = value;
                                        setVoucherDetails([...data]);
                                      }}
                                    />
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  <div class="input-container w-100 m-1">
                                    <input
                                      placeholder=" "
                                      type="text"
                                      value={row?.price}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        let data = voucherDetails;
                                        data[index].price = value;
                                        if (data[index].qty)
                                          data[index].amount =
                                            parseFloat(data[index].qty) *
                                            parseFloat(value);
                                        setVoucherDetails([...data]);
                                      }}
                                    />
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell
                                  align=""
                                  className="button-items"
                                >
                                  <div class="input-container w-100 m-1">
                                    <input
                                      placeholder=" "
                                      type="text"
                                      disabled
                                      id={`qty${index}`}
                                      name={`qty${index}`}
                                      value={row.amount}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        let data = voucherDetails;
                                        data[index].amount = value;
                                        setVoucherDetails([...data]);
                                      }}
                                    />
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell
                                  align=""
                                  className="button-items"
                                >
                                  <a
                                    href="javascript:void(0);"
                                    title="Delete"
                                    className="btn btn-danger delete-btn"
                                    onClick={() => {
                                      if (voucherDetails.length > 1) {
                                        const voucherDetail = _.filter(
                                          voucherDetails,
                                          (key, i) => i != index
                                        );
                                        console.log(
                                          voucherDetail,
                                          "check voucherDetails"
                                        );
                                        setVoucherDetails(voucherDetail);
                                      }
                                      // To clear the values of the one and only row

                                      //  else {
                                      //   setVoucherDetails([
                                      //     {
                                      //       product_desc: "",
                                      //       qty: "",
                                      //       amount: 0,
                                      //       vat: "",
                                      //       vat_amount: "",
                                      //       total_amount: 0,
                                      //       id: null,
                                      //     },
                                      //   ]);
                                      // }
                                    }}
                                  >
                                    <i className="ri-delete-bin-line"></i>
                                  </a>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </CardBody>
                </Card> */}
                <Card>
                  <CardBody className="d-flex justify-content-between">
                    <div className="invoice-width">
                      <div class="input-container w-100">
                        <textarea
                          id="net_amount"
                          rows={3}
                          // value={notes}
                          name="notes"
                          // onChange={(e) => setNotes(e.target.value)}
                          {...register("notes")}
                        />
                        <label for="notes">Notes</label>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="text-end">
                  <Button
                    color="primary"
                    onClick={() => console.log("hello")}
                    type="submit"
                    disabled={isWarning}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default VendorPayementAdd;
