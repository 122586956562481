import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "react-dropdown-select";
import { useNavigate, Link } from "react-router-dom";

import { customerValidationSchema } from "../../utils/Validation";
import { assestURL } from "../../services/config";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  Spinner,
} from "reactstrap";
//Import Breadcrumb
import { products } from "../../common/data/ecommerce";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
// import Breadcrumb from "../src/Common/Breadcrumb";
import dayjs from "dayjs";

import { API_STATUS } from "../../utils/constants";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  companySelector,
  getCompanyDetails,
} from "../../store/reducer/CompanyReducer";
import { Box, Modal, Typography } from "@mui/material";
import { debounce } from "lodash";

import {
  purchaseInvoiceList,
  purchaseSelector,
  purchaseReturnDatas,
  updatePurchaseReturnStatus,
  clearPurchaseLoadings,
  purchaseReturnList,
} from "../../store/reducer/PurchaseReducer";

const PurchaseReturn = () => {
  const dropdownValues = {
    0: {
      data: [
        { handlerName: "Proforma", label: "Set To Proforma" },
        { handlerName: "Invoice", label: "Set To Invoice" },
      ],
    },
    1: {
      data: [
        { handlerName: "Invoice", label: "Set To Invoice" },
        ,
        { handlerName: "Draft", label: "Set To Draft" },
      ],
    },
    2: {
      data: [
        // { handlerName: 'setProfoma', label: 'Set To Proforma' }, { handlerName: 'setDraft', label: 'Set To Draft' }
      ],
    },
  };
  const style = {
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 30,
    p: 4,
  };

  const infoStyle = {
    boxShadow: " 2px 4px 2px 4px #d6e6ff ",
    padding: "10px",
  };

  const WarningStyle = {
    boxShadow: " 2px 4px 2px 4px #ffd28e ",
    padding: "10px",
    marginTop: "16px",
  };

  const {
    purchaseReturnListData,
    purchaseReturnCardDatas,
    purchaseerrorMessage,
    updatePurchaseReturnStatusLoad,
  } = useSelector(purchaseSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [sortBy, setSortBy] = useState("invoice_no");
  const [openDrawer, setOpenDrawer] = useState(false);
  let [activesortBy, setActiveSortBy] = useState("invoice_no");
  const [info, setInfo] = useState({ 0: false });
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("desc");
  let [searchQuery, setSearchQuery] = useState("");
  const [state, setState] = useState({ modal_fullscreen: false });
  let { company_details } = useSelector(companySelector);
  const [accord, setAccord] = useState({
    col1: false,
    col2: false,
    col3: false,
  });

  const tog_fullscreen = () => {
    navigate("add");
  };

  const purchaseInvoiceHit = () => {
    dispatch(
      purchaseReturnList({
        query: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
      })
    );
    dispatch(purchaseReturnDatas({}));
  };

  useEffect(() => {
    purchaseInvoiceHit();
  }, [searchQuery]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
    purchaseInvoiceHit();
  };

  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchData = (e) => {
    searchQuery = e;
    setSearchQuery(e);
    setPage(0);
    // dispatch(searchInvoiceDetails({ e }));
  };
  /** Debounce For Search the Data */
  const debounceSearch = useCallback(debounce(searchData, 500), []);

  /** Sorting the Table */
  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
  };
  /** Initial Call Function */
  useEffect(() => {
    purchaseInvoiceHit();
  }, []);

  const handleDropDown = (data, type) => {
    let formData = {};
    formData.id = data.id;
    if (type == "Proforma") {
      formData.status = 1;
      dispatch(updatePurchaseReturnStatus({ formData }));
    }
    if (type == "Draft") {
      formData.status = 0;
      dispatch(updatePurchaseReturnStatus({ formData }));
    }
    if (type == "Invoice") {
      formData.status = 2;
      dispatch(updatePurchaseReturnStatus({ formData }));
    }
  };

  /** Message Updates */

  useEffect(() => {
    if (updatePurchaseReturnStatusLoad === API_STATUS.FULFILLED) {
      toastr.clear();
      toastr.success("Purchase Retrun Updated Successfully!");
      purchaseInvoiceHit();
      dispatch(clearPurchaseLoadings());
    }

    if (purchaseerrorMessage) {
      toastr.clear();
      if (purchaseerrorMessage.message)
        toastr.error(purchaseerrorMessage.message);
      else if (purchaseerrorMessage.errors) {
        let data = "";
        purchaseerrorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        toastr.error(data);
      }
      purchaseInvoiceHit();
      dispatch(clearPurchaseLoadings());
    }
  }, [purchaseerrorMessage, updatePurchaseReturnStatusLoad]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-content-between">
            <h2>Purchase Return</h2>
            <button
              type="button"
              className="waves-effect waves-light btn btn-primary mb-2"
              onClick={tog_fullscreen}
            >
              Add
            </button>
          </div>
          <Row>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Total Invoices This Year
                      </p>
                      <h4 className="mb-0">
                        {purchaseReturnCardDatas?.invoiceData &&
                          purchaseReturnCardDatas?.invoiceData[0]
                            ?.total_invoices}
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-stack-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Total Returns This Year
                      </p>
                      <h4 className="mb-0">
                        {purchaseReturnCardDatas?.returnsData &&
                          purchaseReturnCardDatas?.returnsData[0]
                            ?.total_returns}
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-funds-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Total Invoice Amount
                      </p>
                      <h4 className="mb-0">
                        {purchaseReturnCardDatas?.invoiceData &&
                          purchaseReturnCardDatas?.invoiceData[0]
                            ?.total_invoice_amount}
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-stack-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Total Return Amount
                      </p>
                      <h4 className="mb-0">
                        {purchaseReturnCardDatas?.returnsData &&
                          purchaseReturnCardDatas?.returnsData[0]
                            ?.total_return_amount}
                      </h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-funds-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-md-8">
                  <h5>
                    {" "}
                    <span>{}</span>
                  </h5>
                </div>
                <div className="col-md-4 row">
                  <input
                    type="text"
                    name="search"
                    className="form-control"
                    style={{ float: "right" }}
                    onChange={(e) => {
                      debounceSearch(e.target.value);
                    }}
                    placeholder="Search"
                  />
                </div>
              </div>
              <div className="table-responsive react-table">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} stickyHeader>
                    <TableHead className="table-light table-nowrap">
                      <TableRow>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "return_no" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("return_no");
                            }}
                          >
                            Purchase Return No
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "return_date" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("return_date");
                            }}
                          >
                            Return Date
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "vendor" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("vendor");
                            }}
                          >
                            Vendor
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "invoice_no" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("invoice_no");
                            }}
                          >
                            Invoice No
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          Return Amount
                        </StyledTableCell>
                        <StyledTableCell align="">Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {purchaseReturnListData &&
                        purchaseReturnListData?.rows?.map((row, index) => (
                          <StyledTableRow>
                            <StyledTableCell align="">
                              {row?.return_no}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {dayjs(row?.return_date).format("DD-MM-YYYY")}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row?.vendor?.vendor_name}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row?.purchase_invoice?.invoice_no}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row?.bill_amount}
                            </StyledTableCell>
                            <StyledTableCell align="" className="button-items">
                              <div className="d-flex">
                                {row.status != 2 ? (
                                  <>
                                    <Dropdown
                                      isOpen={info[index]}
                                      direction="left"
                                      toggle={() => {
                                        info[index] = !info[index];
                                        setInfo({ ...info });
                                      }}
                                    >
                                      <DropdownToggle color="danger" caret>
                                        <i class="ri-more-2-line"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        {dropdownValues[row.status].data.map(
                                          (items) => {
                                            return (
                                              <DropdownItem
                                                onClick={() =>
                                                  handleDropDown(
                                                    row,
                                                    items.handlerName
                                                  )
                                                }
                                              >
                                                {items.label}
                                              </DropdownItem>
                                            );
                                          }
                                        )}
                                      </DropdownMenu>
                                    </Dropdown>
                                  </>
                                ) : (
                                  <>
                                    {/* <div
                                        className="btn btn-success"
                                        title="View"
                                        // onClick={() => handleZatcaView(row)}
                                      >
                                        <i class="ri-eye-line"></i>
                                      </div> */}
                                    <Row className="w-100 ">
                                      <Col md="6">
                                        <div className="text-end">
                                          <h3>-</h3>
                                        </div>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50]}
                          colSpan={12}
                          count={purchaseReturnListData?.count}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};
export default PurchaseReturn;
