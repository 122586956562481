import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Table,
} from "reactstrap";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { Breadcrumb, BreadcrumbItem } from "reactstrap";
// import "../../App.css";
import {
  PaymentVoucherValidator,
  PurchaseOrderValidator,
  paymentValidator,
} from "../../utils/Validation";
import { useForm, Controller, Form } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  API_STATUS,
  payAccount,
  paymentType,
  paymentTypewithcheck,
  productType,
  requiredTaxes,
} from "../../utils/constants";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-dropdown-select";
import dayjs from "dayjs";
import {
  companySelector,
  getCompanyDetails,
  postCompanyDetails,
} from "../../store/reducer/CompanyReducer";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import {
  Paper,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { getInvoiceDetails } from "../../store/reducer/SalesInvoiceReducer";
import {
  AddPaymentVoucherSchedule,
  clearPVLoadings,
  getAccountsList,
  getGeneratedPaymentVoucherNumber,
  paymentVoucherSelector,
} from "../../store/reducer/PaymentVoucherReducer";
import { FinanceAccountsSelector } from "../../store/reducer/FinanceAccountsReducer";
import { vendorData, vendorsSelector } from "../../store/reducer/VendorReducer";
import {
  productData,
  productsSelector,
} from "../../store/reducer/ProductReducer";
import {
  settingsSelector,
  stockUnitList,
} from "../../store/reducer/SettingsReducer";
import {
  clearPurchaseLoadings,
  createPurchaseOrder,
  getOrderNo,
  purchaseSelector,
} from "../../store/reducer/PurchaseReducer";
import _ from "loadsh";

function PurchaseOrderAdd() {
  const { stockUnitListData } = useSelector(settingsSelector);  
  let [sortBy, setSortBy] = useState("created_at");
  let [activesortBy, setActiveSortBy] = useState("customer_name");
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("asc");
  const [taxType, setTaxType] = useState([]);
  const [payaccount, setPayAccount] = useState();
  const [voucherDetails, setVoucherDetails] = useState([
    {
      product_description: "",
      additional_description: "",
      qty: "",
      unit: 0,
      price: "",
      amount: "",
    },
  ]);
  console.log(voucherDetails, "voucherDetails");
  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
  };
  const taxcategories = [
    {
      id: 0,
      category: "VAT 15%",
    },
  ];
  const { accountsListDatas } = useSelector(FinanceAccountsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(PurchaseOrderValidator),
  });

  console.log(errors, "errors watcher");
  const { purchaseOrderLoad, orderNumber } = useSelector(purchaseSelector);
  const { vendorDatas } = useSelector(vendorsSelector);
  let company_details = [{ payment_type: "1" }];
  console.log(vendorDatas, "vendorDatas");
  const [total, setTotal] = useState(0);
  console.log(total, "total");
  const [isWarning, setIsWarning] = useState(false);
  const [ids, setIds] = useState([]);
  const { productDatas } = useSelector(productsSelector);

  useEffect(() => {
    dispatch(
      productData({
        query: "",
        page: parseInt(page + 1),
        limit: parseInt(rowsPerPage),
        sortby: "",
        order: "",
      })
    );
    // dispatch(getAccountsList({}));
    dispatch(
      vendorData({
        query: "",
        page: page + 1,
        limit: rowsPerPage,
        id: "",
      })
    );
    dispatch(getOrderNo({}));
    dispatch(stockUnitList({ page: 0, limit: 0, query: "" }));
    setTaxType([requiredTaxes && requiredTaxes[1]]);
    setValue("vat_cat", requiredTaxes && requiredTaxes[1].name);
  }, []);

  // Calculation part
  useEffect(() => {
    const date = new Date(getValues("date"));
    const eta = new Date(getValues("eta"));
    let Difference_In_Time = eta.getTime() - date.getTime();
    let Difference_In_Days = Math.round(
      Difference_In_Time / (1000 * 3600 * 24)
    );
    if (Difference_In_Days < 0) {
      toastr.clear();
      setValue("eta", []);
      toastr.error("Please choose the valid Date!");
      setValue("due_days", 0);
    } else
      setValue("due_days", isNaN(Difference_In_Days) ? 0 : Difference_In_Days);
    const discount = getValues("discount_amount");
    let net = parseFloat(total);
    const vattype = getValues("vat_cat");
    if (discount !== "") net -= parseFloat(discount);
    let vatPercent = vattype == "VAT 15%" ? 15 : 0;
    let vatAmount = (parseFloat(total) * vatPercent) / 100;
    setValue("vat_amount", vatAmount);
    net += vatAmount;
    const round = getValues("round_off");
    if (round !== "") net += parseFloat(round);
    setValue("net_amount", net);
  }, [
    watch("discount_amount"),
    total,
    watch("round_off"),
    watch("date"),
    watch("eta"),
    watch("vat_cat"),
    watch("product_type"),
  ]);

  const onSubmitHandler = (formData) => {
    let details = voucherDetails.map((ele) => {
      return {
        product_description: ele.product_description?.id,
        additional_description: ele.additional_description,
        qty: ele.qty,
        amount: ele.amount,
        unit: ele.unit,
        price: ele.price,
      };
    });

    // Remove unwanted payload keys here

    // const removeKey = ["country", "unit"];
    // const totalKey = Object.keys(formData);
    // totalKey.forEach((ele) => {
    //   if (removeKey.includes(ele)) {
    //     delete formData[ele];
    //   }
    // });

    formData.details = [details[0]];
    dispatch(createPurchaseOrder({ formData }));
  };

  useEffect(() => {
    let sum = _.sumBy(voucherDetails, function (o) {
      return o.amount;
    });
    setValue("total_amount", total);
    if (sum !== "") setTotal(sum);
  }, [voucherDetails]);

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
  };

  const handleChange = (event) => {
    setValue("vendor_id", event[0]?.id);
  };

  useEffect(() => {
    setValue("quotation_no", orderNumber);
  }, [orderNumber]);

  useEffect(() => {
    if (purchaseOrderLoad === API_STATUS.FULFILLED) {
      toastr.clear();
      toastr.success("Added Successfully");
      reset();
      dispatch(clearPurchaseLoadings());
      navigate(-1);
    }

    return () => {
      toastr.clear();
    };
  }, [purchaseOrderLoad]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="row">
            <div className="col-md-6">
              <h4>
                <Breadcrumb>
                  <BreadcrumbItem>Purchase Order / New</BreadcrumbItem>
                </Breadcrumb>
              </h4>
            </div>
            <div className="col-md-6 text-end">
              <h4>Bill Amount : {getValues("net_amount")}</h4>
            </div>
          </div>
          <Row>
            <Col xs={12}>
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Card>
                  <CardBody>
                    <div>
                      <Row>
                        <Col md="3">
                          <div className="input-container mb-0">
                            <input
                              placeholder=" "
                              type="text"
                              disabled
                              id="quotation_no"
                              name="quotation_no"
                              {...register("quotation_no")}
                              // onChange={handleAmountValidation}
                            />
                            <label for="quotation_no">Quotation No.</label>
                          </div>
                          {errors?.quotation_no?.message && (
                            <p className="error">
                              {errors.quotation_no.message}
                            </p>
                          )}
                          <span></span>
                        </Col>
                        <Col md="3">
                          <div className="input-container">
                            <Controller
                              name="vendor_id"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className="js-states select-control"
                                  placeholder={"Vendor"}
                                  options={vendorDatas}
                                  {...field}
                                  disabled={false}
                                  dropdownHandle={true}
                                  // values={settingTz}
                                  labelField="vendor_name"
                                  searchable={true}
                                  valueField={"id"}
                                  name="vendor_id"
                                  required
                                  onChange={handleChange}
                                />
                              )}
                              {...register("vendor_id")}
                            />
                          </div>
                          {errors?.vendor_id?.message && (
                            <p className="error">{errors.vendor_id.message}</p>
                          )}
                        </Col>
                        <Col md="3">
                          <div className="input-container">
                            <input
                              placeholder=" "
                              type="date"
                              defaultValue={dayjs().format("YYYY-MM-DD")}
                              id="date"
                              name="date"
                              {...register("date")}
                            />
                            <label for="date">Date</label>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="input-container">
                            <input
                              placeholder=" "
                              type="date"
                              defaultValue={dayjs().format("YYYY-MM-DD")}
                              id="eta"
                              name="eta"
                              {...register("eta")}
                            />
                            <label for="eta">ETA</label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="3">
                          <div className="input-container">
                            <Controller
                              name="product_type"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className="js-states select-control"
                                  placeholder={"Product Type"}
                                  options={productType}
                                  {...field}
                                  disabled={false}
                                  dropdownHandle={true}
                                  // values={settingTz}
                                  labelField="type"
                                  searchable={true}
                                  valueField={"id"}
                                  name="product_type"
                                  onChange={(e) => {
                                    setValue("product_type", e[0]?.type);
                                  }}
                                />
                              )}
                              {...register("product_type")}
                            />
                          </div>
                          {errors?.product_type?.message && (
                            <p className="error">
                              {errors.product_type.message}
                            </p>
                          )}
                        </Col>
                        <Col md="3">
                          <div className="input-container">
                            <input
                              placeholder=" "
                              type="number"
                              id="due_days"
                              disabled
                              name="due_days"
                              {...register("due_days")}
                            />
                            <label for="due_days">Due Days</label>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="input-container">
                            <input
                              placeholder=" "
                              type="date"
                              defaultValue={dayjs().format("YYYY-MM-DD")}
                              id="quotation_date"
                              name="quotation_date"
                              {...register("quotation_date")}
                            />
                            <label for="quotation_date">Quotation Date</label>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div className="table-responsive react-table">
                      <TableContainer
                        component={Paper}
                        style={{ minHeight: "300px" }}
                      >
                        <Table sx={{ minWidth: 700 }} stickyHeader>
                          <TableHead className="table-light table-nowrap">
                            <TableRow>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={
                                    sortBy == "payment_type" ? true : false
                                  }
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("payment_type");
                                  }}
                                >
                                  Product Description
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={
                                    sortBy == "additional_description" ? true : false
                                  }
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("additional_description");
                                  }}
                                >
                                  Additional Description
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={sortBy == "invoice_no" ? true : false}
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("invoice_no");
                                  }}
                                >
                                  Qty
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell width={150} align="">
                                <TableSortLabel
                                  active={sortBy == "date" ? true : false}
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("date");
                                  }}
                                >
                                  Unit
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell width={300} align="">
                                <TableSortLabel
                                  active={
                                    sortBy == "description" ? true : false
                                  }
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("description");
                                  }}
                                >
                                  Price
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <TableSortLabel
                                  active={
                                    sortBy == "project_name" ? true : false
                                  }
                                  direction={order}
                                  className="table-header-column-name"
                                  onClick={(e) => {
                                    sortByKey("project_name");
                                  }}
                                >
                                  Amount
                                </TableSortLabel>
                              </StyledTableCell>
                              <StyledTableCell align="">Action</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {voucherDetails?.map((row, index) => (
                              <StyledTableRow>
                                <StyledTableCell align="">
                                  <Select
                                    className="js-states select-control"
                                    options={productDatas}
                                    value={
                                      voucherDetails[index]
                                        .product_description ?? ""
                                    }
                                    // {...field}
                                    disabled={false}
                                    dropdownHandle={true}
                                    searchable={true}
                                    labelField={"description"}
                                    valueField={"id"}
                                    name="country"
                                    onChange={(value) => {
                                      let data = voucherDetails;
                                      data[index].product_description = value;
                                      data[index].unit = 1;
                                      data[index].price =
                                        _.find(productDatas, {
                                          id: value[0]?.id,
                                        })?.selling_price ?? 0;
                                      setVoucherDetails([
                                        ...data,
                                        {
                                          additional_description: "",
                                          qty: "",
                                          unit: 0,
                                          price: "",
                                          amount: "",
                                        },
                                      ]);
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  <div class="input-container w-100 m-1">
                                    <input
                                      placeholder=" "
                                      type="text"
                                      value={row?.additional_description}
                                      id={`narration${index}`}
                                      name={`narration${index}`}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        let data = voucherDetails;
                                        data[index].additional_description =
                                          value;
                                        setVoucherDetails([...data]);
                                      }}
                                    />
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  <div class="input-container w-100 m-1">
                                    <input
                                      placeholder=" "
                                      type="number"
                                      value={row?.qty}
                                      id={`qty${index}`}
                                      name={`qty${index}`}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        let data = voucherDetails;
                                        data[index].qty = value;
                                        if (data[index].price) {
                                          data[index].amount =
                                            parseFloat(value) *
                                            parseFloat(data[index].price);
                                        }
                                        setVoucherDetails([...data]);
                                      }}
                                    />
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  <Select
                                    className="js-states select-control"
                                    options={stockUnitListData}
                                    // {...field}
                                    dropdownHandle={true}
                                    searchable={true}
                                    labelField={"unit_name"}
                                    values={
                                      stockUnitListData?.filter(
                                        (ele) =>
                                          ele.id == voucherDetails[index].unit
                                      ) ?? []
                                    }
                                    valueField={"id"}
                                    name="unit"
                                    onChange={(value) => {
                                      let data = voucherDetails;
                                      data[index].unit = Number(value[0]?.id);
                                      setVoucherDetails([...data]);
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  <div class="input-container w-100 m-1">
                                    <input
                                      placeholder=" "
                                      type="number"
                                      value={row?.price}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        let data = voucherDetails;
                                        data[index].price = value;
                                        if (data[index].qty)
                                          data[index].amount =
                                            parseFloat(data[index].qty) *
                                            parseFloat(value);
                                        setVoucherDetails([...data]);
                                      }}
                                    />
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell
                                  align=""
                                  className="button-items"
                                >
                                  <div class="input-container w-100 m-1">
                                    <input
                                      placeholder=" "
                                      type="text"
                                      disabled
                                      id={`qty${index}`}
                                      name={`qty${index}`}
                                      value={row.amount}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        let data = voucherDetails;
                                        data[index].amount = value;
                                        setVoucherDetails([...data]);
                                      }}
                                    />
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell
                                  align=""
                                  className="button-items"
                                >
                                  <a
                                    href="javascript:void(0);"
                                    title="Delete"
                                    className="btn btn-danger delete-btn"
                                    onClick={() => {
                                      if (voucherDetails.length > 1) {
                                        const voucherDetail = _.filter(
                                          voucherDetails,
                                          (key, i) => i != index
                                        );
                                        console.log(
                                          voucherDetail,
                                          "check voucherDetails"
                                        );
                                        setVoucherDetails(voucherDetail);
                                      }
                                      // To clear the values of the one and only row

                                      //  else {
                                      //   setVoucherDetails([
                                      //     {
                                      //       product_description: "",
                                      //       qty: "",
                                      //       amount: 0,
                                      //       vat: "",
                                      //       vat_amount: "",
                                      //       total_amount: 0,
                                      //       id: null,
                                      //     },
                                      //   ]);
                                      // }
                                    }}
                                  >
                                    <i className="ri-delete-bin-line"></i>
                                  </a>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody className="d-flex justify-content-between">
                    <div className="invoice-width">
                      <div class="input-container w-100">
                        <textarea
                          id="net_amount"
                          rows={3}
                          // value={notes}
                          name="notes"
                          // onChange={(e) => setNotes(e.target.value)}
                          {...register("notes")}
                        />
                        <label for="notes">Notes</label>
                      </div>
                    </div>
                    <div className="invoice-width align-self-end">
                      <div class="input-container w-100">
                        <input
                          id="total_amount"
                          placeholder=" "
                          value={total}
                          disabled
                          name="total_amount"
                          // onChange={(e) => setNotes(e.target.value)}
                          {...register("total_amount")}
                        />
                        <label for="total_amount">Total Amount</label>
                      </div>
                      <div class="input-container w-100">
                        <input
                          id="discount_amount"
                          placeholder=" "
                          type="number"
                          disabled={false}
                          name="discount_amount"
                          // onChange={(e) => {
                          //   const {value} = e.target;
                          //   console.log(
                          //     value,
                          //     "discount_amount"
                          //   );
                          // }}
                          {...register("discount_amount")}
                        />
                        <label for="discount_amount">Discount</label>
                      </div>
                      <div className="input-container w-100">
                        <Controller
                          name="vat_cat"
                          control={control}
                          render={({ field }) => (
                            <Select
                              className="js-states select-control"
                              options={requiredTaxes}
                              {...field}
                              dropdownHandle={true}
                              searchable={true}
                              values={taxType}
                              labelField={"name"}
                              valueField={"name"}
                              name="vat_cat"
                              onChange={(e) => {
                                setValue("vat_cat", e[0]?.name);
                              }}
                            />
                          )}
                          {...register("vat_cat")}
                        />
                      </div>
                      <div class="input-container w-100">
                        <input
                          id="vat_amount"
                          placeholder=" "
                          // value={total}
                          disabled
                          name="vat_amount"
                          // onChange={(e) => setNotes(e.target.value)}
                          {...register("vat_amount")}
                        />
                        <label for="vat_amount">VAT Amount</label>
                      </div>
                      <div class="input-container w-100">
                        <input
                          id="round_off"
                          placeholder=" "
                          disabled={false}
                          name="round_off"
                          {...register("round_off")}
                        />
                        <label for="round_off">Round Off</label>
                      </div>
                      <div class="input-container w-100">
                        <input
                          id="net_amount"
                          placeholder=" "
                          disabled
                          name="net_amount"
                          {...register("net_amount")}
                        />
                        <label for="net_amount">Net Amount</label>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="text-end">
                  <Button color="primary" type="submit">
                    Save
                  </Button>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default PurchaseOrderAdd;
