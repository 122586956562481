import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toastr from "toastr";
import {
  dashboardCards,
  dashboardChart,
  dashboardInvoices,
  reportinvoiceList,
  salesReportDownload
} from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from "./../../utils/encrypt&decrypt";
import { secretKey } from "../../services/config";
import alertmessage from "./../../utils/alert";
const namespace = 'dashboard'

const initialState = {
  data: null,
  status: 'idle',
  error: null,
  cardDatas: null,
  dashboardinvoiceDatas: null,
  paymentinvoiceDatas:null,
  draftinvoiceDatas:null,
  salesinvoiceCount: null,
  reportinvoiceDatas: null,
  reportinvoiceCount: null,
  dashboardChartDatas: null,
  generateLoading: false,
  excelMessage: "",
  productDatas: null
};

export const productData = createAsyncThunk(
  `${namespace}/productData`,
  async (
    { rejectWithValue, dispatch }
  ) => {
    try {
      console.log("interdata123");
      //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
      const data = await dashboardCards();
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);


export const dashboardinvoiceData = createAsyncThunk(
  `${namespace}/dashboardinvoiceData`,
  async (
    { query = "", page = 0, limit = 0, sortby = "created_at", order = "asc" },
    { rejectWithValue, dispatch }
  ) => {
    try {
      console.log("dashboardInvoices");
      //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
      const data = await dashboardInvoices(query, page, limit, sortby, order);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const dashboardChartData = createAsyncThunk(
  `${namespace}/dashboardChartData`,
  async ({ rejectWithValue, dispatch }) => {
    try {
      console.log("dashboardChart");
      //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
      const data = await dashboardChart();
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const reportinvoiceData = createAsyncThunk(
  `${namespace}/reportinvoiceData`,
  async (
    { query = "", from_date = "", to_date = "", page = 0, limit = 0, sortby = "description", order = "asc" },
    { rejectWithValue, dispatch }
  ) => {
    try {
      //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
      const data = await reportinvoiceList(query, from_date, to_date, page, limit, sortby, order);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);


export const reportDownload = createAsyncThunk(
  `${namespace}/reportDownload`,
  async ({ query = "", from_date = "", to_date = ""},
    { rejectWithValue, dispatch }) => {
    try {
      //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
      const data = await salesReportDownload(query, from_date, to_date);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

const dashboardSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
    clearProdLoadingDatas: (state) => {
      state.loading = "initial";
      state.addloading = "initial";
      state.deleteloading = "initial";
      state.updateloading = "initial";
    },
    clearProdUpdateLoading: (state) => {
      state.updateloading = "initial";
    },
    clearProdDeleteLoading: (state) => {
      state.deleteloading = "initial";
    },
    clearErrormsg: (state) => {
      state.errorMessage = null;
    },
    clearExcelData: (state) => {
      state.excelMessage = "";
      state.generateLoading = false;
    },
  },
  extraReducers: (builder) => {
    console.log(builder, 'payload');
    builder.addCase(productData.pending, (state) => {
      state.loading = API_STATUS.PENDING;
    });
    builder.addCase(productData.fulfilled, (state, { payload }) => {
      state.loading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );
      console.log(payloaddatas, 'payloaddatas');
      state.cardDatas = payloaddatas;
    });
    builder.addCase(productData.rejected, (state, action) => {
      state.loading = API_STATUS.REJECTED;
      if (action?.payload?.data?.auth === "deleted") {
        alertmessage.sweetalert(action?.payload?.data?.message);
      }
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(dashboardinvoiceData.pending, (state) => {
      state.loading = API_STATUS.PENDING;
    });
    builder.addCase(dashboardinvoiceData.fulfilled, (state, { payload }) => {
      console.log(payload, "payload");
      state.loading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );
      state.salesinvoiceCount = payloaddatas?.count;
      state.dashboardinvoiceDatas = payloaddatas?.data;
      state.paymentinvoiceDatas = payloaddatas?.overdueInvoices;
      state.draftinvoiceDatas = payloaddatas?.draftInvoices;
    });
    builder.addCase(dashboardinvoiceData.rejected, (state, action) => {
      state.loading = API_STATUS.REJECTED;
      if (action?.payload?.data?.auth === "deleted") {
        alertmessage.sweetalert(action?.payload?.data?.message);
      }
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(dashboardChartData.pending, (state) => {
      state.loading = API_STATUS.PENDING;
    });
    builder.addCase(dashboardChartData.fulfilled, (state, { payload }) => {
      console.log(payload, "dashboardChartDataPay");
      state.loading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );
      console.log(payloaddatas, "payloaddatasEn");
      state.dashboardChartDatas = payloaddatas;
    });
    builder.addCase(dashboardChartData.rejected, (state, action) => {
      state.loading = API_STATUS.REJECTED;
      if (action?.payload?.data?.auth === "deleted") {
        alertmessage.sweetalert(action?.payload?.data?.message);
      }
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(reportinvoiceData.pending, (state) => {
      state.loading = API_STATUS.PENDING;
    });
    builder.addCase(reportinvoiceData.fulfilled, (state, { payload }) => {
      console.log(payload, "payload");
      state.loading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );
      state.reportinvoiceCount = payloaddatas?.count;
      state.reportinvoiceDatas = payloaddatas?.data;
    });
    builder.addCase(reportinvoiceData.rejected, (state, action) => {
      state.loading = API_STATUS.REJECTED;
      if (action?.payload?.data?.auth === "deleted") {
        alertmessage.sweetalert(action?.payload?.data?.message);
      }
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(reportDownload.pending, (state) => {
      state.generateLoading = API_STATUS.PENDING;
    });
    builder.addCase(reportDownload.fulfilled, (state, { payload }) => {
      console.log(payload, "reportDownload");      
      state.generateLoading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );
      console.log(payloaddatas,"REportpayloaddatas");
      state.excelMessage = payloaddatas?.path;
      toastr.success("Report Excel Downloaded");
    });
    builder.addCase(reportDownload.rejected, (state, action) => {
      state.generateLoading = API_STATUS.REJECTED;
      if (action?.payload?.data?.auth === "deleted") {
        alertmessage.sweetalert(action?.payload?.data?.message);
      }
      state.errorMessage = action?.payload?.data;
    });
  },
});

export const {
  clearData,
  clearProdUpdateLoading,
  clearProdLoadingDatas,
  clearProdDeleteLoading,
  clearErrormsg,
  clearExcelData,
} = dashboardSlice.actions;

export const dashboardSelector = (state) => state.dashboard;

export default dashboardSlice.reducer;
