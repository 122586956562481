import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/constants";
import {
  accounts_list,
  create_payment_voucher,
  generatepaymentvoucher,
  payment_voucher_list,
  generatereceiptvoucher,
  receiptvoucher_list,
  create_receipt_voucher,
  receiptData,
  journal_voucher_list,
  journal_card_data,
  updatePaymentStatus,
  getpvstat,
  generatejournalvoucher,
  create_journal_voucher,
  journalvoucherUpdate,
  receiptvoucherUpdate,
} from "../../services/api";
import EncryptDecrypt, { decryptdata } from "./../../utils/encrypt&decrypt";
import { secretKey } from "../../services/config";

const namespace = "paymentVoucher";

const initialState = {
  errorMessage: "",
  AddPaymentVoucherLoad: "initial",
  ListPaymentVoucher: "initial",
  ListReceiptVoucherLoader: "initial",
  accountsListLoader: "initial",
  generatedPVLoad: "initial",
  generatedRVLoad: "initial",
  updateStatusLoad: "initial",
  createReceiptVoucherLoad: "initial",
  getStatLoad: "initial",
  createJournalVoucherLoad: "initial",
  generatedJVLoad: "initial",
  updateJournalStatusVoucherLoad: "initial",
  updateReceiptStatusLoad: "initial",
  paymentVoucherListData: [],
  receiptVoucherListData: [],
  journalVoucherListData: [],
  statData: {},
  accountAllData: [],
  receiptCardDatas: [],
  journalCardDatas: [],
  generatedPVno: 0,
  generatedRVno: 0,
  generatedJVno: 0,
};

export const AddPaymentVoucherSchedule = createAsyncThunk(
  `${namespace}/AddPaymentVoucherSchedule`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "AddPaymentVoucherSchedule formData");
      const { data } = await create_payment_voucher(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const ListPaymentVoucher = createAsyncThunk(
  `${namespace}/ListPaymentVoucher`,
  async ({ query, page, limit }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await payment_voucher_list(query, page, limit);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getGeneratedPaymentVoucherNumber = createAsyncThunk(
  `${namespace}/getGeneratedPaymentVoucherNumber`,
  async ({ query, page, limit }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await generatepaymentvoucher(query, page, limit);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getAccountsList = createAsyncThunk(
  `${namespace}/getAccountsList`,
  async (
    { query = "", page = 1, limit = 0, group_id = 0, sortby = "", order = "" },
    { rejectWithValue, dispatch }
  ) => {
    try {
      console.log(page, "accounts_list1");
      const { data } = await accounts_list(
        query,
        page,
        limit,
        sortby,
        order,
        group_id
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createReceiptVoucher = createAsyncThunk(
  `${namespace}/createReceiptVoucher`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "createReceiptVoucher formData");
      const { data } = await create_receipt_voucher(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getGeneratedReceiptVoucherNumber = createAsyncThunk(
  `${namespace}/getGeneratedReceiptVoucherNumber`,
  async ({ query, page, limit }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await generatereceiptvoucher(query, page, limit);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const ListReceiptVoucher = createAsyncThunk(
  `${namespace}/ListReceiptVoucher`,
  async ({ query, page, limit }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await receiptvoucher_list(query, page, limit);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const receiptCards = createAsyncThunk(
  `${namespace}/receiptCards`,
  async ({}, { rejectWithValue, dispatch }) => {
    try {
      const data = await receiptData();
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const updateStatus = createAsyncThunk(
  `${namespace}/updateStatus`,
  async ({ id, type }, { rejectWithValue, dispatch }) => {
    try {
      const data = await updatePaymentStatus(id, type);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getStats = createAsyncThunk(
  `${namespace}/getStats`,
  async ({}, { rejectWithValue, dispatch }) => {
    try {
      const data = await getpvstat();
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const ListJournalVoucher = createAsyncThunk(
  `${namespace}/ListJournalVoucher`,
  async ({ query, page, limit }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await journal_voucher_list(query, page, limit);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const journalCards = createAsyncThunk(
  `${namespace}/journalCards`,
  async ({}, { rejectWithValue, dispatch }) => {
    try {
      const data = await journal_card_data();
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);
export const getGeneratedJournalVoucherNumber = createAsyncThunk(
  `${namespace}/getGeneratedJournalVoucherNumber`,
  async ({ query, page, limit }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await generatejournalvoucher(query, page, limit);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createJournalVoucher = createAsyncThunk(
  `${namespace}/createJournalVoucher`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "createJournalVoucher formData");
      const { data } = await create_journal_voucher(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateJournalStatus = createAsyncThunk(
  `${namespace}/updateJournalStatus`,
  async ({ id, status }, { rejectWithValue, dispatch }) => {
    try {
      const data = await journalvoucherUpdate(id, status);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateReceiptStatus = createAsyncThunk(
  `${namespace}/updateReceiptStatus`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const data = await receiptvoucherUpdate(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const paymentVoucherSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearPVData: () => {
      return initialState;
    },
    clearPVLoadings: (state) => {
      state.AddPaymentVoucherLoad = "initial";
      state.ListPaymentVoucher = "initial";
      state.generatedPVLoad = "initial";
      state.generatedRVLoad = "initial";
      state.accountsListLoader = "initial";
      state.createReceiptVoucherLoad = "initial";
      state.ListReceiptVoucherLoader = "initial";
      state.updateStatusLoad = "initial";
      state.getStatLoad = "initial";
      state.generatedJVLoad = "initial";
      state.createJournalVoucherLoad = "initial";
      state.updateJournalStatusVoucherLoad = "initial";
      state.updateReceiptStatusLoad = "initial";
    },
    logOut: () => {
      localStorage.clear();
      window.location.reload(true);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AddPaymentVoucherSchedule.pending, (state) => {
      state.AddPaymentVoucherLoad = API_STATUS.PENDING;
    });
    builder.addCase(
      AddPaymentVoucherSchedule.fulfilled,
      (state, { payload }) => {
        state.AddPaymentVoucherLoad = API_STATUS.FULFILLED;
      }
    );
    builder.addCase(AddPaymentVoucherSchedule.rejected, (state, action) => {
      state.AddPaymentVoucherLoad = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(getAccountsList.pending, (state) => {
      state.accountsListLoader = API_STATUS.PENDING;
    });
    builder.addCase(getAccountsList.fulfilled, (state, { payload }) => {
      state.accountsListLoader = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.datas, secretKey)
      );
      state.accountAllData = payloaddatas;
    });
    builder.addCase(getAccountsList.rejected, (state, action) => {
      state.accountsListLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(ListPaymentVoucher.pending, (state) => {
      state.ListPaymentVoucher = API_STATUS.PENDING;
    });
    builder.addCase(ListPaymentVoucher.fulfilled, (state, { payload }) => {
      state.ListPaymentVoucher = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.datas, secretKey)
      );
      state.paymentVoucherListData = payloaddatas;
    });
    builder.addCase(ListPaymentVoucher.rejected, (state, action) => {
      state.ListPaymentVoucher = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });

    builder.addCase(getGeneratedPaymentVoucherNumber.pending, (state) => {
      state.generatedPVLoad = API_STATUS.PENDING;
    });
    builder.addCase(
      getGeneratedPaymentVoucherNumber.fulfilled,
      (state, { payload }) => {
        state.generatedPVLoad = API_STATUS.FULFILLED;
        state.generatedPVno = payload;
      }
    );
    builder.addCase(
      getGeneratedPaymentVoucherNumber.rejected,
      (state, action) => {
        state.generatedPVLoad = API_STATUS.REJECTED;
        state.errorMessage = action?.payload?.data;
      }
    );
    builder.addCase(createReceiptVoucher.pending, (state) => {
      state.createReceiptVoucherLoad = API_STATUS.PENDING;
    });
    builder.addCase(createReceiptVoucher.fulfilled, (state, { payload }) => {
      state.createReceiptVoucherLoad = API_STATUS.FULFILLED;
    });
    builder.addCase(createReceiptVoucher.rejected, (state, action) => {
      state.createReceiptVoucherLoad = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(getStats.pending, (state) => {
      state.getStatLoad = API_STATUS.PENDING;
    });
    builder.addCase(getStats.fulfilled, (state, { payload }) => {
      state.getStatLoad = API_STATUS.FULFILLED;
      state.statData = payload.data;
      console.log(payload, "sjohsduofhsuihuifsuifgfi");
    });
    builder.addCase(getStats.rejected, (state, action) => {
      state.getStatLoad = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });

    builder.addCase(updateStatus.pending, (state) => {
      state.updateStatusLoad = API_STATUS.PENDING;
    });
    builder.addCase(updateStatus.fulfilled, (state, { payload }) => {
      state.updateStatusLoad = API_STATUS.FULFILLED;
    });
    builder.addCase(updateStatus.rejected, (state, action) => {
      state.updateStatusLoad = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(getGeneratedReceiptVoucherNumber.pending, (state) => {
      state.generatedRVLoad = API_STATUS.PENDING;
    });
    builder.addCase(
      getGeneratedReceiptVoucherNumber.fulfilled,
      (state, { payload }) => {
        state.generatedRVLoad = API_STATUS.FULFILLED;
        state.generatedRVno = payload;
      }
    );
    builder.addCase(
      getGeneratedReceiptVoucherNumber.rejected,
      (state, action) => {
        state.generatedRVLoad = API_STATUS.REJECTED;
        state.errorMessage = action?.payload?.data;
      }
    );
    builder.addCase(ListReceiptVoucher.pending, (state) => {
      state.ListReceiptVoucherLoader = API_STATUS.PENDING;
    });
    builder.addCase(ListReceiptVoucher.fulfilled, (state, { payload }) => {
      state.ListReceiptVoucherLoader = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.datas, secretKey)
      );
      state.receiptVoucherListData = payloaddatas;
    });
    builder.addCase(ListReceiptVoucher.rejected, (state, action) => {
      state.ListReceiptVoucherLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });

    builder.addCase(receiptCards.pending, (state) => {
      state.ReceiptCardLoader = API_STATUS.PENDING;
    });
    builder.addCase(receiptCards.fulfilled, (state, { payload }) => {
      state.ReceiptCardLoader = API_STATUS.FULFILLED;
      console.log(payload, "receiptCards");
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );
      console.log(payloaddatas, "PAYLOADCAARD");
      state.receiptCardDatas = payloaddatas;
    });
    builder.addCase(receiptCards.rejected, (state, action) => {
      state.ReceiptCardLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(ListJournalVoucher.pending, (state) => {
      state.ListJournalVoucherLoader = API_STATUS.PENDING;
    });
    builder.addCase(ListJournalVoucher.fulfilled, (state, { payload }) => {
      state.ListJournalVoucherLoader = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.datas, secretKey)
      );
      console.log(payloaddatas, "PAYLOASDFASDF");
      state.journalVoucherListData = payloaddatas;
    });
    builder.addCase(ListJournalVoucher.rejected, (state, action) => {
      state.ListJournalVoucherLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(journalCards.pending, (state) => {
      state.JournalCardLoader = API_STATUS.PENDING;
    });
    builder.addCase(journalCards.fulfilled, (state, { payload }) => {
      state.JournalCardLoader = API_STATUS.FULFILLED;
      console.log(payload, "journalCards");
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );
      state.journalCardDatas = payloaddatas;
    });
    builder.addCase(journalCards.rejected, (state, action) => {
      state.JournalCardLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });

    builder.addCase(getGeneratedJournalVoucherNumber.pending, (state) => {
      state.generatedJVLoad = API_STATUS.PENDING;
    });
    builder.addCase(
      getGeneratedJournalVoucherNumber.fulfilled,
      (state, { payload }) => {
        state.generatedJVLoad = API_STATUS.FULFILLED;
        state.generatedJVno = payload;
      }
    );
    builder.addCase(
      getGeneratedJournalVoucherNumber.rejected,
      (state, action) => {
        state.generatedJVLoad = API_STATUS.REJECTED;
        state.errorMessage = action?.payload?.data;
      }
    );
    builder.addCase(createJournalVoucher.pending, (state) => {
      state.createJournalVoucherLoad = API_STATUS.PENDING;
    });
    builder.addCase(createJournalVoucher.fulfilled, (state, { payload }) => {
      state.createJournalVoucherLoad = API_STATUS.FULFILLED;
    });
    builder.addCase(createJournalVoucher.rejected, (state, action) => {
      state.createJournalVoucherLoad = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(updateJournalStatus.pending, (state) => {
      state.updateJournalStatusVoucherLoad = API_STATUS.PENDING;
    });
    builder.addCase(updateJournalStatus.fulfilled, (state, { payload }) => {
      state.updateJournalStatusVoucherLoad = API_STATUS.FULFILLED;
    });
    builder.addCase(updateJournalStatus.rejected, (state, action) => {
      state.updateJournalStatusVoucherLoad = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(updateReceiptStatus.pending, (state) => {
      state.updateReceiptStatusLoad = API_STATUS.PENDING;
    });
    builder.addCase(updateReceiptStatus.fulfilled, (state, { payload }) => {
      state.updateReceiptStatusLoad = API_STATUS.FULFILLED;
    });
    builder.addCase(updateReceiptStatus.rejected, (state, action) => {
      state.updateReceiptStatusLoad = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
  },
});

export const { clearPVData, clearPVLoadings } = paymentVoucherSlice.actions;

export const paymentVoucherSelector = (state) => state.paymentVoucher;

export default paymentVoucherSlice.reducer;
