import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";

import { useForm, Controller } from "react-hook-form";
import Select from "react-dropdown-select";
import { useNavigate, Link } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Swal from "sweetalert2";

import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  CardHeader,
  Collapse,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import Breadcrumb from "../Common/Breadcrumb";
import dayjs from "dayjs";
import { userValidationSchema } from "../../utils/Validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { filter } from "lodash";
import withReactContent from "sweetalert2-react-content";
import {
  userData,
  UpdateUser,
  DeleteUser,
  AddUser,
  EditUser,
  clearData,
  usersSelector,
  clearUserUpdateLoading,
  clearUserAddLoading,
  clearUserLoadingDatas,
  clearUserDeleteLoading,
  clearErrormsg,
  countryList,
  ScreenUserData,
  getUserSelectScreen,
} from "../../store/reducer/UserReducer";
import { API_STATUS } from "../../utils/constants";

const UserList = () => {
  const dispatch = useDispatch();
  let [sortBy, setSortBy] = useState("name");
  let [activesortBy, setActiveSortBy] = useState("name");
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("asc");
  let [searchQuery, setSearchQuery] = useState("");
  const [selectedActions, setSelectedActions] = useState({});
  const [state, setState] = useState({ modal_fullscreen: false });
  const [edit_state, setEditState] = useState({ edit_modal_fullscreen: false });
  const MySwal = withReactContent(Swal);
  const userId = localStorage.getItem("id");
  const roleID = localStorage.getItem("Role");
  console.log(roleID, "56789")
  const {
    userDatas,
    userCount,
    loading,
    addloading,
    user_details,
    deleteloading,
    updateloading,
    errorMessage,
    countries,
    currencies,
    screenData,
    selectScreenData,
  } = useSelector(usersSelector);
console.log(updateloading, "thiru")
  const [accord, setAccord] = useState({
    col1: false,
    col2: false,
    col3: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(userValidationSchema),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    control: control2,
    reset: reset2,
  } = useForm({
    resolver: yupResolver(userValidationSchema),
  });

  // const countries = [
  //   { id: "1", name: "Saudi Arabia" },
  //   { id: "2", name: "Qatar" },
  // ]

  const roles = [
    { id: 1, name: "Admin" },
    // { id: 3, name: "Customer" },
  ];

  const _ = require("lodash");
  const SelectedCountry = (countrId) => {
    return _.filter(countries, ["id", countrId]);
  };
  const SelectedRole = (roleId) => {
    return _.filter(roles, ["id", roleId]);
  };

  const [selectedCountryVal, setCountry] = useState([]);
  const [selectedRoleVal, setRole] = useState([]);
  const [userActions, setUserActions] = useState({});
  const handleChange = (event) => {
    setCountry(event);
  };

  const handleRoleChange = (event) => {
    setRole(event);
  };
  console.log(user_details, "user_details");
  useEffect(() => {
    const data = countries?.filter(
      (country) => country.id == user_details?.user?.country
    );
    setCountry(data ?? []);
    setRole(SelectedRole(user_details?.user?.role_id));
  }, [user_details?.user]);

  const tog_fullscreen = () => {
    setState((prevState) => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }));
    removeBodyCss();
  };

  const edit_tog_fullscreen = (row) => {
    setEditState((prevState) => ({
      edit_modal_fullscreen: !prevState.edit_modal_fullscreen,
    }));
    const userID = row.id;
    if (userID !== undefined) {
      dispatch(EditUser({ userID }));
      removeBodyCss();
    }
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const [userDetails, setUserDetails] = useState({
    id: 1,
    role_id: "",
    name: "",
    mobile: "",
    email: "",
    password: "",
    country: null,
    // profile_url: null,
  });
  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
    userDispatch();
  };
  const handleInput = (e, dest, type) => {
    const { value } = e.target;
    console.log(value);
    userDetails[dest] = type !== "" ? Number(value) : value;
    setUserDetails({
      ...userDetails,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    userDispatch();

  };
  const searchData = (e) => {
    searchQuery = e.target.value;
    setSearchQuery(e.target.value);
    setPage(0);
    userDispatch();
  };

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
    userDispatch();
  };

  const handleCheckboxChange = (screenName, action) => {
    const key = `${screenName}-${action}`;
    setSelectedActions((prevSelectedActions) => {
      const updatedActions = { ...prevSelectedActions };

      if (updatedActions[key]) {
        delete updatedActions[key];
      } else {
        updatedActions[key] = true;
      }
      console.log(updatedActions, "4567890");

      return updatedActions;
    });
  };
  const CapFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  console.log(selectedActions, "76ujhiui");
  /* UseEffect */
  useEffect(() => {
    userDispatch();
  }, []);
  const userDispatch = () => {
    dispatch(
      userData({
        query: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
        sortby: sortBy,
        order: order,
      })
    );
    const query = "";
    dispatch(countryList({ query }));
  };

  useEffect(() => {
    if (user_details) {
      reset2(user_details?.user);
      if (user_details?.userSelect != null) {
        setSelectedActions(user_details?.userSelect?.actions);
       
      }
    }
  }, [user_details]);

  useEffect(() => {
    if (selectScreenData && selectScreenData.length > 0) {
      const value = selectScreenData[0]?.actions;
      setUserActions(value || {});
    }
  }, [selectScreenData]);
  useEffect(() => {
    if (addloading === API_STATUS.FULFILLED) {
      toastr.clear();
      toastr.success("User Added Successfully!");
      userDispatch();
      dispatch(clearUserLoadingDatas());
      setState((prevState) => ({
        modal_fullscreen: !prevState.modal_fullscreen,
      }));
    }
    if (updateloading === API_STATUS.FULFILLED) {
      toastr.clear();
      toastr.success("User Updated Successfully!");
      userDispatch();
      dispatch(clearUserLoadingDatas());
      reset2();
      reset();
      setEditState((prevState) => ({
        edit_modal_fullscreen: !prevState.edit_modal_fullscreen,
      }));
    }
    if (deleteloading === API_STATUS.FULFILLED) {
      toastr.clear();
      toastr.success("User Deleted Successfully!");
      userDispatch();
      dispatch(clearUserLoadingDatas());
    }
    if (errorMessage) {
      toastr.clear();
      if (errorMessage.message) toastr.error(errorMessage.message);
      else if (errorMessage.errors) {
        let data = "";
        errorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        toastr.error(data);
      }
      dispatch(clearErrormsg());
    }
  }, [addloading, updateloading, errorMessage, deleteloading]);

  const t_col1 = () => {
    setAccord({ col1: !accord.col1, col2: false, col3: false });
  };
  const t_col2 = () => {
    setAccord({ col1: false, col2: !accord.col2, col3: false });
  };

  const onSubmitHandler = (formData) => {
    console.log({ formData }, "FormData");
    formData.screen_data = selectedActions;
    dispatch(AddUser({ formData }));
    // reset();
  };
  const onEditSubmitHandler = (formData) => {
    const updateID = user_details?.user.id;
    formData.country = selectedCountryVal[0]?.id.toString();
    formData.role_id = selectedRoleVal[0]?.id;
    formData.screen_data = selectedActions;
    delete formData.updated_at;
    delete formData.created_at;
    delete formData.token;
    delete formData.access_token;
    delete formData.created_by;
    delete formData.updated_by;
    delete formData.password;
    delete formData.trash;
    delete formData.status;
    delete formData.profile_url;

    console.log(formData, "formData");
    if (updateID != undefined) {
      dispatch(UpdateUser({ formData, updateID }));
    }
    // reset2();
  };
  useEffect(() => {
    if (userId) {
      const id = userId;
      dispatch(getUserSelectScreen({ id }));
    }
  }, [dispatch, userId]);
  useEffect(() => {
    console.log(errors);
    dispatch(ScreenUserData({}));
  }, [errors]);
  useEffect(() => {
    setSelectedActions({
      "Purchase Payment-act": true,
      "Purchase Report-act": true,
      "Sales Payment-act": true,
      "Sales Report-act": true,
    });
  }, []);
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Position",
        accessor: "position",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Office",
        accessor: "office",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Age",
        accessor: "age",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Start Date",
        accessor: "startdate",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Salary",
        accessor: "salary",
        disableFilters: true,
        filterable: false,
      },
    ],
    []
  );
  const deleteUser = (row) => {
    const deleteID = row.id;
    MySwal.fire({
      title: "Are You Sure to Delete the User?",
      icon: "warning",
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(DeleteUser({ deleteID }));
      }
    });
  };
  const breadcrumbItems = [
    { title: "Tables", link: "/" },
    { title: "Data Tables", link: "#" },
  ];

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-content-between">
            <h2>Users</h2>
            {userActions["Users-add"] && (
              <button
                type="button"
                className="waves-effect waves-light btn btn-primary mb-2"
                onClick={tog_fullscreen}
              >
                Add
              </button>
            )}
          </div>
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="col-md-8 float-right page-title-right">
                    <input
                      type="text"
                      name="search"
                      className="form-control"
                      value={searchQuery}
                      onChange={(e) => {
                        searchData(e);
                      }}
                      placeholder="Search User"
                    />
                  </div>
                </div>
              </div>
              <div className="table-responsive react-table">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} stickyHeader>
                    <TableHead className="table-light table-nowrap">
                      <TableRow>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "name" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("name");
                            }}
                          >
                            Name
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "mobile" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("mobile");
                            }}
                          >
                            Mobile
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "email" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("email");
                            }}
                          >
                            Email
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "status" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("status");
                            }}
                          >
                            Status
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "created_at" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("created_at");
                            }}
                          >
                            Created At
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userDatas &&
                        userDatas.map((row) => (
                          <StyledTableRow>
                            <StyledTableCell align="">
                              {row?.name}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row.mobile}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row.email}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row.status === 1 ? "Active" : "In-active"}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {dayjs(row.created_at).format(
                                "MM-DD-YYYY hh:mm A"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="" className="button-items">
                              {(roleID==2||userActions["Users-edit"]) && (
                                <a
                                  href="javascript:void(0);"
                                  title="Edit"
                                  className="btn btn-primary  "
                                  onClick={(e) => {
                                    edit_tog_fullscreen(row);
                                  }}
                                >
                                  <i className=" ri-ball-pen-line"></i>
                                </a>
                              )}

                              {userActions["Users-delete"] && (
                                <a
                                  href="javascript:void(0);"
                                  title="Delete"
                                  className="btn btn-danger"
                                  onClick={(e) => {
                                    deleteUser(row);
                                  }}
                                >
                                  <i className="ri-delete-bin-line"></i>
                                </a>
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50]}
                          colSpan={8}
                          count={userCount}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
              <Col sm={6} md={4} xl={3} className="mt-4">
                <Modal
                  size="xl"
                  isOpen={state.modal_fullscreen}
                  toggle={tog_fullscreen}
                >
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="modal-header">
                      <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                      >
                        User
                      </h5>
                      <button
                        onClick={() => setState({ modal_fullscreen: false })}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <Row>
                        <Col md="5">
                          <div class="input-container">
                            <input
                              type="text"
                              id="name"
                              name="name"
                              {...register("name")}
                              placeholder=" "
                            />
                            <label for="name" className="required">
                              User Name
                            </label>
                          </div>
                          {errors?.name?.message && (
                            <p className="error">{errors.name.message}</p>
                          )}
                        </Col>

                        <Col md="5">
                          <div class="input-container">
                            <input
                              type="text"
                              id="mobile"
                              name="mobile"
                              {...register("mobile")}
                              placeholder=" "
                            />
                            <label for="mobile" className="required">
                              Mobile
                            </label>
                          </div>
                          {errors?.mobile?.message && (
                            <p className="error">{errors.mobile.message}</p>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="5">
                          <div class="input-container">
                            <input
                              type="text"
                              id="email"
                              name="email"
                              {...register("email")}
                              placeholder=" "
                            />
                            <label for="email" className="required">
                              Email
                            </label>
                          </div>
                          {errors?.email?.message && (
                            <p className="error">{errors.email.message}</p>
                          )}
                        </Col>
                        <Col md="5">
                          <div class="input-container">
                            <input
                              type="password"
                              id="password"
                              name="password"
                              {...register("password")}
                              placeholder=" "
                            />
                            <label for="password" className="required">
                              Password
                            </label>
                          </div>
                          {errors?.password?.message && (
                            <p className="error">{errors.password.message}</p>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="5">
                          <div class="input-container">
                            <Controller
                              name="country"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className="js-states select-control"
                                  placeholder="Country"
                                  options={countries}
                                  {...field}
                                  disabled={false}
                                  dropdownHandle={true}
                                  searchable={true}
                                  labelField={"country_name"}
                                  valueField={"country_code"}
                                  name="country"
                                  // onChange={(value) => {
                                  //   DropdownCustom(value);
                                  // }}
                                />
                              )}
                            />
                            {/* <label for="country">Country</label> */}
                          </div>
                          {errors?.country?.message && (
                            <p className="error">{errors.country.message}</p>
                          )}
                        </Col>
                        <Col md="5">
                          <div class="input-container">
                            <Controller
                              name="role_id"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className="js-states select-control"
                                  placeholder="Role"
                                  options={roles}
                                  {...field}
                                  disabled={false}
                                  dropdownHandle={true}
                                  searchable={true}
                                  labelField={"name"}
                                  valueField={"id"}
                                  name="role_id"
                                />
                              )}
                            />
                            {/* <label for="role_id">Role</label> */}
                          </div>
                          {errors?.role_id?.message && (
                            <p className="error">{errors.role_id.message}</p>
                          )}
                        </Col>
                        {/* <Col md="5">
                          <b>Profile Image</b>
                          <div className="input-group">
                            <input placeholder=" " type="file" id="profile_url" onChange={(e) => handleInput(e, 'profile_url', '')} name="profile_url"{...register("profile_url")} />
                            {errors?.profile_url?.message && (
                              <p className="error">
                                {errors.profile_url.message}
                              </p>
                            )}
                          </div>
                        </Col> */}
                      </Row>
                      <div id="accordion">
                        <Card
                          className="mb-1 shadow-none hide"
                          // style={{ display: "none" }}
                        >
                          <Link
                            to="#"
                            onClick={t_col1}
                            style={{ cursor: "pointer" }}
                            className="text-dark"
                          >
                            <CardHeader id="headingOne">
                              <h6 className="m-0 font-14">
                                Select Screen & Action For User
                                <i
                                  className={
                                    accord.col1
                                      ? "mdi mdi-minus float-end accor-plus-icon"
                                      : "mdi mdi-plus float-end accor-plus-icon"
                                  }
                                ></i>
                              </h6>
                            </CardHeader>
                          </Link>
                          <Collapse isOpen={accord.col1}>
                            <CardBody>
                              <div>
                                {screenData?.map((screen) => (
                                  <div key={screen.id}>
                                    <h3>{screen.screen_name}</h3>
                                    <div>
                                      {Object.keys(screen.actions).map(
                                        (action) => (
                                          <label
                                            key={action}
                                            className="position-static"
                                          >
                                            <input
                                              type="checkbox"
                                              onChange={() =>
                                                handleCheckboxChange(
                                                  screen.screen_name,
                                                  action
                                                )
                                              }
                                            />
                                            {CapFirstLetter(action)}
                                          </label>
                                        )
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </CardBody>
                          </Collapse>
                        </Card>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => setState({ modal_fullscreen: false })}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-primary ">
                        Save changes
                      </button>
                    </div>
                  </form>
                </Modal>
              </Col>
              {/* User Edit */}
              <Col sm={6} md={4} xl={3} className="mt-4">
                <Modal
                  size="xl"
                  isOpen={edit_state.edit_modal_fullscreen}
                  toggle={edit_tog_fullscreen}
                >
                  <form onSubmit={handleSubmit2(onEditSubmitHandler)}>
                    <div className="modal-header">
                      <h5 className="modal-title mt-0" id="EditUserModel">
                        User Edit
                      </h5>
                      <button
                        onClick={() =>
                          setEditState({ edit_modal_fullscreen: false })
                        }
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <Row>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              type="text"
                              id="edit_name"
                              {...register2("name")}
                              placeholder=" "
                              defaultValue={user_details?.user?.name}
                            />
                            <label for="name" className="required">
                              User Name
                            </label>
                          </div>
                          {errors?.name?.message && (
                            <p className="error">{errors.name.message}</p>
                          )}
                        </Col>

                        <Col md="4">
                          <div class="input-container">
                            <input
                              type="text"
                              id="edit_mobile"
                              {...register2("mobile")}
                              placeholder=" "
                              defaultValue={user_details?.user?.mobile}
                            />
                            <label for="mobile" className="required">
                              Mobile
                            </label>
                          </div>
                          {errors?.mobile?.message && (
                            <p className="error">{errors.mobile.message}</p>
                          )}
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              type="text"
                              id="edit_email"
                              {...register2("email")}
                              placeholder=" "
                              defaultValue={user_details?.user?.email}
                            />
                            <label for="email" className="required">
                              Email
                            </label>
                          </div>
                          {errors?.email?.message && (
                            <p className="error">{errors.email.message}</p>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        {/* <Col md="5">
                          <div class="input-container">
                            <input
                              type="password"
                              id="edit_password"                              
                              {...register2("password")}
                              placeholder=" "
                              defaultValue={user_details?.user?.password}
                            />
                            <label for="password" className="required">Password</label>
                          </div>
                          {errors?.password?.message && (
                            <p className="error">
                              {errors.password.message}
                            </p>
                          )}
                        </Col> */}
                      </Row>
                      <Row>
                        <Col md="5">
                          <div class="input-container">
                            <Controller
                              name="edit_country"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className="js-states select-control"
                                  placeholder="Country"
                                  options={countries}
                                  {...field}
                                  disabled={false}
                                  dropdownHandle={true}
                                  values={selectedCountryVal}
                                  searchable={true}
                                  labelField={"country_name"}
                                  valueField={"country_code"}
                                  name="country"
                                  onChange={handleChange}
                                />
                              )}
                            />
                            {/* <label for="country">Country</label> */}
                          </div>
                          {errors?.country?.message && (
                            <p className="error">{errors.country.message}</p>
                          )}
                        </Col>
                        <Col md="5">
                          <div class="input-container">
                            <Controller
                              name="edit_role_id"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className="js-states select-control"
                                  placeholder="Role"
                                  options={roles}
                                  {...field}
                                  disabled={false}
                                  dropdownHandle={true}
                                  values={selectedRoleVal}
                                  searchable={true}
                                  labelField={"name"}
                                  valueField={"id"}
                                  name="role_id"
                                  onChange={handleRoleChange}
                                />
                              )}
                            />
                            {/* <label for="role_id">Role</label> */}
                          </div>
                          {errors?.role_id?.message && (
                            <p className="error">{errors.role_id.message}</p>
                          )}
                        </Col>
                        {/* <Col md="5">
                          <b>Profile Image</b>
                          <div className="input-group">
                            <input placeholder=" " type="file" id="profile_url" onChange={(e) => handleInput(e, 'profile_url', '')} name="profile_url"{...register2("profile_url")} />
                            {errors?.profile_url?.message && (
                              <p className="error">
                                {errors.profile_url.message}
                              </p>
                            )}
                          </div>
                        </Col> */}
                      </Row>
                      <div id="accordion">
                        <Card
                          className="mb-1 shadow-none hide"
                          // style={{ display: "none" }}
                        >
                          <Link
                            to="#"
                            onClick={t_col1}
                            style={{ cursor: "pointer" }}
                            className="text-dark"
                          >
                            <CardHeader id="headingOne">
                              <h6 className="m-0 font-14">
                                Select Screen & Action For User
                                <i
                                  className={
                                    accord.col1
                                      ? "mdi mdi-minus float-end accor-plus-icon"
                                      : "mdi mdi-plus float-end accor-plus-icon"
                                  }
                                ></i>
                              </h6>
                            </CardHeader>
                          </Link>
                          <Collapse isOpen={accord.col1}>
                            <CardBody>
                              <div>
                                {screenData?.map((screen) => (
                                  <div key={screen.id}>
                                    <h3>{screen.screen_name}</h3>
                                    <div>
                                      {Object.keys(screen?.actions).map(
                                        (action) => {
                                          const actionKey = `${screen.screen_name}-${action}`;
                                          return (
                                            <label
                                              key={action}
                                              className="position-static"
                                            >
                                              <input
                                                type="checkbox"
                                                checked={
                                                  selectedActions[actionKey] ||
                                                  false
                                                }
                                                onChange={() =>
                                                  handleCheckboxChange(
                                                    screen?.screen_name,
                                                    action
                                                  )
                                                }
                                              />
                                              {CapFirstLetter(action)}
                                            </label>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </CardBody>
                          </Collapse>
                        </Card>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() =>
                          setEditState({ edit_modal_fullscreen: false })
                        }
                        className="btn btn-secondary "
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-primary ">
                        Update changes
                      </button>
                    </div>
                  </form>
                </Modal>
              </Col>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default UserList;
