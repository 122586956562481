import axios from "axios";
import { getTranslate } from "../services/api";

export const handleTranslate = async (text) => {
  try {
    const response = await getTranslate(text);
    console.log(response, "response");
    return response.data.data.translations[0].translatedText;
  } catch (error) {
    console.error("Error translating text:", error);
    return false;
  }
};

export const handleTranslateNo = async (text) => {
  const arabicNumerals = "٠١٢٣٤٥٦٧٨٩";
  return text
    .toString()
    .split("")
    .map((digit) => arabicNumerals[digit] || digit)
    .join("");
};
