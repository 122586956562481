import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "react-dropdown-select";
import { useNavigate, Link } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { customerValidationSchema } from "../../utils/Validation";
import { read, utils, writeFile } from "xlsx";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  CardHeader,
  Collapse,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
//Import Breadcrumb
import { products } from "../../common/data/ecommerce";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import Breadcrumb from "../Common/Breadcrumb";
import dayjs from "dayjs";
import {
  customerData,
  UpdateCustomer,
  DeleteCustomer,
  customersSelector,
  clearData,
  clearCustUpdateLoading,
  clearCustLoadingDatas,
  clearCustDeleteLoading,
  clearErrormsg,
} from "../../store/reducer/CustomerReducer";
import {
  clearSinvLoadingDatas,
  salesOrderList,
  salesOrderListAll,
  salesinvoiceSelector,
  vatreturndata,
} from "../../store/reducer/SalesInvoiceReducer";
import {
  invoiceStatus,
  paymentStatus,
  zatcaStatus,
} from "../../utils/constants";

const TaxReports = () => {
  const dispatch = useDispatch();
  let [sortBy, setSortBy] = useState("tax_month");
  let [activesortBy, setActiveSortBy] = useState("tax_month");
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("asc");
  let [searchQuery, setSearchQuery] = useState("");
  const [state, setState] = useState({ modal_fullscreen: false });
  const [accord, setAccord] = useState({
    col1: false,
    col2: false,
    col3: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(customerValidationSchema),
  });
  const {
    customerDatas,
    customerCount,
    loading,
    addloading,
    deleteloading,
    updateloading,
    errorMessage,
  } = useSelector(customersSelector);
  const { salesOrders, salesOrdersAll, vatReturnDetails } =
    useSelector(salesinvoiceSelector);
  console.log(salesOrders, salesOrdersAll, "45678");
  const navigate = useNavigate();
  const [stockReport, setStockReport] = useState({
    from_date: "",
    to_date: "",
    id: 0,
  });
  const [value, setValue] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [quotationXcelDetails, setQuotationXcelDetails] = useState([]);

  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("");
  const [selectedZatcaStatus, setSelectedZatcaStatus] = useState("");
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState("");
  const [quotationDetails, setQuotationDetails] = useState([]);
  console.log(value, selectedCustomer, selectedPaymentStatus, "657ui");
  let totBillAmt = 0;
  for (let index = 0; index < quotationDetails.length; index++) {
    const element = quotationDetails[index];
    totBillAmt += element.pay_amount;
  }
  console.log(quotationDetails, "quotationDetails");

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
  };

  const showButton = (formData) => {
    // console.log(formData.target, "5yuio")
    // const type = formData.type;
    // setType(type);
    // setState({
    //   from_date: formData.from_date,
    //   to_date: formData.to_date,
    // });
    if (selectedInvoiceStatus == "" && selectedZatcaStatus != "") {
      toastr.error("select invoice status!");
    } else {
      dispatch(
        salesOrderList({
          query: searchQuery,
          page: page + 1,
          limit: rowsPerPage,
          sortby: sortBy,
          order: order,
          from_date: formData.from_date,
          to_date: formData.to_date,
          customer_id: selectedCustomer ?? "",
          payment_status: selectedPaymentStatus ?? "",
          invoice_status: selectedInvoiceStatus ?? "",
          zatca_status: selectedZatcaStatus ?? "",
        })
      );
      dispatch(
        salesOrderListAll({
          query: searchQuery,
          page: page + 1,
          limit: 0,
          sortby: sortBy,
          order: order,
          from_date: formData.from_date,
          to_date: formData.to_date,
          customer_id: selectedCustomer ?? "",
          payment_status: selectedPaymentStatus ?? "",
          invoice_status: selectedInvoiceStatus ?? "",
          zatca_status: selectedZatcaStatus ?? "",
        })
      );
    }
  };

  const showButton1 = () => {
    setQuotationDetails([
      {
        id: 1,
        quotation_no: 1211,
        customer_name: "Quinton",
        quotation_date: "01-11-2022",
        rfq_ref_date: "01-11-2022",
        bill_amount: 1645.0,
        rfq_doc_no: 1822,
        notes: "",
        status: 1,
      },
    ]);
  };
  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const handleCustomerChange = (selectedOption) => {
    console.log(selectedOption, "werty");
    setSelectedCustomer(selectedOption[0]?.id);
  };

  const handlePaymentStatusChange = (selectedOption) => {
    setSelectedPaymentStatus(selectedOption[0].value);
  };
  const handleInvoiceStatusChange = (selectedOption) => {
    setSelectedInvoiceStatus(selectedOption[0].value);
  };
  const handleZatcaStatusChange = (selectedOption) => {
    setSelectedZatcaStatus(selectedOption[0].value);
  };
  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const searchData = (e) => {
    searchQuery = e.target.value;
    setSearchQuery(e.target.value);
    setPage(0);
  };

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
  };
  useEffect(() => {
    dispatch(
      vatreturndata({
        query: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
        sortby: sortBy,
        order: order,
        invoice: 0,
      })
    );
    dispatch(customerData({}));
    dispatch(clearSinvLoadingDatas());
  }, []);

  useEffect(() => {
    if (salesOrdersAll) {
      const xcelData = salesOrdersAll?.rows?.map((ele) => {
        const {
          status,
          id,
          customer_id,
          invoice_date,
          tax,
          round_amount,
          invoice_type,
          payment_mode,
          customer_iban,
          qr,
          invoice_hash,
          payment_status,
          payment_balance,
          invoice_id,
          trash,
          created_at,
          updated_at,
          customer,
          sales_invoice_details,
          credit_debit_type,
          invoice_main_id,
          credit_note_no,
          credit_reason,
          ...rest
        } = ele;

        return {
          customer: customer.customer_name,
          contact: customer.contact_number,
          invoice_date: dayjs(ele?.invoice_date).format("YYYY-MM-DD HH:mm"),
          ...rest,
          total_paid: ele.total - payment_balance,
          sell_due: payment_balance,
          credit_or_debit_note_no: ele.credit_note_no,
          credit_or_debit_reason: credit_reason,
          invoice_status:
            ele.status === 0
              ? "Draft"
              : ele.status === 1
              ? "Proforma"
              : ele.status === 2 || ele.status === 3 || ele.status === 4
              ? "Invoice"
              : ele?.credit_debit_type == 381
              ? "Credit Note"
              : "Debit Note",
          zatca_status:
            ele.status == 3
              ? "Validated"
              : ele.status === 4
              ? "Error"
              : ele.status === 6
              ? "Validated"
              : ele?.status == 7
              ? "Error"
              : "-",
          payment_status:
            ele?.payment_status == 0
              ? "Unpaid"
              : ele?.payment_status == 2
              ? "Partially paid"
              : "Paid",
        };
      });
      setQuotationXcelDetails(xcelData);
    }
    console.log(salesOrdersAll, "salesOrders");
  }, [salesOrdersAll]);

  const t_col1 = () => {
    setAccord({ col1: !accord.col1, col2: false, col3: false });
  };
  const t_col2 = () => {
    setAccord({ col1: false, col2: !accord.col2, col3: false });
  };

  const onSubmitHandler = (formData) => {
    console.log({ formData });
    //dispatch(signInScheduler({ formData }));
    // reset();
  };
  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const breadcrumbItems = [
    { title: "Tables", link: "/" },
    { title: "Data Tables", link: "#" },
  ];

  const exportFile = useCallback(() => {
    const ws = utils.json_to_sheet(quotationXcelDetails);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    const keys = Object.keys(ws);
    const CaptKeys = keys.slice(keys.indexOf("A1"), -1);
    CaptKeys.map((ele) => {
      ws[ele].v = ws[ele]?.v?.toLocaleUpperCase().split("_").join(" ");
    });
    writeFile(wb, `Sales Invoice.xlsx`);
  }, [quotationXcelDetails]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div>
            <h2>VAT Return Report</h2>
          </div>
          <Card>
            <CardBody>
              {/* <div className="row">
                <div className="col-md-12 d-flex">
                  <div className="col-md-3">
                    <div class="input-container">
                      <Select
                        placeholder="Customer"
                        className="js-states select-control"
                        options={customerDatas ? customerDatas : []}
                        disabled={false}
                        dropdownHandle={true}
                        searchable={true}
                        labelField={"customer_name"}
                        valueField={"id"}
                        name="customer_name"
                        value={selectedCustomer}
                        onChange={handleCustomerChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="input-container">
                      <Select
                        placeholder="Invoice Status"
                        className="js-states select-control"
                        options={invoiceStatus ? invoiceStatus : []}
                        disabled={false}
                        dropdownHandle={true}
                        searchable={true}
                        labelField={"mode"}
                        valueField={"id"}
                        name="invoice_status"
                        value={selectedInvoiceStatus}
                        onChange={handleInvoiceStatusChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="input-container">
                      <Select
                        placeholder="Zatca Status"
                        className="js-states select-control"
                        options={zatcaStatus ? zatcaStatus : []}
                        disabled={false}
                        dropdownHandle={true}
                        searchable={true}
                        labelField={"mode"}
                        valueField={"id"}
                        name="zatca_status"
                        value={selectedZatcaStatus}
                        onChange={handleZatcaStatusChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="input-container">
                      <Select
                        placeholder="Payment Status"
                        className="js-states select-control"
                        options={paymentStatus ? paymentStatus : []}
                        disabled={false}
                        dropdownHandle={true}
                        searchable={true}
                        labelField={"mode"}
                        valueField={"id"}
                        name="payment_status"
                        value={selectedPaymentStatus}
                        onChange={handlePaymentStatusChange}
                      />
                    </div>
                  </div>
                  </div>
                <div className="col-md-12 d-flex">
                  <div className="col-md-3">
                    <div class="input-container">
                      <DateRangePicker
                        value={value}
                        onChange={(newValue) => setValue(newValue)}
                        placeholder="Select Date Range"
                      />
                    </div>
                  </div>
                  <div className="col-md-1 d-flex justify-content-start align-items-center">
                    <Button color="primary" onClick={showButton}>
                      Filter
                    </Button>
                  </div>

                  <div className="col-md-1  d-flex justify-content-start align-items-center">
                    <Button color="primary" onClick={exportFile}>
                      Export xlsx File
                    </Button>
                  </div>
                </div>
              </div> */}
              <div className="table-responsive react-table">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} stickyHeader>
                    <TableHead className="table-light table-nowrap">
                      <TableRow>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "quotation_no" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("quotation_no");
                            }}
                          >
                            Status
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "customer_name" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("customer_name");
                            }}
                          >
                            Tax Return
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "quotation_no" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("quotation_no");
                            }}
                          >
                            Filed On
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "quotation_no" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("quotation_no");
                            }}
                          >
                            Total Sales
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "quotation_no" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("quotation_no");
                            }}
                          >
                            Sales VAT
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "quotation_no" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("quotation_no");
                            }}
                          >
                            Total Purchase
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "quotation_no" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("quotation_no");
                            }}
                          >
                            Purchase VAT
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "quotation_no" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("quotation_no");
                            }}
                          >
                            Returns
                          </TableSortLabel>
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {vatReturnDetails &&
                        vatReturnDetails?.map((row) => (
                          <StyledTableRow>
                            <StyledTableCell align="">
                              <Link to={'/viewTaxreturns/'+dayjs(row?.tax_month).format("MM-YYYY")}>{row?.status == 0 ? "Unfiled" : "Filed"}</Link>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {dayjs(row?.tax_month).format("MMMM-YYYY")}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row?.file_date}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50]}
                          colSpan={12}
                          count={vatReturnDetails?.length ?? 0}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default TaxReports;
