import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  productsList,
  productUpdate,
  productDelete,
  productEdit,
  productAdd,
} from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from "./../../utils/encrypt&decrypt";
import { secretKey } from "../../services/config";
import alertmessage from "./../../utils/alert";
import toastr from 'toastr';
const namespace = 'products'

const initialState = {
  data: null,
  status: 'idle',
  error: null,
  productCount:null,
  product_details:null,
  productDatas:null
};

export const productData = createAsyncThunk(
  `${namespace}/productData`,
  async (
    { query = "", page = 0, limit = 0, sortby = "description", order = "asc" },
    { rejectWithValue, dispatch }
  ) => {
    try {
      console.log("interdata123");
      //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
      const data = await productsList(query, page, limit, sortby, order);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);
export const EditProduct = createAsyncThunk(
  `${namespace}/EditProduct`,
  async ({ productId }, { rejectWithValue, dispatch }) => {
    try {          
      // let payload = EncryptDecrypt.encryptdata(productId, secretKey);
      const data = await productEdit(productId);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);
export const UpdateProduct = createAsyncThunk(
  `${namespace}/UpdateProduct`,
  async ({ formData, productId }, { rejectWithValue, dispatch }) => {
    try {
      console.log(productId, "updateID");
      let payload = EncryptDecrypt.encryptdata(formData, secretKey);
      console.log(formData,payload,"Pay");
      const data = await productUpdate({ payload: payload }, productId);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);
export const AddProduct = createAsyncThunk(
  `${namespace}/AddProduct`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "interdata");
      let payload = EncryptDecrypt.encryptdata(formData, secretKey);
      const data = await productAdd({ payload: payload });
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);
export const DeleteProduct = createAsyncThunk(
  `${namespace}/DeleteProduct`,
  async ({ deleteID }, { rejectWithValue, dispatch }) => {
    try {
      console.log(deleteID, "interdata");
      const data = await productDelete(deleteID);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);


const productsSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
    clearProdLoadingDatas: (state) => {
      state.loading = "initial";
      state.addloadings = "initial";
      state.deleteloading = "initial";
      state.updateloading = "initial";
      state.product_details=null
    },
    clearProdUpdateLoading: (state) => {
      state.updateloading = "initial";
    },
    clearProdDeleteLoading: (state) => {
      state.deleteloading = "initial";
    },
    clearErrormsg: (state) => {
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
      console.log(builder,'payload');
      builder.addCase(productData.pending, (state) => {
      state.loading = API_STATUS.PENDING;
    });
    builder.addCase(productData.fulfilled, (state, { payload }) => {
      console.log(payload,'payload');
      state.loading = API_STATUS.FULFILLED;
      
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );
      state.productCount = payloaddatas?.count;
      state.productDatas = payloaddatas?.data;
    });
    builder.addCase(productData.rejected, (state, action) => {
      state.loading = API_STATUS.REJECTED;
      if (action?.payload?.data?.auth === "deleted") {
        alertmessage.sweetalert(action?.payload?.data?.message);
      }
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(AddProduct.pending, (state) => {
      state.addloadings = API_STATUS.PENDING;
    });
    builder.addCase(AddProduct.fulfilled, (state, { payload }) => {
      state.addloadings = API_STATUS.FULFILLED;
    });
    builder.addCase(AddProduct.rejected, (state, action) => {
      state.addloadings = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder
    .addCase(EditProduct.pending, (state) => {
      state.status = 'loading'
    }); 
    builder.addCase(EditProduct.fulfilled, (state, { payload }) => {
      state.loading = API_STATUS.FULFILLED;      
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );      
      state.product_details = payloaddatas?.data;
    });
  builder.addCase(EditProduct.rejected, (state, action) => {
    state.status = 'failed';
    state.error = action.payload;
  });
    builder.addCase(UpdateProduct.pending, (state) => {
      state.updateloading = API_STATUS.PENDING;
    });
    builder.addCase(UpdateProduct.fulfilled, (state, { payload }) => {
      state.updateloading = API_STATUS.FULFILLED;
    });
    builder.addCase(UpdateProduct.rejected, (state, action) => {
      state.updateloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(DeleteProduct.pending, (state) => {
      state.deleteloading = API_STATUS.PENDING;
    });
    builder.addCase(DeleteProduct.fulfilled, (state, { payload }) => {
      state.deleteloading = API_STATUS.FULFILLED;
    });
    builder.addCase(DeleteProduct.rejected, (state, action) => {
      state.deleteloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
  },
});

export const {
  clearData,
  clearProdUpdateLoading,
  clearProdLoadingDatas,
  clearProdDeleteLoading,
  clearErrormsg,
} = productsSlice.actions;

export const productsSelector = (state) => state.products;

export default productsSlice.reducer;
