import {
  BreadcrumbItem,
  Breadcrumb,
  Container,
  Card,
  Row,
  Col,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Popover, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import {
  EditVendor,
  UpdateVendor,
  clearErrormsg,
  clearVendorLoadingDatas,
  clearVendorUpdateLoading,
  createVendor,
  vendorData,
  vendorsSelector,
} from "../../store/reducer/VendorReducer";
import Swal from "sweetalert2";
import { API_STATUS, paymentTypewithcheck } from "../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-dropdown-select";
import {
  countryList,
  currencyList,
  usersSelector,
} from "../../store/reducer/UserReducer";
import { vendorCreate } from "../../utils/Validation";
import { handleTranslate } from "../../utils/translate";

const VendorAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { vendorId } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeTab, setActiveTab] = useState("5");
  const [autoGen, setautoGen] = useState(false);
  const [isInActive, setisInActive] = useState(false);
  const [allowCash, setallowCash] = useState(false);
  const [selectedCountryVal, setCountry] = useState([]);
  const [selectedCurrencyVal, setCurrency] = useState([]);
  const {
    error409,
    createVendorLoad,
    vendorDatas,
    editloading,
    updateloading,
    vendor_details,
  } = useSelector(vendorsSelector);
  const { countries, currencies } = useSelector(usersSelector);
  console.log(countries, updateloading, "6yuhj");
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  console.log(vendorId, "vendorId");

  const open = Boolean(anchorEl);
  const _ = require("lodash");

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(vendorCreate),
  });

  console.log(errors, "errors");

  const handleNext = () => {
    setActiveTab("6");
  };

  const Translate = async (value, field) => {
    const translated_data = await handleTranslate(value);
    setValue("ar_" + field, translated_data);
  };
  const onSubmitHandler = (formData) => {
    console.log(formData, "chlk formdata");
    delete formData.vendor_name_arabic;
    formData.is_inactive = isInActive;
    formData.auto_gen_gl = autoGen;
    formData.allow_cash = allowCash;
    formData.country_name = selectedCountryVal[0]?.id ?? 183;
    formData.currency_type = selectedCurrencyVal[0]?.id ?? 183;
    console.log(formData, "ioishkjk");
    if (!vendorId) dispatch(createVendor({ formData }));
    else dispatch(UpdateVendor({ formData, vendorId }));
  };

  const handleChange = (event) => {
    setCountry(event);
    setCurrency(currencies?.filter((curr) => curr?.id == event[0]?.id));
  };
  const handleCurrencyChange = (event) => {
    setCurrency(event);
    setCountry(
      countries?.filter((country) => country?.id == event[0]?.country_id)
    );
  };
  const SelectedCountry = (countrId) => {
    return _.filter(countries, ["id", countrId]);
  };
  const SelectedCurrency = (currencyId) => {
    return _.filter(currencies, ["id", currencyId]);
  };

  useEffect(() => {
    if (vendorId && vendor_details) {
      console.log(vendor_details, "vendorDatas");
      reset(vendor_details);
      setCountry(SelectedCountry(vendor_details?.country_name));
      setCurrency(SelectedCurrency(vendor_details?.currency_type));
    }

    return () => {
      //
    };
  }, [vendor_details]);

  useEffect(() => {
    if (error409?.status == 409) {
      toastr.error(`${error409?.data?.message}`);
      dispatch(clearErrormsg());
    }
    if (createVendorLoad === API_STATUS.FULFILLED) {
      toastr.success("Added Successfully");
      dispatch(clearVendorLoadingDatas());
      navigate(-1);
    }
    if (updateloading === API_STATUS.FULFILLED) {
      toastr.success("Updated Successfully");
      dispatch(clearVendorUpdateLoading());
      navigate(-1);
    }
  }, [error409, createVendorLoad, updateloading]);

  useEffect(() => {
    if (vendorId) dispatch(EditVendor({ vendorId }));
  }, [vendorId]);

  useEffect(() => {
    dispatch(currencyList({ query: "" }));
    dispatch(countryList({ query: "" }));
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <div className="row">
          <div className="col-md-6">
            <h4>
              <Breadcrumb>
                <BreadcrumbItem>
                  {vendorId !== undefined ? "Vendor / Edit" : "Vendor / Add "}
                </BreadcrumbItem>
              </Breadcrumb>
            </h4>
          </div>
        </div>
        <Row>
          <Card>
            <CardBody>
              {/* <Nav pills className="navtab-bg nav-justified">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "5",
                    })}
                    onClick={() => {
                      toggleTab("5");
                    }}
                  >
                    Vendor Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "6",
                    })}
                    onClick={() => {
                      toggleTab("6");
                    }}
                  >
                    About
                  </NavLink>
                </NavItem>
              </Nav> */}
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="5">
                          <div>
                            <Row>
                              <Col md={4}>
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="vendor_name"
                                    name="vendor_name"
                                    {...register("vendor_name")}
                                    onBlur={(e) => {
                                      Translate(e.target.value, "vendor_name");
                                    }}
                                  />
                                  <label for="vendor_name" className="required">Vendor Name</label>
                                </div>
                                {errors?.vendor_name?.message && (
                                  <p className="error">
                                    {errors.vendor_name.message}
                                  </p>
                                )}
                              </Col>
                              <Col md={4}>
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="ar_vendor_name"
                                    name="ar_vendor_name"
                                    style={{ direction: "rtl" }}
                                    {...register("ar_vendor_name")}
                                  />
                                  <label for="ar_vendor_name" className="required">
                                    Vendor Name Arabic
                                  </label>
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="contact_person"
                                    name="contact_person"
                                    {...register("contact_person")}
                                  />
                                  <label for="contact_person">
                                    Contact Person
                                  </label>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="contact_number"
                                    name="contact_number"
                                    {...register("contact_number")}
                                  />
                                  <label for="contact_number">
                                    Contact Number
                                  </label>
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="fax"
                                    name="fax"
                                    {...register("fax")}
                                  />
                                  <label for="fax">Fax Number</label>
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="contact_email"
                                    name="contact_email"
                                    {...register("contact_email")}
                                  />
                                  <label for="contact_email">Email</label>
                                </div>
                              </Col>
                              {/* <Col md={3}>
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="web_address"
                                    name="web_address"
                                    {...register("web_address")}
                                  />
                                  <label for="web_address">Website</label>
                                </div>
                              </Col> */}
                            </Row>
                            <Row>
                              <Col md={4}>
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="phone"
                                    name="phone"
                                    {...register("phone")}
                                  />
                                  <label for="phone">Phone Number</label>
                                </div>
                              </Col>
                              <Col md={4}>
                                {/* <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="country_name"
                                    name="country_name"
                                    {...register("country_name")}
                                  />
                                  <label for="country_name">Country</label>
                                </div> */}
                                <div className="input-container">
                                  <Controller
                                    name="country"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        className="js-states select-control"
                                        placeholder={"Country"}
                                        options={countries}
                                        {...field}
                                        disabled={false}
                                        dropdownHandle={true}
                                        // values={settingTz}
                                        searchable={true}
                                        searchBy="country_name"
                                        labelField={"country_name"}
                                        valueField={"id"}
                                        name="country_name"
                                        values={selectedCountryVal??[]}
                                        // onChange={(value) => {
                                        //   setValue("country_name", value[0].id);
                                        // }}
                                        onChange={handleChange}

                                      />
                                    )}
                                  />
                                </div>
                                {errors?.country && (
                                  <p className="error">Country is Required</p>
                                )}
                              </Col>
                              <Col md={4}>
                                {/* <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="currency_type"
                                    name="currency_type"
                                    {...register("currency_type")}
                                  />
                                  <label for="currency_type">Currency</label>
                                </div> */}
                                <div className="input-container">
                                  <Controller
                                    name="currency"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        className="js-states select-control"
                                        placeholder="Currency"
                                        options={currencies}
                                        {...field}
                                        // defaultValue={company_details?.currency ? company_details?.currency : []}
                                        disabled={false}
                                        dropdownHandle={true}
                                        // values={Currency}
                                        searchable={true}
                                        labelField={"currency_name"}
                                        valueField={"currency_code"}
                                        values={selectedCurrencyVal??[]}
                                        searchBy="currency_name"
                                        name="currency_type"
                                  onChange={handleCurrencyChange}

                                        // onChange={handleCurrency}
                                        // onChange={(value) => {
                                        //   setValue(
                                        //     "currency_type",
                                        //     value[0].id
                                        //   );
                                        // }}
                                      />
                                    )}
                                  />
                                </div>
                                {/* {errors?.currency && (
                                  <p className="error">Currency is Currency</p>
                                )} */}
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="cr_no"
                                    name="cr_no"
                                    {...register("cr_no")}
                                  />
                                  <label for="cr_no">CR Number</label>
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="vat_number"
                                    name="vat_number"
                                    {...register("vat_number")}
                                  />
                                  <label for="vat_number">VAT Number</label>
                                </div>
                                {errors?.vat_number?.message && (
                            <p className="error">
                              {errors.vat_number.message}
                            </p>
                          )}
                              </Col>
                              <Col md={4}>
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="zip_code"
                                    name="zip_code"
                                    {...register("zip_code")}
                                  />
                                  <label for="zip_code">Zip Code</label>
                                </div>
                              </Col>
                              <Col md={4} className="align-self-end">
                                <div className="input-container">
                                  <textarea
                                    placeholder=" "
                                    id="net_amount"
                                    rows={3}
                                    disabled={false}
                                    // id="name"
                                    name="address"
                                    {...register("address")}
                                  />
                                  <label for="address">Address</label>
                                </div>
                              </Col>
                              {/* <Col md={3} className="align-self-end">
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="pay_payments_from"
                                    name="pay_payments_from"
                                    {...register("pay_payments_from")}
                                  />
                                  <label for="pay_payments_from">
                                    Pay all payments from
                                  </label>
                                </div>
                              </Col>
                              <Col md={3} className="align-self-end">
                                <div className="input-container">
                                  <Controller
                                    name="payment_method"
                                    control={control}
                                    render={({ field, ref }) => (
                                      <Select
                                        className="js-states select-control"
                                        placeholder={"Payment Method"}
                                        options={paymentTypewithcheck}
                                        {...field}
                                        ref={ref}
                                        disabled={false}
                                        dropdownHandle={true}
                                        // values={settingTz}
                                        searchable={true}
                                        labelField={"mode"}
                                        valueField={"id"}
                                        name="payment_method"
                                        onChange={handleChange}
                                      />
                                    )}
                                    {...register("payment_method")}
                                  /> */}
                              {/* <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="payment_method"
                                    name="payment_method"
                                    {...register("payment_method")}
                                  />
                                  <label for="payment_method">
                                    Payment Method
                                  </label> */}
                              {/* </div>
                              </Col> */}
                              {/* <Col md={3} className="align-self-end">
                                <div className="input-container">
                                  <label
                                    style={{
                                      paddingLeft: "20px",
                                      marginTop: "4px",
                                      marginRight: "10px",
                                    }}
                                    className="label"
                                    for="allow_cash_pay"
                                  >
                                    Allow Cash Pay{" "}
                                    <Typography
                                      aria-owns={
                                        open ? "mouse-over-popover" : undefined
                                      }
                                      aria-haspopup="true"
                                      onMouseEnter={handlePopoverOpen}
                                      onMouseLeave={handlePopoverClose}
                                      className="d-inline"
                                    >
                                      <i class="ri-question-line"></i>
                                    </Typography>
                                  </label>
                                  <input
                                    placeholder=" "
                                    className="form-check-input"
                                    type="checkbox"
                                    id="allow_cash_pay"
                                    onChange={(e) =>
                                      setallowCash(e.target.checked)
                                    }
                                    name="allow_cash_pay"
                                    checked={allowCash}
                                    // {...register("zatca_validation")}
                                  />
                                </div>
                                <Popover
                                  id="mouse-over-popover"
                                  sx={{
                                    pointerEvents: "none",
                                  }}
                                  open={open}
                                  anchorEl={anchorEl}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                  onClose={handlePopoverClose}
                                  disableRestoreFocus
                                >
                                  <Typography sx={{ p: 1 }}>
                                    <div style={{ maxWidth: "400px" }}>
                                      By Selecting this checkbox, you must fill
                                      some required fields which was not
                                      mandatory yet.
                                    </div>
                                  </Typography>
                                </Popover>
                              </Col> */}
                            </Row>
                          </div>
                        </TabPane>
                        {/* <TabPane tabId="6">
                          <div>
                            <Row>
                              <Col md={3}>
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="vendor_type"
                                    name="vendor_type"
                                    {...register("vendor_type")}
                                  />
                                  <label for="vendor_type">Vendor Type</label>
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="partner_type"
                                    name="partner_type"
                                    {...register("partner_type")}
                                  />
                                  <label for="partner_type">Partner Type</label>
                                </div>
                              </Col>
                            
                            </Row>
                            <Row>
                              <Col md={3}>
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="gl_account"
                                    name="gl_account"
                                    {...register("gl_account")}
                                  />
                                  <label for="gl_account">GL Account</label>
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="advanced_acc_mapped_to"
                                    name="advanced_acc_mapped_to"
                                    {...register("advanced_acc_mapped_to")}
                                  />
                                  <label for="advanced_acc_mapped_to">
                                    Advance Account Mapped To
                                  </label>
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="vat_code"
                                    name="vat_code"
                                    {...register("vat_code")}
                                  />
                                  <label for="vat_code">VAT Code</label>
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="avg_delivery_delay"
                                    name="avg_delivery_delay"
                                    {...register("avg_delivery_delay")}
                                  />
                                  <label for="avg_delivery_delay">
                                    Average Delevery Delay (in days)
                                  </label>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="invoice_due_days"
                                    name="invoice_due_days"
                                    {...register("invoice_due_days")}
                                  />
                                  <label for="invoice_due_days">
                                    Invoice Due Days
                                  </label>
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="input-container">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    disabled={false}
                                    id="remainder"
                                    name="remainder"
                                    {...register("remainder")}
                                  />
                                  <label for="remainder">Remainder</label>
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="input-container">
                                  <label
                                    style={{
                                      paddingLeft: "20px",
                                      marginTop: "4px",
                                      marginRight: "10px",
                                    }}
                                    className="label"
                                    for="auto_generate_gl"
                                  >
                                    Auto Generate GL Account{" "}
                                    <Typography
                                      aria-owns={
                                        open ? "mouse-over-popover" : undefined
                                      }
                                      aria-haspopup="true"
                                      onMouseEnter={handlePopoverOpen}
                                      onMouseLeave={handlePopoverClose}
                                      className="d-inline"
                                    >
                                      <i class="ri-question-line"></i>
                                    </Typography>
                                  </label>
                                  <input
                                    placeholder=" "
                                    className="form-check-input"
                                    type="checkbox"
                                    id="auto_generate_gl"
                                    onChange={(e) =>
                                      setautoGen(e.target.checked)
                                    }
                                    name="auto_generate_gl"
                                    checked={autoGen}
                                    // {...register("zatca_validation")}
                                  />
                                </div>
                                <Popover
                                  id="mouse-over-popover"
                                  sx={{
                                    pointerEvents: "none",
                                  }}
                                  open={open}
                                  anchorEl={anchorEl}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                  onClose={handlePopoverClose}
                                  disableRestoreFocus
                                >
                                  <Typography sx={{ p: 1 }}>
                                    <div style={{ maxWidth: "400px" }}>
                                      By Selecting this checkbox, you must fill
                                      some required fields which was not
                                      mandatory yet.
                                    </div>
                                  </Typography>
                                </Popover>
                              </Col>
                              <Col md={3}>
                                <div className="input-container">
                                  <label
                                    style={{
                                      paddingLeft: "20px",
                                      marginTop: "4px",
                                      marginRight: "10px",
                                    }}
                                    className="label"
                                    for="inactive"
                                  >
                                    Inactive{" "}
                                    <Typography
                                      aria-owns={
                                        open ? "mouse-over-popover" : undefined
                                      }
                                      aria-haspopup="true"
                                      onMouseEnter={handlePopoverOpen}
                                      onMouseLeave={handlePopoverClose}
                                      className="d-inline"
                                    >
                                      <i class="ri-question-line"></i>
                                    </Typography>
                                  </label>
                                  <input
                                    placeholder=" "
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inactive"
                                    onChange={(e) =>
                                      setisInActive(e.target.checked)
                                    }
                                    name="inactive"
                                    checked={isInActive}
                                    // {...register("zatca_validation")}
                                  />
                                </div>
                                <Popover
                                  id="mouse-over-popover"
                                  sx={{
                                    pointerEvents: "none",
                                  }}
                                  open={open}
                                  anchorEl={anchorEl}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                  onClose={handlePopoverClose}
                                  disableRestoreFocus
                                >
                                  <Typography sx={{ p: 1 }}>
                                    <div style={{ maxWidth: "400px" }}>
                                      By Selecting this checkbox, you must fill
                                      some required fields which was not
                                      mandatory yet.
                                    </div>
                                  </Typography>
                                </Popover>
                              </Col>
                            </Row>
                          </div>
                        </TabPane> */}
                      </TabContent>
                      {/* <div
                        className={
                          activeTab != 5
                            ? "d-flex justify-content-between"
                            : "float-right"
                        }
                      >
                        <div
                          className={activeTab != 5 ? "" : "visually-hidden"}
                        >
                          <Button onClick={(e) => setActiveTab("5")}>
                            Previous
                          </Button>
                        </div>
                        <div>
                          <Button
                            onClick={handleNext}
                            type={activeTab != 5 ? "submit" : "button"}
                          >
                            {activeTab == 5 ? "Next" : "Submit"}
                          </Button>
                        </div>
                      </div> */}
                      <Button
                        className="float-right"
                        color="primary"
                        type="submit"
                      >
                        {" "}
                        Submit
                      </Button>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </CardBody>
          </Card>
        </Row>
      </Container>
    </div>
  );
};

export default VendorAdd;
